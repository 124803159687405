import { Button, margin, marginRight, unit, breakpoints, TableRow, TableHeadCell, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const LoaderContainer = styled.div `
  ${margin(4, 0)};
  display: flex;
  justify-content: center;
`;
export const TooltipButton = styled(Button) `
  width: ${unit(5)};
  height: ${unit(5)};
  color: ${themeGet('colors.gray.600')};
  ${marginRight(4)};
`;
export const PaginationContainer = styled.div `
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;

  ${breakpoints.md} {
    justify-content: flex-start;
  }
`;
export const HiddenTableRow = styled(TableRow) `
  height: 0 !important;
`;
export const HiddenTableHeadCell = styled(TableHeadCell) `
  border: none !important;
`;
