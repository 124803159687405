import { t } from '@lingui/macro';
import { List, ListHeader, IconButton, ListItem, Avatar, AvatarLetter, Typography, Box } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link } from 'react-router-dom';
import { useAccount, useAccounts, SSORestricted, activeAccount } from 'shared/domains/users';
export function CompanySelector() {
    var _a;
    const account = useAccount();
    const accounts = useAccounts();
    const activeCompany = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const filteredAccounts = accounts;
    const handleSelectAccount = (selection) => {
        const { id, company } = selection;
        const { marketCode } = company;
        activeAccount.set(id);
        window.location.href = `${window.location.origin}/${marketCode}`;
    };
    return (<List>
      <ListHeader title={t({
            message: 'My companies',
            comment: 'User menu: my companies list header text',
        })} actions={<SSORestricted>
            <Link to="/add-company">
              <IconButton $round appearance="ghost" size="small" aria-label={t({ message: 'Add company', comment: 'User menu: add company button' })}>
                <Falcon icon="plus"/>
              </IconButton>
            </Link>
          </SSORestricted>}/>
      {filteredAccounts.map(({ id, company }) => (<ListItem key={id} $selected={activeCompany === company.id} onClick={() => handleSelectAccount({ id, company })}>
          <Avatar size="sm" appearance="light">
            <AvatarLetter>{company.name[0]}</AvatarLetter>
          </Avatar>
          <Box display="flex" flexDirection="column">
            <Typography $appearance="300" $fontSize="medium">
              {company.name}
            </Typography>
          </Box>
        </ListItem>))}
    </List>);
}
