var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useFileSystemAcls } from 'app/domains/filesystem';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { createFileSystemAclsMutation } from './createFileSystemAclsMutation';
export function useCreateFileSystemAcls(params) {
    const successMessage = t({
        message: 'Access successfully given',
        comment: 'add access to folder or document toast success message',
    });
    const errorMessage = t({
        message: 'Something went wrong, try again.',
        comment: 'add access to folder or document toast error message',
    });
    const { entryId = '' } = params;
    const [mutate, { loading, error }] = useMutation(createFileSystemAclsMutation);
    const { addToast } = useToast();
    const account = useAccount();
    const { companyId } = account.data;
    const { refetch, usersWithoutAccess, teamsWithoutAccess } = useFileSystemAcls({ entryId });
    const createFileSystemAcls = (input) => __awaiter(this, void 0, void 0, function* () {
        const { actions, userIds = [], teamIds = [] } = input;
        const userAclsToUpdate = userIds
            .filter((userId) => usersWithoutAccess.some((user) => user.id === userId && user.hasTraverseAccess))
            .map((userId) => {
            const existingUser = usersWithoutAccess.find((user) => user.id === userId);
            return {
                id: (existingUser === null || existingUser === void 0 ? void 0 : existingUser.aclId) || '',
                entryId,
                actions,
            };
        });
        const userAclsToCreate = userIds
            .filter((userId) => !usersWithoutAccess.some((user) => user.id === userId && user.hasTraverseAccess))
            .map((userId) => ({ userId, entryId, actions, companyId }));
        const teamAclsToUpdate = teamIds
            .filter((teamId) => teamsWithoutAccess.some((team) => team.id === teamId && team.hasTraverseAccess))
            .map((teamId) => {
            const existingTeam = teamsWithoutAccess.find((team) => team.id === teamId);
            return {
                id: (existingTeam === null || existingTeam === void 0 ? void 0 : existingTeam.aclId) || '',
                entryId,
                actions,
            };
        });
        const teamAclsToCreate = teamIds
            .filter((teamId) => !teamsWithoutAccess.some((team) => team.id === teamId && team.hasTraverseAccess))
            .map((teamId) => ({ teamId, entryId, actions, companyId }));
        yield mutate({
            variables: {
                userAclsToUpdate,
                userAclsToCreate,
                teamAclsToUpdate,
                teamAclsToCreate,
                hasUserAclsToUpdate: userAclsToUpdate.length > 0,
                hasUserAclsToCreate: userAclsToCreate.length > 0,
                hasTeamAclsToUpdate: teamAclsToUpdate.length > 0,
                hasTeamAclsToCreate: teamAclsToCreate.length > 0,
            },
            update() {
                refetch({ entryId });
            },
            onCompleted() {
                addToast({
                    title: successMessage,
                    appearance: 'success',
                });
            },
            onError() {
                addToast({
                    title: errorMessage,
                    appearance: 'danger',
                });
            },
        });
    });
    return { createFileSystemAcls, loading, error };
}
