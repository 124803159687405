import { themeGet, pxToRem } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const ItemContainer = styled.li `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${pxToRem(1)} solid ${themeGet('colors.gray.50')};
`;
export const StyledLink = styled(Link) `
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${themeGet('spacing.lg')} ${themeGet('spacing.xs')};
  column-gap: ${themeGet('spacing.md')};
  text-decoration: none;

  &:focus-visible {
    outline: none;
    box-shadow: ${themeGet('dropShadow.300')};
  }
`;
export const TextContainer = styled.div `
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const DetailsContainer = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: ${themeGet('spacing.md')};
  margin-top: ${themeGet('spacing.md')};
`;
export const StyledText = styled(Text) `
  line-height: 1;
  ${(props) => props.$uppercase && `text-transform: uppercase;`}
`;
