import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { MenuBody, MenuFooter, MenuHeader, MenuList } from '@pocketlaw/tetris';
import { ErrorAlert } from './components/ErrorAlert';
import { ItemList } from './components/ItemList';
import { Search } from './components/Search';
import { SubmitButton } from './components/SubmitButton';
import { useItemSelect } from './hooks/useItemSelect';
/**
 * use this component to list users and/or teams and be able to select and submit them
 */
export function UserAndTeamSelect(props) {
    const { users = [], teams = [], search, onSearch, onSubmit, submitButtonLabel, searching } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [selectedUsers, unselectedUsers, selectUser, unselectUser] = useItemSelect(users);
    const [selectedTeams, unselectedTeams, selectTeam, unselectTeam] = useItemSelect(teams);
    const handleSubmit = () => {
        setLoading(true);
        setError(false);
        const selectedUserIds = selectedUsers.map(({ id }) => id);
        const selectedTeamIds = selectedTeams.map(({ id }) => id);
        onSubmit({ selectedUserIds, selectedTeamIds })
            .catch(() => {
            setError(true);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const hasSelectedItems = selectedUsers.length > 0 || selectedTeams.length > 0;
    return (<Fragment>
      <MenuHeader sticky>
        <Search search={search} onSearch={onSearch} users={users} teams={teams} loading={searching}/>
      </MenuHeader>
      <MenuBody>
        <MenuList>
          {error && <ErrorAlert />}
          {selectedTeams.length > 0 && (<ItemList checked type="team" items={selectedTeams} onChange={unselectTeam} subheader={t({
                comment: 'Select teams and users dropdown - selected teams label',
                message: 'Selected teams',
            })}/>)}
          {selectedUsers.length > 0 && (<ItemList checked type="user" items={selectedUsers} onChange={unselectUser} subheader={t({
                comment: 'Select teams and users dropdown - selected users label',
                message: 'Selected users',
            })}/>)}
          {unselectedTeams.length > 0 && (<ItemList type="team" checked={false} items={unselectedTeams} onChange={selectTeam} subheader={t({
                comment: 'Select teams and users dropdown - teams label',
                message: 'Teams',
            })}/>)}
          {unselectedUsers.length > 0 && (<ItemList type="user" checked={false} items={unselectedUsers} onChange={selectUser} subheader={t({
                comment: 'Select teams and users dropdown - users label',
                message: 'Users',
            })}/>)}
        </MenuList>
      </MenuBody>
      <MenuFooter sticky>
        <SubmitButton loading={loading} onClick={handleSubmit} disabled={!hasSelectedItems}>
          {submitButtonLabel}
        </SubmitButton>
      </MenuFooter>
    </Fragment>);
}
