import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function getSearchEntityAppearance(type) {
    switch (type) {
        case SearchEntityTypeEnum.InboxFile:
        case SearchEntityTypeEnum.Task:
        case SearchEntityTypeEnum.Template:
        case SearchEntityTypeEnum.User:
            return 'primary';
        case SearchEntityTypeEnum.Folder:
            return 'success';
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case SearchEntityTypeEnum.DocumentUploaded:
        case SearchEntityTypeEnum.Draft:
        default:
            return 'info';
    }
}
