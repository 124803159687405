import { t } from '@lingui/macro';
import { SidebarMenuCollapsible } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAutotagFeatureAvailability } from 'app/domains/metadata';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { useDesktopNavigation } from '../../../DesktopNavigation';
import { CollapsibleItemLink } from '../CollapsibleItemLink';
import { useLinkMatch } from '../useLinkMatch';
export function CollapsibleDocuments() {
    const { data: isAutoTagAvailable } = useAutotagFeatureAvailability();
    const { collapsibleStates, toggleCollapsible } = useDesktopNavigation();
    const isSharedWithMeAvailable = useFeatureFlag('feature_shared_with_me');
    const activeChild = useLinkMatch({
        matchPath: ['/files', '/drafts', '/all-documents', '/shared-with-me', '/unverified-tags', '/document/:id'],
        matchExact: false,
    });
    return (<SidebarMenuCollapsible icon={<Falcon icon="files"/>} label={t({ comment: 'Sidebar menu - Repository label', message: 'Repository' })} activeChild={activeChild} collapsibleKey="repository" open={collapsibleStates.repository} onToggle={toggleCollapsible}>
      <CollapsibleItemLink to="/files" label={t({ comment: 'Sidebar menu - Files label', message: 'Files' })} matchPath={['/files/:folderId?', '/document/:id']}/>
      <CollapsibleItemLink to="/all-documents" label={t({ comment: 'Sidebar menu - All documents label', message: 'All documents' })} matchPath={['/all-documents']}/>
      <CollapsibleItemLink to="/drafts" label={t({ comment: 'Sidebar menu - Template drafts label', message: 'Template drafts' })} matchPath={['/drafts']}/>
      {isSharedWithMeAvailable && (<CollapsibleItemLink to="/shared-with-me" label={t({ comment: 'Sidebar menu - Shared with me label', message: 'Shared with me' })} matchPath={['/shared-with-me/:folderId?']}/>)}
      {isAutoTagAvailable && (<CollapsibleItemLink to="/unverified-tags" label={t({ comment: 'Sidebar menu - Unverified tags label', message: 'Unverified tags' })} matchPath={['/unverified-tags']}/>)}
    </SidebarMenuCollapsible>);
}
