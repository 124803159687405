export const systemPrompts = [
    {
        id: 'd76c9bed-50c9-4c11-9a72-d6892cc0ac82',
        name: 'NDA Review (Receiving Party Perspective, with Redlines)',
        content: `1. Summary: Provide a concise table summarizing the agreement with the following information:
   - Parties involved (full legal names)
   - Effective date and duration
   - Subject matter of the confidentiality
   - Key restrictions on the receiving party
   - Exclusions from confidentiality obligations

2. Please analyze the following key terms from the receiving party's perspective and provide specific redline suggestions according to these guidelines:

   a) Term and Duration:
      - If term ≤ 2 years: Mark as "ACCEPTABLE"
      - If term > 2 years: Suggest redline to reduce to 2 years with specific language
   
   b) Governing Law and Jurisdiction:
      - If Sweden: Mark as "ACCEPTABLE"
      - If not Sweden: Provide specific replacement clause for Swedish law and jurisdiction
   
   c) Liquidated Damages:
      - If present: Suggest complete deletion with explanation
      - If absent: Mark as "ACCEPTABLE"
   
   d) Return of Confidential Information:
      - Modify any "return" requirements to "destroy" requirements with specific language
      - If destruction only: Mark as "ACCEPTABLE"

3. Present responses in table format with the following columns:
   - Term
   - Current Language (quoted from document)
   - Assessment (ACCEPTABLE/REQUIRES CHANGE)
   - Proposed Redline (specific language)
   - Rationale (brief business-focused justification)`,
        labels: ['Review', 'NDA'],
    },
    {
        id: '8b0978f4-2980-4149-a920-91dd1abe277e',
        name: 'NDA Review (Receiving Party Perspective, with Email Draft)',
        content: `1. Summary: Create a concise table summarizing the NDA with:
   - Full legal names of all parties
   - Effective date and termination date
   - Subject matter and purpose of the NDA
   - Reciprocity status (mutual or one-way)
   - Key restrictions and permitted uses

2. Analysis: Review the NDA from the receiving party's perspective and identify necessary changes according to these guidelines:

   a) Term Duration:
      - If ≤ 3 years: Note as acceptable
      - If > 3 years: Flag for reduction to 3 years
   
   b) Governing Law/Jurisdiction:
      - If not Sweden: Flag for change to Swedish law
   
   c) Liquidated Damages:
      - Flag any liquidated damages provisions for deletion
   
   d) Non-Solicitation:
      - Ensure limited to 1 year maximum
   
   e) Return Requirements:
      - Flag any "return" requirements for change to "destroy"

3. Email Draft: Compose a professional email to the counterparty that:
   - Maintains a collaborative tone
   - Clearly explains each requested change with brief business justification
   - Uses a table format for clarity
   - Suggests next steps for finalizing the agreement
   - Maintains appropriate legal formality while being accessible`,
        labels: ['Review', 'NDA', 'Email'],
    },
    {
        id: '3a9fb5f7-1d32-4f46-bc3f-5f83e7f8d41b',
        name: 'NDA Review (Disclosing Party Perspective)',
        content: `1. Summary: Provide a table summarizing:
   - Parties (full legal names)
   - Duration of confidentiality obligations
   - Scope of confidential information
   - Key protections for the disclosing party
   - Key dates (effective date, expiration)

2. Analysis: Review the NDA from the disclosing party's perspective and assess the adequacy of:

   a) Definition of Confidential Information:
      - Is it sufficiently broad to capture all relevant information?
      - Are exclusions reasonable and standard?
   
   b) Permitted Uses:
      - Are the limitations on use clearly defined?
      - Does it prevent use beyond the stated purpose?
   
   c) Term and Survival:
      - Do confidentiality obligations survive termination?
      - Is the survival period adequate (minimum 3 years)?
   
   d) Remedies:
      - Are injunctive relief provisions included?
      - Are the enforcement mechanisms adequate?
   
   e) Return/Destruction:
      - Are requirements for return/destruction clear?
      - Is certification of destruction required?

3. Recommendations: For each inadequate provision, provide specific enhancement language to strengthen the disclosing party's position.

4. Format: Present in a table with:
   - Provision
   - Current Status (ADEQUATE/INADEQUATE)
   - Risk Assessment (LOW/MEDIUM/HIGH)
   - Recommended Enhancement (specific language)`,
        labels: ['Review', 'NDA'],
    },
    {
        id: '6d352fbe-19d7-4ab7-8a2c-4289694604ea',
        name: 'Supplier Review (Buyer Perspective, with Redlines)',
        content: `1. Summary: Provide a concise table with:
   - Full legal names of parties
   - Contract type and purpose
   - Key services/products
   - Financial terms summary
   - Effective date and term

2. Analysis: From the buyer's perspective, review these specific terms against our guidelines. Present review result in table format with:
   - Provision
   - Page/Section Reference
   - Current Language (quoted)
   - Proposed Redline (specific language)
   - Business Rationale (brief justification)

   a) Term and Termination:
      - If term > 12 months: Redline to 12 months
      - Ensure buyer has appropriate termination rights
   
   b) Automatic Renewal:
      - Flag and redline any automatic renewal provisions
   
   c) Governing Law:
      - If not Sweden: Redline to Swedish jurisdiction
   
   d) Assignment:
      - Ensure written consent required for supplier assignment
      - Confirm buyer termination rights if assignee is unacceptable
   
   e) Marketing/Trademark Usage:
      - Ensure prior written approval required for any usage
   
   f) IP Indemnification:
      - Confirm adequate indemnity for IP infringement exists
      - If missing: Provide standard clause
   
   g) Liquidated Damages:
      - Redline to remove any buyer liquidated damages obligations
   
   h) Liability Limitations:
      - Ensure exceptions for gross negligence, fraud, willful misconduct
      - Confirm confidentiality breach and logo misuse are not limited
   
   i) Confidentiality Duration:
      - Ensure minimum 2-year post-termination confidentiality period`,
        labels: ['Review', 'Supplier Contract'],
    },
    {
        id: '91c6c1c9-2076-48e6-bc29-3fd11d45357c',
        name: 'Supplier Review (Buyer Perspective, without Redlines)',
        content: `1. Summary: Create a table summarizing:
   - Contract parties (full legal names)
   - Contract subject matter
   - Key deliverables
   - Total value and payment structure
   - Term and renewal provisions

2. Acceptability Analysis: For each provision below, indicate ACCEPTABLE or NOT ACCEPTABLE according to our guidelines, with specific reasons:

   a) Term and Duration:
      - Maximum acceptable term: 12 months
   
   b) Automatic Renewal:
      - Not acceptable under any circumstances
   
   c) Governing Law:
      - Only Swedish jurisdiction acceptable
   
   d) Assignment:
      - Acceptable only with written consent and termination rights
   
   e) Marketing/Trademarks:
      - Acceptable only with prior written approval requirement
   
   f) IP Indemnification:
      - Acceptable only if indemnity clause benefits buyer
   
   g) Liquidated Damages:
      - Not acceptable for buyer obligations
   
   h) Liability Limitations:
      - Acceptable only with exceptions for gross negligence, etc.
   
   i) Confidentiality Duration:
      - Acceptable only if ≥ 2 years post-termination

3. Overall Assessment: Provide final recommendation:
   - ACCEPTABLE AS IS
   - ACCEPTABLE WITH MINOR CHANGES (list specific changes)
   - REQUIRES SIGNIFICANT RENEGOTIATION (list deal-breakers)
   - NOT ACCEPTABLE (explain fatal flaws)

4. Format: Present in table with:
   - Provision
   - Section reference
   - Status (ACCEPTABLE/NOT ACCEPTABLE)
   - Explanation (with reference to specific language)
   - Recommendation`,
        labels: ['Review', 'Supplier Contract'],
    },
    {
        id: '709c0100-c942-4f10-b5a3-c9b2fd056877',
        name: 'Supplier Review (IP Protection Focus)',
        content: `1. IP Provision Summary: Create a table identifying all IP-related provisions:
   - Section reference
   - Type of IP addressed (patents, copyrights, trademarks, trade secrets)
   - Ownership designation
   - License grants (if any)
   - Duration of rights/licenses

2. Gap Analysis: Identify any missing protections regarding:
   - Background IP ownership
   - Foreground IP ownership
   - Third-party IP usage
   - Open source components
   - IP indemnification
   - Assignment of employee/contractor rights

3. Protection Assessment: For each party, analyze:

   Supplier's Perspective:
   - Are their pre-existing IP rights adequately protected?
   - Are license limitations clearly defined?
   - Are attribution requirements properly addressed?

   Buyer's Perspective:
   - Is ownership of paid-for deliverables clearly established?
   - Are usage rights sufficiently broad for business needs?
   - Is IP indemnification adequate against third-party claims?
   - Are escrow provisions included for critical software/IP?

4. Recommendations: Provide specific language enhancements to address identified gaps.

5. Format: Present analysis in table with:
   - Provision Category
   - Current Status
   - Risk Assessment (LOW/MEDIUM/HIGH)
   - Party Most Affected
   - Recommended Improvement (specific language)`,
        labels: ['Review', 'Supplier Contract', 'Intellectual Property'],
    },
    {
        id: '8bfc6091-2f2b-4acd-ac5a-3eb12e17e269',
        name: 'Supplier Review (SLA Analysis)',
        content: `1. SLA Inventory: Create a comprehensive table of all service level commitments:
   - Service description
   - Performance metric
   - Target threshold
   - Measurement methodology
   - Reporting frequency
   - Consequence of non-compliance

2. Enforceability Analysis: For each SLA, assess:
   - Specificity: Is the metric precisely defined?
   - Measurability: Is the methodology objective?
   - Attainability: Is the target realistic?
   - Relevance: Does it address business-critical functions?
   - Time-bound: Are measurement periods clearly defined?

3. Remedies Assessment: Analyze the adequacy of:
   - Credits/refunds (are they proportional to impact?)
   - Termination rights (are thresholds appropriate?)
   - Problem escalation procedures
   - Force majeure exceptions

4. Gap Analysis: Identify missing elements:
   - Critical services without SLAs
   - SLAs without meaningful consequences
   - Ambiguous measurement methodologies
   - Reporting without verification rights

5. Recommendations: Provide specific language to:
   - Strengthen underperforming SLAs
   - Add missing SLAs for critical functions
   - Enhance remedy provisions
   - Improve measurement methodology

6. Format: Present as a detailed table with traffic light status (RED/YELLOW/GREEN) for each element.`,
        labels: ['Review', 'Supplier Contract'],
    },
    {
        id: '4f048faf-03ff-48c8-8eaa-e6ba6b8abca3',
        name: 'Supplier Review (Payment Terms Analysis)',
        content: `1. Payment Structure Summary: Create a comprehensive table:
   - Fee type (fixed, time & materials, milestone-based)
   - Calculation method
   - Currency and tax treatment
   - Payment triggers and timing
   - Invoice requirements
   - Dispute resolution process

2. Risk Analysis: For each payment provision, assess:

   Buyer's Perspective:
   - Payment triggers: Are they tied to clear deliverables/acceptance?
   - Withholding rights: Can payments be withheld for non-performance?
   - Dispute mechanisms: Is the process for challenging invoices clear?
   - Refund provisions: Are there adequate protections for overpayment?
   - Price increases: Are limits and notice periods reasonable?

   Supplier's Perspective:
   - Payment timing: Are terms in line with industry standards?
   - Late payment consequences: Are interest/penalties reasonable?
   - Currency risk: Who bears exchange rate fluctuations?
   - Minimum commitments: Are they clearly defined and fair?

3. Clarity Assessment: Identify any ambiguous terms regarding:
   - Calculation methodologies
   - Acceptance criteria
   - Expense reimbursement policies
   - Fee adjustment mechanisms

4. Recommendations: Provide specific language to:
   - Clarify ambiguous provisions
   - Balance protections for both parties
   - Address identified risks
   - Enhance dispute resolution mechanisms

5. Format: Present in a table with risk rating (LOW/MEDIUM/HIGH) for each element.`,
        labels: ['Review', 'Supplier Contract'],
    },
    {
        id: 'ebaac875-a6f8-4c6e-8f98-6d783215ecd9',
        name: 'Sales Contract Review (Seller Perspective)',
        content: `1. Summary: Create a table with:
   - Parties (full legal names)
   - Product/service description
   - Total value and payment structure
   - Term and delivery timeline
   - Key milestones and dependencies

2. Customer Obligation Analysis: From the seller's perspective, identify:
   - All customer dependencies with specific deadlines
   - Cooperation requirements
   - Access provisions
   - Resource commitments
   - Information provision obligations
   - Consequence mechanisms for customer delays

3. Protection Assessment: Evaluate adequacy of:
   - Limitation of liability provisions
   - Warranty scope and duration
   - IP ownership and license provisions
   - Termination rights
   - Change order processes
   - Force majeure protections

4. Payment Security: Analyze:
   - Payment triggers and milestones
   - Late payment consequences
   - Dispute impact on payment obligations
   - Termination impact on payment obligations
   - Change order impact on pricing

5. Recommendations: Provide specific language enhancements to:
   - Strengthen seller protections
   - Clarify customer obligations
   - Improve remedy provisions
   - Enhance payment security

6. Format: Present in a detailed table with risk assessment (LOW/MEDIUM/HIGH) for each element.`,
        labels: ['Review', 'Sales Contract'],
    },
    {
        id: '3531c70d-a56f-4524-ad3c-b08ea6395656',
        name: 'Sales Contract Review (Delivery Terms Analysis)',
        content: `1. Delivery Framework Summary: Create a comprehensive table of:
   - Deliverables (tangible and intangible)
   - Delivery timelines and milestones
   - Delivery methods and locations
   - Acceptance criteria for each deliverable
   - Testing procedures and timelines
   - Rejection processes and remediation periods

2. Clarity Assessment: For each delivery element, evaluate:
   - Specificity: Are deliverables precisely defined?
   - Measurability: Are acceptance criteria objective?
   - Timeliness: Are deadlines and timelines clear?
   - Consequence: Are the effects of non-compliance defined?

3. Risk Analysis: Identify potential issues regarding:
   - Ambiguous specifications leading to disputes
   - Subjective acceptance criteria
   - Unclear responsibility for delays
   - Inadequate testing procedures
   - Unreasonable rejection mechanisms
   - Insufficient remediation periods

4. Recommendation: Provide specific language to:
   - Clarify ambiguous specifications
   - Objectify acceptance criteria
   - Balance timeline responsibilities
   - Enhance testing protocols
   - Improve rejection and remediation processes

5. Format: Present in a detailed table with risk rating (LOW/MEDIUM/HIGH) for each element.`,
        labels: ['Review', 'Sales Contract'],
    },
    {
        id: '357c9d1e-c69e-464f-bfa4-ad505501b7eb',
        name: 'Employment Contract Review',
        content: `1. Compensation Summary: Extract and present in bullet points:
   - Base salary (amount, currency, payment frequency)
   - Bonus structure (triggers, calculation, payment timing)
   - Equity compensation (type, vesting schedule, conditions)
   - Benefits (insurance, retirement, PTO)
   - Expense reimbursement policies
   (Maximum 100 words)

2. Non-Compete Analysis: In plain language, summarize:
   - Geographic scope
   - Duration
   - Industry/activity limitations
   - Consideration provided
   - Enforcement mechanisms
   - Potential impact on future employment
   (Maximum 100 words)

3. Non-Solicitation Summary: Clearly explain:
   - Who cannot be solicited (clients, employees, suppliers)
   - Duration of restrictions
   - Geographic limitations
   - Permitted activities
   - Excluded relationships
   (Maximum 100 words)

4. Notice Requirements: Extract:
   - Notice period for employee resignation
   - Notice period for employer termination
   - Any differential based on tenure
   - Payment in lieu of notice provisions
   (Maximum 30 words)

5. Probation Terms: Calculate and highlight:
   - Probation end date (based on start date)
   - Performance evaluation criteria
   - Early termination rights during probation
   - Post-probation status changes
   (Maximum 100 words)

6. Format: Present each section separately with clear headings.`,
        labels: ['Review', 'Employment Contract'],
    },
    {
        id: '17d663a7-ea69-4289-a57c-9b042351c7a1',
        name: 'Legal Review Trigger Assessment',
        content: `Please review this contract and determine if formal legal review is required based on these specific criteria:

1. Analyze contract value:
   - Calculate total contract value over full term
   - Identify if value exceeds EUR 100,000 threshold
   - Consider all potential fees, not just base amounts

2. Assess contract duration:
   - Identify initial term
   - Account for any renewal periods (automatic or optional)
   - Determine if total possible duration exceeds 3 years

3. Examine IP provisions:
   - Identify any assignment of intellectual property
   - Look for creation of new IP within scope
   - Consider any unusual licensing arrangements

4. Check for real property elements:
   - Determine if contract involves sale/purchase of land
   - Identify any lease arrangements or easements
   - Look for construction or improvement obligations

5. If any threshold is met, explain specifically why legal review is required in 50 words or less.

6. Format: Present as a simple checklist with YES/NO for each criterion and final recommendation.`,
        labels: ['Review', 'General', 'Internal Policies'],
    },
    {
        id: '245ba416-c410-4499-aa3f-c93d878df26c',
        name: 'Contract Termination Analysis',
        content: `1. Termination Rights Summary: Create a comprehensive table of all termination provisions:

   For Each Party:
   - Termination for convenience (notice period, effective date)
   - Termination for cause (specific trigger events)
   - Special termination rights (change of control, force majeure)
   - Cure periods for remediable breaches
   - Required notification methods

2. Consequence Analysis: For each termination scenario, identify:
   - Financial consequences (fees, penalties, refunds)
   - Transition obligations (data return, knowledge transfer)
   - Survival provisions (which terms continue post-termination)
   - Wind-down procedures and timelines
   - Post-termination restrictions

3. Balance Assessment: Evaluate whether termination provisions fairly protect both parties:
   - Are notice periods equitable?
   - Are cause definitions reasonable?
   - Are consequences proportional?
   - Are cure opportunities adequate?

4. Recommendation: Suggest specific improvements for:
   - Clarifying ambiguous triggers
   - Balancing inequitable provisions
   - Addressing missing scenarios
   - Enhancing protection for vulnerable party

5. Format: Present as a side-by-side comparison table with separate columns for each party.`,
        labels: ['Review', 'General'],
    },
    {
        id: 'c60705bd-5a04-4c8c-b020-cbd62c0efa78',
        name: 'Contract Proofreading and Language Review',
        content: `Please conduct a thorough proofreading analysis of this contract, examining:

1. Technical Errors:
   - Spelling mistakes
   - Grammatical errors
   - Punctuation issues
   - Formatting inconsistencies
   - Numbering/cross-reference accuracy

2. Structural Issues:
   - Section organization logic
   - Paragraph flow and transitions
   - Definition placement and usage
   - Exhibit/schedule integration
   - Internal consistency of terms

3. Language Precision:
   - Ambiguous terminology
   - Vague or subjective phrasing
   - Undefined technical terms
   - Inconsistent usage of defined terms
   - Passive voice impacting clarity

4. Format: Present findings in a detailed table with:
   - Issue Category
   - Specific Location (page/section/paragraph)
   - Current Text (quoted)
   - Issue Description
   - Suggested Correction

5. Prioritize issues by potential impact on interpretation or enforceability.`,
        labels: ['Proofread', 'General'],
    },
    {
        id: '8f5e5121-ed98-4484-8090-715f6833129c',
        name: 'Plain Language Conversion',
        content: `Please analyze this document for unnecessarily complex language that could be simplified while maintaining legal precision:

1. Complexity Assessment: Identify:
   - Sentences exceeding 25 words
   - Excessive use of passive voice
   - Multiple conditions within single sentences
   - Unnecessarily archaic legal terminology
   - Nested clauses creating ambiguity

2. Simplification Recommendations: For each complex element:
   - Quote the original text
   - Provide a plain language alternative
   - Explain how the alternative preserves legal meaning
   - Address any potential risks in simplification

3. Focus on these high-priority sections:
   - Definitions of key terms
   - Payment obligations
   - Performance requirements
   - Termination provisions
   - Limitation of liability

4. Format: Present in a side-by-side comparison with:
   - Original Text
   - Plain Language Alternative
   - Explanation of Equivalence
   - Complexity Reduction (estimated percentage)`,
        labels: ['Proofread', 'General'],
    },
    {
        id: 'f6967094-9a5c-468d-a602-8eea1555738b',
        name: 'Definitions Audit',
        content: `Please conduct a comprehensive audit of definitions in this document:

1. Defined Terms Inventory: Create a table of:
   - All capitalized/defined terms
   - Section where definition appears
   - Complete definition text
   - Count of term usage throughout document

2. Missing Definitions: Identify:
   - Capitalized terms without definitions
   - Terms that appear to require definition based on context
   - Industry-specific terminology lacking explanation
   - Acronyms without expansion

3. Definition Quality Assessment: Evaluate:
   - Circular definitions (terms defined using themselves)
   - Ambiguous definitions with multiple interpretations
   - Inconsistent usage compared to definition
   - Overlapping definitions creating confusion

4. Usage Analysis: Identify:
   - Defined terms never actually used
   - Terms used inconsistently (sometimes capitalized)
   - Terms used outside their intended context
   - Definitions that contradict standard industry meaning

5. Format: Present in a comprehensive table with:
   - Term
   - Definition Status (DEFINED/UNDEFINED)
   - Definition Location
   - Usage Count
   - Issues Identified
   - Recommended Action`,
        labels: ['Proofread', 'General'],
    },
    {
        id: '992aa08c-5689-403b-9009-b8dac13e1f3d',
        name: 'Obligation Extraction and Timeline',
        content: `Please extract and organize all obligations from this contract into a comprehensive timeline:

1. Obligation Inventory: For each obligation, identify:
   - Responsible party (specific legal entity)
   - Nature of obligation (payment, delivery, notice, etc.)
   - Trigger event (effective date, specific milestone, etc.)
   - Deadline specification (exact date or calculation method)
   - Cross-referenced sections
   - Consequence of non-compliance

2. Timeline Construction: Organize obligations by:
   - Pre-execution obligations
   - Immediate post-execution obligations
   - Periodic ongoing obligations (with frequency)
   - Conditional obligations (with trigger events)
   - Termination and post-termination obligations

3. Interdependency Analysis: Identify:
   - Obligations dependent on other party's performance
   - Cascading deadline relationships
   - Potential timing conflicts
   - Critical path obligations affecting multiple provisions

4. Format: Present as both:
   - Chronological timeline with exact/calculated dates
   - Categorized table grouped by responsible party

5. Include a special section for time-sensitive obligations requiring action within 30 days of execution.`,
        labels: ['General', 'Supplier Contract', 'Sales Contract'],
    },
    {
        id: '540bcc7b-9ca7-4eb4-9f64-a071fd67cc4d',
        name: 'Negotiation Strategy Analysis',
        content: `Please analyze this contract and suggest a strategic negotiation approach:

1. Key Term Identification: For each party, identify:
   - Most favorable provisions
   - Most restrictive provisions
   - Unusual terms deviating from industry standards
   - Provisions with significant financial impact
   - Terms with potential future operational constraints

2. Balance Assessment: Evaluate overall balance of:
   - Risk allocation between parties
   - Remedy symmetry for similar breaches
   - Notice and cure provisions
   - Termination rights
   - Liability exposure

3. Strategic Recommendations: For the focus party, suggest:
   - High-priority negotiation targets with specific revisions
   - Acceptable compromise positions
   - Alternative approaches that achieve similar goals
   - Areas where current terms are reasonable and defensible
   - "Must-have" vs. "nice-to-have" distinctions

4. Format: Present as a negotiation playbook with:
   - Provision
   - Current Status
   - Target Revision
   - Fallback Position
   - Supporting Rationale`,
        labels: ['Review', 'Supplier Contract', 'Sales Contract'],
    },
    {
        id: '349a851d-740b-407b-912f-428939d12a12',
        name: 'Risk Assessment Matrix',
        content: `Please conduct a thorough risk assessment of this contract:

1. Risk Identification: Analyze the contract for:
   - Ambiguous language creating interpretation risk
   - Unusual or onerous provisions
   - Missing standard protections
   - Operational challenges in performance
   - Financial exposure beyond industry norms

2. Risk Categorization: Group identified risks by:
   - Legal/compliance risks
   - Financial risks
   - Operational risks
   - Reputational risks
   - Strategic risks

3. Risk Analysis: For each identified risk:
   - Assign probability rating (1-5)
   - Assign impact rating (1-5)
   - Calculate overall risk score (probability × impact)
   - Identify the specific party most affected
   - Suggest specific mitigation strategies

4. Prioritization: Rank risks by overall score and identify:
   - Critical risks requiring immediate attention
   - Significant risks warranting careful mitigation
   - Moderate risks acceptable with monitoring
   - Low risks requiring minimal attention

5. Format: Present as a detailed risk matrix with:
   - Risk Description
   - Risk Category
   - Affected Party
   - Probability Rating (1-5)
   - Impact Rating (1-5)
   - Overall Risk Score
   - Mitigation Strategy
   - Residual Risk After Mitigation`,
        labels: ['Review', 'General', 'Supplier Contract', 'Sales Contract'],
    },
    {
        id: 'f49f8506-2075-4266-8c54-86c0d6651765',
        name: 'Executive Summary Email',
        content: `Please create a concise, business-focused email summarizing this contract for a busy executive:

1. Executive Overview: In 3-5 bullet points, summarize:
   - Agreement type and parties
   - Core business purpose
   - Total value and term
   - Key commitments
   - Critical dates

2. Strategic Implications: In 2-3 bullet points, highlight:
   - How this agreement advances business objectives
   - Key operational requirements
   - Resource commitments required

3. Risk Summary: In 2-3 bullet points, identify:
   - Most significant risks
   - Unusual terms requiring attention
   - Potential obstacles to performance

4. Next Steps: In 1-2 bullet points, outline:
   - Immediate actions required
   - Decision points with deadlines
   - Implementation considerations

5. Style Guidelines:
   - Use concise, direct language
   - Avoid legal jargon
   - Focus on business impact, not legal technicalities
   - Maintain professional but conversational tone
   - Total length: Maximum 150 words

6. Format: Structure as a typical business email with subject line, greeting, bulleted content, and signature.`,
        labels: ['Email', 'General'],
    },
    {
        id: '473353cf-9acb-4403-bc0a-f7bd3edc75b4',
        name: 'DORA Assessment (Critical or Important Functions)',
        content: `Please carefully review the contract and follow these instructions:

1. Analyze the contract to determine if the requirements in Article 30 of the Digital Operational Resilience Act (DORA) are met. This contract regulates critical or important functions, so it should also fulfill the requirements of bullet point 3 of Article 30. For each applicable requirement in Article 30 of DORA:
 a.Identify if the requirement is met in the contract.
 b. If met, note the specific section or clause that fulfills the requirement. State also which requirement in article 30 it refers to (through number and letter)
 c. If not met or not explicitly stated, indicate that it is not specified.For each requirement, state whether it is met and which section or clause from the contract fulfills the requirement.

Present in table format. 

2. Extract the company name of the supplier from the contract, typically found in the opening paragraphs or signature blocks.
3. Determine if the service should be considered an ICT service according to DORA's definition, and provide your reasoning. This is the definition: 'ICT services' means digital and data services provided through ICT systems to one or more internal or external users on an ongoing basis, including hardware as a service and hardware services which includes the provision of technical support via software or firmware updates by the hardware provider, excluding traditional analogue telephone services;

Remember to be thorough in your analysis and provide accurate information based solely on the content of the contract. If any information is not explicitly stated in the contract, indicate that it is not specified.`,
        labels: ['Review', 'DORA', 'Supplier Contract'],
    },
    {
        id: '575f1941-fbff-465d-919d-a2ff78024c2d',
        name: 'DORA Assessment (Non Critical or Important Functions)',
        content: `Please carefully review the contract and follow these instructions:

1. Analyze the contract to determine if the requirements in Article 30 of the Digital Operational Resilience Act (DORA) are met. For each applicable requirement in Article 30 of DORA:
 a.Identify if the requirement is met in the contract.
 b. If met, note the specific section or clause that fulfills the requirement. State also which requirement in article 30 it refers to (through number and letter)
 c. If not met or not explicitly stated, indicate that it is not specified.For each requirement, state whether it is met and which section or clause from the contract fulfills the requirement.

Present in table format. 

2. Extract the company name of the supplier from the contract, typically found in the opening paragraphs or signature blocks.
3. Determine if the service should be considered an ICT service according to DORA's definition, and provide your reasoning. This is the definition: 'ICT services' means digital and data services provided through ICT systems to one or more internal or external users on an ongoing basis, including hardware as a service and hardware services which includes the provision of technical support via software or firmware updates by the hardware provider, excluding traditional analogue telephone services;

Remember to be thorough in your analysis and provide accurate information based solely on the content of the contract. If any information is not explicitly stated in the contract, indicate that it is not specified.`,
        labels: ['Review', 'DORA', 'Supplier Contract'],
    },
].map((props) => (Object.assign(Object.assign({}, props), { isSystemPrompt: true, isPublic: true, __typename: 'DocumentAssistantPrompt' })));
