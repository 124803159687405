import { Table, TableBody, LoaderOverlay, TableRowCollapse } from '@pocketlaw/tetris';
import { useTeamMembers, useTeamRole } from 'app/domains/teams';
import { UserAvatarWithLabel, useRole } from 'app/domains/users';
import { ErrorAlert } from './components/ErrorAlert';
import { NoMembers } from './components/NoMembers';
import { RemoveTeamMemberButton } from './components/RemoveTeamMemberButton';
import { TableRowCollapseContent } from './components/TableRowCollapseContent';
import { TeamMembersTableHead } from './components/TeamMembersTableHead';
import { TeamRoleSelect } from './components/TeamRoleSelect';
import { UserRoleChip } from './components/UserRoleChip';
import { StyledCol, StyledTableBodyCell } from './styled';
export function TeamMembersTable(props) {
    const { teamId } = props;
    const { teamMembers, loading, error } = useTeamMembers(teamId);
    const role = useRole();
    const teamRole = useTeamRole(teamId);
    const canRemoveTeamMembers = role.can('delete:team_member') || teamRole.can('delete:team_member');
    if (loading) {
        return <LoaderOverlay />;
    }
    if (error) {
        return <ErrorAlert />;
    }
    if (teamMembers.length < 1) {
        return <NoMembers />;
    }
    return (<Table>
      <colgroup>
        <StyledCol $showMobileOnly width="auto"/>
        <StyledCol width="auto"/>
        <StyledCol $hideMobile width="auto"/>
        <StyledCol $hideMobile width="auto"/>
        {canRemoveTeamMembers && <StyledCol width={1}/>}
      </colgroup>
      <TeamMembersTableHead canRemoveTeamMembers={canRemoveTeamMembers}/>
      <TableBody>
        {teamMembers.map((teamMember) => (<TableRowCollapse key={teamMember.id} colSpan={3} expandibleHeaders="dropdown" dropdownContent={<TableRowCollapseContent teamId={teamId} teamMemberId={teamMember.id} teamRole={teamMember.teamRole} userRoleName={teamMember.userRoleName}/>} hideDropdownOnBreakpoint="sm">
            <StyledTableBodyCell headers="name">
              <UserAvatarWithLabel size="md" title={teamMember.name || ''} subtitle={teamMember.email || ''}/>
            </StyledTableBodyCell>
            <StyledTableBodyCell $hideMobile headers="userRole">
              <UserRoleChip userRoleName={teamMember.userRoleName}/>
            </StyledTableBodyCell>
            <StyledTableBodyCell $hideMobile headers="teamRole">
              <TeamRoleSelect role={teamMember.teamRole} teamId={teamId} teamMemberId={teamMember.id}/>
            </StyledTableBodyCell>
            {canRemoveTeamMembers && (<StyledTableBodyCell headers="actions">
                <RemoveTeamMemberButton memberId={teamMember.id} memberName={teamMember.name} teamId={teamId}/>
              </StyledTableBodyCell>)}
          </TableRowCollapse>))}
      </TableBody>
    </Table>);
}
