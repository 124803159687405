import { getDateRangeBucketFilterPayload } from './getDateRangeBucketFilterPayload';
import { getKeyedBucketFilterPayload } from './getKeyedBucketFilterPayload';
import { getMetricFilterPayload } from './getMetricFilterPayload';
import { allowedPropertiesTranslations, getMetricTypeTranslations } from '../../WidgetForm/MetricInput/translations';
import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
import { isDateRangeBucket } from '../typeGuards';
function transformDateRangeBucket(bucket, aggregation) {
    const { value, from, to } = bucket;
    const formattedValue = value !== null && value !== void 0 ? value : 0;
    const primaryField = getSelectedField(aggregation);
    const formattedFrom = fieldDisplayValue(primaryField, from);
    const formattedTo = fieldDisplayValue(primaryField, to);
    const payload = getDateRangeBucketFilterPayload(bucket, aggregation);
    return {
        name: `${formattedFrom} - ${formattedTo}`,
        total: formattedValue,
        payload,
        breakdownPayloads: [],
    };
}
function transformKeyedBucket(bucket, aggregation) {
    const { name, value } = bucket;
    const formattedValue = value || 0;
    const primaryField = getSelectedField(aggregation);
    const formattedPrimaryName = fieldDisplayValue(primaryField, name);
    const payload = getKeyedBucketFilterPayload(bucket, aggregation);
    return {
        name: formattedPrimaryName,
        total: formattedValue,
        payload,
        breakdownPayloads: [],
    };
}
export function transformMetricBucket(bucket, aggregation) {
    const { value, name } = bucket;
    const { metricInput } = aggregation;
    const formattedValue = Number((value !== null && value !== void 0 ? value : 0).toFixed(2));
    const payload = getMetricFilterPayload(bucket, aggregation);
    const tooltip = `${getMetricTypeTranslations(metricInput === null || metricInput === void 0 ? void 0 : metricInput.type)} ${allowedPropertiesTranslations(metricInput === null || metricInput === void 0 ? void 0 : metricInput.field)}: ${formattedValue}`;
    return {
        name,
        total: formattedValue,
        payload,
        breakdownPayloads: [],
        tooltip,
    };
}
export function getBucketData(bucket, aggregation) {
    if (isDateRangeBucket(bucket)) {
        return transformDateRangeBucket(bucket, aggregation);
    }
    return transformKeyedBucket(bucket, aggregation);
}
