export function extractLabels(allPrompts) {
    const labels = new Map();
    allPrompts.forEach((prompt) => {
        var _a;
        (_a = prompt.labels) === null || _a === void 0 ? void 0 : _a.forEach((label) => {
            if (labels.has(label)) {
                labels.set(label, labels.get(label) + 1);
            }
            else {
                labels.set(label, 1);
            }
        });
    });
    return {
        allLabels: Array.from(labels.keys()),
        topLabels: Array.from(labels.entries())
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10),
    };
}
