import { Formik } from 'formik';
import { useAutotagFeatureAvailability } from 'app/domains/metadata/hooks/useAutotagFeatureAvailability';
import { FileSystemManagerSchema } from './schema';
import FolderPickerDialog from '../../FolderPickerDialog';
import FileSystemDialog from '../FileSystemDialog';
export function FileSystemManager(props) {
    const { defaultParentId, defaultName, onSubmit, onClose, entryType, error, onDiscard } = props;
    const autoTagFeature = useAutotagFeatureAvailability();
    const isDevelopmentEnvironment = process.env.APP_ENV !== 'production';
    const autoTag = isDevelopmentEnvironment ? false : autoTagFeature.data;
    return (<Formik initialValues={{
            name: defaultName || '',
            parentId: defaultParentId,
            userAcls: [],
            teamAcls: [],
            autoTag,
        }} validationSchema={FileSystemManagerSchema} onSubmit={onSubmit} validateOnMount>
      {({ values, setFieldValue }) => {
            if (values.parentId === 'otherOptions') {
                return (<FolderPickerDialog defaultParentId={defaultParentId} entryType={entryType} actionType="create" onSubmit={(folder) => { var _a; return setFieldValue('parentId', (_a = folder === null || folder === void 0 ? void 0 : folder.id) !== null && _a !== void 0 ? _a : null); }} onClose={() => setFieldValue('parentId', null)}/>);
            }
            return (<FileSystemDialog onDiscard={onDiscard} error={error} entryType={entryType} onClose={onClose} 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error: unclear `undefined | null` - ripple effect if trying to change this
            selectedId={values.parentId}/>);
        }}
    </Formik>);
}
