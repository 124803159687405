import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { MenuItem } from '@pocketlaw/tetris';
import { useDownloadFilesystemDocument } from 'app/domains/filesystem';
import { useActiveDocument, usePreview } from 'shared/domains/documents';
import { SupportedFileMimeTypes } from 'shared/domains/filesystem';
import { getDownloadIcon } from './getDownloadIcon';
export function DownloadDocumentMenuItems(props) {
    const { onDownload, onShowKeepRedlinesModal } = props;
    const { isFreeText, isPleditorImportExportAvailable } = usePreview();
    const { id: documentId } = useActiveDocument();
    const { files } = useDownloadFilesystemDocument(documentId);
    const handleDownload = (downloadType, downloadCallback) => {
        if (isFreeText && isPleditorImportExportAvailable && downloadType === SupportedFileMimeTypes.DOCX) {
            onShowKeepRedlinesModal(downloadCallback);
        }
        else {
            onDownload();
            downloadCallback();
        }
    };
    return (<Fragment>
      {files.map((file) => (<MenuItem key={file.type} onClick={() => handleDownload(file.type, file.fetch)}>
          {getDownloadIcon(file.type)}
          <Trans comment="Download document label">Download {file.name}</Trans>
        </MenuItem>))}
    </Fragment>);
}
