import { createContext, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { isUserAgent } from 'shared/domains/common-utils';
import { SearchPopup } from './SearchPopup';
import { useLazySearch } from './useLazySearch';
export const MIN_SEARCH_TEXT_LENGTH = 1;
export const UniversalSearchContext = createContext({});
export function UniversalSearchProvider(props) {
    const { children } = props;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('query') || '';
    const [searchText, setSearchText] = useState(query);
    const [focusIndex, setFocusIndex] = useState(null);
    const [open, setOpen] = useState(false);
    const [writing, setWriting] = useState(false);
    const { search, data, loading: searchLoading, error, } = useLazySearch({
        referrer: 'preview',
    });
    const loading = writing || searchLoading;
    const debouncedSearch = useDebouncedCallback((string) => {
        if (string.length >= MIN_SEARCH_TEXT_LENGTH) {
            search(string);
        }
        setWriting(false);
    }, 100, { leading: false });
    useEffect(() => {
        const sanitizedString = (searchText === null || searchText === void 0 ? void 0 : searchText.trim()) || '';
        debouncedSearch(sanitizedString);
    }, [debouncedSearch, searchText]);
    const openSearchPopup = () => {
        setOpen(true);
    };
    const handleSetSearchText = (str) => {
        if (searchText.length) {
            setWriting(true);
        }
        setSearchText(str);
    };
    const closeSearchPopup = () => {
        setFocusIndex(null);
        setSearchText('');
        setOpen(false);
    };
    useLayoutEffect(() => {
        const handleKeyDown = (event) => {
            if (isUserAgent('mac')) {
                if (event.metaKey && event.key === 'k') {
                    event.preventDefault();
                    setOpen(true);
                }
            }
            else if (event.ctrlKey && event.key === 'k') {
                event.preventDefault();
                setOpen(true);
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [setOpen]);
    return (<UniversalSearchContext.Provider value={{
            loading,
            error,
            data,
            openSearchPopup,
            searchText,
            setSearchText: handleSetSearchText,
            closeSearchPopup,
            focusIndex,
            setFocusIndex,
        }}>
      <SearchPopup open={open} onClose={() => {
            setOpen(false);
            setFocusIndex(null);
            setSearchText('');
        }}/>
      {children}
    </UniversalSearchContext.Provider>);
}
