import { t } from '@lingui/macro';
import { SearchAggregationType } from 'shared/domains/apollo/generated/types';
import { allowedValuesTranslations } from './translations';
const values = [
    'metadataDocumentNoticePeriodMonths',
    'metadataDocumentOriginalTermMonths',
    'metadataDocumentRenewalTermMonths',
    'metadataDocumentTotalContractCost',
    'metadataDocumentTotalContractValue',
    'metadataDocumentNoticePeriodDays',
];
export const getTopHitsAllowedProperties = () => ({
    type: SearchAggregationType.TopHits,
    label: t({ message: 'Top Hits', comment: 'Insights - Top hits aggregation' }),
    values: values.map((value) => ({ value, label: allowedValuesTranslations(value) })),
});
