import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import useAccount from 'shared/domains/users/hooks/useAccount';
import EmailField from './EmailField';
import RoleField from './RoleField';
import { RoleInfo } from '../../RoleInfo';
import { SubmissionError } from '../SubmissionError';
import { ContentSpacer } from './styled';
export function InviteUserForm() {
    var _a, _b;
    const account = useAccount();
    const companyName = ((_b = (_a = account.data) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b.name) || '';
    return (<ContentSpacer>
      <Typography>
        <Trans comment="invite user modal - email and role step description">
          Invite members to cooperate with {companyName} on PocketLaw. Add the email and choose role for the person you
          want to invite.
        </Trans>
      </Typography>

      <SubmissionError />

      <EmailField />

      <RoleField />

      <RoleInfo />
    </ContentSpacer>);
}
