import { t } from '@lingui/macro';
import { Switch } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField, useFormikContext } from 'formik';
import { useEsigningSignatoriesFormField } from 'app/domains/esigning/components/EsigningForm';
import { getSigningOrder } from 'app/domains/esigning/utils';
import { SectionListItem } from '../../../components';
const FIELD_NAME = 'settings.useSigningOrder';
export function SigningOrder() {
    const [useSigningOrder] = useField(FIELD_NAME);
    const { signatories } = useEsigningSignatoriesFormField();
    const { values, setValues } = useFormikContext();
    const handleChange = (checked) => {
        setValues(Object.assign(Object.assign({}, values), { settings: Object.assign(Object.assign({}, values.settings), { useSigningOrder: checked }), signatories: signatories.map((signatory) => (Object.assign(Object.assign({}, signatory), { order: getSigningOrder(signatory, checked) }))) }));
    };
    const title = t({
        comment: 'Label for e-signing setting signing order',
        message: 'Signing order',
    });
    const description = t({
        comment: 'Description for e-signing setting signing order',
        message: 'Set the preferable signing order',
    });
    return (<SectionListItem shape="square" icon={<Falcon icon="arrow-down-arrow-up"/>} primary={title} secondary={description} endElement={<Switch name={FIELD_NAME} checked={useSigningOrder.value} onBlur={useSigningOrder.onBlur} onCheckedChange={handleChange}/>}/>);
}
