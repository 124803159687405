export var CreateSignRequestErrors;
(function (CreateSignRequestErrors) {
    CreateSignRequestErrors["NoSignatory"] = "INVALID_QUERY_INPUT";
    CreateSignRequestErrors["NoProviderConnected"] = "COMPANY_NOT_CONNECTED_TO_PROVIDER";
    CreateSignRequestErrors["NoPdf"] = "NO_PDF_FILE_IN_DOCUMENT";
    CreateSignRequestErrors["NoPdfConversion"] = "NO_PDF_CONVERSION";
    CreateSignRequestErrors["NoDocument"] = "DOCUMENT_DOES_NOT_EXIST";
    CreateSignRequestErrors["NoScriveToken"] = "FAILED:PROVIDER_OUT_OF_TOKENS";
    CreateSignRequestErrors["NoCredits"] = "FAILED:ERROR_INSUFFICIENT_CREDITS";
    CreateSignRequestErrors["UnexpectedError"] = "UNEXPECTED_ERROR";
    CreateSignRequestErrors["NoApproval"] = "ERROR_DOCUMENT_LACKS_APPROVAL";
})(CreateSignRequestErrors || (CreateSignRequestErrors = {}));
export var CreateSignRequestFailedSubcodes;
(function (CreateSignRequestFailedSubcodes) {
    CreateSignRequestFailedSubcodes["AttachmentUploadFailed"] = "ATTACHMENTS_UPLOAD_JOB_FAILED";
    CreateSignRequestFailedSubcodes["AttachmentUploadFailedInvalidPdf"] = "ATTACHMENTS_UPLOAD_JOB_FAILED__INVALID_PDF";
})(CreateSignRequestFailedSubcodes || (CreateSignRequestFailedSubcodes = {}));
export const HANDLED_ERRORS = [
    CreateSignRequestErrors.NoSignatory,
    CreateSignRequestErrors.NoScriveToken,
    CreateSignRequestErrors.NoCredits,
    CreateSignRequestErrors.NoApproval,
    CreateSignRequestErrors.NoPdfConversion,
];
export const HANDLED_SUBCODES = [
    CreateSignRequestFailedSubcodes.AttachmentUploadFailed,
    CreateSignRequestFailedSubcodes.AttachmentUploadFailedInvalidPdf,
];
