import { gql } from '@apollo/client';
export const mutation = gql `
  mutation GetCurrentCollaborators($documentId: ID!, $versionNumber: Int!) {
    synchronizeFileSystemDocumentCollaborationSession(input: { documentId: $documentId, version: $versionNumber }) {
      collaborators {
        id
        userId
        documentId
        createdAt
        updatedAt
        lastModifiedAt
        lastAcknowledgedDocumentVersion
      }
    }
  }
`;
