import { useState } from 'react';
import Fuse from 'fuse.js';
import { useAccount } from 'shared/domains/users';
import { PromptLibraryContext } from './Context';
import { systemPrompts } from './defaultPrompts/systemPrompts';
import { usePromptUsage, usePrompts } from './usePrompts';
import { useTrackPrompt } from './useTrackPrompt';
import { extractLabels } from './utils';
export function Provider(props) {
    var _a, _b;
    const { children, onSelect, onClose } = props;
    const { prompts, loading: loadingPrompts, error: promptsError } = usePrompts();
    const recentlyUsedPromptsArray = usePromptUsage();
    const userId = (_a = useAccount().data) === null || _a === void 0 ? void 0 : _a.userId;
    const trackPromptUsage = useTrackPrompt();
    const [searchQuery, setSearchQuery] = useState('');
    const [view, setView] = useState('library');
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [shortcutsFilter, setShortcutsFilter] = useState('shortcuts:all');
    const addLabel = (label) => {
        if (!selectedLabels.includes(label)) {
            setSelectedLabels([...selectedLabels, label]);
        }
    };
    const removeLabel = (label) => {
        setSelectedLabels(selectedLabels.filter((l) => l !== label));
    };
    const [currentPromptID, setCurrentPromptID] = useState(undefined);
    const handleSelect = (selectedPrompt) => {
        trackPromptUsage({ variables: { promptId: selectedPrompt.id } });
        onSelect(selectedPrompt.content);
    };
    const allPrompts = [...prompts, ...systemPrompts];
    const { allLabels, topLabels } = extractLabels(allPrompts);
    const companyPrompts = (_b = allPrompts.filter((prompt) => prompt.isPublic && !prompt.isSystemPrompt)) !== null && _b !== void 0 ? _b : [];
    const myPrompts = allPrompts.filter((prompt) => { var _a; return ((_a = prompt.createdByUser) === null || _a === void 0 ? void 0 : _a.id) === userId && !prompt.isSystemPrompt; });
    const recentlyUsedPrompts = recentlyUsedPromptsArray.flatMap((prompt) => allPrompts.find((p) => p.id === prompt.id) || []);
    const shortcuttedPrompts = (() => {
        switch (shortcutsFilter) {
            case 'shortcuts:all':
                return allPrompts;
            case 'shortcuts:company':
                return companyPrompts;
            case 'shortcuts:system':
                return systemPrompts;
            case 'shortcuts:my-prompts':
                return myPrompts;
            case 'shortcuts:recently-used':
                return recentlyUsedPrompts;
            default:
                return allPrompts;
        }
    })();
    const labeledPrompts = selectedLabels.length
        ? shortcuttedPrompts.filter((prompt) => { var _a; return (_a = prompt.labels) === null || _a === void 0 ? void 0 : _a.some((label) => selectedLabels.includes(label)); })
        : shortcuttedPrompts;
    const shortcutsAmount = {
        'shortcuts:all': allPrompts.length,
        'shortcuts:company': companyPrompts.length,
        'shortcuts:system': systemPrompts.length,
        'shortcuts:my-prompts': myPrompts.length,
        'shortcuts:recently-used': recentlyUsedPrompts.length,
    };
    const fuse = new Fuse(labeledPrompts, {
        keys: ['name', 'content', 'createdByUser'],
    });
    const searchedPrompts = searchQuery ? fuse.search(searchQuery).map((result) => result.item) : labeledPrompts;
    return (<PromptLibraryContext.Provider value={{
            prompts: searchedPrompts,
            loading: loadingPrompts,
            error: promptsError,
            onSelect: handleSelect,
            onClose,
            searchQuery,
            setSearchQuery,
            shortcutsAmount,
            shortcutsFilter,
            setShortcutsFilter,
            closeDialog: onClose,
            view,
            setView,
            currentPromptID,
            setCurrentPromptID,
            allLabels,
            topLabels,
            selectedLabels,
            addLabel,
            removeLabel,
        }}>
      {children}
    </PromptLibraryContext.Provider>);
}
