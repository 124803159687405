import { Trans, t } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { UserAvatarWithLabel } from 'app/domains/users';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { getIcon } from './utils';
export function ApprovalRequestCreated(props) {
    const { user, timestamp, message, approvalType, approvers } = props;
    const fallbackName = t({
        message: 'Unknown',
        comment: 'Fallback name when user does not exist',
    });
    const userName = (user === null || user === void 0 ? void 0 : user.name) || fallbackName;
    function isNotNull(value) {
        return user !== null;
    }
    const mappedApprovers = approvers
        .filter((approver) => isNotNull(approver.user))
        .map((approver) => {
        var _a, _b;
        return ({
            id: (_a = approver.user) === null || _a === void 0 ? void 0 : _a.id,
            name: ((_b = approver.user) === null || _b === void 0 ? void 0 : _b.name) || fallbackName,
        });
    });
    return (<ActivityEntry appearance="warning" icon={getIcon('warning')} timestamp={timestamp} text={<ActivityEntryText>
          {approvalType === ApprovalType.All ? (<Trans comment="Approval activity - User asked for approval by all approvers">
              <b>{userName}</b> asked for approval by all approvers
            </Trans>) : (<Trans comment="Approval activity - User asked for approval by one of the approvers">
              <b>{userName}</b> asked for approval by one of the approvers
            </Trans>)}
        </ActivityEntryText>} message={message}>
      <Box flexDirection="column" gap="sm">
        <Typography $fontSize="tiny" $appearance="100">
          <Trans comment="Approval activity - Requested approval from">Requested approval from:</Trans>
        </Typography>
        {mappedApprovers.map((approver) => (<UserAvatarWithLabel key={approver.id} size="sm" title={approver.name}/>))}
      </Box>
    </ActivityEntry>);
}
