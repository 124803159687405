import { t } from '@lingui/macro';
import { Alert, Box } from '@pocketlaw/tetris';
import { UserAvatarWithLabel } from 'app/domains/users';
export function ApproversPermissionList(props) {
    const { approvers } = props;
    return (<Box flexDirection="column" gap="lg">
      <Alert appearance="warning" message={t({
            comment: 'Warning message that appears if the document has approvers without access to the document itself when the user is asking for approval',
            message: 'The following approvers do not have access to this document, by asking for approval they will gain access',
        })}/>
      {approvers.map((approver) => (<UserAvatarWithLabel key={approver.userId} size="sm" title={approver.name}/>))}
    </Box>);
}
