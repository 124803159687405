import { padding, marginTop, marginBottom, fontSize, unit, ToggleButton, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Divider = styled.hr `
  display: block;
  border: none;
  height: ${pxToRem(1)};
  margin: 0;
  margin-bottom: ${(props) => (props.marginBottom ? pxToRem(props.marginBottom) : 0)};
  padding: 0;
  background-color: ${themeGet('colors.gray.50')};
`;
export const LongTextItemContainer = styled.li `
  display: block;
  ${padding(2)};
  ${marginBottom(2)};
  ${fontSize('small')};
  border-radius: ${unit(2)};
  background-color: ${themeGet('colors.gray.50')};
`;
export const ItemText = styled.button `
  border: none;
  background: none;
  text-align: left;

  cursor: pointer;
`;
export const StyledButton = styled(ToggleButton) `
  display: block;
  ${marginTop(2)};
`;
