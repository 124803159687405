import { TableHead, TableHeadCell, TableHeaderText, TableRow, VisuallyHidden } from '@pocketlaw/tetris';
import { getTableHeaders } from '../../utils/tableHeaders';
import { DesktopTableHeadCell, MobileTableHeadCell } from './styled';
export function UserTableHead() {
    const { dropDown, name, createdAt, role, action } = getTableHeaders();
    return (<TableHead>
      <TableRow>
        <MobileTableHeadCell id={dropDown.id}>
          <VisuallyHidden>{dropDown.label}</VisuallyHidden>
        </MobileTableHeadCell>
        <TableHeadCell id={name.id}>
          <TableHeaderText sortId={name.id}>{name.label}</TableHeaderText>
        </TableHeadCell>
        <DesktopTableHeadCell id={createdAt.id}>
          <TableHeaderText sortId={createdAt.id}>{createdAt.label}</TableHeaderText>
        </DesktopTableHeadCell>
        <DesktopTableHeadCell id={role.id}>
          <TableHeaderText>{role.label}</TableHeaderText>
        </DesktopTableHeadCell>
        <TableHeadCell id={action.id}>
          <VisuallyHidden>{action.label}</VisuallyHidden>
        </TableHeadCell>
      </TableRow>
    </TableHead>);
}
