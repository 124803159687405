import T from 'prop-types';

import { DuplicateDocumentDialog } from 'app/domains/documents';
import {
  CreateFolderDialog,
  MoveDialog,
  QuickMoveDialog,
  RenameEntryDialog,
  DeleteFolderDialog,
  DeleteDocumentDialog,
  UploadDocumentDialog,
} from 'app/domains/filesystem';
import { UploadDocumentsDialog } from 'app/domains/upload';
import { EntryKind } from 'shared/domains/apollo/generated/types';

function Dialog({ action, onCreated, onDuplicated, onRenamed, onDeleted, onDocumentsUploaded, ...restProps }) {
  const { name, type, payload } = action || {};

  switch ([name, type].filter(Boolean).join(':')) {
    case 'CREATE:UPLOAD_FOLDER':
      return <UploadDocumentsDialog {...restProps} onCompleted={onDocumentsUploaded} />;
    case `CREATE:${EntryKind.Folder}`:
      return <CreateFolderDialog {...restProps} onCreated={onCreated} />;
    case `CREATE:${EntryKind.Document}`:
      return <UploadDocumentDialog {...restProps} files={payload.files} onCreated={onCreated} />;
    case `DUPLICATE:${EntryKind.Document}`:
      /**
       * FIXME: /!\ payload is replaced with id /!\
       */

      return <DuplicateDocumentDialog {...restProps} id={payload.id} onDuplicated={onDuplicated} />;
    case `RENAME:${EntryKind.Folder}`:
    case `RENAME:${EntryKind.Document}`:
      return <RenameEntryDialog {...restProps} payload={payload} type={type} onRenamed={onRenamed} />;
    case `DELETE:${EntryKind.Folder}`:
      return <DeleteFolderDialog {...restProps} payload={payload} onDeleted={onDeleted} />;
    case `DELETE:${EntryKind.Document}`:
      return <DeleteDocumentDialog {...restProps} id={payload.id} onDeleted={onDeleted} />;
    case `MOVE:${EntryKind.Folder}`:
    case `MOVE:${EntryKind.Document}`:
      return <MoveDialog {...restProps} payload={payload} type={type} />;
    case `QUICK_MOVE:${EntryKind.Folder}`:
    case `QUICK_MOVE:${EntryKind.Document}`:
      return <QuickMoveDialog {...restProps} payload={payload} type={type} />;
    default:
      return null;
  }
}

Dialog.propTypes = {
  action: T.shape({
    name: T.oneOf(['CREATE', 'DELETE', 'DUPLICATE', 'RENAME', 'MOVE', 'QUICK_MOVE', 'ADD_REMINDER', 'EDIT_ACCESS']),
    type: T.oneOf([EntryKind.Folder, EntryKind.Document, 'UPLOAD_FOLDER']),
    // eslint-disable-next-line react/forbid-prop-types
    payload: T.object,
  }),
  onCreated: T.func,
  onDuplicated: T.func,
  onRenamed: T.func,
  onDeleted: T.func,
  onDocumentsUploaded: T.func,
};

Dialog.defaultProps = {
  action: undefined,
  onCreated: undefined,
  onDuplicated: undefined,
  onRenamed: undefined,
  onDeleted: undefined,
  onDocumentsUploaded: undefined,
};

export default Dialog;
