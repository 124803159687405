import { Trans } from '@lingui/macro';
import { TextInput } from '@pocketlaw/tetris';
import { Spacer } from '@pocketlaw/tetris/deprecated';
import { useField, useFormikContext } from 'formik';
import { ErrorMessage } from 'shared/domains/forms/components/ErrorMessage';
import { FieldMessage } from 'shared/domains/forms/components/FieldMessage';
import { AnimateHeightContainer } from '../AnimateHeightContainer';
export function TextInputField(props) {
    const { name, label } = props;
    const { isSubmitting } = useFormikContext();
    const [field, meta] = useField(name);
    const error = Boolean(meta.touched && meta.error);
    const disabled = isSubmitting;
    const lengthWarning = field.value.length > 50;
    return (<div>
      <TextInput disabled={disabled} stretch label={label} {...field} invalid={error}/>
      <Spacer spacing={0}>
        <AnimateHeightContainer isOpen={error}>
          <ErrorMessage name={name} show/>
        </AnimateHeightContainer>
        <AnimateHeightContainer isOpen={lengthWarning}>
          <FieldMessage show>
            <Trans comment="Error message when text us to long">Keep the text short and descriptive</Trans>
          </FieldMessage>
        </AnimateHeightContainer>
      </Spacer>
    </div>);
}
