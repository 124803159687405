import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { IconButton, Menu, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DeleteDocumentDialog, DownloadDocumentDialog, MoveDialog } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
export function DocumentActionMenu(props) {
    const { cacheId, entityId, parentId, typename } = props;
    const [open, setOpen] = useState(false);
    const [actionDialog, setActionDialog] = useState();
    const menuItemClickHandler = (dialogType) => () => {
        setActionDialog(dialogType);
        setOpen(false);
    };
    const closeDialog = () => setActionDialog(undefined);
    const handleUpdate = (cache) => {
        cache.modify({
            id: cache.identify({ id: cacheId, __typename: typename }),
            fields(_, { DELETE }) {
                return DELETE;
            },
        });
    };
    return (<Fragment>
      <Menu target={<IconButton $round appearance="ghost" size="small" onClick={() => setOpen(!open)}>
            <Falcon icon="ellipsis-h"/>
          </IconButton>} onClose={() => setOpen(false)} open={open}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={menuItemClickHandler('download')}>
              <Falcon icon="download"/>
              <Trans comment="Search Table Row Context Menu item - Download">Download</Trans>
            </MenuItem>
            <MenuItem onClick={menuItemClickHandler('moveToFolder')}>
              <Falcon icon="folder"/>
              <Trans comment="Search Table Row Context Menu item - Move to folder">Move to folder</Trans>
            </MenuItem>
            <MenuItem onClick={menuItemClickHandler('delete')}>
              <Falcon icon="trash"/>
              <Trans comment="Search Table Row Context Menu item - Delete">Delete</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>

      {actionDialog === 'download' && <DownloadDocumentDialog id={entityId} onClose={closeDialog}/>}

      {actionDialog === 'moveToFolder' && (<MoveDialog type={EntryKind.Document} payload={{ id: entityId }} parentId={parentId} onClose={closeDialog}/>)}

      {actionDialog === 'delete' && (<DeleteDocumentDialog id={entityId} onClose={closeDialog} onUpdate={handleUpdate}/>)}
    </Fragment>);
}
