import { MenuItem, Checkbox } from '@pocketlaw/tetris';
import { UserAvatarWithLabel } from 'app/domains/users';
import { AlignRight } from './styled';
export function AssigneeList(props) {
    const { items, onChange, checked = false } = props;
    return items.map((item) => (<MenuItem key={item.id} as="label">
      <UserAvatarWithLabel title={item.name} size="xs" solid/>
      <AlignRight>
        <Checkbox checked={checked} onChange={() => onChange(item)}/>
      </AlignRight>
    </MenuItem>));
}
