import { MenuBody, MenuList } from '@pocketlaw/tetris';
import { SearchMenuEmptyState } from './SearchMenuEmptyState';
import { useSearchableMenu } from './SearchableMenuContext';
export function ComponentChildren(props) {
    const { children } = props;
    const { hasResult, items, open, setOpen } = useSearchableMenu();
    const renderProps = {
        items,
        open,
        setOpen,
    };
    if (typeof children === 'function') {
        return <MenuBody>{hasResult ? <MenuList>{children(renderProps)}</MenuList> : <SearchMenuEmptyState />}</MenuBody>;
    }
    return children;
}
