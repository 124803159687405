var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DocumentMetadataFeature, DocumentMetadataPropertySetterEnum } from 'shared/domains/apollo/generated/types';
export function getPropertyPairs(data) {
    const { pairs, properties } = data || {};
    const transformedProperties = (properties || []).map((property) => {
        const { id, value, lastSetBy, approvedByUserId, aiAutoTaggingReasoning } = (pairs === null || pairs === void 0 ? void 0 : pairs.find((documentPair) => { var _a; return ((_a = documentPair.property) === null || _a === void 0 ? void 0 : _a.id) === property.id; })) || {};
        const needsVerification = lastSetBy === DocumentMetadataPropertySetterEnum.Ai && !approvedByUserId;
        const { schema } = property, restOfProperty = __rest(property, ["schema"]);
        return {
            property: Object.assign({ schema: schema }, restOfProperty),
            pair: {
                id: id,
                lastSetBy: lastSetBy,
                approvedByUserId: approvedByUserId,
                value: value,
                needsVerification,
                aiAutoTaggingReasoning,
            },
        };
    });
    return transformedProperties;
}
export function checkFeatureAvailability(data) {
    const { companies } = data || {};
    const [company] = companies || [];
    const { documentMetadataFeatures } = company || {};
    const autoTagFeature = documentMetadataFeatures === null || documentMetadataFeatures === void 0 ? void 0 : documentMetadataFeatures.find(({ feature }) => feature === DocumentMetadataFeature.AiAutoTagging);
    const featureAvailability = Boolean(autoTagFeature);
    return featureAvailability;
}
