var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Formik } from 'formik';
import { SectionFooter } from 'app/domains/esigning/components/components';
import { useSetDefaultSignatories } from 'app/domains/esigning/hooks/useSetDefaultSignatories';
import { DefaultSignatories } from './DefaultSignatories';
import { OverrideAlert } from './OverrideAlert';
import { SubmitAlert } from './SubmitAlert';
import { SubmitButton } from './SubmitButton';
import { useSchema } from './useSchema';
import { cleanDefaultCcRecipient, cleanDefaultSignatory } from './utils';
import { MessagesContainer, StyledSectionContent } from './styled';
export function DefaultSignatoriesForm(props) {
    const { isEdit, isEqual, composerIds, initialSignatories = [], onSubmit } = props;
    const { setDefaultSignatories } = useSetDefaultSignatories();
    const [error, setError] = useState(false);
    const isOverrideEdit = isEdit && !isEqual;
    const schema = useSchema();
    const handleSubmit = (values) => __awaiter(this, void 0, void 0, function* () {
        setError(false);
        const signatories = values.signatories.filter(({ isSigning }) => isSigning).map(cleanDefaultSignatory);
        const ccRecipients = values.signatories.filter(({ isSigning }) => !isSigning).map(cleanDefaultCcRecipient);
        try {
            yield setDefaultSignatories({ composerIds, signatories, ccRecipients });
            onSubmit();
        }
        catch (_a) {
            setError(true);
        }
    });
    return (<Formik validateOnMount initialValues={{ signatories: initialSignatories }} validationSchema={schema} onSubmit={handleSubmit}>
      <Fragment>
        <StyledSectionContent>
          <MessagesContainer>
            {isOverrideEdit && <OverrideAlert />}
            <Typography $fontSize="small" $appearance="100">
              <Trans comment="Manage default signing parties drawer - informative paragraph one">
                Any signing parties you add to templates will be added automatically to any document drafted from this
                template.
              </Trans>
            </Typography>
            <Typography $fontSize="small" $appearance="100">
              <Trans comment="Manage default signing parties drawer - informative paragraph two">
                Default parties cannot be edited or removed from the document.
              </Trans>
            </Typography>
          </MessagesContainer>
          <DefaultSignatories />
        </StyledSectionContent>
        <SectionFooter>
          {error && <SubmitAlert />}
          <SubmitButton />
        </SectionFooter>
      </Fragment>
    </Formik>);
}
