import { ApolloError } from '@apollo/client/errors';
export function hasSubcode(error, code) {
    if (!error) {
        return false;
    }
    if (error instanceof ApolloError) {
        const { graphQLErrors } = error;
        return graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.some((gqlError) => {
            const { extensions } = gqlError || {};
            const { subcode } = extensions || {};
            return subcode === code;
        });
    }
    return false;
}
