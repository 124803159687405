import { Trans } from '@lingui/macro';
import { IconButton, Box, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SelectApprovalType } from '../../../SelectApprovalType';
import { SelectApprovers } from '../../../SelectApprovers';
import { useVoluntaryApprovers } from '../../VoluntaryApproversProvider';
import { Approver as ApproverItem } from '../Approver';
import { ApproversHeader } from '../ApproversHeader';
export function WithApprovers() {
    const { selectedApprovers, selectedTeamApprovers, selectedApprovalType, addApprovers, addTeamApprovers, removeApprover, removeTeamApprover, setSelectedApprovalType, } = useVoluntaryApprovers();
    const removeButtonProps = {
        $round: true,
        size: 'xs',
        appearance: 'ghost',
    };
    return (<Box flexDirection="column" gap="2xl">
      <Box flexDirection="column" gap="sm">
        <Typography $fontSize="small" $appearance="100">
          <Trans comment="Approval sidebar - Approval type label">Approval type</Trans>
        </Typography>
        <SelectApprovalType value={selectedApprovalType} onChange={setSelectedApprovalType}/>
      </Box>
      <Box flexDirection="column" gap="md">
        <ApproversHeader />
        {selectedApprovers.map((approver) => (<ApproverItem key={approver.userId} name={approver.name} subtitle={approver.email} endElement={<IconButton {...removeButtonProps} onClick={() => removeApprover(approver.userId)}>
                <Falcon icon="trash"/>
              </IconButton>}/>))}
        {selectedTeamApprovers.map((teamApprover) => (<ApproverItem type="team" key={teamApprover.teamId} name={teamApprover.name} endElement={<IconButton {...removeButtonProps} onClick={() => removeTeamApprover(teamApprover.teamId)}>
                <Falcon icon="trash"/>
              </IconButton>}/>))}
        <Box justifyContent="center">
          <SelectApprovers buttonAppearance="secondary" approvers={selectedApprovers} teamApprovers={selectedTeamApprovers} onAddApprovers={addApprovers} onAddTeamApprovers={addTeamApprovers}/>
        </Box>
      </Box>
    </Box>);
}
