import { Formik } from 'formik';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
import { OverlayLoader } from 'app/domains/esigning/components/components/OverlayLoader';
import { useEsigningProviderMetadata } from 'app/domains/esigning/hooks/useEsigningProviderMetadata';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { documentFlush, usePreview } from 'shared/domains/documents';
import { useMakeValidationSchema } from './EsigningSchema';
import { SignatoriesFormFieldProvider } from './useEsigningSignatoriesFormField';
import { useSignatoriesInForm } from './useSignatoriesInForm';
import { cleanDataCcRecipient, cleanDataSignatory, transformSignatoryValue, removeAuthenticationModeKey, removeNameFields, removeDefaultSignatory, getInitialSigningOrder, keepSigningDefaultSignatory, transformDefaultSignatory, } from './utils';
export function EsigningForm(props) {
    const { children } = props;
    const { sendDocumentForEsigning } = useEsigning();
    const { isRtcAvailable } = usePreview();
    const { lastSignRequest } = useLastSignRequest();
    const { message: initialGroupMessage } = lastSignRequest || {};
    const { formSignatories, loading: loadingFormSignatories } = useSignatoriesInForm();
    const providerMetadata = useEsigningProviderMetadata();
    const { nameFormat } = providerMetadata.properties;
    const validationSchema = useMakeValidationSchema();
    const initialUseSigningOrder = getInitialSigningOrder(formSignatories);
    const initialUseGroupMessage = Boolean(initialGroupMessage);
    const initialValues = {
        signatories: formSignatories,
        settings: {
            useSigningOrder: initialUseSigningOrder,
            useGroupMessage: initialUseGroupMessage,
        },
        globals: {
            groupMessage: initialGroupMessage,
        },
    };
    const handleSubmit = (values) => {
        if (isRtcAvailable) {
            documentFlush({ state: 'initialized' });
        }
        const { globals, settings, signatories: formikSignatories } = values;
        const { useGroupMessage } = settings;
        const { groupMessage } = globals;
        const signatoriesToSave = formikSignatories
            .filter(removeDefaultSignatory)
            .filter((signatory) => signatory.saveSignee)
            .map((signatory) => transformSignatoryValue(signatory, nameFormat));
        const signatories = formikSignatories
            .filter(removeDefaultSignatory)
            .filter((signatory) => signatory.isSigning === true)
            .map((signatory) => removeNameFields(signatory, nameFormat))
            .map(cleanDataSignatory);
        const ccRecipients = formikSignatories
            .filter(removeDefaultSignatory)
            .filter((signatory) => signatory.isSigning === false)
            .map(removeAuthenticationModeKey)
            .map((signatory) => removeNameFields(signatory, nameFormat))
            .map(cleanDataCcRecipient);
        const defaultSignatories = formikSignatories.filter(keepSigningDefaultSignatory).map(transformDefaultSignatory);
        const signRequestMessage = useGroupMessage ? groupMessage : undefined;
        sendDocumentForEsigning({
            signatoriesToSave,
            signatories,
            ccRecipients,
            defaultSignatories,
            message: signRequestMessage,
        });
    };
    if (loadingFormSignatories) {
        return <OverlayLoader />;
    }
    return (<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <SignatoriesFormFieldProvider>{children}</SignatoriesFormFieldProvider>
    </Formik>);
}
export { useEsigningSignatoriesFormField } from './useEsigningSignatoriesFormField';
