var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { DeleteInsightsWidgetDocument, RemoveSlotForWidgetDocument } from './RemoveWidgetMutations.gql';
export function useDeleteDashboardWidget() {
    const [removeSlotMutation] = useMutation(RemoveSlotForWidgetDocument);
    const [deleteWidgetMutation] = useMutation(DeleteInsightsWidgetDocument);
    const removeWidget = (options) => __awaiter(this, void 0, void 0, function* () {
        const { dashboardId, slotId, widgetId } = options;
        yield removeSlotMutation({
            variables: { dashboardId, slotId: [slotId] },
            update(cache) {
                cache.modify({
                    id: cache.identify({ id: slotId, __typename: 'SearchInsightsDashboardSlot' }),
                    fields(_, { DELETE }) {
                        return DELETE;
                    },
                });
            },
        });
        yield deleteWidgetMutation({
            variables: { id: widgetId },
            update(cache) {
                cache.modify({
                    id: cache.identify({ id: slotId, __typename: 'SearchInsightsWidget' }),
                    fields(_, { DELETE }) {
                        return DELETE;
                    },
                });
            },
        });
    });
    return { removeWidget };
}
