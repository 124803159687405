import { gql } from '@apollo/client';
export default gql `
  # The @export directive is part of ApolloClient.
  # We need to manually define it for graphql-inspector to understand it.
  directive @export(as: String) on FIELD
  directive @client on FIELD

  directive @unmask on FRAGMENT_SPREAD | FIELD

  extend type DocumentAssistantPrompt {
    isSystemPrompt: Boolean!
  }

  enum AuthenticatedVar {
    AUTHENTICATED
    NOT_AUTHENTICATED
    NO_USER
  }

  type Profile {
    userId: String!
    email: String!
    preferredLocale: String
    name: String
    picture: String
    verified: Boolean
  }

  extend type Query {
    locale: String!
    marketCode: String!
    storedAccountId: UUID
    touchedEntries: [String!]
    profile: Profile
    authenticated: AuthenticatedVar!
    account: CompanyUser
    accounts: [CompanyUser!]!
  }

  extend type MarketLocale {
    name: String!
    nameInLocalLanguage: String!
    locale: String!
  }

  extend type Market {
    marketLocales: [MarketLocale!]!
  }

  extend type FileSystemFolder {
    topicId: UUID @deprecated(reason: "Use field 'categoryId' instead")
    topic: Topic @deprecated(reason: "Use field 'category' instead")
  }

  extend type FileSystemGuidance {
    topicId: UUID @deprecated(reason: "Use field 'categoryId' instead")
    topic: Topic @deprecated(reason: "Use field 'category' instead")
  }

  extend type FileSystemDocument {
    topicId: UUID @deprecated(reason: "Use field 'categoryId' instead")
    topic: Topic @deprecated(reason: "Use field 'category' instead")
    body: JSON @deprecated(reason: "Use field 'htmlBody' instead")
    lastVersion: FileSystemDocumentVersion @deprecated(reason: "Deprecated, do not use")
  }

  extend type Template {
    name: String @deprecated(reason: "Use 'metadata' on composer instead")
  }

  extend type Composer {
    name: String @deprecated(reason: "Use field 'metadata.name' instead")
    description: String @deprecated(reason: "Use field 'metadata.description' instead")
  }

  extend type Topic {
    id: UUID @deprecated(reason: "Topic is deprecated, use Category instead")
    title: String @deprecated(reason: "Use field 'metadata.title' instead")
    description: String @deprecated(reason: "Use field 'metadata.description' instead")
  }

  extend type Diagnosis {
    guidance_diagnosis: GuidanceDiagnosis @deprecated(reason: "Guides is deprecated, do not use")
    guidance_diagnosis_id: UUID @deprecated(reason: "Guides is deprecated, do not use")
    guide: Guide! @deprecated(reason: "Guides is deprecated, do not use")
    guide_id: UUID! @deprecated(reason: "Guides is deprecated, do not use")
    id: UUID @deprecated(reason: "Diagnosis is deprecated, do not use")
  }

  extend type Guide {
    title: String @deprecated(reason: "Use field 'metadata.title' instead")
    description: String @deprecated(reason: "Use field 'metadata.description' instead")
    id: UUID @deprecated(reason: "Guides is deprecated, do not use")
  }

  extend type Mutation {
    cloneFileSystemDocument: FileSystemDocument
      @deprecated(reason: "Mutation.cloneFileSystemDocument is deprecated, Do not use")
    createFileSystemDocumentVersion: FileSystemDocumentVersion
      @deprecated(reason: "Mutation.createFileSystemDocumentVersion is deprecated, Do not use")
  }
`;
