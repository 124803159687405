import { useState } from 'react';
import { t } from '@lingui/macro';
import { TableColumnToggle, LoaderOverlay } from '@pocketlaw/tetris';
import { EmptyList } from './components/EmptyList';
import { TasksFilterMenu } from './components/TasksFilterMenu';
import { initialColumns } from './initialColumns';
import { useTasks } from '../TasksProvider';
import { TasksTable } from '../TasksTable';
import { Wrapper } from './styled';
export function TasksTableWithFilters() {
    const [columns, setColumns] = useState(initialColumns());
    const { tasks, setSortBy, sortBy } = useTasks();
    const haveTasks = Boolean(tasks.data.length);
    if (tasks.error) {
        return null;
    }
    return (<LoaderOverlay enabled={tasks.loading}>
      <Wrapper>
        <TasksFilterMenu />
        <TableColumnToggle onChange={setColumns} headers={columns} size="small">
          {t({ message: 'Columns', comment: 'Toggle tasks column button title' })}
        </TableColumnToggle>
      </Wrapper>

      {!haveTasks && <EmptyList />}

      {haveTasks && <TasksTable tasks={tasks.data} columns={columns} sort={sortBy} setSort={setSortBy}/>}
    </LoaderOverlay>);
}
