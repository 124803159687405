import { v4 } from 'uuid';
import { getAllFolderItems } from './getAllFolderItems';
import { getFolderMap } from './getFolderMap';
import { getPreviewFile } from './getPreviewFile';
import { isFile, isNotUndefined } from './guards';
const NO_FOLDER_KEY = v4();
const fileHasFolder = (file) => file.path.includes('/');
const getRootFolder = (file) => file.path.split('/')[0];
export const getPreviewItems = (originalFiles) => {
    const itemsMap = originalFiles.reduce((acc, file) => {
        const hasFolder = fileHasFolder(file);
        if (hasFolder) {
            const rootFolder = getRootFolder(file);
            if (!acc[rootFolder]) {
                acc[rootFolder] = [];
            }
            acc[rootFolder].push(file);
        }
        else {
            if (!acc[NO_FOLDER_KEY]) {
                acc[NO_FOLDER_KEY] = [];
            }
            acc[NO_FOLDER_KEY].push(file);
        }
        return acc;
    }, {});
    // Step 1. Create the maps
    const fileMap = {};
    const folderMap = {};
    // Step 2. Get the file and folder arrays that we want to iterate
    const standaloneFiles = itemsMap[NO_FOLDER_KEY] || [];
    const folders = Object.entries(itemsMap).reduce((acc, [key, items]) => {
        if (key !== NO_FOLDER_KEY) {
            acc.push(items);
        }
        return acc;
    }, []);
    // Step 3. Add the files and folders to the maps
    standaloneFiles.forEach((file) => {
        fileMap[file.id] = getPreviewFile(file);
    });
    folders.forEach((folderFiles) => {
        const newFolderMap = getFolderMap(folderFiles);
        Object.assign(folderMap, newFolderMap);
        Object.values(newFolderMap)
            .filter(isNotUndefined)
            .flatMap(getAllFolderItems)
            .filter(isFile)
            .forEach((file) => {
            fileMap[file.id] = file;
        });
    });
    return { fileMap, folderMap };
};
