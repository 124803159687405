import { useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Button, Menu, MenuBody, MenuHeader, MenuList, MenuSubheader } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { HiddenItem } from './HiddenItem';
import { NoColumns } from './NoColumns';
import { SortableItem } from './SortableItem';
import { useSearchContext } from '../SearchProvider';
export function SearchTableColumnToggle() {
    const [open, setOpen] = useState(false);
    const { setHiddenColRows, values } = useSearchContext();
    const { columns } = values;
    const lingui = useLingui();
    const allColumns = columns
        .map((header) => (Object.assign(Object.assign({ active: false, disabled: false }, header), { label: lingui._(header.label) })))
        .filter(({ id }) => id !== 'action');
    const visibleColumns = allColumns.filter(({ active }) => active);
    const hiddenColumns = allColumns.filter(({ active }) => !active);
    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    const handleSortable = (event) => {
        const { active, over } = event;
        const sortOnItself = active.id === (over === null || over === void 0 ? void 0 : over.id);
        if (sortOnItself) {
            return;
        }
        const columnIdArray = columns.map((column) => column.id);
        const oldIndex = columnIdArray.indexOf(active.id);
        const newIndex = columnIdArray.indexOf(over === null || over === void 0 ? void 0 : over.id);
        const newColumnOrder = arrayMove(columns, oldIndex, newIndex);
        setHiddenColRows(newColumnOrder);
    };
    const handleToggleColumn = (id) => {
        setHiddenColRows(columns.map((column) => {
            if (column.id === id) {
                return Object.assign(Object.assign({}, column), { active: !column.active });
            }
            return column;
        }));
    };
    const handleBulkToggle = (active) => {
        setHiddenColRows(columns.map((column) => (Object.assign(Object.assign({}, column), { active: column.disabled ? column.active : active }))));
    };
    return (<Menu open={open} onClose={() => setOpen(false)} target={<Button type="button" onClick={() => setOpen(!open)} appearance="ghost" size="small">
          <Falcon icon="table-columns"/>
          <Trans comment="Table: Column toggle button">Columns</Trans>
        </Button>}>
      <MenuHeader title={t({ message: 'Columns', comment: 'Table: Column toggle menu header' })} action={<Button type="button" appearance="ghost" size="xs" disabled={!visibleColumns.length} onClick={() => handleBulkToggle(false)}>
            <Trans comment="Table: Hide all columns button">Hide all</Trans>
          </Button>}/>

      <MenuBody>
        {Boolean(!visibleColumns.length) && <NoColumns />}

        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleSortable} modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}>
          <SortableContext items={visibleColumns} strategy={verticalListSortingStrategy}>
            <MenuList>
              {visibleColumns.map(({ id, label, disabled, active }) => (<SortableItem key={id} id={id} active={active} disabled={disabled} onToggle={() => handleToggleColumn(id)}>
                  {label}
                </SortableItem>))}
            </MenuList>
          </SortableContext>
        </DndContext>

        <MenuSubheader action={<Button type="button" appearance="ghost" size="xs" disabled={!hiddenColumns.length} onClick={() => handleBulkToggle(true)}>
              <Trans comment="Table: Show all columns button">Show all</Trans>
            </Button>}>
          <Trans comment="Table: Hidden columns subheader">Hidden</Trans>
        </MenuSubheader>

        {!hiddenColumns.length && <NoColumns />}

        {Boolean(hiddenColumns.length) && (<MenuList>
            {hiddenColumns.map(({ id, label, disabled, active }) => (<HiddenItem key={id} active={active} disabled={disabled} onToggle={() => handleToggleColumn(id)}>
                {label}
              </HiddenItem>))}
          </MenuList>)}
      </MenuBody>
    </Menu>);
}
