var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { getAllMarketCodes } from 'app/domains/markets/config';
import { localeConfig } from 'shared/domains/languages';
import { useAccount } from 'shared/domains/users';
import { TemplateBrowserComposersDocument } from './query.gql';
export const useTemplates = () => {
    var _a, _b, _c;
    const account = useAccount();
    const companyId = (_a = account.data) === null || _a === void 0 ? void 0 : _a.companyId;
    const marketCodes = getAllMarketCodes();
    const _d = useQuery(TemplateBrowserComposersDocument, {
        variables: { companyId, marketCodes },
    }), { data } = _d, rest = __rest(_d, ["data"]);
    const { company, composers = [] } = data || {};
    const companyPlan = (_c = (_b = company === null || company === void 0 ? void 0 : company.plans) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.plan;
    const flattenedTemplates = composers.flatMap((composer) => {
        const { templates, composerPlans } = composer;
        if (!templates) {
            return [];
        }
        const [metadata] = composer.metadata || [];
        return templates.map((template) => {
            var _a;
            return ({
                id: template.id,
                name: metadata.name,
                composerId: composer.id,
                locked: !((_a = composerPlans === null || composerPlans === void 0 ? void 0 : composerPlans.map(({ plan }) => plan)) === null || _a === void 0 ? void 0 : _a.find((plan) => (plan === null || plan === void 0 ? void 0 : plan.id) === (companyPlan === null || companyPlan === void 0 ? void 0 : companyPlan.id))),
                locale: {
                    code: template.marketLocaleCode,
                    name: localeConfig.getLocale({ languageCode: template.marketLocaleCode }).getName(),
                },
            });
        });
    });
    const sortedTemplates = flattenedTemplates.sort((a, b) => a.name.localeCompare(b.name));
    return Object.assign(Object.assign({}, rest), { templates: sortedTemplates });
};
