import { useLingui } from '@lingui/react';
import { Loader, Select } from '@pocketlaw/tetris';
import { useRole } from 'app/domains/users/hooks/useRole';
import { useUpdateUserRole } from 'app/domains/users/hooks/useUpdateUserRole';
import { roles } from 'app/domains/users/utils/roles';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { Container, SelectContainer } from './styled';
export function UserRole(props) {
    const { role, disabled, id, isSelf, selectLabel } = props;
    const userRole = useRole();
    const { updateRole, loading } = useUpdateUserRole();
    const { i18n } = useLingui();
    const { data } = useAccount();
    const filteredRoles = roles.filter(({ value }) => {
        var _a;
        if ((_a = data.company) === null || _a === void 0 ? void 0 : _a.ssoEnterprise) {
            return value !== 'admin';
        }
        return true;
    });
    // eslint-disable-next-line no-underscore-dangle
    const _i18n = i18n;
    const hasLowerRoleLevel = role === 'owner' && userRole.has('admin');
    const isDisabled = disabled || !userRole.can('invite:user') || isSelf || hasLowerRoleLevel;
    return (<Container>
      {loading && <Loader />}
      <SelectContainer>
        <Select stretch size="small" value={role} label={selectLabel} onChange={(e) => {
            const value = e.target.value;
            updateRole({ role: value, id });
        }} disabled={isDisabled || loading}>
          {filteredRoles.map(({ value, label }) => (<option disabled={userRole.has('admin') && value === 'owner'} key={value} value={value}>
              {_i18n._(label)}
            </option>))}
        </Select>
      </SelectContainer>
    </Container>);
}
