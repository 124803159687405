var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AclAction, CompanyRoleEnum } from 'shared/domains/apollo/generated/types';
import { DocumentUsersWithAclsDocument } from './query.gql';
const OWNER_ACTIONS = [AclAction.Read, AclAction.Write, AclAction.Delete];
export function getDocumentUsersWithAcls(apiConnector_1, documentId_1) {
    return __awaiter(this, arguments, void 0, function* (apiConnector, documentId, isExternal = false) {
        if (isExternal) {
            return {
                users: [],
                userAclsMap: {},
            };
        }
        const { data } = yield apiConnector.query({
            query: DocumentUsersWithAclsDocument,
            variables: { documentId, where: { fileSystemDocumentId: { eq: documentId } } },
        });
        const { companyUsers, entry, invites } = data;
        const { acls = [] } = entry || {};
        const documentCompanyUsers = companyUsers.map((companyUser) => {
            var _a, _b;
            return ({
                id: (_a = companyUser.user) === null || _a === void 0 ? void 0 : _a.id,
                name: (_b = companyUser.user) === null || _b === void 0 ? void 0 : _b.name,
            });
        });
        const documentExternalUsers = invites
            .filter(({ invitee }) => !!invitee)
            .map(({ invitee }) => ({ id: invitee === null || invitee === void 0 ? void 0 : invitee.id, name: invitee === null || invitee === void 0 ? void 0 : invitee.name }));
        const users = [...documentCompanyUsers, ...documentExternalUsers];
        const userAclsMap = companyUsers.reduce((acc, companyUser) => {
            const { role, user } = companyUser;
            const { id: userId } = user || {};
            if (!userId) {
                return acc;
            }
            acc[userId] = role === CompanyRoleEnum.Owner ? OWNER_ACTIONS : [];
            return acc;
        }, {});
        acls.forEach((acl) => {
            userAclsMap[acl.userId] = acl.actions;
        });
        const transformedExternalUsers = invites
            .filter(({ invitee }) => !!invitee)
            .map((user) => (Object.assign(Object.assign({}, user), { actions: [AclAction.Read] })));
        transformedExternalUsers.forEach((user) => {
            if (user.invitee) {
                userAclsMap[user.invitee.id] = user.actions;
            }
        });
        return { users, userAclsMap };
    });
}
