import { t } from '@lingui/macro';
import { SidebarMenuCollapsible } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useGetAllSavedViews } from './useGetAllSavedViews';
import { useDesktopNavigation } from '../../../DesktopNavigation';
import { CollapsibleItemLink } from '../CollapsibleItemLink';
import { useLinkMatch } from '../useLinkMatch';
export function CollapsibleSavedViews() {
    const { collapsibleStates, toggleCollapsible } = useDesktopNavigation();
    const { savedViews } = useGetAllSavedViews();
    const activeChild = useLinkMatch({
        matchPath: ['/saved-view'],
        matchExact: false,
    });
    if (!savedViews.length) {
        return null;
    }
    return (<SidebarMenuCollapsible icon={<Falcon icon="bookmark"/>} label={t({ comment: 'Sidebar menu - Saved views label', message: 'Saved views' })} activeChild={activeChild} collapsibleKey="savedViews" open={collapsibleStates.savedViews} onToggle={toggleCollapsible}>
      {savedViews.map((savedView) => (<CollapsibleItemLink key={savedView.id} to={`/saved-view/${savedView.id}`} label={savedView.name} matchPath={`/saved-view/${savedView.id}`}/>))}
    </SidebarMenuCollapsible>);
}
