import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
function useResetRefEffect(ref) {
    useEffect(() => {
        // eslint-disable-next-line no-param-reassign
        ref.current = undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
function useInitialValue(value) {
    const initialRef = useRef(value);
    useResetRefEffect(initialRef);
    return initialRef.current;
}
export function useURLState(initial, schema) {
    const history = useHistory();
    const initialState = useInitialValue(initial || {});
    const updateSearchParams = (validatedParams, oldSearchParams, shouldReRender = false) => {
        const newSearchParams = new URLSearchParams();
        Object.entries(validatedParams).forEach(([key, value]) => {
            if (value === undefined || value === null) {
                return;
            }
            if (Array.isArray(value)) {
                const paramValues = value;
                paramValues.forEach((paramValue) => {
                    newSearchParams.append(key, paramValue);
                });
            }
            else {
                const paramValue = value;
                newSearchParams.append(key, paramValue);
            }
        });
        const search = newSearchParams.toString();
        if (!oldSearchParams || oldSearchParams.toString().length !== search.length) {
            const url = `${window.location.pathname}?${search}`;
            if (shouldReRender) {
                history.replace(Object.assign(Object.assign({}, history.location), { search }));
            }
            else {
                window.history.replaceState({}, '', url);
            }
        }
    };
    const get = () => {
        const searchParams = new URLSearchParams(history.location.search);
        const data = {};
        searchParams.forEach((value, key) => {
            var _a;
            const { type } = ((_a = schema.describe()) === null || _a === void 0 ? void 0 : _a.fields[key]) || {};
            const typedKey = key;
            const defaultData = [];
            if (type === 'array' && !Array.isArray(data[typedKey])) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data[key] = defaultData;
            }
            if (type === 'array') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data[key].push(value);
            }
            else if (value === 'true' || value === 'false') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data[key] = value === 'true';
            }
            else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data[key] = value;
            }
        });
        const validatedParams = schema.validateSync(Object.assign(Object.assign({}, (initialState !== null && initialState !== void 0 ? initialState : {})), data), { stripUnknown: true });
        updateSearchParams(validatedParams, searchParams);
        return validatedParams;
    };
    const set = (params) => {
        updateSearchParams(params, undefined, true);
    };
    return { get, set };
}
