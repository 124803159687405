import { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
export function RedirectIndicator() {
    const [countdown, setCountdown] = useState(10);
    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (countdown === 0) {
                window.location.href = `/signed-out?returnTo=${window.location.pathname}`;
            }
            else {
                setCountdown(countdown - 1);
            }
        }, 1000);
        return () => clearInterval(countdownInterval);
    });
    return (<Typography>
      <Trans comment="Token expired dialog: Redirect indicator">You will be redirected in {countdown} seconds...</Trans>
    </Typography>);
}
