import { Command } from 'ckeditor5';
import { ATTACHMENT_CONSTANTS } from './editing';
export class InsertAttachmentWidgetCommand extends Command {
    static get pluginName() {
        return 'insertAttachment';
    }
    execute(attachment) {
        this.editor.model.enqueueChange({ isLocal: false, isUndoable: false }, (writer) => {
            const element = writer.createElement(ATTACHMENT_CONSTANTS.modelElement, {
                id: attachment.id,
                oembedUrl: attachment.oembedUrl,
            });
            this.editor.model.insertContent(element);
        });
    }
    refresh() {
        const { model } = this.editor;
        const { selection } = model.document;
        const position = selection.getFirstPosition();
        if (!position) {
            this.isEnabled = false;
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, ATTACHMENT_CONSTANTS.modelElement);
        this.isEnabled = allowedIn !== null;
    }
}
