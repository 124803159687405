import { Plugin } from 'shared/domains/pleditor@next';
// ---- Private
const REVIEW_REDLINE_HIGHLIGHT_NAME = 'review-redline-highlight';
const REVIEW_REDLINE_HIGHLIGHT_CLASS = 'review-redline-highlight';
const REVIEW_HIGHLIGHT_NAME = 'review-highlight';
const REVIEW_HIGHLIGHT_CLASS = 'review-highlight';
const NAME_MAP = {
    highlight: REVIEW_HIGHLIGHT_NAME,
    redline: REVIEW_REDLINE_HIGHLIGHT_NAME,
};
const CLASS_MAP = {
    highlight: REVIEW_HIGHLIGHT_CLASS,
    redline: REVIEW_REDLINE_HIGHLIGHT_CLASS,
};
const singleton = {
    plugin: null,
    init: (plugin) => {
        singleton.plugin = plugin;
    },
    destroy: () => {
        singleton.plugin = null;
    },
};
// ---- Public
export const withReviewPlugin = (fn) => {
    if (singleton.plugin) {
        return fn(singleton.plugin);
    }
    return null;
};
export const getReviewPluginMarkerRep = (kind) => ({
    name: `${NAME_MAP[kind]}-${Math.round(Math.random() * 100000)}`,
    kind,
});
export const highlightInDoc = (node, rep, fromOffset, toOffset) => withReviewPlugin((p) => {
    p.editor.conversion.for('downcast').markerToHighlight({
        model: rep.name,
        view: () => ({ classes: CLASS_MAP[rep.kind] }),
        converterPriority: 'high',
    });
    p.editor.editing.model.change((writer) => {
        const root = p.editor.model.document.getRoot();
        if (!root)
            return;
        const range = fromOffset !== undefined && toOffset !== undefined
            ? writer.createRange(writer.createPositionAt(node, fromOffset !== null && fromOffset !== void 0 ? fromOffset : 0), writer.createPositionAt(node, toOffset !== null && toOffset !== void 0 ? toOffset : node.offsetSize))
            : writer.createRangeOn(node);
        writer.addMarker(rep.name, {
            range,
            usingOperation: false,
            affectsData: false,
        });
    });
});
export const redlineInDoc = (node, text, type, fromOffset, toOffset) => withReviewPlugin((p) => {
    const trackChangesCommand = p.editor.commands.get('trackChanges');
    if (!trackChangesCommand) {
        return;
    }
    const currentTrackChangesValue = trackChangesCommand.value;
    // Start track changes if it is not already on
    if (!currentTrackChangesValue) {
        p.editor.execute('trackChanges');
    }
    p.editor.editing.model.change((writer) => {
        const root = p.editor.model.document.getRoot();
        if (!root)
            return;
        const range = fromOffset !== undefined && toOffset !== undefined
            ? writer.createRange(writer.createPositionAt(node, fromOffset !== null && fromOffset !== void 0 ? fromOffset : 0), writer.createPositionAt(node, toOffset !== null && toOffset !== void 0 ? toOffset : node.offsetSize))
            : writer.createRangeOn(node);
        if (type === 'modify') {
            const suggestion = writer.createText(text);
            p.editor.model.insertContent(suggestion, range);
        }
        if (type === 'remove') {
            const suggestion = writer.createText('');
            p.editor.model.insertContent(suggestion, range);
        }
        if (type === 'prepend') {
            const suggestion = writer.createText(`${text} `);
            p.editor.model.insertContent(suggestion, node, 0);
        }
        if (type === 'append' && node.is('element')) {
            const suggestion = writer.createText(` ${text}`);
            p.editor.model.insertContent(suggestion, node, 'end');
        }
    });
    // Stop track changes again if it was turned off to begin with
    if (!currentTrackChangesValue) {
        p.editor.execute('trackChanges');
    }
});
export class ReviewPlugin extends Plugin {
    constructor() {
        super(...arguments);
        this.init = () => {
            // TODO: No need for a singleton pattern because all ckeditor plugins only have one instance. see https://github.com/pocketsolutions/app/pull/4068#discussion_r1530606055
            singleton.init(this);
        };
    }
    static get pluginName() {
        return 'Review';
    }
    destroy() {
        singleton.destroy();
        this.stopListening();
    }
}
