import { useState } from 'react';
import { hasValidValue } from '../MetadataField/utils';
export function useToggleEntryVisibility(properties) {
    const [draft, setDraft] = useState();
    const entries = properties.filter(({ pair }) => hasValidValue(pair.value)).map(({ property }) => property.name);
    const toggleActiveEntry = (entryName) => {
        setDraft((prev) => {
            const isEntry = entries.includes(entryName);
            const isDraft = prev === entryName;
            if (isDraft || isEntry) {
                return undefined;
            }
            return entryName;
        });
    };
    const activeEntries = draft ? [...new Set([...entries, draft])] : entries;
    return {
        activeEntries,
        toggleActiveEntry,
    };
}
