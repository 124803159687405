import { Fragment, useState, useId } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { TasksTableFilters } from '../TasksTableFilters';
import { StyledDrawer, MobileContainer, DesktopContainer, DrawerHeader, DrawerBody } from './styled';
export function TasksFilterMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const drawerId = useId();
    return (<Fragment>
      <StyledDrawer open={isOpen} position="right" onClose={() => setIsOpen(false)}>
        <DrawerHeader id={drawerId}>
          <Typography $fontSize="large">
            <Trans comment="Tasks filter drawer title">Filters</Trans>
          </Typography>
        </DrawerHeader>
        <DrawerBody>
          <TasksTableFilters />
          <Button appearance="ghost" $stretch onClick={() => setIsOpen(false)}>
            <Trans comment="close filter drawer tasks">Done</Trans>
          </Button>
        </DrawerBody>
      </StyledDrawer>

      <MobileContainer>
        <Button appearance="secondary" onClick={() => setIsOpen(!isOpen)} aria-expanded={isOpen} aria-controls={drawerId} size="small">
          <Trans comment="Tasks filter drawer button">Filter</Trans>
          <Falcon icon="plus"/>
        </Button>
      </MobileContainer>

      <DesktopContainer>
        <TasksTableFilters />
      </DesktopContainer>
    </Fragment>);
}
