import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
const fadeInAnimation = keyframes `
  0% {
    opacity: 0;
    filter: blur(0.5rem);
    transform: translateY(50%);
  }
  
  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    filter: blur(0rem);
    transform: translateY(0%);
  }
`;
const getAppearanceStyles = (appearance) => {
    if (appearance === 'primary') {
        return css `
      background-color: ${themeGet('colors.background.100')};
    `;
    }
    return css `
    padding-left: 0;
    padding-right: 0;
    background-color: ${themeGet('colors.background.50')};
  `;
};
const MessageStreamingDot = css `
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: ${pxToRem(8)};
  height: ${pxToRem(8)};
  margin-left: ${themeGet('spacing.md')};
  background: ${themeGet('colors.ai.500')};
  border-radius: ${themeGet('borderRadius.circle')};
`;
export const MessageHeaderContainer = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.md')};
  justify-content: ${(props) => (props.$align === 'left' ? 'flex-start' : 'flex-end')};
`;
export const MessageBubble = styled.div `
  padding: ${themeGet('spacing.md')} ${themeGet('spacing.xl')};
  border-radius: ${themeGet('borderRadius.lg')};

  ${(props) => props.$streaming &&
    css `
      table {
        overflow: hidden;
      }

      & > *:last-child:not(table, blockquote, :has(sup)),
      & > blockquote:last-child p:last-child,
      & > ul:last-child li:last-child,
      & > ol:last-child li:last-child,
      & > table:last-child tr:last-child td:not(:empty):last-of-type,
      & > table:last-child tr:last-child td:not(:empty):has(+ td:empty) {
        animation: ${fadeInAnimation} 450ms ease-out forwards;

        &:after {
          ${MessageStreamingDot}
        }
      }
    `}
`;
export const MessageActions = styled.div `
  display: flex;
  align-items: center;
  gap: ${themeGet('spacing.sm')};
  padding: 0 ${themeGet('spacing.xl')};
  transition: opacity 175ms ease-in-out;
`;
export const MessageBadge = styled.div `
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  background-color: ${themeGet('colors.blue.500')};
  border-radius: ${themeGet('borderRadius.circle')};

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.0')};
    width: ${pxToRem(12)};
    height: ${pxToRem(12)};
  }
`;
export const MessageContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xs')};

  ${({ $grouped }) => `margin-bottom: ${$grouped ? '0' : themeGet('spacing.2xl')}`};

  margin-left: ${({ $align }) => ($align === 'right' ? 'auto' : '0')};
  max-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '80%')};

  ${MessageBubble} {
    margin-left: ${({ $align }) => ($align === 'right' ? 'auto' : '0')};
    ${({ $appearance }) => getAppearanceStyles($appearance)};
  }

  ${MessageActions} {
    ${({ $showActions }) => ($showActions ? 'opacity: 1' : 'opacity: 0')};
  }
`;
