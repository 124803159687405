import { pxToRem, scrollbar, themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const TemplateCardContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${pxToRem(200)};
  justify-content: space-between;

  background-image: url(${(props) => props.bg});
  background-position: center;

  padding: ${themeGet('spacing.xl')} ${themeGet('spacing.lg')};
  margin-bottom: ${themeGet('spacing.lg')};

  border-radius: ${themeGet('borderRadius.md')};
  border: ${pxToRem(1)} solid ${themeGetColor('border.100')};

  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: ${themeGetColor('border.200')};
  }
`;
export const EmptyButton = styled.button `
  width: 100%;
  cursor: pointer;

  &:hover {
    ${TemplateCardContainer} {
      cursor: pointer;
    }
  }
`;
export const RightColumn = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: auto;
  ${scrollbar({ trackMargin: 'xl' })};
`;
export const HeaderContainer = styled.div `
  display: flex;
  width: 100%;
  height: 10rem;
  align-items: flex-end;
  gap: ${themeGet('spacing.md')};
  padding: 0 ${themeGet('spacing.4xl')};
  padding-bottom: ${themeGet('spacing.2xl')};

  flex-shrink: 0;

  background-color: ${themeGetColor('background.75')};
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center;
`;
