import { Fragment } from 'react';
import { Box, Chip, IconButton, Typography, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
export function CustomerDetail(props) {
    const { title, value, chips } = props;
    const { addToast } = useToast();
    const handleCopy = () => {
        if (value) {
            navigator.clipboard.writeText(value);
            addToast({
                appearance: 'success',
                title: 'Copied to clipboard!',
            });
        }
    };
    return (<Box flexDirection="column" gap="sm">
      <Typography $appearance="300" $fontSize="large">
        {title}
      </Typography>
      <Box alignItems="center" gap="md" style={{ flexWrap: 'wrap' }}>
        {value && (<Fragment>
            <Typography $appearance="200">{value}</Typography>
            <IconButton $round size="xs" appearance="ghost" title="Copy to clipboard" onClick={handleCopy}>
              <Falcon icon="copy"/>
            </IconButton>
          </Fragment>)}
        {chips && chips.map((chip) => <Chip key={chip} appearance="secondary" label={chip}/>)}
      </Box>
    </Box>);
}
