import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
const LINE_HEIGHT = pxToRem(2);
const HEADER_LINE_HEIGHT = pxToRem(4);
const SUBHEADER_LINE_HEIGHT = pxToRem(3);
const Redlined = css `
  background-color: ${themeGet('colors.red.200')};
`;
export const Line = styled.div `
  position: relative;
  height: ${LINE_HEIGHT};
  width: ${(props) => props.$width}%;
  border-radius: ${themeGet('borderRadius.xs')};
  background-color: ${themeGet('colors.gray.100')};

  ${(props) => props.$redlined && Redlined}
`;
export const HeaderLine = styled(Line) `
  height: ${HEADER_LINE_HEIGHT};
  background-color: ${themeGet('colors.gray.300')};
`;
export const SubheaderLine = styled(Line) `
  height: ${SUBHEADER_LINE_HEIGHT};
  background-color: ${themeGet('colors.gray.200')};
`;
export const Paragraph = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.sm')};
`;
