import { gql } from '@apollo/client';
import { AccountFragment, ProfileFragment } from 'shared/domains/users/utils/fragments';
export const accountQuery = gql `
  query ProfileAccountQuery {
    profile @client {
      ...ProfileFragment @unmask
    }
    account @client {
      ...AccountFragment @unmask
    }
  }
  ${ProfileFragment}
  ${AccountFragment}
`;
