import { useField } from 'formik';
import { DocumentAttachmentItem } from 'shared/domains/documents';
export function FormItem(props) {
    const { attachment, disabled } = props;
    const [{ value: attachments }, , { setValue }] = useField('attachments');
    const handleRemoveAttachment = () => {
        setValue(attachments.filter((a) => a.id !== attachment.id));
    };
    const handleRenameAttachment = (name) => {
        setValue(attachments.map((a) => {
            if (a.id === attachment.id) {
                return Object.assign(Object.assign({}, a), { name });
            }
            return a;
        }));
    };
    return (<DocumentAttachmentItem attachment={attachment} disabled={disabled} onRemove={handleRemoveAttachment} onRename={handleRenameAttachment}/>);
}
