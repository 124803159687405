import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Tab, TabContent, TabNav, Tabs, Typography } from '@pocketlaw/tetris';
import { dashboardTemplates, useInsightsTemplates } from './Context';
import { TemplatePreview } from './TemplatePreview';
export function TemplateOverview() {
    const { selected } = useInsightsTemplates();
    const { _ } = useLingui();
    const selectedTemplate = dashboardTemplates.find((template) => template.id === selected);
    if (!selectedTemplate) {
        return null;
    }
    return (<Box flexDirection="column" p="2xl" pb="0">
      <Tabs>
        <TabNav>
          <Tab id="preview">
            <Trans comment="Insight dashboard template: Preview tab">Preview</Trans>
          </Tab>
          <Tab id="description">
            <Trans comment="Insight dashboard template: Description tab">Description</Trans>
          </Tab>
        </TabNav>
        <Box my="2xl" width="100%" height="100%" display="block">
          <TabContent tabId="preview">
            <TemplatePreview />
          </TabContent>
          <TabContent tabId="description">
            <Typography>{_(selectedTemplate.description)}</Typography>
          </TabContent>
        </Box>
      </Tabs>
    </Box>);
}
