import { Component } from 'react';
import * as Sentry from 'shared/domains/sentry';
/**
 * ComponentErrorBoundary is a component that catches errors in its children and renders a fallback
 * component instead.
 */
export class ComponentErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        Sentry.withScope((scope) => {
            scope.setExtras(info);
            Sentry.captureException(error);
        });
    }
    render() {
        const { children, fallback } = this.props;
        const { hasError } = this.state;
        if (hasError) {
            return fallback;
        }
        return children;
    }
}
