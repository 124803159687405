import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Alert, Avatar, Button, Dialog, DialogContent, DialogFooter, DialogHeader, IconButton, Loader, RadioCard, } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useDownloadFilesystemDocument } from 'app/domains/filesystem';
import { LoaderContainer, RadioCardContainer } from './styled';
export function DownloadDocumentDialog(props) {
    const { onClose, id } = props;
    const { files, loading, error } = useDownloadFilesystemDocument(id);
    const [selectedFile, setSelectedFile] = useState();
    const isLoading = !files.length && loading;
    const isFetching = Boolean(files.length) && loading;
    const handleDownloadClick = () => {
        selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.fetch().then(onClose);
    };
    return (<Dialog size="sm">
      <DialogHeader title={t({ message: 'Download', comment: 'Download dialog header title' })} subtitle={t({
            message: 'Choose the format of your file',
            comment: 'Download dialog header subtitle',
        })} media={<Avatar appearance="secondary">
            <Falcon icon="download"/>
          </Avatar>} action={<IconButton appearance="ghost" onClick={onClose}>
            <Falcon icon="close"/>
          </IconButton>}/>
      <DialogContent>
        {isLoading && (<LoaderContainer>
            <Loader size={10}/>
          </LoaderContainer>)}

        {error ? (<Alert message={t({
                message: 'Something went wrong. Please try again later',
                comment: 'Error alert message while downloading document',
            })} appearance="danger"/>) : (<RadioCardContainer>
            {files.map((file) => (<RadioCard key={file.type} checked={file.type === (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.type)} title={file.name} onChange={() => {
                    setSelectedFile(file);
                }}/>))}
          </RadioCardContainer>)}
      </DialogContent>
      <DialogFooter>
        <Button appearance="secondary" onClick={onClose}>
          <Trans comment="Cancel button label">Cancel</Trans>
        </Button>
        <Button disabled={!selectedFile || isFetching} onClick={handleDownloadClick}>
          <Trans comment="Download button label">Download</Trans>
          {isFetching && <Loader />}
        </Button>
      </DialogFooter>
    </Dialog>);
}
