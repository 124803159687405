import { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AccessTokenExpiredDialog } from 'app/domains/auth/components/AccessTokenExpiredDialog';
import useAuthRedirect from 'app/domains/auth/components/useAuthRedirect';
import { useAuth } from 'app/domains/auth/hooks/useAuth';
import { useGetPlanIdFromType } from 'app/domains/companies';
import { GlobalProviders } from 'app/domains/init';
import useEnsureCorrectMarket from 'app/domains/markets/hooks/useEnsureCorrectMarket';
import { signupDataHelper } from 'app/domains/signup/utils/signupDataHelper';
import { signupPlanType } from 'app/domains/signup/utils/signupPlanType';
import useSendGtmData from 'app/domains/tracking/hooks/useSendGtmData';
import { PageLoader } from 'shared/domains/common-ui';
import { useCompanyInviteLocalStorage } from 'shared/domains/common-utils';
import { AppError } from 'shared/domains/errors';
import * as Sentry from 'shared/domains/sentry'; // TODO: better import path or location
import useInit from './useInit';
/**
 * Routes that require auth to access
 */
export function AuthRoutes(props) {
    var _a, _b, _c, _d;
    const { children } = props;
    const location = useLocation();
    const auth = useAuth();
    const initQuery = useInit({ skip: !((_b = (_a = auth.data) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.userId) });
    const planType = signupPlanType.get();
    const plan = useGetPlanIdFromType(planType);
    const { getInviteCompanyId } = useCompanyInviteLocalStorage();
    const { account, profile } = (_c = initQuery.data) !== null && _c !== void 0 ? _c : {};
    const { userId, verified } = profile !== null && profile !== void 0 ? profile : {};
    const { companyId } = account || {};
    const redirectingToMarket = useEnsureCorrectMarket((_d = account === null || account === void 0 ? void 0 : account.company) === null || _d === void 0 ? void 0 : _d.marketCode);
    const redirectingToLogin = useAuthRedirect();
    const loading = auth.loading || redirectingToLogin || initQuery.loading || redirectingToMarket || plan.loading;
    const error = !userId || auth.error || initQuery.error;
    useSendGtmData({ skip: Boolean(loading || error) });
    useEffect(() => {
        Sentry.getCurrentScope()
            .setUser({
            id: userId,
        })
            .setTag('companyId', companyId);
    }, [userId, companyId]);
    if (loading) {
        return <PageLoader delay={0}/>;
    }
    if (error) {
        return (<AppError code={400} message={<Trans>
            There was an error when we tried to authenticate you. Please try to reload the page. Please contact us if
            this message persists.
          </Trans>}/>);
    }
    if (!companyId) {
        const inviteId = getInviteCompanyId();
        if (inviteId) {
            return <Redirect to={`/invite/${inviteId}`}/>;
        }
        return <Redirect to="/company-creation"/>;
    }
    // clear onboarding data. Make sure previous data is not used again
    signupDataHelper.clear();
    if (companyId && !verified && location.pathname !== '/verify') {
        return <Redirect to="/verify"/>;
    }
    if (plan.planId && verified && companyId) {
        signupPlanType.clear();
        return <Redirect to={`/checkout?planId=${plan.planId}`}/>;
    }
    return (<Route>
      <GlobalProviders>
        <Switch>{children}</Switch>
        <AccessTokenExpiredDialog />
      </GlobalProviders>
    </Route>);
}
