import { Plural, Trans, t } from '@lingui/macro';
import { Button, LoaderOverlay } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { getMarketByCode } from 'app/domains/markets/config';
import { DocumentAttachmentItem, DocumentAttachments } from 'shared/domains/documents';
import { MetadataApprovers } from './MetadataApprovers';
import { MetadataEntry } from './MetadataEntry';
import { SelectLanguage } from './SelectLanguage';
import { useDraftPreview } from '../../DraftPreviewProvider';
import { Container } from './styled';
export function TemplateData() {
    const { locked, estimatedTime, marketCode, createDraft, category, composer } = useDraftPreview();
    const { attachments } = composer;
    const { approvers, loading } = useDraftPreview();
    const market = getMarketByCode(marketCode);
    return (<LoaderOverlay enabled={loading}>
      <Container>
        <Button $stretch disabled={locked} onClick={createDraft}>
          {locked && <Falcon icon="lock"/>}
          <Trans comment="Preview template - Create document from template label">Create document</Trans>
        </Button>
        <MetadataEntry label={t({
            comment: 'Preview template - Language label',
            message: 'Language',
        })} component={<SelectLanguage />}/>
        <MetadataEntry label={t({ comment: 'Preview template - Category label', message: 'Category' })} component={category.title}/>
        {estimatedTime !== undefined && (<MetadataEntry label={t({
                comment: 'Preview template - Estimated time label',
                message: 'Estimated time',
            })} component={<Plural value={estimatedTime} one="# minute" other="# minutes"/>}/>)}
        {approvers.length > 0 && (<MetadataEntry label={t({ comment: 'Preview template - Approvers label', message: 'Approvers' })} component={<MetadataApprovers />}/>)}
        <MetadataEntry label={t({ comment: 'Preview template - Jurisdiction label', message: 'Jurisdiction' })} component={market === null || market === void 0 ? void 0 : market.getName()}/>
        {attachments.length > 0 && (<MetadataEntry label={t({ comment: 'Preview template - Attachments label', message: 'Attachments' })} component={<DocumentAttachments hideAdd attachments={attachments} render={(attachment) => <DocumentAttachmentItem key={attachment.id} attachment={attachment}/>}/>}/>)}
      </Container>
    </LoaderOverlay>);
}
