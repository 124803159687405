import { breakpoints, pxToRem, themeGet, TableBodyCell, TableHeadCell } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const SelectContainer = styled.div ``;
export const SelectInnerContainer = styled.div `
  max-width: ${pxToRem(150)};
`;
export const StyledCol = styled.col `
  ${breakpoints.sm} {
    ${(props) => props.$showMobileOnly && 'display: none;'};
  }

  ${breakpoints.xs} {
    ${(props) => props.$hideMobile && 'display: none;'};
  }
`;
export const StyledTableBodyCell = styled(TableBodyCell) `
  ${breakpoints.xs} {
    ${(props) => props.$hideMobile && 'display: none;'};
  }
`;
export const StyledTableHeadCell = styled(TableHeadCell) `
  ${breakpoints.sm} {
    ${(props) => props.$showMobileOnly && 'display: none;'};
  }

  ${breakpoints.xs} {
    ${(props) => props.$hideMobile && 'display: none;'};
  }
`;
export const TableRowCollapseContentContainer = styled.ul `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${themeGet('spacing.xl')};
  padding: ${themeGet('spacing.md')};
`;
export const TableRowCollapseContentItem = styled.li `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
