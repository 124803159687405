var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { Box, H6 } from '@pocketlaw/tetris';
import * as yup from 'yup';
import { DefaultSearchFilters } from 'app/domains/global-search';
import { FormDialog, SubmitButton, CancelButton, TextAreaField, TextField } from 'shared/domains/forms';
import { AddDataPoint } from './AddDataPoint';
import { ChartSelection } from './ChartSelection';
import { ColorSelection } from './ColorSelection';
import { DataPoints } from './DataPoints';
import { Label } from './Label';
import { Preview } from './Preview';
import { widgetValuesValidation } from '../../utils/widgetValuesValidation';
import { ColumnContainer, InputColumn, PreviewColumn, PreviewFooter } from './styled';
export function WidgetForm(props) {
    const { onSubmit, onClose, initialValues, initialQuery } = props;
    const values = widgetValuesValidation(initialValues);
    const validationSchema = yup.object({
        label: yup
            .string()
            .required(t({ message: 'Label is required', comment: 'Insights widget form: Label validation' })),
    });
    const handleOnSubmit = (data) => __awaiter(this, void 0, void 0, function* () {
        const { aggregations, label, type, colorScheme, description, filters, isHorizontal, id, sourceId } = data;
        yield onSubmit({
            aggregations,
            label,
            type,
            colorScheme,
            description,
            filters,
            isHorizontal,
            id,
            sourceId,
        });
        onClose();
    });
    return (<FormDialog initialValues={values} onClose={onClose} size="xl" onSubmit={handleOnSubmit} validationSchema={validationSchema}>
      <ColumnContainer>
        <InputColumn>
          <Box mb="2xl">
            <H6>
              <Trans comment="Insights widget form: Options">Options</Trans>
            </H6>
          </Box>
          <Box gap="2xl" flexDirection="column">
            <Box flexDirection="column">
              <Label>
                <Trans comment="Insights widget form: Chart selection label">Visual</Trans>
              </Label>
              <ChartSelection />
            </Box>

            <Box flexDirection="column">
              <Label>
                <Trans comment="Insights widget form: Chart selection label">Color</Trans>
              </Label>
              <ColorSelection />
            </Box>

            <Box flexDirection="column">
              <Label>
                <Trans comment="Insights widget form: Data point selection label">Metric</Trans>
              </Label>

              <Box flexDirection="column">
                <AddDataPoint />
                <Box gap="2xl" flexDirection="column">
                  <DataPoints name="aggregations"/>
                </Box>
              </Box>
            </Box>

            <Box flexDirection="column">
              <Label>
                <Trans comment="Insights widget form: Filters label">Filters</Trans>
              </Label>
              <DefaultSearchFilters />
            </Box>
          </Box>
        </InputColumn>
        <PreviewColumn>
          <Box gap="md" flexDirection="column">
            <Preview initialQuery={initialQuery}/>

            <TextField name="label" label={t({
            message: 'Name',
            comment: 'Insights widget form: Name placeholder',
        })} placeholder={t({
            message: 'Give your widget a name',
            comment: 'Insights widget form: Name placeholder',
        })}/>
            <TextAreaField name="description" label={t({ message: 'Description', comment: 'Insights widget form: Description' })} placeholder={t({
            message: 'Help others understand your widget by adding a description',
            comment: 'Insights widget form: Description placeholder',
        })} minHeight={24}/>
          </Box>

          <PreviewFooter>
            <CancelButton onClick={onClose}>
              <Trans comment="Insights widget form: Cancel button">Cancel</Trans>
            </CancelButton>
            <SubmitButton>
              <Trans comment="Insights widget form: Submit button">Submit</Trans>
            </SubmitButton>
          </PreviewFooter>
        </PreviewColumn>
      </ColumnContainer>
    </FormDialog>);
}
