import { Trans } from '@lingui/macro';
import { MainHeading } from '@pocketlaw/tetris';
import { Paragraph, Spacer } from '@pocketlaw/tetris/deprecated';
import { showReportDialog } from '@sentry/browser';
import DeriveTitle from './DeriveTitle';
import illustration from './illustration.svg';
import { Container, ErrorCode, Image, Line, StyledButton } from './styled';
export function AppError(props) {
    const { id, code = 500, message, title, action } = props;
    return (<Container data-testid="app-error">
      <div>
        {code && <ErrorCode appearance="neutral">{String(code)}</ErrorCode>}
        <MainHeading>{title || <DeriveTitle code={code}/>}</MainHeading>
        <Line />
        <Paragraph appearance="neutral">
          {message || (<Trans>We have been notified and are working on fixing the issue. Please try to reload the page.</Trans>)}
        </Paragraph>
        <Spacer>
          {id && (<StyledButton type="button" size="large" onClick={() => showReportDialog({ eventId: id })}>
              <Trans>Report error feedback</Trans>
            </StyledButton>)}
          {action || (<StyledButton as="a" href="/" size="large">
              <Trans>Take me back</Trans>
            </StyledButton>)}
        </Spacer>
      </div>
      <Image src={illustration} alt="Not Found"/>
    </Container>);
}
