import { useCallback, memo } from 'react';
import T from 'prop-types';

import { useFileSystemAccessDrawer } from 'app/domains/filesystem';
import { AclAction, EntryKind } from 'shared/domains/apollo/generated/types';
import { isFreeTextDocument } from 'shared/domains/documents';

import DocumentTableRow from './DocumentTableRow';
import FolderTableRow from './FolderTableRow';

function FileSystemTableRow(props) {
  const { id, type, name, acl, teamAcls, onDuplicate, onRename, onMove, onQuickMove, onDelete, ...restProps } = props;

  const { setEntryId } = useFileSystemAccessDrawer();

  const handleDuplicate = useCallback(() => {
    const { documentType } = restProps;
    const isFreeText = isFreeTextDocument({ type: documentType });

    onDuplicate({ type, id, isFreeText, name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDuplicate, type, id]);

  const handleRename = useCallback(() => {
    onRename({ type, id, name });
  }, [onRename, type, id, name]);

  const handleMove = useCallback(() => {
    onMove({ type, id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMove, id]);

  const handleQuickMove = useCallback(
    (source) => {
      const [sourceType, sourceId] = source?.split(':') || [];

      if (sourceType && sourceId) {
        onQuickMove({ type: sourceType, sourceId, targetId: id });
      }
    },
    [onQuickMove, id],
  );

  const handleDelete = useCallback(() => {
    onDelete({ type, id });
  }, [onDelete, type, id]);

  const handleEditAccess = useCallback(() => {
    setEntryId(id);
  }, [id, setEntryId]);

  const newProps = {
    id,
    type,
    name,
    onRename: handleRename,
    onDelete: handleDelete,
    onEditAccess: handleEditAccess,
  };

  switch (type) {
    case EntryKind.Folder:
      return (
        <FolderTableRow
          {...restProps}
          {...newProps}
          acl={acl}
          teamAcls={teamAcls}
          onQuickMove={handleQuickMove}
          onMove={handleMove}
        />
      );
    case EntryKind.Document:
      return <DocumentTableRow {...restProps} {...newProps} onDuplicate={handleDuplicate} onMove={handleMove} />;
    default:
      return null;
  }
}

FileSystemTableRow.propTypes = {
  id: T.string,
  type: T.oneOf([EntryKind.Folder, EntryKind.Document]).isRequired,
  name: T.string.isRequired,
  acl: T.arrayOf(
    T.shape({
      user: T.shape({
        id: T.string.isRequired,
        name: T.string,
        email: T.string,
      }),
      actions: T.arrayOf(T.oneOf([AclAction.Read, AclAction.Write, AclAction.Delete, AclAction.Traverse])),
    }).isRequired,
  ).isRequired,
  teamAcls: T.arrayOf(
    T.shape({
      id: T.string.isRequired,
      teamId: T.string.isRequired,
      actions: T.arrayOf(T.oneOf([AclAction.Read, AclAction.Write, AclAction.Delete, AclAction.Traverse])),
      team: T.shape({
        id: T.string.isRequired,
        name: T.string.isRequired,
      }),
    }).isRequired,
  ).isRequired,
  onDuplicate: T.func.isRequired,
  onRename: T.func.isRequired,
  onMove: T.func.isRequired,
  onQuickMove: T.func.isRequired,
  onDelete: T.func.isRequired,
};

FileSystemTableRow.defaultProps = {
  id: undefined,
};

FileSystemTableRow.displayName = 'FileSystemTableRow';

const MemoizedComponent = memo(FileSystemTableRow);
MemoizedComponent.displayName = 'FileSystemTableRow';

export default MemoizedComponent;
