import { padding, unit, Logotype, pxToRem, themeGet } from '@pocketlaw/tetris';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const SIDE_WIDTH = 25;
export const StyledLink = styled(Link) `
  color: ${themeGet('colors.gray.700')};
`;
export const Container = styled.div `
  display: grid;
  grid-template-areas: 'left center right';
  grid-template-columns: ${unit(SIDE_WIDTH)} 1fr ${unit(SIDE_WIDTH)};
  align-items: center;
  height: ${(props) => pxToRem(props.$height)};
  ${padding(0, 6)};
  border-bottom: ${pxToRem(1)} solid ${themeGet('colors.gray.100')};
  background-color: ${themeGet('colors.gray.0')};
`;
export const Left = styled.div `
  grid-area: left;
`;
export const Center = styled.div `
  grid-area: center;
  text-align: center;
  display: inline-flex;
  margin: auto;
`;
export const Spacer = styled.div `
  grid-area: right;
`;
export const StyledLogotype = styled(Logotype) `
  width: ${unit(22)};
  height: ${unit(4)};
`;
