import { memo, useReducer, useCallback, useState, useEffect } from 'react';
import { Trans, t, select } from '@lingui/macro';
import {
  Loader,
  Button,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  Grid,
  Column,
  Table,
  TableBody,
  AvatarWithLabel,
  Typography,
} from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import T from 'prop-types';

import { useFolderPicker, Breadcrumbs, getBreadcrumbs, useACLCan } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { Pagination } from 'shared/domains/common-ui';

import { FolderPickerTableHead } from './FolderPickerTableHead';
import FolderPickerTableRow from './FolderPickerTableRow';
import { ROOT } from '../../utils/constants';
import { LoaderContainer, PaginationContainer } from './styled';

function reducer(state, action) {
  switch (action.type) {
    case 'ENTER':
      return { folderId: undefined, parentId: action.payload };
    case 'WORKSPACE':
      return { folderId: undefined, parentId: undefined };
    case 'CHANGE':
      return { ...state, folderId: action.payload };
    default:
      return state;
  }
}

const PAGE_SIZE = 50;

function FolderPickerDialog(props) {
  const { loading, entryId, showDisabledRows, defaultParentId, entryType, actionType, onSubmit, onClose } = props;

  const [entriesOffset, setEntriesOffset] = useState(0);

  const [{ parentId, folderId }, dispatch] = useReducer(reducer, {
    parentId: defaultParentId,
    folderId: undefined,
  });

  useEffect(() => {
    setEntriesOffset(0);
  }, [parentId]);

  const selectedId = folderId || parentId;

  const handleSelect = useCallback((id) => {
    dispatch({ type: 'ENTER', payload: id });
  }, []);

  const handleSelectBreadcrumb = useCallback((crumb) => {
    if (crumb.kind === 'FOLDER') {
      dispatch({ type: 'ENTER', payload: crumb.id });
    }

    if (crumb.kind === 'WORKSPACE') {
      dispatch({ type: 'WORKSPACE' });
    }
  }, []);

  const handleChange = useCallback((id) => {
    dispatch({ type: 'CHANGE', payload: id });
  }, []);

  const query = useFolderPicker({
    entryId,
    parentId,
    limit: PAGE_SIZE,
    offset: entriesOffset,
  });

  const { folder, children, entry, count } = query.data || {};
  const entryName = entry?.name;

  const breadcrumbs = getBreadcrumbs(query.data);

  const selectedFolder = children?.find(({ id }) => id === selectedId) ?? folder;

  const { canWrite, canTraverse } = useACLCan({
    userAcls: selectedFolder?.acl,
    teamAcls: selectedFolder?.teamAcls,
  });

  const handleSubmit = useCallback(() => {
    onSubmit(selectedFolder);
  }, [onSubmit, selectedFolder]);

  const getDisabledState = () => {
    const movingToSameFolder = entry && entry.parentId === selectedId;
    const notAllowedToSaveInFolder = selectedFolder && !canWrite() && canTraverse();
    const movingToSameRoot = !selectedFolder && entry?.parentId === ROOT;

    return movingToSameFolder || notAllowedToSaveInFolder || movingToSameRoot;
  };

  function getDialogTitle() {
    if (actionType === 'move') {
      return t({
        message: `Move "${entryName}"`,
        comment: 'Move folder dialog title',
      });
    }

    if (actionType === 'create') {
      return select(entryType, {
        FOLDER: 'New Folder',
        DOCUMENT: 'New Document',
        other: null,
      });
    }

    return '';
  }

  const dialogTitle = getDialogTitle();

  return (
    <Dialog size="md">
      <DialogHeader
        media={
          <AvatarWithLabel size="xl" shape="square" title={dialogTitle}>
            <Falcon icon="arrow-circle-right" />
          </AvatarWithLabel>
        }
      />
      <DialogContent>
        <Grid>
          <Column width="100%">
            <Typography $appearance="200">
              {select(entryType, {
                FOLDER: `Choose where you want to save the Folder`,
                DOCUMENT: `Choose where you want to save the Document`,
                other: `Choose where you want to save`,
              })}
            </Typography>
          </Column>
          <Column width="100%">
            {query.loading && !children?.length ? null : (
              <Breadcrumbs breadcrumbs={breadcrumbs} onSelect={handleSelectBreadcrumb} />
            )}
            {children?.length > 0 ? (
              <Table dense>
                <colgroup>
                  <col width={1} />
                  <col width="auto" />
                  <col width={1} />
                </colgroup>
                <FolderPickerTableHead />
                <TableBody>
                  {children
                    .filter(({ kind }) => showDisabledRows || kind === EntryKind.Folder)
                    .map(({ id, kind, ...rest }) => (
                      <FolderPickerTableRow
                        {...rest}
                        key={id}
                        id={id}
                        type={kind}
                        selected={id === folderId}
                        onSelect={handleSelect}
                        onChange={handleChange}
                        movedEntryId={entryId}
                      />
                    ))}
                </TableBody>
              </Table>
            ) : null}
            {children && !children.length ? (
              <p>
                <Trans>This folder is empty</Trans>
              </p>
            ) : null}
            {query.loading && !children ? (
              <LoaderContainer>
                <Loader size={12} />
              </LoaderContainer>
            ) : null}
          </Column>
          <Column width="100%">
            <PaginationContainer>
              <Pagination limit={PAGE_SIZE} count={count} offset={entriesOffset} setOffset={setEntriesOffset} />
            </PaginationContainer>
          </Column>
        </Grid>
      </DialogContent>
      <DialogFooter>
        <Button type="button" appearance="secondary" disabled={loading} onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="button"
          appearance="primary"
          disabled={query.loading || loading || getDisabledState()}
          onClick={handleSubmit}
        >
          {folderId ? <Trans>Save</Trans> : <Trans>Save here</Trans>}
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

FolderPickerDialog.displayName = 'FolderPickerDialog';

FolderPickerDialog.propTypes = {
  entryId: T.string,
  loading: T.bool,
  showDisabledRows: T.bool,
  defaultParentId: T.string,
  entryType: T.oneOf([EntryKind.Folder, EntryKind.Document]).isRequired,
  actionType: T.oneOf(['create', 'move']).isRequired,
  onSubmit: T.func.isRequired,
  onClose: T.func.isRequired,
};

FolderPickerDialog.defaultProps = {
  entryId: undefined,
  loading: false,
  showDisabledRows: true,
  defaultParentId: undefined,
};

export default memo(FolderPickerDialog);
