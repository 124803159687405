import { t, Trans } from '@lingui/macro';
import { ConfirmDialog, useToast, Alert, Avatar } from '@pocketlaw/tetris';
import { Paragraph } from '@pocketlaw/tetris/deprecated';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { useDeleteUser } from 'app/domains/users/hooks/useDeleteUser';
export function ConfirmDeleteUserDialog(props) {
    const { id, name, onClose, navigateTo } = props;
    const [deleteUser, { loading, error }] = useDeleteUser(id);
    const { addToast } = useToast();
    const history = useHistory();
    const handleConfirm = () => {
        deleteUser()
            .then(() => {
            addToast({ title: t `User removed`, appearance: 'success' });
            onClose();
            if (navigateTo) {
                history.push(navigateTo);
            }
        })
            .catch(() => {
            addToast({
                title: t({
                    message: 'Something went wrong, please try again later',
                    comment: 'Error alert message delete user',
                }),
                appearance: 'danger',
            });
        });
    };
    return (<ConfirmDialog appearance="danger" size="sm" loading={loading} media={<Avatar appearance="danger" shape="square" size="lg">
          <Falcon icon="triangle-exclamation"/>
        </Avatar>} heading={t `Remove ${name}`} confirm={t `Remove`} cancel={t `Cancel`} onConfirm={handleConfirm} onCancel={onClose} onClose={onClose}>
      <Paragraph>
        <Trans>
          If you remove a user, their personal information will be permanently deleted. Any content created by the said
          user will remain on PocketLaw.
        </Trans>
      </Paragraph>
      {error && (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again later',
                comment: 'error alert message delete user',
            })}/>)}
    </ConfirmDialog>);
}
