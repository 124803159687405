import { AssistantContext } from 'app/domains/assistant';
import { ChatMessageType } from 'shared/domains/apollo/generated/types';
import { useFeatureFlag } from 'shared/domains/featureFlag';
export function useDefaultChatType(context) {
    const isPlaiQuotesEnabled = useFeatureFlag('feature_plai_quotes');
    switch (context) {
        case AssistantContext.Document:
            return isPlaiQuotesEnabled ? ChatMessageType.DocumentAskWithQuotes : ChatMessageType.DocumentAsk;
        case AssistantContext.Selection:
            return isPlaiQuotesEnabled ? ChatMessageType.SelectionAskWithQuotes : ChatMessageType.SelectionAsk;
        default:
            return null;
    }
}
