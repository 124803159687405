import { t } from '@lingui/macro';
import { Alert } from '@pocketlaw/tetris';
import { useUpload } from '../../UploadProvider';
export function UploadAlert() {
    const { uploadError } = useUpload();
    switch (uploadError) {
        case 'upload:error':
            return (<Alert appearance="danger" title={t({
                    comment: 'Bulk upload failed - Alert title',
                    message: 'Could not upload the documents',
                })} message={t({
                    comment: 'Bulk upload failed - Alert message',
                    message: 'Something went wrong while trying to upload the documents, please try again',
                })}/>);
        case 'files:amount':
            return (<Alert appearance="warning" title={t({
                    comment: 'Too many files selected for bulk upload - Alert title',
                    message: 'Too many files selected',
                })} message={t({
                    comment: 'Too many files selected for bulk upload - Alert message',
                    message: 'We only support uploading 100 files at a time',
                })}/>);
        case 'files:size':
            return (<Alert appearance="warning" title={t({
                    comment: 'Too big total file size for bulk upload - Alert title',
                    message: 'Total file size exceeded',
                })} message={t({
                    comment: 'Too big total file size for bulk upload - Alert message',
                    message: 'We only support uploading 2GB at a time',
                })}/>);
        default:
            return null;
    }
}
