import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import T from 'prop-types';

import { FileSystemManager } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';

import useCreateFolder from './useCreateFolder';

export function CreateFolderDialog({ folder, onCreated, onClose, ...restProps }) {
  const { addToast } = useToast();

  const [createFolder, { error }] = useCreateFolder({ onCreated });

  const handleSubmit = async (values) => {
    try {
      await createFolder(values);
      onClose();

      addToast({
        appearance: 'success',
        title: t({
          message: 'Folder created',
          comment: 'Message once a folder has been created in a workspace',
        }),
      });
    } catch (e) {
      addToast({
        appearance: 'danger',
        title: t({
          message: 'An error occurred',
          comment: 'Error message when creating a folder in workspaces.',
        }),
      });
    }
  };

  return (
    <FileSystemManager
      {...restProps}
      entryType={EntryKind.Folder}
      defaultParentId={folder?.id}
      error={error}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}

CreateFolderDialog.propTypes = {
  folder: T.shape({
    id: T.string.isRequired,
  }),
  onCreated: T.func,
  onClose: T.func.isRequired,
};

CreateFolderDialog.defaultProps = {
  folder: undefined,
  onCreated: undefined,
};
