import { Trans, t } from '@lingui/macro';
import { Box, Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory, useRouteMatch } from 'react-router-dom';
export function ApprovalPendingState() {
    const history = useHistory();
    const { url } = useRouteMatch();
    const handleClick = () => {
        history.push(`${url}/approval`);
    };
    return (<Box px="2xl">
      <EmptyState withAvatar icon={<Falcon icon="eye"/>} title={t({
            comment: 'Esigning sidebar - Approval pending title',
            message: 'Approval is pending',
        })} description={t({
            comment: 'Esigning sidebar - Approval pending description',
            message: 'This documents approval is pending. After it has been approved it can be sent for eSigning.',
        })} actions={<Button size="small" onClick={handleClick}>
            <Trans comment="Esigning sidebar - Approval pending - Go to approval button label">Go to approval</Trans>
          </Button>}/>
    </Box>);
}
