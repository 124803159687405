var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { APPROVAL_IGNORE_CODES } from 'app/domains/esigning/utils';
import { AclAction } from 'shared/domains/apollo/generated/types';
import { useAddUserAccess } from 'shared/domains/users';
import { CreateVoluntaryApprovalRequestDocument } from './mutation.gql';
import { GetLastApprovalRequestQuery } from '../useLastApprovalRequest';
export const useCreateVoluntaryApprovalRequest = () => {
    const { mutate: addUserAccess } = useAddUserAccess();
    const { addToast } = useToast();
    const [mutate] = useMutation(CreateVoluntaryApprovalRequestDocument);
    const createVoluntaryApprovalRequest = (options) => __awaiter(void 0, void 0, void 0, function* () {
        const { fileSystemDocumentId, approverIdsWithoutPermission, approverUserIds, message, approvalType } = options;
        const approvers = approverUserIds.map((userId) => ({ userId }));
        yield mutate({
            variables: { input: { fileSystemDocumentId, approvers, message, approvalType } },
            refetchQueries: [GetLastApprovalRequestQuery],
            context: {
                sentry: {
                    ignore: APPROVAL_IGNORE_CODES,
                },
            },
        });
        if (approverIdsWithoutPermission.length > 0) {
            yield addUserAccess(fileSystemDocumentId, {
                users: approverIdsWithoutPermission,
                actions: [AclAction.Read],
            });
        }
        addToast({
            appearance: 'success',
            title: t({
                comment: 'Create voluntary approval request success message',
                message: 'Document is sent for voluntary approval',
            }),
        });
    });
    return { createVoluntaryApprovalRequest };
};
