import { AclAction } from 'shared/domains/apollo/generated/types';
export function useTeamAccess(params) {
    const { teamAcls, parentTeamAcls } = params;
    const teamHasAccess = (team) => {
        const teamAcl = teamAcls.find((acl) => acl.teamId === team.id);
        const parentTeamAcl = parentTeamAcls.find((acl) => acl.teamId === team.id);
        const isTraverse = teamAcl === null || teamAcl === void 0 ? void 0 : teamAcl.actions.includes(AclAction.Traverse);
        const isParentTraverse = parentTeamAcl === null || parentTeamAcl === void 0 ? void 0 : parentTeamAcl.actions.includes(AclAction.Traverse);
        if ((parentTeamAcl && !isParentTraverse) || (teamAcl && !isTraverse)) {
            return true;
        }
        return false;
    };
    return { teamHasAccess };
}
