import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import client from 'app/domains/apollo';
import { PartialCompanyUserRoleEnum } from 'shared/domains/apollo/generated/types';
import { updateUserRole, companyUserDataFragment } from './mutation';
const roleMap = Object.freeze({
    owner: {
        role: PartialCompanyUserRoleEnum.Owner,
        can_manage_member: true,
    },
    admin: {
        role: PartialCompanyUserRoleEnum.Member,
        can_manage_member: true,
    },
    member: {
        role: PartialCompanyUserRoleEnum.Member,
        can_manage_member: false,
    },
});
export function useUpdateUserRole() {
    const { addToast } = useToast();
    const [mutate, response] = useMutation(updateUserRole);
    const updateRole = (options) => {
        const { role, id } = options;
        const typeName = 'CompanyUser';
        const identifier = client.cache.identify({ id, __typename: typeName });
        const prevValue = client.readFragment({
            id: identifier,
            fragment: companyUserDataFragment,
        });
        const input = roleMap[role];
        mutate({
            variables: { id, input },
            onError: () => {
                addToast({
                    title: t({
                        message: 'Failed to update user role',
                        comment: 'Message in toast for when user role failed to update',
                    }),
                    appearance: 'danger',
                });
            },
            onCompleted() {
                const roles = Object.values(PartialCompanyUserRoleEnum);
                const prevRole = roles.find((companyRole) => (prevValue === null || prevValue === void 0 ? void 0 : prevValue.role) === companyRole);
                if (prevValue && prevRole) {
                    addToast({
                        title: t({
                            message: 'Successfully updated user role',
                            comment: 'Message in toast for when user role was successfully updated',
                        }),
                        appearance: 'success',
                        action: {
                            label: t({
                                message: 'Undo',
                                comment: 'Undo button in toast for when user role was successfully updated',
                            }),
                            onClick: () => {
                                mutate({
                                    variables: {
                                        id: prevValue.id,
                                        input: {
                                            can_manage_member: prevValue.canManageMember || false,
                                            role: prevRole,
                                        },
                                    },
                                });
                            },
                        },
                    });
                }
            },
        });
    };
    return {
        loading: response.loading,
        error: response.error,
        updateRole,
    };
}
