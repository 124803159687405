import { useQuery } from '@apollo/client';
import { HIDDEN_CATEGORIES } from 'app/domains/categories/utils/hiddenCategories';
import { InsightsCategoriesDocument } from './InsightsCategories.gql';
export function useGetCategories() {
    const { data, error, loading } = useQuery(InsightsCategoriesDocument, {
        variables: {
            hiddenCategoryIds: HIDDEN_CATEGORIES,
        },
    });
    const { categories = [] } = data || {};
    return {
        data: categories.map((category) => ({
            id: category.id,
            title: category.metadata[0].title,
        })),
        error,
        loading,
    };
}
