import { Button, fontSize, margin, padding, pxToRem, unit, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  ${padding(4, 0)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${unit(2)};

  &:not(:last-of-type) {
    border-bottom: solid ${pxToRem(1)} ${themeGet('colors.gray.50')};
  }
`;
export const Label = styled.p `
  ${margin(0)};
  ${fontSize('small', 1.25)};
  font-weight: ${({ $pending }) => ($pending ? 'normal' : 600)};
`;
export const LeftCell = styled.div `
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  cursor: ${({ $pending }) => !$pending && 'pointer'};

  &:hover ${Label} {
    text-decoration: ${({ $pending }) => !$pending && 'underline'};
  }
`;
export const RightCell = styled.div `
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: ${unit(2)};
`;
export const TextContainer = styled.div `
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
`;
export const Description = styled.p `
  ${margin(0)};
  ${fontSize('tiny', 1.25)}
  color: ${({ $pending }) => ($pending ? themeGet('colors.orange.500') : themeGet('colors.gray.700'))};
`;
export const StyledButton = styled(Button) `
  height: ${pxToRem(34)};
`;
