import { t } from '@lingui/macro';
import { getOrderedSignatoriesMap } from 'app/domains/esigning/utils';
import { PendingParty } from './PendingParty';
import { SectionList } from '../../../components';
export function OrderedSigningParties(props) {
    const { signingParties } = props;
    if (!signingParties.length) {
        return null;
    }
    const getOrderedLabel = (order) => t({
        comment: 'Label for pending signing parties with order selected',
        message: `Signing order ${order + 1}`,
    });
    const orderMap = getOrderedSignatoriesMap(signingParties);
    return Object.entries(orderMap).map(([order, orderedSigningParties]) => (<SectionList hideTooManyItems key={order} title={getOrderedLabel(+order)}>
      {orderedSigningParties.map((party) => (<PendingParty key={party.id} party={party} isNotOnlyParty={signingParties.length > 1}/>))}
    </SectionList>));
}
