var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { Button, Dialog, useToast, DialogHeader, DialogContent, Typography, DialogFooter, Loader, } from '@pocketlaw/tetris';
import { useMetadataActions } from '../../hooks/useMetadataActions';
import { useDocumentMetadata } from '../DocumentMetadataProvider';
import { StyledAlert } from './styled';
export function RemoveAll(props) {
    const { open, close } = props;
    const { data } = useDocumentMetadata();
    const action = useMetadataActions();
    const { addToast } = useToast();
    const disabled = action.loading;
    const pairs = data.properties.filter((property) => { var _a; return (_a = property.pair) === null || _a === void 0 ? void 0 : _a.id; });
    const handleRemoveAllPairs = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield Promise.allSettled(pairs.map((_b) => __awaiter(this, [_b], void 0, function* ({ pair }) {
                if (typeof pair.id === 'string') {
                    yield action.delete(pair.id);
                }
            })));
            addToast({
                title: t({
                    message: 'All metadata pairs removed',
                    comment: 'Toast message when removing all metadata pairs',
                }),
                appearance: 'success',
            });
            close();
        }
        catch (_a) {
            addToast({
                title: t({
                    message: 'Some metadata pairs could not be removed',
                    comment: 'Toast message when removing all metadata pairs fails',
                }),
                appearance: 'danger',
            });
        }
    });
    if (!open) {
        return null;
    }
    return (<Dialog onClose={close}>
      <DialogHeader title={t({
            message: 'Remove all metadata tags',
            comment: 'Dialog header when removing all tags',
        })}/>
      <DialogContent>
        <Typography>
          <Trans comment="Metadata remove all: Information text">
            Are you sure you want to remove all metadata pairs? This is irreversible.
          </Trans>
        </Typography>

        {action.error && (<StyledAlert appearance="warning" message={t({
                message: 'An error occurred when removing metadata tags. Not all tags could be removed.',
                comment: 'Error message when removing metadata tags fails',
            })}/>)}
      </DialogContent>
      <DialogFooter>
        <Button appearance="secondary" onClick={close} disabled={disabled}>
          <Trans comment="Metadata remove all: Cancel">Cancel</Trans>
        </Button>
        <Button appearance="danger" onClick={handleRemoveAllPairs} disabled={disabled}>
          {action.loading && <Loader />}
          <Trans comment="Metadata remove all: Confirm">Remove all</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
