import { useQuery } from '@apollo/client';
import { invitesQuery } from './query';
import { removeDuplicates } from './removeDuplicates';
import { useRoleName } from './useRoleName';
export function useInvites(options) {
    const { sort, search = '' } = options;
    const sortId = sort.id || 'email';
    const { getRoleName } = useRoleName();
    const { data, previousData, loading, error, refetch } = useQuery(invitesQuery, { variables: { orderBy: [{ [sortId]: sort.order }], search: `%${search}%` } });
    const invitesWithDuplicateEmails = (loading ? previousData === null || previousData === void 0 ? void 0 : previousData.userInvites : data === null || data === void 0 ? void 0 : data.userInvites) || [];
    const invitesWithoutRoles = removeDuplicates(invitesWithDuplicateEmails);
    const invites = invitesWithoutRoles.map((invite) => (Object.assign(Object.assign({}, invite), { role: getRoleName(invite) })));
    return {
        invites,
        initialLoading: !previousData && loading,
        error,
        refetch,
        loading,
    };
}
