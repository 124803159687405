import { Command } from 'ckeditor5';
/**
 * This commands allows us to add the `AssistantSelectionHighlight` to the current `Selection`.
 */
export class AssistantReplaceCommand extends Command {
    static get pluginName() {
        return 'assistantReplace';
    }
    constructor(editor, state) {
        super(editor);
        this.state = state;
    }
    refresh() {
        const { state } = this;
        this.isEnabled = state.marker != null;
    }
    execute(options) {
        const { editor, state } = this;
        const { model } = editor;
        const { text } = options;
        model.change((writer) => {
            if (!state.marker) {
                return;
            }
            const range = state.marker.getRange();
            const selection = writer.createSelection(range);
            model.insertContent(writer.createText(text), selection);
            state.clear(model);
        });
    }
}
