import { useState, Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { IconButton, Menu, TableBodyCell, MenuItem, MenuList, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { useTeamRole } from 'app/domains/teams';
import { useRole } from 'app/domains/users';
import { ConfirmDeleteTeamDialog } from '../ConfirmDeleteTeamDialog';
export function ContextCell(props) {
    const { id, name } = props;
    const [open, setOpen] = useState(false);
    const [deleteTeamDialog, setDeleteTeamDialog] = useState(false);
    const role = useRole();
    const teamRole = useTeamRole(id);
    const { push } = useHistory();
    const canDeleteTeam = role.can('delete:team') || teamRole.can('delete:team');
    return (<Fragment>
      <TableBodyCell headers="menu" stickyColumn="right">
        <Menu open={open} closeOnOutsideClick onClose={() => setOpen(false)} target={<IconButton onClick={() => setOpen(!open)} $round appearance="ghost" size="small">
              <Falcon icon="ellipsis-vertical"/>
            </IconButton>}>
          <MenuBody>
            <MenuList>
              <MenuItem onClick={() => push(`users-teams/team/${id}`)}>
                <Falcon icon="eye"/>
                <Trans comment="team context menu - view team list item">View Team</Trans>
              </MenuItem>
              {canDeleteTeam && (<MenuItem onClick={() => {
                setOpen(false);
                setDeleteTeamDialog(true);
            }}>
                  <Falcon icon="trash"/>
                  <Trans comment="team context menu - delete team list item">Delete Team</Trans>
                </MenuItem>)}
            </MenuList>
          </MenuBody>
        </Menu>
      </TableBodyCell>
      {deleteTeamDialog && <ConfirmDeleteTeamDialog id={id} onClose={() => setDeleteTeamDialog(false)} name={name}/>}
    </Fragment>);
}
