const isSignatoriesEqual = (signatoriesA, signatoriesB) => {
    const uniqueIdentifiersA = signatoriesA.map(({ email, name }) => `${email}-${name}`);
    const uniqueIdentifiersB = signatoriesB.map(({ email, name }) => `${email}-${name}`);
    return (uniqueIdentifiersA.every((ui) => uniqueIdentifiersB.includes(ui)) &&
        uniqueIdentifiersB.every((ui) => uniqueIdentifiersA.includes(ui)));
};
export const isComposerDefaultSignatoriesEqual = (composerSignatories) => {
    const firstSignatories = composerSignatories[0];
    return [...composerSignatories].slice(1).every((signatories) => {
        if (!firstSignatories && !signatories) {
            return true;
        }
        if (firstSignatories && signatories) {
            return isSignatoriesEqual(firstSignatories, signatories);
        }
        return false;
    });
};
export const isComposerDefaultSignatoriesEdit = (composerSignatories) => composerSignatories.some((signatories) => signatories.length > 0);
