import { Fragment } from 'react';
import { Box, Skeleton, pxToRem } from '@pocketlaw/tetris';
import { Alerts } from '../Alerts';
import { Empty } from '../Empty';
import { useAttachments } from '../Provider';
import { UploadAttachmentDropdown } from '../UploadAttachmentDropdown';
export function Attachments() {
    const { render, renderProps, hideAdd, attachments, uploadingAttachment, showAddingAttachmentLoader } = useAttachments();
    const showAddButton = !hideAdd;
    const showSkeleton = showAddingAttachmentLoader || uploadingAttachment;
    const amount = attachments.length;
    const empty = amount === 0;
    return (<Fragment>
      <Alerts />
      {empty ? (<Empty />) : (<Box flexDirection="column" gap="2xl" width="100%">
          <Box flexDirection="column" gap="md">
            {attachments.map((attachment) => render(attachment, renderProps))}
            {showSkeleton && <Skeleton width="100%" height={pxToRem(54)} borderRadius="md"/>}
          </Box>
          {showAddButton && (<Box alignItems="center" justifyContent="center">
              <UploadAttachmentDropdown origin="listAction"/>
            </Box>)}
        </Box>)}
    </Fragment>);
}
