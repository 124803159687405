import { getCurrentMarket } from 'app/domains/markets/config';
import { localeConfig } from 'shared/domains/languages/config';
// marketCode:localeCode
export const links = {
    homepage: {
        'uk:en-gb': '',
        'de:en-gb': '',
        'se:sv': 'sv',
        'se:sv-se': 'sv',
        'se:en-gb': '',
        'no:en-gb': '',
        'no:nb-no': '',
    },
    cookies: {
        'uk:en-gb': 'en-uk/cookies',
        'de:en-gb': 'en-uk/cookies',
        'se:sv': 'sv-se/cookies',
        'se:sv-se': 'sv-se/cookies',
        'se:en-gb': 'sv-se/cookies',
        'no:en-gb': 'nb-no/cookies-norway',
        'no:nb-no': 'nb-no/cookies-norway',
    },
    pricing: {
        'uk:en-gb': 'pricing',
        'de:en-gb': 'pricing',
        'se:sv': 'pricing',
        'se:sv-se': 'pricing',
        'se:en-gb': 'pricing',
        'no:en-gb': 'pricing',
        'no:nb-no': 'pricing',
    },
    documents: {
        'uk:en-gb': 'en-uk/contracts-and-documents',
        'de:en-gb': 'en-uk/contracts-and-documents',
        'se:sv': 'sv-se/avtal-och-dokument',
        'se:sv-se': 'sv-se/avtal-och-dokument',
        'se:en-gb': 'sv-se/avtal-och-dokument',
        'no:en-gb': 'nb-no/kontrakter-dokumenter-norge',
        'no:nb-no': 'nb-no/kontrakter-dokumenter-norge',
    },
    about: {
        'uk:en-gb': 'en-uk/how-it-works',
        'de:en-gb': 'en-uk/how-it-works',
        'se:sv': 'sv-se/hur-det-fungerar',
        'se:sv-se': 'sv-se/hur-det-fungerar',
        'se:en-gb': 'sv-se/hur-det-fungerar',
        'no:en-gb': 'nb-no/slik-fungerer-pocketlaw',
        'no:nb-no': 'nb-no/slik-fungerer-pocketlaw',
    },
    extSharingTerms: {
        'uk:en-gb': 'en-uk/external-sharing-terms-of-service',
        'de:en-gb': 'en-uk/external-sharing-terms-of-service',
        'se:sv': 'sv-se/external-sharing-allmana-villkor',
        'se:sv-se': 'sv-se/external-sharing-allmana-villkor',
        'se:en-gb': 'sv-se/external-sharing-allmana-villkor',
        'no:en-gb': 'nb-no/vilkar-og-betingelser-external-sharing',
        'no:nb-no': 'nb-no/vilkar-og-betingelser-external-sharing',
    },
    extSharingPrivacy: {
        'uk:en-gb': 'en-uk/external-sharing-privacy-policy',
        'de:en-gb': 'en-uk/external-sharing-privacy-policy',
        'se:sv': 'sv-se/external-sharing-integritetspolicy',
        'se:sv-se': 'sv-se/external-sharing-integritetspolicy',
        'se:en-gb': 'sv-se/external-sharing-integritetspolicy',
        'no:en-gb': 'nb-no/external-sharing-privacy-policy',
        'no:nb-no': 'nb-no/external-sharing-privacy-policy',
    },
    createCompanyFAQ: {
        'uk:en-gb': 'en-uk/merge-company-to-existing-account',
        'de:en-gb': 'en-uk/merge-company-to-existing-account',
        'se:sv': 'en-uk/merge-company-to-existing-account',
        'se:sv-se': 'en-uk/merge-company-to-existing-account',
        'se:en-gb': 'en-uk/merge-company-to-existing-account',
        'no:en-gb': 'en-uk/merge-company-to-existing-account',
        'no:nb-no': 'en-uk/merge-company-to-existing-account',
    },
};
export const appLinks = {
    signup: {
        'uk:en-gb': 'uk/signup',
        'de:en-gb': 'uk/signup',
        'se:sv': 'se/signup',
        'se:sv-se': 'se/signup',
        'se:en-gb': 'se/signup',
        'no:en-gb': 'no/signup',
        'no:nb-no': 'no/signup',
    },
};
export const externalLinks = {
    terms: {
        'uk:en-gb': 'https://cdn.pocketlaw.com/Terms+of+Service+for+Pocketlaw+v1.4.pdf',
        'de:en-gb': 'https://cdn.pocketlaw.com/Terms+of+Service+for+Pocketlaw+v1.4.pdf',
        'se:sv': 'https://cdn.pocketlaw.com/Terms+of+Service+for+Pocketlaw+v1.4.pdf',
        'se:sv-se': 'https://cdn.pocketlaw.com/Terms+of+Service+for+Pocketlaw+v1.4.pdf',
        'se:en-gb': 'https://cdn.pocketlaw.com/Terms+of+Service+for+Pocketlaw+v1.4.pdf',
        'no:en-gb': 'https://cdn.pocketlaw.com/Terms+of+Service+for+Pocketlaw+v1.4.pdf',
        'no:nb-no': 'https://cdn.pocketlaw.com/Terms+of+Service+for+Pocketlaw+v1.4.pdf',
    },
    contact: {
        'uk:en-gb': 'https://pocketlaw.com/support-tickets',
        'de:en-gb': 'https://pocketlaw.com/support-tickets',
        'se:sv': 'https://pocketlaw.com/support-tickets',
        'se:sv-se': 'https://pocketlaw.com/support-tickets',
        'se:en-gb': 'https://pocketlaw.com/support-tickets',
        'no:en-gb': 'https://pocketlaw.com/support-tickets',
        'no:nb-no': 'https://pocketlaw.com/support-tickets',
    },
    privacy: {
        'uk:en-gb': 'https://cdn.pocketlaw.com/Privacy+policy+19+Feb+2025.pdf',
        'de:en-gb': 'https://cdn.pocketlaw.com/Privacy+policy+19+Feb+2025.pdf',
        'se:sv': 'https://cdn.pocketlaw.com/Privacy+policy+19+Feb+2025.pdf',
        'se:sv-se': 'https://cdn.pocketlaw.com/Privacy+policy+19+Feb+2025.pdf',
        'se:en-gb': 'https://cdn.pocketlaw.com/Privacy+policy+19+Feb+2025.pdf',
        'no:en-gb': 'https://cdn.pocketlaw.com/Privacy+policy+19+Feb+2025.pdf',
        'no:nb-no': 'https://cdn.pocketlaw.com/Privacy+policy+19+Feb+2025.pdf',
    },
};
function getMarket(localeCode, marketCode) {
    if (marketCode) {
        return marketCode;
    }
    switch (localeCode) {
        case 'sv':
        case 'sv-se':
            return 'se';
        case 'nb-no':
            return 'no';
        case 'en-gb':
        default:
            return 'uk';
    }
}
export function useGetLinks() {
    const localeCode = localeConfig.getActiveLocale().code;
    const marketCode = getCurrentMarket().code;
    const linkObject = {};
    Object.entries(links).forEach((link) => {
        const marketLocaleKey = `${getMarket(localeCode, marketCode)}:${localeCode}`;
        const [key, value] = link;
        const url = value[marketLocaleKey];
        linkObject[key] = `${process.env.WEBSITE_URL}/${url}`;
    });
    Object.entries(appLinks).forEach((link) => {
        const marketLocaleKey = `${getMarket(localeCode, marketCode)}:${localeCode}`;
        const [key, value] = link;
        const url = value[marketLocaleKey];
        linkObject[key] = `${process.env.APP_URL}/${url}`;
    });
    Object.entries(externalLinks).forEach((link) => {
        const marketLocaleKey = `${getMarket(localeCode, marketCode)}:${localeCode}`;
        const [key, value] = link;
        linkObject[key] = value[marketLocaleKey];
    });
    return linkObject;
}
