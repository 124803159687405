import { gql } from '@apollo/client';
export const fileSystemAclsQuery = gql `
  query fileSystemAcls($entryId: ID!, $hasEntryId: Boolean!, $parentEntryId: ID!, $hasParentEntryId: Boolean!) {
    fileSystemEntry(id: $entryId) @include(if: $hasEntryId) {
      id
      name
      userAcls: acls {
        id
        userId
        actions
      }
      teamAcls {
        id
        teamId
        actions
      }
      parent {
        id
        userAcls: acls {
          id
          userId
          actions
        }
        teamAcls {
          id
          teamId
          actions
        }
      }
    }
    parentEntry: fileSystemEntry(id: $parentEntryId) @include(if: $hasParentEntryId) {
      id
      name
      userAcls: acls {
        id
        userId
        actions
      }
      teamAcls {
        id
        teamId
        actions
      }
    }
    companyUsers(order_by: [{ user: { name: asc, email: asc } }]) {
      id
      role
      canManageMember: can_manage_member
      userId: user_id
      user {
        id
        name
        email
      }
    }
    teams(orderBy: { name: asc }) {
      id
      name
      members {
        id
      }
    }
  }
`;
