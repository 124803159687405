import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, List, ListItem, SidebarHeader, Typography, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SettingRow } from './SettingRow';
import { useGetFeatureFlagsMap } from './useGetFeatureFlagsMap';
import { featureFlagKeys } from '../../constants';
import { ActionContainer, AlignRight, Container, Content, Trigger, StyledDrawer, StyledSidebar } from './styled';
export function FeatureFlagSettings() {
    const [isOpen, setIsOpen] = useState(false);
    const isProd = process.env.APP_ENV === 'production';
    useGetFeatureFlagsMap();
    if (!featureFlagKeys.length || isProd) {
        return null;
    }
    return (<React.Fragment>
      <Container>
        <Trigger onClick={() => setIsOpen(true)}>
          <Trans comment="Button to open feature flag settings">Feature flags</Trans>
        </Trigger>
      </Container>
      <StyledDrawer onClose={() => setIsOpen(false)} open={isOpen} position="left">
        <StyledSidebar>
          <SidebarHeader>
            <Typography $fontWeight="medium" $fontSize="large">
              <Trans comment="Feature flags header">Feature flags</Trans>
            </Typography>
            <AlignRight>
              <IconButton $round appearance="ghost" size="small" onClick={() => setIsOpen(false)}>
                <Falcon icon="close"/>
              </IconButton>
            </AlignRight>
          </SidebarHeader>
          <Content>
            <List>
              {!featureFlagKeys.length && (<ListItem>
                  <Trans comment="No flag available text">None</Trans>
                </ListItem>)}
              {featureFlagKeys.map((flag) => (<SettingRow key={flag} flag={flag}/>))}
            </List>
          </Content>
          <ActionContainer>
            <Button $stretch appearance="secondary" onClick={() => setIsOpen(false)}>
              <Trans comment="Close drawer button">Close</Trans>
            </Button>
          </ActionContainer>
        </StyledSidebar>
      </StyledDrawer>
    </React.Fragment>);
}
