import { getDateHistogramAllowedProperties } from '../DateHistogramInput/allowedProperties';
import { getDateRangeAllowedProperties } from '../DateRangeInput/allowedProperties';
import { getMetricAllowedProperties } from '../MetricInput/allowedProperties';
import { getTermsAllowedProperties } from '../TermsInput/allowedProperties';
import { getTopHitsAllowedProperties } from '../TopHitsInput/allowedProperties';
export function getAllowedProperties() {
    return [
        getTermsAllowedProperties(),
        getDateHistogramAllowedProperties(),
        getDateRangeAllowedProperties(),
        getMetricAllowedProperties(),
        getTopHitsAllowedProperties(),
    ];
}
export function getLabelForAllowedProperty(aggregation, field) {
    var _a;
    const { label } = ((_a = getAllowedProperties()
        .find((property) => property.type === aggregation)) === null || _a === void 0 ? void 0 : _a.values.find((value) => value.value === field)) || {};
    return label;
}
