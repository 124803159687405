import { useState } from 'react';
import { plural, t } from '@lingui/macro';
import { Avatar, AvatarWithLabel, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { UnsupportedChip } from '../UnsupportedChip';
import { Container, FileList, File, ToggleButtonContainer } from './styled';
export function UnsupportedPreview(props) {
    const { files } = props;
    const [showFiles, setShowFiles] = useState(false);
    const title = t({
        comment: 'Upload document dialog - All unsupported files title',
        message: 'All unsupported',
    });
    const subtitle = t({
        comment: 'Upload document dialog - All unsupported files subtitle',
        message: plural(files.length, {
            one: '# document (will not be uploaded)',
            other: '# documents (will not be uploaded)',
        }),
    });
    if (!files.length) {
        return null;
    }
    return (<Container>
      <ToggleButtonContainer onClick={() => setShowFiles(!showFiles)}>
        <AvatarWithLabel size="md" appearance="danger" title={title} subtitle={subtitle}>
          <Avatar>
            <Falcon icon="circle-exclamation"/>
          </Avatar>
        </AvatarWithLabel>
        <IconButton $round size="small" appearance="ghost">
          {showFiles ? <Falcon icon="chevron-down"/> : <Falcon icon="chevron-up"/>}
        </IconButton>
      </ToggleButtonContainer>
      <FileList $show={showFiles}>
        {files.map((file) => (<File key={file.id}>
            <AvatarWithLabel size="md" appearance="secondary" title={file.name + file.extension}>
              <Avatar>
                <Falcon icon="file"/>
              </Avatar>
            </AvatarWithLabel>
            <UnsupportedChip unsupportedReason={file.unsupportedReason}/>
          </File>))}
      </FileList>
    </Container>);
}
