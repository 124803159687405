import { Plugin } from 'shared/domains/pleditor@next';
export class CommentMentionConversion extends Plugin {
    init() {
        this.useUpcast();
        this.useDowncast();
    }
    useUpcast() {
        const { editor } = this;
        editor.conversion.for('upcast').elementToAttribute({
            view: {
                name: 'span',
                classes: 'mention',
                attributes: { 'data-user-id': true },
            },
            model: {
                key: 'mention',
                value: (viewItem) => {
                    const mentionPlugin = editor.plugins.get('Mention');
                    const mentionAttribute = mentionPlugin.toMentionAttribute(viewItem, {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        userId: viewItem.getAttribute('data-user-id'),
                    });
                    return mentionAttribute;
                },
            },
            converterPriority: 'high',
        });
    }
    useDowncast() {
        const { editor } = this;
        editor.conversion.for('downcast').attributeToElement({
            model: 'mention',
            view: (modelAttributeValue, { writer }) => {
                if (!modelAttributeValue) {
                    return null;
                }
                return writer.createAttributeElement('span', {
                    class: 'mention',
                    'data-mention': modelAttributeValue.id,
                    'data-user-id': modelAttributeValue.userId,
                }, { id: modelAttributeValue.uid });
            },
            converterPriority: 'high',
        });
    }
}
