import { marginTop, fontSize, fontWeight, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const mobileNavItemHeight = '4.375rem';
export const Container = styled.div `
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;

  background: ${themeGet('colors.gray.0')};

  box-shadow:
    0 0 ${pxToRem(0.1)} rgba(0, 0, 0, 0.02),
    0 0 ${pxToRem(0.3)} rgba(0, 0, 0, 0.028),
    0 0 ${pxToRem(0.6)} rgba(0, 0, 0, 0.035),
    0 0 ${pxToRem(1.1)} rgba(0, 0, 0, 0.042),
    0 0 ${pxToRem(2.1)} rgba(0, 0, 0, 0.05),
    0 0 ${pxToRem(5)} rgba(0, 0, 0, 0.07);
`;
export const Navigation = styled.nav `
  display: flex;
  width: 100%;
  align-items: stretch;
  justify-content: stretch;
`;
export const NavItemText = styled.span `
  ${marginTop(2)};
  ${fontSize('tiny', 1)};
`;
export const NavItemContainer = styled.button `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: ${mobileNavItemHeight};

  border: none;
  background-color: transparent;
  text-decoration: none;

  svg {
    color: ${({ $active }) => ($active ? themeGet('colors.primary.500') : themeGet('colors.gray.400'))};
  }

  ${({ $active }) => fontWeight($active ? 'bold' : 'normal')};

  ${NavItemText} {
    color: ${({ $active }) => ($active ? themeGet('colors.gray.700') : themeGet('colors.gray.400'))};
  }
`;
