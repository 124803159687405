import { useReactiveVar } from '@apollo/client';
import { AvatarLetter, AvatarList } from '@pocketlaw/tetris';
import { documentUsers, usePreview } from 'shared/domains/documents';
export function ActiveUsers() {
    const { isRtcAvailable } = usePreview();
    const users = useReactiveVar(documentUsers);
    if (!isRtcAvailable || !users.length) {
        return null;
    }
    const uniqueUsers = Array.from(new Set(users));
    const sortedUsers = uniqueUsers.sort((user) => (user.isMe ? -1 : 1));
    return (<AvatarList solid avatars={sortedUsers.map((user) => ({
            id: user.id,
            tooltip: { title: user.name },
            component: <AvatarLetter>{user.name.slice(0, 1)}</AvatarLetter>,
        }))}/>);
}
