import { AclAction } from 'shared/domains/apollo/generated/types';
export function useUserAccess(params) {
    const { userAcls, parentUserAcls } = params;
    const userHasAccess = (companyUser) => {
        const userAcl = userAcls.find((acl) => acl.userId === companyUser.userId);
        const parentUserAcl = parentUserAcls.find((acl) => acl.userId === companyUser.userId);
        const isTraverse = userAcl === null || userAcl === void 0 ? void 0 : userAcl.actions.includes(AclAction.Traverse);
        const isParentTraverse = parentUserAcl === null || parentUserAcl === void 0 ? void 0 : parentUserAcl.actions.includes(AclAction.Traverse);
        if (companyUser.role === 'owner' || (parentUserAcl && !isParentTraverse) || (userAcl && !isTraverse)) {
            return true;
        }
        return false;
    };
    return { userHasAccess };
}
