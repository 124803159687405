import { Button, themeGetColor } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const SelectedText = styled(Button).attrs(() => ({
    as: 'span',
    appearance: 'secondary',
    size: 'small',
})) `
  cursor: regular;

  ${(props) => props.disabled &&
    `
    color: ${themeGetColor('text.200')};
    background-color: ${themeGetColor('gray.0')};
    border-color: ${themeGetColor('gray.100')};
    opacity: 0.5;
  `}

  &:not(:disabled) {
    &:hover {
      background-color: ${themeGetColor('gray.0')};
      border-color: ${themeGetColor('border.100')};
      cursor: unset;
    }
  }
`;
