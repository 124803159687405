import { useMutation } from '@apollo/client';
import { mutation } from './mutation';
import { getTasksQuery } from '../useGetTasks/query';
function useDeleteTask() {
    const [deleteMutation, { error, loading }] = useMutation(mutation);
    const deleteTask = (id) => deleteMutation({
        variables: {
            id,
        },
        refetchQueries: [getTasksQuery],
        update: (cache) => {
            const typename = 'Task';
            cache.modify({
                id: cache.identify({ __typename: typename, id }),
                fields(_, { DELETE }) {
                    return DELETE;
                },
            });
        },
    });
    return { mutate: deleteTask, error, loading };
}
export { useDeleteTask };
