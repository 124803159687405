import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { MenuList, MenuSubheader } from '@pocketlaw/tetris';
import { MultiselectItem } from './MultiselectItem';
export function SearchResultEntries(props) {
    const { entries, handleSelect, loading } = props;
    return (<Fragment>
      <MenuSubheader id="metadata-list-search-items">
        <Trans comment="Metadata: Filesystem reference, search result header">Result</Trans>
      </MenuSubheader>
      <MenuList aria-labelledby="metadata-list-search-items" as="nav" role="menu">
        {entries === null || entries === void 0 ? void 0 : entries.map((entry) => (<MultiselectItem key={entry.id} {...entry} handleSelect={handleSelect} disabled={loading}>
            {entry.name}
          </MultiselectItem>))}
      </MenuList>
    </Fragment>);
}
