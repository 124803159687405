var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { GetDocumentChatsDocument } from './query.gql';
export function useGetChats() {
    const { id: documentId } = useActiveDocument();
    const _a = useQuery(GetDocumentChatsDocument, {
        variables: { documentId },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const chats = (data === null || data === void 0 ? void 0 : data.documentChatsByUser) || [];
    const normalizedChats = chats.map((chat) => {
        const { messages } = chat;
        const reversedMessages = messages.slice(0).reverse();
        return Object.assign(Object.assign({}, chat), { messages: reversedMessages });
    });
    return Object.assign({ chats: normalizedChats }, rest);
}
