export function filterSignatoryNameFormats(signatories, nameFormat) {
    return signatories.filter((signatory) => {
        const { firstName, lastName, name } = signatory;
        if (nameFormat === 'firstNameLastName' && (!firstName || !lastName)) {
            return false;
        }
        if (nameFormat === 'fullName' && !name) {
            return false;
        }
        return true;
    });
}
