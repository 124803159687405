import { Fragment, useRef } from 'react';
import { Trans } from '@lingui/macro';
import { AiBorderStackingOrderGuard, Button } from '@pocketlaw/tetris';
import { ReviewFeedbackStatus } from 'shared/domains/apollo/generated/types';
import { objectEntries } from 'shared/domains/common-utils';
import { useDocumentDrawer } from 'shared/domains/documents';
import { Legend } from './Legend';
import { ProgressBar } from './ProgressBar';
import { ReviewHeader } from './ReviewHeader';
import { countBy, progressTypeMap, compareProgress } from './utils';
import { usePlai } from '../PlaiProvider';
import { ProgressContainer } from './styled';
export function ReviewProgress(props) {
    var _a, _b;
    const { previewVersion = false } = props;
    const { review } = usePlai();
    const { openDrawer } = useDocumentDrawer();
    const { feedbacks = [] } = review || {};
    const reviewRef = useRef(review);
    if (feedbacks.length < 1) {
        return null;
    }
    const handleGoToReview = () => openDrawer('app:review');
    const skipAnimation = Boolean(reviewRef.current);
    const playbookName = (_a = review === null || review === void 0 ? void 0 : review.playbook) === null || _a === void 0 ? void 0 : _a.name;
    const totalAmount = feedbacks.length;
    const feedbackCounts = countBy(feedbacks, (feedback) => {
        switch (feedback.feedback.status) {
            case ReviewFeedbackStatus.Accepted: {
                return progressTypeMap[ReviewFeedbackStatus.Accepted];
            }
            case ReviewFeedbackStatus.Discarded: {
                return progressTypeMap[ReviewFeedbackStatus.Discarded];
            }
            default: {
                return progressTypeMap[feedback.feedback.criticality];
            }
        }
    });
    const progressItems = objectEntries(feedbackCounts)
        .sort(([a], [b]) => compareProgress(a, b))
        .filter(([type]) => type !== 'resolved');
    const currentProgressValue = (_b = feedbackCounts.resolved) !== null && _b !== void 0 ? _b : 0;
    const reviewCompleted = currentProgressValue === totalAmount;
    if (previewVersion) {
        return (<ProgressContainer $skipAnimation={skipAnimation}>
        <ReviewHeader reviewCompleted={reviewCompleted} playbookName={playbookName}/>
        {!reviewCompleted && (<Fragment>
            <ProgressBar totalAmount={totalAmount} currentValue={currentProgressValue} reviewCompleted={reviewCompleted}/>
            <AiBorderStackingOrderGuard guard>
              <Button size="xs" appearance="ai" onClick={handleGoToReview}>
                <Trans comment="Plai review progress status - continue button label">Continue</Trans>
              </Button>
            </AiBorderStackingOrderGuard>
          </Fragment>)}
      </ProgressContainer>);
    }
    return (<ProgressContainer $skipAnimation={skipAnimation}>
      <ReviewHeader reviewCompleted={reviewCompleted} playbookName={playbookName}/>
      <ProgressBar totalAmount={totalAmount} currentValue={currentProgressValue} reviewCompleted={reviewCompleted}/>
      <Legend items={progressItems}/>
    </ProgressContainer>);
}
