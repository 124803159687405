import { createContext, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';
import { useOnEnterRoute } from './useOnEnterRoute';
export const DesktopNavigationContext = createContext(null);
export function DesktopNavigationProvider(props) {
    const { children } = props;
    const { getLsMinimized, getLsStates, setLsMinimized, setLsStates } = useLocalStorage();
    const [minimized, setMinimized] = useState(getLsMinimized());
    const [states, setStates] = useState(getLsStates());
    const handleSetMinimized = (newMinimized) => {
        setMinimized(newMinimized);
        setLsMinimized(newMinimized);
    };
    const handleCollapsibleToggle = (key) => {
        const newStates = Object.assign(Object.assign({}, states), { [key]: !states[key] });
        setStates(newStates);
        setLsStates(newStates);
    };
    useOnEnterRoute('/document/:id', () => {
        handleSetMinimized(true);
    });
    const value = {
        minimized,
        collapsibleStates: states,
        setMinimized: handleSetMinimized,
        toggleCollapsible: handleCollapsibleToggle,
    };
    return <DesktopNavigationContext.Provider value={value}>{children}</DesktopNavigationContext.Provider>;
}
