import { fieldDisplayValue } from '../fieldDisplayValue';
import { getSelectedField } from '../getSelectedField';
import { topHitsTypeGuard } from '../typeGuards';
function getTopHitsResult(data) {
    const isTopHitsAggregation = topHitsTypeGuard(data);
    if (!isTopHitsAggregation) {
        return [];
    }
    const { results } = data || {};
    return results;
}
export function getTopHitsEntities(data, aggregations) {
    var _a;
    const [dataPoint] = data || [];
    const [aggregation] = aggregations;
    const result = getTopHitsResult(dataPoint);
    const field = getSelectedField(aggregation);
    const [orderBy] = ((_a = aggregation === null || aggregation === void 0 ? void 0 : aggregation.topHitsInput) === null || _a === void 0 ? void 0 : _a.orderBy) || [];
    const [selectedFieldKey] = Object.keys(orderBy || {});
    const entries = result
        .map((row) => {
        var _a;
        return ({
            id: row.id,
            entityId: row.entityId,
            link: `/document/${row.entityId}`,
            name: fieldDisplayValue(field, row.name),
            value: ((_a = row[selectedFieldKey]) === null || _a === void 0 ? void 0 : _a.toLocaleString()) || '',
        });
    })
        .filter((row) => row.value !== '');
    return entries;
}
