import { SidebarMenuItem } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { useLinkMatch } from '../useLinkMatch';
export function ItemLink(props) {
    const { to, icon, label, matchPath, matchExact = false, adornmentRight } = props;
    const active = useLinkMatch({ matchPath, matchExact });
    const history = useHistory();
    const handleClick = () => {
        history.push(to);
    };
    return (<SidebarMenuItem icon={icon} label={label} active={active} onClick={handleClick} adornmentRight={adornmentRight}/>);
}
