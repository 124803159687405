import { Trans } from '@lingui/macro';
import { Chip } from '@pocketlaw/tetris';
export function DocumentAiDisabledBadge(props) {
    const { reason } = props;
    switch (reason) {
        case 'TEXT_EXTRACTION_FAILED': {
            return (<Chip size="small" appearance="danger" label={<Trans comment="Label for document with failed text extraction in File Browser">Failed</Trans>}/>);
        }
        case 'TEXT_EXTRACTION_PENDING': {
            return (<Chip size="small" appearance="info" label={<Trans comment="Label for document with pending text extraction in File Browser">Processing</Trans>}/>);
        }
        default: {
            return null;
        }
    }
}
