import { t } from '@lingui/macro';
import { ButtonGroup } from '@pocketlaw/tetris';
import { withFilterErrorBoundary } from './FilterErrorBoundary';
import { ClearFilter } from './components/ClearFilter';
import { NullOperator } from './components/NullOperator';
import { NumberEqualsOperator } from './components/NumberEqualsOperator';
import { NumberRangeOperator } from './components/NumberRangeOperator';
import { OperatorSelector } from './components/OperatorSelector';
import { useFilter } from './useFilter';
export const NumberRangeFilter = withFilterErrorBoundary((props) => {
    const { name, label } = props;
    const filter = useFilter(name);
    const operators = Object.keys(filter.value || {});
    const displayRangeOperator = operators.some((operator) => ['gt', 'gte', 'lte', 'lt'].includes(operator));
    return (<ButtonGroup>
      <OperatorSelector label={label} name={name} operators={[
            {
                id: 'eq',
                menuItem: t({
                    message: 'Equals',
                    comment: 'Insights: Number range filter: Equals',
                }),
                label: t({ message: 'equals', comment: 'Insights: Number range filter: Equals' }),
                onSelect: () => filter.setValue({ eq: undefined }),
            },
            {
                id: ['gte', 'lte', 'lt', 'gt'],
                menuItem: t({
                    message: 'Greater than or equals',
                    comment: 'Insights: Number range filter: Greater than',
                }),
                label: t({
                    message: 'greater than or equals',
                    comment: 'Insights: Number range filter: Greater than',
                }),
                onSelect: () => filter.setValue({ lte: undefined, gte: undefined }),
            },
            {
                id: 'isNull',
                menuItem: t({
                    message: 'Has value',
                    comment: 'Insights: Number range filter: Has value',
                }),
                label: t({ message: 'has', comment: 'Insights: Number range filter: Has value' }),
                onSelect: () => filter.setValue({ isNull: undefined }),
            },
        ]}/>

      {operators.includes('isNull') && <NullOperator name={name}/>}

      {operators.includes('eq') && <NumberEqualsOperator name={name}/>}

      {displayRangeOperator && <NumberRangeOperator name={name}/>}

      <ClearFilter name={name}/>
    </ButtonGroup>);
});
