var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TableBodyCell } from '@pocketlaw/tetris';
import { getSearchEntityLink, getSearchHighlightContext, useSanitizeHTMLString } from 'app/domains/global-search';
import { TypeAvatarWithLabel } from 'shared/domains/common-ui';
import { entityTypeToAvatarType } from './utils';
import { TITLE_CELL_WIDTH } from '../../constants';
export function TitleCell(props) {
    const { entityType, entityId, name, highlight, selected, onSelect } = props, rest = __rest(props, ["entityType", "entityId", "name", "highlight", "selected", "onSelect"]);
    const to = getSearchEntityLink(entityType, entityId);
    const { highlightText, showNameOnly } = getSearchHighlightContext(name, highlight);
    const htmlString = useSanitizeHTMLString({
        allowedTags: ['mark'],
        text: highlightText,
    });
    // eslint-disable-next-line react/no-danger
    const highlightMarkup = <span dangerouslySetInnerHTML={{ __html: htmlString }}/>;
    const avatarType = entityTypeToAvatarType(entityType);
    return (<TableBodyCell maxWidth={TITLE_CELL_WIDTH} {...rest}>
      <TypeAvatarWithLabel truncate type={avatarType} title={name} subtitle={showNameOnly ? undefined : highlightMarkup} link={to} selected={selected} onSelect={onSelect}/>
    </TableBodyCell>);
}
