import { gql } from '@apollo/client';
export const docxToPdfMutation = gql `
  mutation docxToPdf($companyId: UUID!, $path: String!, $type: String!, $size: Int!) {
    files: docxToPdfByFilePath(input: { companyId: $companyId, path: $path, mimeType: $type, size: $size }) {
      id
      path
      name
      type: mimeType
      size
    }
  }
`;
