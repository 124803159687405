var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { DocumentAutoTagQueueDocument } from './DocumentAutoTagQueue.gql';
/**
 * This hook is used to start the auto tag process for a list of documents
 * @param documentIds Array of document id's to tag
 * @returns
 */
export function useAutoTagDocuments() {
    const { addToast } = useToast();
    const [mutation, state] = useMutation(DocumentAutoTagQueueDocument);
    const handleMutation = (documentIds) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield mutation({
                variables: {
                    documentIds,
                },
                update(cache, { data: newData }) {
                    const { newQueueEntries } = newData || {};
                    if (newQueueEntries && newQueueEntries.length) {
                        cache.modify({
                            fields: {
                                countDocumentMetadataAiAutoTaggingQueueEntries(existing = 0) {
                                    return existing + newQueueEntries.length;
                                },
                                documentMetadataAiAutoTaggingQueueEntries(existing = [], { toReference }) {
                                    const newReferences = newQueueEntries.map((entry) => {
                                        const { fileSystemDocument } = entry, rest = __rest(entry, ["fileSystemDocument"]);
                                        return Object.assign(Object.assign({}, rest), { fileSystemDocument: fileSystemDocument
                                                ? toReference({
                                                    __typename: 'FileSystemDocument',
                                                    id: fileSystemDocument.id,
                                                })
                                                : null });
                                    });
                                    return [...existing, ...newReferences];
                                },
                            },
                        });
                    }
                },
            });
            addToast({
                title: t({
                    message: 'Document metadata extraction started.',
                    comment: 'Metadata Auto tag: Started',
                }),
                appearance: 'info',
            });
        }
        catch (_a) {
            addToast({
                title: t({
                    message: 'Document metadata extraction failed.',
                    comment: 'Metadata Auto tag: Error starting auto tag',
                }),
                appearance: 'danger',
            });
        }
    });
    return {
        mutate: handleMutation,
        state,
    };
}
