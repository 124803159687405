import { gql } from '@apollo/client';
export const fileSystemEntriesQuery = gql `
  query fileSystemEntries($orderBy: FileSystemEntryOrderByInput, $parentId: ID!, $limit: Int!, $offset: Int!) {
    entries: fileSystemEntries(
      where: { parentId: { eq: $parentId }, kind: { neq: GUIDANCE } }
      orderBy: $orderBy
      limit: $limit
      offset: $offset
    ) {
      id
      parentId
      categoryId
      name
      depth
      marketCode
      date: updatedAt
      kind
      acl: acls {
        id
        userId
        actions
        user {
          id
          name
          email
        }
      }
      teamAcls {
        id
        teamId
        actions
        team {
          id
          name
        }
      }
      user {
        id
        name
        email
      }
      ... on FileSystemDocument {
        draftId
        type

        lastSignRequest {
          id
          status
          signedAt: signed_at
        }
      }
    }
  }
`;
