import { useQuery } from '@apollo/client';
import { getInviteComposers } from './query';
import { useWizardContext } from '../../../WizardContext';
export function useGetComposers(options) {
    const { categoryIds, search } = options;
    const { planId } = useWizardContext();
    const where = {
        enabled: { eq: true },
        availableForPlans: { id: { eq: planId } },
    };
    if (categoryIds.length > 0) {
        Object.assign(where, { categoryId: { in: categoryIds } });
    }
    if (search) {
        Object.assign(where, { metadata: { name: { ilike: `%${search}%` } } });
    }
    return useQuery(getInviteComposers, {
        variables: {
            where,
        },
    });
}
