import { useCallback } from 'react';
import { t, Trans } from '@lingui/macro';
import { Avatar, ConfirmDialog, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { useSignatory } from 'app/domains/esigning/hooks/useSignatory';
import { useRemoveSignRequestSignatory } from './useRemoveSignRequestSignatory';
import { Copy, Strong } from './styled';
export function RemoveSignatoryDialog(props) {
    const { signatoryId, onClose } = props;
    const { name } = useSignatory(signatoryId) || {};
    const { refetch } = useLastSignRequest();
    const { addToast } = useToast();
    const [removeSignatory, { loading }] = useRemoveSignRequestSignatory(signatoryId);
    const handleConfirm = useCallback(() => {
        removeSignatory()
            .then(() => refetch())
            .catch(() => {
            addToast({
                title: t({
                    message: 'Could not withdraw e-signing',
                    comment: 'RemoveSignatoryDialog e-signing error',
                }),
                appearance: 'danger',
            });
        });
    }, [removeSignatory, refetch, addToast]);
    return (<ConfirmDialog size="sm" onClose={onClose} appearance="danger" heading={t `Withdraw e-signing`} media={<Avatar appearance="danger" shape="square" size="lg">
          <Falcon icon="triangle-exclamation"/>
        </Avatar>} confirm={t `Withdraw`} cancel={t `Cancel`} onCancel={onClose} onConfirm={handleConfirm} loading={loading}>
      <Copy>
        <Trans>
          Are you sure you want to withdraw e-signing for <Strong>{name}</Strong>? They will no longer be able to access
          and sign the document.
        </Trans>
      </Copy>
    </ConfirmDialog>);
}
