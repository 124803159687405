var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { Alert, useToast } from '@pocketlaw/tetris';
import { TemplateDrawer, TemplateDrawerContent } from 'app/domains/esigning/components/TemplateDrawer';
import { AttachmentsForm } from './AttachmentsForm';
import { useComposers } from './useComposers';
import { useSaveDefaultAttachments } from './useSaveDefaultAttachments';
import { isComposerDefaultAttachmentsEdit, isComposerDefaultAttachmentsEqual } from './utils';
export function TemplateDefaultAttachmentsDrawer(props) {
    var _a, _b;
    const { composerIds, onClose } = props;
    const { composers, error, loading } = useComposers(composerIds);
    const { saveDefaultAttachments } = useSaveDefaultAttachments();
    const { addToast } = useToast();
    const composerAttachments = composers.map(({ attachments }) => attachments);
    const firstComposerName = ((_b = (_a = composers[0]) === null || _a === void 0 ? void 0 : _a.metadata[0]) === null || _b === void 0 ? void 0 : _b.name) || '';
    const subheader = composerIds.length === 1
        ? firstComposerName
        : t({
            comment: 'Manage default attachments drawer - subheader',
            message: `${composerIds.length} selected templates`,
        });
    const isEdit = isComposerDefaultAttachmentsEdit(composerAttachments);
    const isEqual = isComposerDefaultAttachmentsEqual(composerAttachments);
    const initialAttachments = isEqual && composerAttachments.length > 0 ? composerAttachments[0] : [];
    const handleSubmit = (attachments) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield saveDefaultAttachments({ composerIds, attachments });
            addToast({
                appearance: 'success',
                title: t({
                    message: 'Successfully saved default attachments',
                    comment: 'Success message for saving default attachments toast',
                }),
            });
            onClose();
        }
        catch (_c) {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Could not save default attachments',
                    comment: 'Error message for saving default attachments toast',
                }),
            });
        }
    });
    return (<TemplateDrawer header={t({
            comment: 'Manage default attachments drawer - header',
            message: 'Default attachments',
        })} subheader={subheader} loading={loading} onClose={onClose}>
      {error ? (<TemplateDrawerContent>
          <Alert appearance="danger" message={t({
                message: 'Could not load attachments, please try again',
                comment: 'Error message for loading template default attachments',
            })}/>
        </TemplateDrawerContent>) : (<AttachmentsForm isEdit={isEdit} isEqual={isEqual} initialAttachments={initialAttachments} onSubmit={handleSubmit}/>)}
    </TemplateDrawer>);
}
