import { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, TextInput } from '@pocketlaw/tetris';
export function EditDialog(props) {
    const { initialName, onClose, onEdit } = props;
    const [name, setName] = useState(initialName);
    const disabled = name.length < 1;
    const handleChange = (event) => {
        setName(event.target.value);
    };
    const handleSave = () => {
        onEdit(name);
        onClose();
    };
    return (<Dialog size="sm">
      <DialogHeader title={t({
            comment: 'Edit attachment name dialog - title',
            message: 'Edit attachment name',
        })}/>
      <DialogContent>
        <TextInput value={name} onChange={handleChange}/>
      </DialogContent>
      <DialogFooter>
        <Button appearance="ghost" onClick={onClose}>
          <Trans comment="Edit attachment name dialog - cancel button label">Cancel</Trans>
        </Button>
        <Button appearance="primary" onClick={handleSave} disabled={disabled}>
          <Trans comment="Edit attachment name dialog - save button label">Save</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
