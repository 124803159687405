var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useTextNodeDistanceSearch, } from 'shared/domains/documents/hooks/useTextNodeDistanceSearch';
function extractPdfTextNodes(pages) {
    return __awaiter(this, void 0, void 0, function* () {
        const entries = yield Promise.all(pages.map((page) => __awaiter(this, void 0, void 0, function* () {
            const textContent = yield page.getTextContent({
                includeMarkedContent: false,
            });
            // Complicated because the `includeMarkedContent` is not respected by the types
            return [page, textContent.items];
        })));
        return new WeakMap(entries);
    });
}
function pdfToSearchableTextNodes(pages, pdfTextNodes) {
    return pages.flatMap((page) => {
        const pageTextNodes = pdfTextNodes.get(page);
        if (!pageTextNodes) {
            return [];
        }
        return pageTextNodes.map((item, index) => ({
            page: page.pageNumber,
            index,
            value: 'str' in item ? item.str : '',
        }));
    });
}
export function useHighlightTexts(pages) {
    const [search, { data: searchResultNodes }] = useTextNodeDistanceSearch();
    const fuzzySearchTextNodes = useCallback((query) => __awaiter(this, void 0, void 0, function* () {
        const pdfTextNodesMap = yield extractPdfTextNodes(pages);
        const searchableNodes = pdfToSearchableTextNodes(pages, pdfTextNodesMap);
        search(searchableNodes, query);
    }), [pages, search]);
    const isTextNodeHighlighted = useCallback((pageNumber, textNodeIndex) => searchResultNodes.some((textNode) => textNode.page === pageNumber && textNode.index === textNodeIndex), [searchResultNodes]);
    const isFirstHighlightedTextNode = useCallback((pageNumber, textNodeIndex) => {
        const [firstNode] = searchResultNodes;
        if (!firstNode) {
            return false;
        }
        return pageNumber === firstNode.page && textNodeIndex === firstNode.index;
    }, [searchResultNodes]);
    return { fuzzySearchTextNodes, isTextNodeHighlighted, isFirstHighlightedTextNode };
}
