import { metadataConfig } from 'shared/domains/metadata';
import { SingleSelectList } from './SingleSelectList';
import { useMetadataField } from '../useMetadataField';
export function AllowedValuesList() {
    const { property } = useMetadataField();
    const { allowedValues } = metadataConfig.get(property.name) || {};
    const data = allowedValues === null || allowedValues === void 0 ? void 0 : allowedValues.reduce((all, current) => {
        const { text, value, groupValues } = current;
        const localizedGroupValues = (groupValues === null || groupValues === void 0 ? void 0 : groupValues.map((groupValue) => ({ text: groupValue.text, value: groupValue.value }))) || [];
        return [...all, { text, value }, ...localizedGroupValues];
    }, []);
    if (!data)
        return null;
    return <SingleSelectList data={data}/>;
}
