import { t } from '@lingui/macro';
import { ButtonGroup } from '@pocketlaw/tetris';
import { ClearFilter } from './components/ClearFilter';
import { NullOperator } from './components/NullOperator';
import { OperatorSelector } from './components/OperatorSelector';
import { SingleSelectOperator } from './components/SingleSelectOperator';
import { useFilter } from './useFilter';
export function BooleanFilter(props) {
    const { name, label } = props;
    const filter = useFilter(name);
    const items = [
        {
            id: 'true',
            value: true,
            title: t({ message: 'Yes', comment: 'Insights: Boolean filter - yes' }),
        },
        {
            id: 'false',
            value: false,
            title: t({ message: 'No', comment: 'Insights: Boolean filter - yes' }),
        },
    ];
    const selectedItem = items.find((item) => {
        var _a;
        const currentValue = (_a = filter.value) === null || _a === void 0 ? void 0 : _a[filter.operator];
        return currentValue === item.value;
    });
    return (<ButtonGroup>
      <OperatorSelector label={label} name={name} operators={[
            {
                id: 'eq',
                menuItem: t({ message: 'Equals', comment: 'Search: Filter operator - is' }),
                label: t({ message: 'equals', comment: 'Search: Filter operator - is' }),
                onSelect: () => filter.setValue({ eq: undefined }),
            },
            {
                id: 'isNull',
                menuItem: t({ message: 'Has value', comment: 'Search: Filter operator - has value' }),
                label: t({ message: 'has', comment: 'Search: Filter operator - has value' }),
                onSelect: () => filter.setValue({ isNull: undefined }),
            },
        ]}/>

      {filter.operator === 'eq' && (<SingleSelectOperator name={name} items={items} selectedId={selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.id}/>)}

      {filter.operator === 'isNull' && <NullOperator name={name}/>}

      <ClearFilter name={name}/>
    </ButtonGroup>);
}
