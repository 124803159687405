export const AutoTagQueueFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AutoTagQueueFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentMetadataAiAutoTaggingQueueEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileSystemDocument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export const AutoTagQueueDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'AutoTagQueue' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'count' },
                        name: { kind: 'Name', value: 'countDocumentMetadataAiAutoTaggingQueueEntries' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'status' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'in' },
                                                        value: {
                                                            kind: 'ListValue',
                                                            values: [
                                                                { kind: 'EnumValue', value: 'DELAYED' },
                                                                { kind: 'EnumValue', value: 'PENDING' },
                                                                { kind: 'EnumValue', value: 'IN_PROGRESS' },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdByUserId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'eq' },
                                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'active' },
                        name: { kind: 'Name', value: 'documentMetadataAiAutoTaggingQueueEntries' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'createdAt' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'documentId' },
                                                    value: { kind: 'EnumValue', value: 'asc' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'status' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'in' },
                                                        value: {
                                                            kind: 'ListValue',
                                                            values: [
                                                                { kind: 'EnumValue', value: 'DELAYED' },
                                                                { kind: 'EnumValue', value: 'PENDING' },
                                                                { kind: 'EnumValue', value: 'IN_PROGRESS' },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdByUserId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'eq' },
                                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AutoTagQueueFragment' },
                                    directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'unmask' } }],
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'completed' },
                        name: { kind: 'Name', value: 'documentMetadataAiAutoTaggingQueueEntries' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'createdAt' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'documentId' },
                                                    value: { kind: 'EnumValue', value: 'asc' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'status' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'in' },
                                                        value: { kind: 'ListValue', values: [{ kind: 'EnumValue', value: 'SUCCESS' }] },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdByUserId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'eq' },
                                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AutoTagQueueFragment' },
                                    directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'unmask' } }],
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'failed' },
                        name: { kind: 'Name', value: 'documentMetadataAiAutoTaggingQueueEntries' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'orderBy' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'createdAt' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'documentId' },
                                                    value: { kind: 'EnumValue', value: 'asc' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'status' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'in' },
                                                        value: {
                                                            kind: 'ListValue',
                                                            values: [
                                                                { kind: 'EnumValue', value: 'FAILED' },
                                                                { kind: 'EnumValue', value: 'CANCELED' },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdByUserId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'eq' },
                                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'AutoTagQueueFragment' },
                                    directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'unmask' } }],
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'AutoTagQueueFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentMetadataAiAutoTaggingQueueEntry' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'documentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdByUserId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fileSystemDocument' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
