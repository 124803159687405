import { Checkbox, ChipAction, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { getStatusTranslations } from 'app/domains/tasks/utils/taskStatus';
import { TaskStatusEnumEnum } from 'shared/domains/apollo/generated/types';
import { ChipDropdown } from 'shared/domains/common-ui';
import { formatLabel } from './utils/formatLabel';
import { ListItemText } from './styled';
export function StatusFilter(props) {
    const { clear, onChange, value = [], label, tab } = props;
    const statusValues = Object.values(TaskStatusEnumEnum);
    const statuses = getStatusTranslations();
    const handleChange = (options) => {
        const { status, checked } = options;
        if (checked) {
            onChange([...value, status]);
        }
        else {
            const filteredStatuses = value.filter((s) => s !== status);
            onChange(filteredStatuses);
        }
    };
    if (tab === 'completed') {
        return null;
    }
    return (<ChipDropdown label={formatLabel(label, value)} action={value.length ? (<ChipAction onClick={clear}>
            <Falcon icon="remove"/>
          </ChipAction>) : undefined} dropdownContent={<MenuBody>
          <MenuList>
            {statusValues.map((status) => (<MenuItem key={status} as="label">
                <ListItemText>{statuses[status].label}</ListItemText>
                <Checkbox id={status} checked={value.includes(status)} onChange={(e) => handleChange({ checked: e.target.checked, status })}/>
              </MenuItem>))}
          </MenuList>
        </MenuBody>}/>);
}
