var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { CreateDashboardSlotDocument } from './CreateDashboardSlot.gql';
import { CreateInsightsWidgetDocument } from './CreateInsightsWidget.gql';
import { defaultSlotSize } from './utils';
export function useCreateDashboardWidget() {
    const [slotMutation] = useMutation(CreateDashboardSlotDocument);
    const [widgetMutation] = useMutation(CreateInsightsWidgetDocument);
    const handleWidgetCreation = (dashboardId, widget) => __awaiter(this, void 0, void 0, function* () {
        const { data } = yield widgetMutation({
            variables: {
                input: {
                    label: widget.label,
                    description: widget.description,
                    colorScheme: widget.colorScheme,
                    filters: widget.filters,
                    aggregations: widget.aggregations,
                    type: widget.type,
                    sourceId: widget.sourceId,
                    isHorizontal: widget.isHorizontal,
                },
            },
        });
        if (!(data === null || data === void 0 ? void 0 : data.createSearchInsightsWidget.id)) {
            throw new Error('Widget creation failed');
        }
        const widgetId = data.createSearchInsightsWidget.id;
        const slotSize = defaultSlotSize(widget.type);
        yield slotMutation({
            variables: {
                input: {
                    dashboardId,
                    slots: [
                        {
                            widgetId,
                            x: 0,
                            y: 0,
                            w: slotSize.w,
                            h: slotSize.h,
                        },
                    ],
                },
            },
            update(cache, { data: newData }) {
                const { addSearchInsightsDashboardSlotsToDashboard } = newData || {};
                if (addSearchInsightsDashboardSlotsToDashboard === null || addSearchInsightsDashboardSlotsToDashboard === void 0 ? void 0 : addSearchInsightsDashboardSlotsToDashboard.length) {
                    const dashboardCacheId = cache.identify({
                        __typename: 'SearchInsightsDashboard',
                        id: dashboardId,
                    });
                    cache.modify({
                        id: dashboardCacheId,
                        fields: {
                            slots(existing = [], { toReference }) {
                                const newRefs = addSearchInsightsDashboardSlotsToDashboard.map((slot) => toReference(slot, true));
                                return [...existing, ...newRefs];
                            },
                        },
                    });
                }
            },
        });
    });
    return [handleWidgetCreation];
}
