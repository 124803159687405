import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ESignSignatoryFormDialog } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { SectionList } from 'app/domains/esigning/components/components';
import { FormDefaultSignatoryItem } from '../FormDefaultSignatoryItem';
import { useDefaultSignatories } from '../useDefaultSignatories';
export function DefaultNonSigningSignatories() {
    const { signatories, addSignatory } = useDefaultSignatories();
    const nonSigningSignatories = signatories.filter(({ isSigning }) => !isSigning);
    const [renderDialog, setRenderDialog] = useState(false);
    const showDialog = () => setRenderDialog(true);
    const hideDialog = () => setRenderDialog(false);
    if (nonSigningSignatories.length === 0) {
        return null;
    }
    return (<Fragment>
      {renderDialog && (<ESignSignatoryFormDialog type="DefaultSignatory" newSignatoryIsNonSigning allSignatories={signatories} onClose={hideDialog} onSubmit={addSignatory}/>)}
      <SectionList hideTooManyItems title={t({
            comment: 'Manage default signing parties drawer - Non signing parties label',
            message: 'Non signing parties',
        })} endElement={<IconButton $round size="small" appearance="ghost" onClick={showDialog}>
            <Falcon icon="plus"/>
          </IconButton>}>
        {nonSigningSignatories.map((signatory) => (<FormDefaultSignatoryItem key={signatory.key} signatory={signatory}/>))}
      </SectionList>
    </Fragment>);
}
