import { t } from '@lingui/macro';
import { ListItem } from '../ListItem';
export function PendingInvitee(props) {
    const { id, email, onRemove } = props;
    const handleRemoveInvitee = () => {
        onRemove({ id, email });
    };
    const description = t({
        message: 'Invitation sent',
        comment: 'Label for pending external invitee',
    });
    return <ListItem pending label={email} description={description} onRemove={handleRemoveInvitee}/>;
}
