import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SignatoryStatus } from 'shared/domains/apollo/generated/types';
export function getIconFromStatus(status, small = false) {
    switch (status) {
        case SignatoryStatus.Opened:
            return small ? <Falcon $size={12} icon="eye"/> : <Falcon icon="eye"/>;
        case SignatoryStatus.Signed:
            return small ? <Falcon $size={12} icon="circle-check"/> : <Falcon icon="circle-check"/>;
        case SignatoryStatus.Rejected:
            return small ? <Falcon $size={12} icon="circle-exclamation"/> : <Falcon icon="circle-exclamation"/>;
        case SignatoryStatus.Pending:
        default:
            return small ? <Falcon $size={12} icon="paper-plane"/> : <Falcon icon="paper-plane"/>;
    }
}
export function getLabelFromStatus(status) {
    switch (status) {
        case SignatoryStatus.Opened:
            return t({ message: 'Viewed', comment: 'Label for opened e-signing party status' });
        case SignatoryStatus.Signed:
            return t({ message: 'Signed', comment: 'Label for signed e-signing party status' });
        case SignatoryStatus.Rejected:
            return t({ message: 'Declined', comment: 'Label for rejected e-signing party status' });
        case SignatoryStatus.Pending:
        default:
            return t({ message: 'Sent', comment: 'Label for pending e-signing party status' });
    }
}
