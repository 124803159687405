import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Dialog, DialogContent, DialogFooter, Typography } from '@pocketlaw/tetris';
import { FileBrowser } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { ConfirmButton } from './ConfirmButton';
import { Header } from './Header';
import { UploadButton } from './UploadButton';
export function EsigningQuickLinkDialog(props) {
    const { onClose, open } = props;
    const [selectedEntry, setSelectedEntry] = useState(undefined);
    if (!open) {
        return null;
    }
    return (<Dialog onClose={onClose}>
      <Header />
      <DialogContent>
        <Typography $appearance="300">
          <Trans comment="Dashboard: send for e-signing modal - body text">
            Select or upload the document you want to send for e-sign.
          </Trans>
        </Typography>
        <FileBrowser selected={selectedEntry} onSelect={setSelectedEntry} selectable={(entry) => entry.kind === EntryKind.Document}/>
      </DialogContent>
      <DialogFooter description={<UploadButton />}>
        <Button appearance="secondary" onClick={onClose}>
          <Trans comment="Dashboard: send for e-signing modal - cancel button">Cancel</Trans>
        </Button>
        <ConfirmButton id={selectedEntry === null || selectedEntry === void 0 ? void 0 : selectedEntry.id}/>
      </DialogFooter>
    </Dialog>);
}
