import { gql } from '@apollo/client';
export const plastToDOCXMutation = gql `
  mutation RenderPlastToDOCX($companyId: UUID!, $content: JSON!, $logoUrl: String, $name: String) {
    response: plastToDocx(input: { companyId: $companyId, content: $content, logoUrl: $logoUrl, title: $name }) {
      id
      signed(name: $name) {
        url
      }
    }
  }
`;
