import { gql } from '@apollo/client';
import { TaskFragment } from '../../utils/fragments';
export const getTasksQuery = gql `
  query GetTasks($whereArgs: TaskBoolExp, $orderByArgs: [TaskOrderBy!]) {
    tasks(order_by: $orderByArgs where: $whereArgs) {
      ...TaskFragment @unmask
    }

    ${TaskFragment}
  }
`;
