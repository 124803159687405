import { Trans, t } from '@lingui/macro';
import { Alert, EmptyState, TableBody, TableHead, TableHeadCell, TableHeaderText, TableRow } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFileBrowserContext } from 'app/domains/filesystem';
import { FolderPermissionWarning } from './FolderPermissionWarning';
import { Row } from './Row';
import { SkeletonRow } from './SkeletonRow';
import { PickerContent } from '../PickerContent';
import { AlertContainer, StyledTable } from './styled';
export function PickerTableFilesystem(props) {
    const { selectedEntryAlert, multiselect } = props;
    const { entries, selected, loading, limit, fileBrowserSearch } = useFileBrowserContext();
    const { searching } = fileBrowserSearch;
    const hasSelection = Boolean(selected.length);
    const showEntryAlert = hasSelection && selectedEntryAlert && Boolean(selected.filter(selectedEntryAlert.check).length);
    const loadingEntries = Array(limit)
        .fill(null)
        .map((_, index) => index);
    if (!entries.length && !loading) {
        if (searching) {
            return (<PickerContent>
          <EmptyState withAvatar icon={<Falcon icon="search"/>} title={t({
                    comment: 'When a search does not return any results - Title',
                    message: 'No results',
                })} description={t({
                    comment: 'When a search does not return any results - Description',
                    message: 'We could not find any results for your search.',
                })}/>
        </PickerContent>);
        }
        return (<PickerContent>
        <EmptyState withAvatar icon={<Falcon icon="folder-open"/>} title={t({
                comment: 'When a folder does not contain any documents - Title',
                message: 'Empty folder',
            })} description={t({
                comment: 'When a folder does not contain any documents - Description',
                message: 'This folder does not contain any documents.',
            })}/>
      </PickerContent>);
    }
    return (<PickerContent>
      {showEntryAlert && (<AlertContainer>
          <Alert {...selectedEntryAlert}/>
        </AlertContainer>)}

      <FolderPermissionWarning />

      <StyledTable dense>
        <colgroup>
          <col width="auto"/>
          <col width="120"/>
          <col width="180"/>
        </colgroup>
        <TableHead>
          <TableRow>
            <TableHeadCell id="cell:name">
              <TableHeaderText>
                <Trans comment="Table column header: Title">Title</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:updated">
              <TableHeaderText>
                <Trans comment="Table column header: Updated">Updated</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:owner">
              <TableHeaderText>
                <Trans comment="Table column header: Creator">Creator</Trans>
              </TableHeaderText>
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? loadingEntries.map((index) => <SkeletonRow key={index}/>)
            : entries.map((entry) => <Row key={entry.id} entry={entry} multiselect={multiselect}/>)}
        </TableBody>
      </StyledTable>
    </PickerContent>);
}
