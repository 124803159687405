import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
/**
 * Blog post about how the svg circle animation works: https://css-tricks.com/svg-line-animation-works/
 */
const getBackgroundStatusColor = (status) => {
    switch (status) {
        case 'success':
            return themeGet('color.success');
        case 'error':
            return themeGet('color.danger');
        case 'finalizing':
            return themeGet('colors.secondary.500');
        case 'uploading':
        default:
            return themeGet('colors.secondary.100');
    }
};
const getIconStatusColor = (status) => {
    switch (status) {
        case 'success':
            return themeGet('colors.background.50');
        case 'error':
            return themeGet('colors.background.50');
        case 'finalizing':
            return themeGet('colors.background.50');
        case 'uploading':
        default:
            return themeGet('colors.secondary.500');
    }
};
const getProgressCircleStatusColor = (status) => {
    switch (status) {
        case 'success':
            return themeGet('color.success');
        case 'error':
            return themeGet('color.danger');
        case 'finalizing':
        case 'uploading':
        default:
            return themeGet('colors.secondary.500');
    }
};
const uploadingAnimation = keyframes `
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(30%);
  }
`;
const enterAnimation = keyframes `
  from {
    opacity: 0;
    transform: translateY(200%) scale(0);
  }

  to {
    opacity: 1;
    transform: translateY(0%) scale(1);
  }
`;
const CONTAINER_SIZE = 72;
const PROGRESS_BAR_WIDTH = 6;
const CIRCLE_SIZE = CONTAINER_SIZE / 2;
const CIRCLE_RADIUS = CIRCLE_SIZE - PROGRESS_BAR_WIDTH / 2;
const ICON_CONTAINER_SIZE = CONTAINER_SIZE - PROGRESS_BAR_WIDTH * 4;
export const CIRCLE_DASH_ARRAY = Math.PI * CIRCLE_RADIUS * 2;
const circleBaseStyles = css `
  cx: ${CIRCLE_SIZE};
  cy: ${CIRCLE_SIZE};
  r: ${CIRCLE_RADIUS};

  fill: none;
  stroke-width: ${pxToRem(PROGRESS_BAR_WIDTH)};
`;
export const SvgContainer = styled.svg `
  width: ${pxToRem(CONTAINER_SIZE)};
  height: ${pxToRem(CONTAINER_SIZE)};
`;
export const CircleBackground = styled.circle `
  ${circleBaseStyles};

  stroke: ${themeGet('colors.gray.100')};
`;
export const CircleProgress = styled.circle `
  ${circleBaseStyles};

  stroke: ${themeGet('colors.secondary.500')};
  stroke-linecap: round;
  stroke-dasharray: ${CIRCLE_DASH_ARRAY};
  stroke-dashoffset: ${(props) => props.$progress};

  transform: rotate(-90deg);
  transform-origin: 50% 50%;

  transition: all 225ms ease-in-out;
`;
export const ProgressIcon = styled.div `
  position: absolute;
  display: flex;

  transition: color 225ms ease-in-out;

  [data-icon='falcon'] {
    transition: inherit;
  }

  [data-animation='uploading'] {
    margin-top: -15%;
    animation: ${uploadingAnimation} 750ms ease-in-out infinite alternate;
  }

  [data-animation='finalizing'],
  [data-animation='success'],
  [data-animation='error'] {
    animation: ${enterAnimation} 375ms cubic-bezier(0.13, 1.5, 0.82, 1.1) forwards;
  }
`;
export const ProgressIconBackground = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  width: ${pxToRem(ICON_CONTAINER_SIZE)};
  height: ${pxToRem(ICON_CONTAINER_SIZE)};
  transition: background-color 225ms ease-in-out;
`;
export const ProgressContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${ProgressIconBackground} {
    background-color: ${(props) => getBackgroundStatusColor(props.$status)};
  }

  ${ProgressIcon} {
    color: ${(props) => getIconStatusColor(props.$status)};

    [data-icon='falcon'] {
      color: ${(props) => getIconStatusColor(props.$status)};
    }
  }

  ${CircleProgress} {
    stroke: ${(props) => getProgressCircleStatusColor(props.$status)};
  }
`;
export const Container = styled.div `
  width: 100%;
  height: ${pxToRem(200)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${unit('lg')};
  border-radius: ${pxToRem(8)};
`;
