import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { AutoTagSwitchField } from 'app/domains/metadata';
import { ConfirmCancelUploadDialog } from '../ConfirmCancelUploadDialog';
import { FilesPreview } from '../FilesPreview';
import { useUpload } from '../UploadProvider';
import { UploadingState } from '../UploadingState';
import { AutoTagContainer } from './styled';
export function UploadDialog() {
    const { isUploading, isUploadDisabled, closeDialog, startUpload, renderConfirmCancelDialog, autoTag, setAutoTag } = useUpload();
    const handleClose = () => closeDialog();
    return (<Fragment>
      {renderConfirmCancelDialog && <ConfirmCancelUploadDialog />}
      <Dialog onClose={handleClose}>
        <DialogHeader title={<Trans comment="Upload documents dialog - Title">Upload documents</Trans>} action={<IconButton $round size="small" appearance="ghost" onClick={handleClose}>
              <Falcon icon="xmark"/>
            </IconButton>}/>
        <DialogContent>
          {isUploading ? <UploadingState /> : <FilesPreview />}
          {!isUploading && (<AutoTagContainer>
              <AutoTagSwitchField enabled={autoTag} onChange={() => setAutoTag(!autoTag)}/>
            </AutoTagContainer>)}
        </DialogContent>
        <DialogFooter>
          <Button type="button" appearance={isUploading ? 'danger' : 'secondary'} onClick={handleClose}>
            <Trans comment="Upload documents dialog - Cancel button label">Cancel</Trans>
          </Button>
          {!isUploading && (<Button type="button" disabled={isUploadDisabled} onClick={startUpload}>
              <Trans comment="Upload documents dialog - Start upload button label">Upload</Trans>
            </Button>)}
        </DialogFooter>
      </Dialog>
    </Fragment>);
}
