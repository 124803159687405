import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
import { SupportedFileMimeTypes } from 'shared/domains/filesystem';
export const UNASSIGNED_SIGNING_ORDER = -1;
export const PROVIDER_IDS = {
    SCRIVE: '3eedcbaf-af27-47e6-a938-7ac200a153d0',
    DOCUSIGN: 'bb2f4618-ba66-490d-a30f-d31863b94127',
    POCKETLAW: '28f16c5a-5981-47cd-a891-9b2dcf21b35e',
};
export const ACTIVE_SIGN_REQUEST_STATUSES = [
    SignRequestStatus.Signed,
    SignRequestStatus.Pending,
    SignRequestStatus.Opened,
    SignRequestStatus.Creating,
];
export const ESIGNING_PL_FILES_ATTACHMENT_SUPPORTED_MIME_TYPES = [
    SupportedFileMimeTypes.PDF,
    SupportedFileMimeTypes.DOCX,
];
export const ESIGNING_UPLOAD_ATTACHMENT_SUPPORTED_MIME_TYPES = [SupportedFileMimeTypes.PDF];
export const ESIGNING_SENDOUT_SUPPORTED_MIME_TYPES = [SupportedFileMimeTypes.PDF, SupportedFileMimeTypes.DOCX];
