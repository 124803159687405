import { DocumentType, FileSystemDocumentStatus, SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export function getAppearance(value) {
    switch (value) {
        case FileSystemDocumentStatus.SigningStageSigned:
            return 'success';
        case FileSystemDocumentStatus.AgreedStage:
        case FileSystemDocumentStatus.AgreedStageAgreedForm:
        case FileSystemDocumentStatus.DraftStage:
            return 'secondary';
        case FileSystemDocumentStatus.ReviewStageApproved:
            return 'success';
        case FileSystemDocumentStatus.ReviewStagePending:
        case FileSystemDocumentStatus.SigningStage:
        case FileSystemDocumentStatus.ReviewStage:
        case FileSystemDocumentStatus.SigningStagePending:
        case FileSystemDocumentStatus.SigningStagePendingNotYetSigned:
        case FileSystemDocumentStatus.SigningStagePendingPartiallySigned:
            return 'info';
        case FileSystemDocumentStatus.ReviewStageCancelled:
        case FileSystemDocumentStatus.ReviewStageRejected:
        case FileSystemDocumentStatus.SigningStageCancelled:
        case FileSystemDocumentStatus.SigningStageFailed:
        case FileSystemDocumentStatus.SigningStageRejected:
        case FileSystemDocumentStatus.Inconsistent:
            return 'danger';
        case FileSystemDocumentStatus.Unknown:
        default:
            return 'warning';
    }
}
function isDocumentType(value) {
    return Object.values(DocumentType).includes(value);
}
export function mapSearchType(type) {
    if (isDocumentType(type)) {
        return type;
    }
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
            return DocumentType.Composed;
        case SearchEntityTypeEnum.DocumentFreetext:
            return DocumentType.Freetext;
        case SearchEntityTypeEnum.DocumentUploaded:
            return DocumentType.Uploaded;
        default:
            return DocumentType.Unknown;
    }
}
