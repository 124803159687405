/**
 * This is added because of translations. The label and description in this mock
 * has to be a string, but since we use msg from lokalise it is not a string.
 * This is temporary mock. I will not fix this now.
 */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { msg as t } from '@lingui/macro';
import { SearchInsightsWidgetTypeEnum } from 'shared/domains/apollo/generated/types';
export const insightWidgetMocks = () => [
    {
        id: '48566d6a-ad29-4e5b-bc9c-a9e82efabc12',
        label: t({ message: 'Contracts with Auto-Renewal', comment: 'Insights - Widget label' }),
        type: SearchInsightsWidgetTypeEnum.Count,
        isHorizontal: false,
        aggregations: [
            {
                type: 'terms',
                name: 'fefe2383-58fc-4ad9-b704-200b7fcbf6fb',
                termsInput: { field: 'metadataDocumentAutomaticRenewal', size: 10, missing: null },
            },
        ],
        colorScheme: 'mono-shaded',
        filters: { metadataDocumentDocumentCategory: { in: ['commercial'] } },
        description: t({
            message: 'Auto-renewing contracts | All time',
            comment: 'Insights - Widget description',
        }),
    },
    {
        id: '56a7bbc0-54cf-466d-a1ec-eb05082afb1c',
        label: t({ message: 'Documents created', comment: 'Insights - Widget label' }),
        description: t({
            message: 'Documents created over time | By users | Last 365 days',
            comment: 'Insights - Widget description',
        }),
        type: SearchInsightsWidgetTypeEnum.Barchart,
        isHorizontal: true,
        filters: null,
        aggregations: [
            {
                type: 'dateHistogram',
                name: '0cd3c203-4d89-4297-8553-012f7ea522ee',
                dateHistogramInput: {
                    field: 'createdAt',
                    interval: 'month',
                    aggregations: [
                        {
                            type: 'terms',
                            name: '470a829a-20f2-444c-8273-2e08900c539e',
                            termsInput: { field: 'metadataDocumentContractOwner', size: 10, missing: null },
                        },
                    ],
                },
            },
        ],
        colorScheme: 'mono-shaded',
    },
    {
        id: '6f44c1b9-55f6-46dc-99b7-e95b1caa22c1',
        label: t({ message: 'Material Agreements', comment: 'Insights - Widget label' }),
        type: SearchInsightsWidgetTypeEnum.Count,
        isHorizontal: false,
        colorScheme: 'mono-shaded',
        aggregations: [
            {
                type: 'terms',
                name: '54eeb661-74e0-4450-9195-0e24a403aaed',
                termsInput: { field: 'metadataDocumentMaterialAgreement', size: 10, missing: null },
            },
        ],
        filters: { metadataDocumentMaterialAgreement: { eq: true } },
        description: t({
            message: 'Material agreements | All time',
            comment: 'Insights - Widget description',
        }),
    },
    {
        id: 'b31f3cb8-e878-407e-adb0-7f9445a94330',
        label: t({ message: 'Upcoming Notice Dates', comment: 'Insights - Widget label' }),
        type: SearchInsightsWidgetTypeEnum.Barchart,
        isHorizontal: false,
        filters: null,
        aggregations: [
            {
                type: 'dateRange',
                name: '34db0bf4-1a1b-474f-b16f-ea9f52945b48',
                dateRangeInput: {
                    field: 'metadataDocumentNoticeDate',
                    ranges: [
                        {
                            fromNow: true,
                            fromOffset: { value: 0, timeUnit: 'day', roundingTimeUnit: 'day' },
                            toNow: true,
                            toOffset: { value: 31, timeUnit: 'day', roundingTimeUnit: 'day' },
                        },
                        {
                            fromNow: true,
                            toNow: true,
                            fromOffset: { value: 31, timeUnit: 'day', roundingTimeUnit: 'day' },
                            toOffset: { value: 61, timeUnit: 'day', roundingTimeUnit: 'day' },
                        },
                        {
                            fromNow: true,
                            toNow: true,
                            fromOffset: { value: 61, timeUnit: 'day', roundingTimeUnit: 'day' },
                            toOffset: { value: 91, timeUnit: 'day', roundingTimeUnit: 'day' },
                        },
                    ],
                    aggregations: [
                        {
                            type: 'terms',
                            name: '9bca69c1-8afa-46cf-ba75-f2cc92373a83',
                            termsInput: {
                                field: 'metadataDocumentDocumentCategory',
                                size: 10,
                                missing: 'missing',
                            },
                        },
                    ],
                },
            },
        ],
        description: t({
            message: 'Contracts with upcoming notice dates | 30/60/90 days',
            comment: 'Insights - Widget description',
        }),
        colorScheme: 'mono-shaded',
    },
    {
        id: 'be2abf1d-3319-43fe-a2cb-26f34ac69db4',
        label: t({ message: 'Top Counterparties', comment: 'Insights - Widget label' }),
        type: SearchInsightsWidgetTypeEnum.Table,
        colorScheme: 'mono-shaded',
        isHorizontal: false,
        aggregations: [
            {
                type: 'terms',
                name: '272744ba-066a-4ffc-a7c8-1e16d5001d4b',
                termsInput: { field: 'metadataDocumentParties', size: 10, missing: 'missing' },
            },
        ],
        filters: {
            metadataDocumentDocumentCategory: {
                in: ['commercial'],
            },
        },
        description: t({
            message: 'Counterparties | By number of contracts | All time',
            comment: 'Insights - Widget description',
        }),
    },
    {
        id: 'd0cc2fa7-c033-4cbc-bf2a-1b2e58129199',
        label: t({ message: 'Documents Signed', comment: 'Insights - Widget label' }),
        type: SearchInsightsWidgetTypeEnum.Linechart,
        isHorizontal: false,
        filters: null,
        aggregations: [
            {
                type: 'dateHistogram',
                name: '5a92b141-fa54-4494-b9d4-6c5cb4835102',
                dateHistogramInput: {
                    field: 'metadataDocumentDateSigned',
                    interval: 'month',
                },
            },
        ],
        colorScheme: 'mono-shaded',
        description: t({
            message: 'Documents entered over time | All time',
            comment: 'Insights - Widget description',
        }),
    },
    {
        id: 'b2e7f191-31b3-4778-9594-668eea68f37b',
        label: t({
            message: 'Contracts Deviating from Standard',
            comment: 'Insights - Widget label',
        }),
        type: SearchInsightsWidgetTypeEnum.Count,
        isHorizontal: false,
        colorScheme: 'mono-shaded',
        aggregations: [
            {
                type: 'terms',
                name: 'de5708ab-0a01-4632-ad32-3d3ce985617d',
                termsInput: { field: 'metadataDocumentDeviateFromStandard', size: 10, missing: null },
            },
        ],
        description: t({
            message: 'Deviating contracts | All time',
            comment: 'Insights - Widget description',
        }),
        filters: { metadataDocumentDeviateFromStandard: { eq: true } },
    },
    {
        id: '645a1b54-9559-4d96-ab7d-1c03bc72e783',
        label: t({ message: 'Top Contracts', comment: 'Insights - Widget label' }),
        type: SearchInsightsWidgetTypeEnum.Table,
        isHorizontal: false,
        colorScheme: 'mono-shaded',
        aggregations: [
            {
                type: 'terms',
                name: '3f732678-d801-4f71-8fe1-8d66df945fc2',
                termsInput: {
                    field: 'metadataDocumentCurrency',
                    size: 6,
                    missing: 'missing',
                    aggregations: [
                        {
                            type: 'topHits',
                            name: '442e4d4a-0cf0-4df2-884d-12d2bcd1ddfc',
                            topHitsInput: {
                                size: 10,
                                offset: 0,
                                orderBy: [{ metadataDocumentTotalContractValue: 'desc' }],
                            },
                        },
                    ],
                },
            },
        ],
        filters: {
            metadataDocumentDocumentCategory: {
                in: ['commercial'],
            },
        },
        description: t({
            message: 'Contracts | By total contract value | All time',
            comment: 'Insights - Widget description',
        }),
    },
    {
        id: 'dd2a4502-7682-4cbd-b1da-ba2bc05c34cb',
        label: t({ message: 'Governing Law', comment: 'Insights - Widget label' }),
        type: SearchInsightsWidgetTypeEnum.Doughnut,
        isHorizontal: false,
        colorScheme: 'twoColor',
        filters: null,
        aggregations: [
            {
                type: 'terms',
                name: 'f60ad016-f666-4b48-b79d-c5d1cfa634dd',
                termsInput: { field: 'metadataDocumentGoverningLaw', size: 11, missing: 'missing' },
            },
        ],
        description: t({
            message: 'Total contracts | By governing law',
            comment: 'Insights - Widget description',
        }),
    },
    {
        id: '227d85dd-4888-40a0-9c6a-3c49c97e3095',
        label: t({ message: 'Data Processing', comment: 'Insights - Widget label' }),
        type: SearchInsightsWidgetTypeEnum.Table,
        isHorizontal: false,
        colorScheme: 'mono-shaded',
        filters: null,
        aggregations: [
            {
                type: 'terms',
                name: '8a884ecb-46e5-4771-8eff-e03e5dd6e8fe',
                termsInput: {
                    field: 'metadataDocumentProcessingOfPersonalData',
                    size: 10,
                    missing: null,
                    aggregations: [
                        {
                            type: 'terms',
                            name: 'e0f4ee40-8cd1-4068-b79e-79ff453b3309',
                            termsInput: {
                                field: 'metadataDocumentParties',
                                size: 10,
                                missing: 'missing',
                            },
                        },
                    ],
                },
            },
        ],
        description: t({
            message: 'Data processing roles | All time',
            comment: 'Insights - Widget description',
        }),
    },
];
export const insightSlotsMocks = () => [
    {
        id: '2d9c5e8a-6840-4a41-8f33-b3252a6da7fe',
        widgetId: '48566d6a-ad29-4e5b-bc9c-a9e82efabc12',
        x: 8,
        y: 0,
        w: 4,
        h: 5,
    },
    {
        id: '05e09314-2a01-489f-95fa-8358b08bf513',
        widgetId: '56a7bbc0-54cf-466d-a1ec-eb05082afb1c',
        x: 0,
        y: 40,
        w: 6,
        h: 18,
    },
    {
        id: '04005007-21e6-4196-8bd3-4170d20926cd',
        widgetId: '6f44c1b9-55f6-46dc-99b7-e95b1caa22c1',
        x: 0,
        y: 0,
        w: 4,
        h: 5,
    },
    {
        id: '5a407237-7cb0-4715-997d-eed4bef19c2b',
        widgetId: 'b31f3cb8-e878-407e-adb0-7f9445a94330',
        x: 6,
        y: 5,
        w: 6,
        h: 18,
    },
    {
        id: '9539fb3c-fc95-4bf7-ac4b-164b368a36d4',
        widgetId: 'be2abf1d-3319-43fe-a2cb-26f34ac69db4',
        x: 8,
        y: 23,
        w: 4,
        h: 17,
    },
    {
        id: 'ce168386-7501-4699-b15a-c85edb08084e',
        widgetId: 'd0cc2fa7-c033-4cbc-bf2a-1b2e58129199',
        x: 0,
        y: 5,
        w: 6,
        h: 18,
    },
    {
        id: 'cad8fb6a-869e-445c-bafe-ce95e9165835',
        widgetId: 'b2e7f191-31b3-4778-9594-668eea68f37b',
        x: 4,
        y: 0,
        w: 4,
        h: 5,
    },
    {
        id: 'b2a5fb76-573f-4b8e-84e4-c8cd660663fd',
        widgetId: '645a1b54-9559-4d96-ab7d-1c03bc72e783',
        x: 4,
        y: 23,
        w: 4,
        h: 17,
    },
    {
        id: 'c00398ce-81ef-466f-b49e-e6688b10328d',
        widgetId: 'dd2a4502-7682-4cbd-b1da-ba2bc05c34cb',
        x: 0,
        y: 23,
        w: 4,
        h: 17,
    },
    {
        id: '3959fca3-ecbf-4ef6-a8b6-0cbb4b0991c8',
        widgetId: '227d85dd-4888-40a0-9c6a-3c49c97e3095',
        x: 6,
        y: 40,
        w: 6,
        h: 18,
    },
];
export const insightsFiltersMocks = () => ({ createdAt: { gte: '2023-01-01T00:00:00.000Z' } });
export const insightsDashboardMock = (id) => ({
    id: id || '6c6d804a-e271-4711-8297-b3063fa33a64',
    label: t({
        message: 'General dashboard',
        comment: 'Insights - General dashboard mock label',
    }),
    description: t({
        message: 'This General Dashboard is a comprehensive, user-friendly tool designed to streamline legal operations by providing an at-a-glance overview of all critical data in one centralized platform. Tailored for law firms, in-house legal teams, and legal service providers, this dashboard delivers real-time insights into case management, deadlines, billing, and compliance, enhancing decision-making and operational efficiency.',
        comment: 'Insights - General dashboard mock description',
    }),
    slots: insightSlotsMocks().map((slot) => (Object.assign(Object.assign({}, slot), { widget: Object.assign(Object.assign({}, insightWidgetMocks().find((widget) => widget.id === slot.widgetId)), { __typename: 'SearchInsightsWidget' }), __typename: 'SearchInsightsDashboardSlot' }))),
    filters: insightsFiltersMocks(),
    __typename: 'SearchInsightsDashboard',
});
