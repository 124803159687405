import { gql } from '@apollo/client';
export const companyUserDataFragment = gql `
  fragment CompanyUserData on CompanyUser {
    id
    role
    canManageMember: can_manage_member
    createdAt: created_at

    user {
      id
      name
      email
    }
  }
`;
export const updateUserRole = gql `
  mutation UpdateUserRole($id: UUID!, $input: UpdatePartialCompanyUserInput) {
    manageCompanyUser(id: $id, input: $input) {
      user: company_user {
        ...CompanyUserData @unmask
      }
    }
    ${companyUserDataFragment}
  }
`;
