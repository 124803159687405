import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
export const ACTIVE_STATUSES = [SignRequestStatus.Opened, SignRequestStatus.Pending, SignRequestStatus.Signed];
export const hasActiveSignRequest = (document) => {
    var _a;
    const status = (_a = document === null || document === void 0 ? void 0 : document.lastSignRequest) === null || _a === void 0 ? void 0 : _a.status;
    if (!status) {
        return false;
    }
    return ACTIVE_STATUSES.includes(status);
};
