import { t, Trans } from '@lingui/macro';
import { EmailField } from './fields/EmailField';
import { MobileField } from './fields/MobileField';
import { Strong } from './styled';
export const getFormData = (formKey, name) => {
    const map = {
        email: {
            title: t({ message: 'Edit email', comment: 'Title for edit email modal' }),
            message: (<Trans comment="Message for edit email modal">
          Edit the email address for <Strong>{name}</Strong>. A new link to sign the document will be sent out.
        </Trans>),
            successToast: t({
                message: 'Successfully updated email!',
                comment: 'Toast message for successfully updating a signing party email',
            }),
            notEditedError: t({
                message: 'The email has not been edited',
                comment: 'Error message for when the email has not been edited',
            }),
            Field: EmailField,
        },
        mobile: {
            title: t({ message: 'Edit mobile', comment: 'Title for edit mobile modal' }),
            message: (<Trans comment="Message for edit mobile modal">
          Edit the mobile number for <Strong>{name}</Strong>. A new link to sign the document will be sent out.
        </Trans>),
            successToast: t({
                message: 'Successfully updated mobile!',
                comment: 'Toast message for successfully updating a signing party mobile',
            }),
            notEditedError: t({
                message: 'The mobile has not been edited',
                comment: 'Error message for when the mobile has not been edited',
            }),
            Field: MobileField,
        },
    };
    return map[formKey];
};
