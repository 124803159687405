import { Avatar, Typography } from '@pocketlaw/tetris';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { MessageHeaderContainer } from './styled';
export function MessageHeader(props) {
    const { align, avatar, sender, timestamp } = props;
    return (<MessageHeaderContainer $align={align}>
      {avatar && (<Avatar solid size="sm" appearance={avatar.appearance}>
          {avatar.icon}
        </Avatar>)}
      <Typography $fontSize="small" $appearance="300">
        {sender}
      </Typography>
      {timestamp && (<Typography $fontSize="tiny" $appearance="100">
          {localizedFormatDate(timestamp, {
                day: '2-digit',
                month: 'short',
                hour: 'numeric',
                minute: 'numeric',
            })}
        </Typography>)}
    </MessageHeaderContainer>);
}
