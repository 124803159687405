import { Fragment } from 'react';
import { MenuSubheader } from '@pocketlaw/tetris';
import { Item } from './Item';
export function ItemList(props) {
    const { items, type, subheader, checked, onChange } = props;
    return (<Fragment>
      <MenuSubheader>{subheader}</MenuSubheader>
      {items.map((item) => (<Item key={item.id} id={item.id} name={item.name} checked={checked} onChange={onChange} type={type}/>))}
    </Fragment>);
}
