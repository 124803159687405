import { themeGetColor } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Highlight = styled.span `
  pointer-events: none;
  background-color: ${themeGetColor('magenta.500', 0.25)};
  position: absolute;
`;
export const Text = styled.span `
  display: inline-block;
  position: absolute;
  height: 1em;
  line-height: auto;
  line-height: 1;
  transform-origin: left bottom;
  white-space: pre;
  pointer-events: all;
  font-family: ${(props) => props.$fontFamily};
`;
