import { t } from '@lingui/macro';
import { formatPhoneNumber } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SectionAccordionList, SectionAccordionListItem } from 'app/domains/esigning/components/components';
import { getAuthenticationModeLabel } from 'app/domains/esigning/utils';
import { AccordionPartyStatus } from './AccordionPartyStatus';
export function ESignPartyItemAccordion(props) {
    const { partyStatus, email, title, mobile, label, isSigning, authenticationMode, message, groupMessage } = props;
    return (<SectionAccordionList>
      {partyStatus && <AccordionPartyStatus {...partyStatus}/>}
      <SectionAccordionListItem icon={<Falcon icon="envelope"/>} label={t({ comment: 'E-signing party email label', message: 'Email' })} text={email}/>
      <SectionAccordionListItem icon={<Falcon icon="briefcase"/>} label={t({ comment: 'E-signing party title label', message: 'Title' })} text={title}/>
      {mobile && (<SectionAccordionListItem icon={<Falcon icon="phone"/>} label={t({ comment: 'E-signing party phone label', message: 'Phone' })} text={formatPhoneNumber(mobile)}/>)}
      <SectionAccordionListItem icon={<Falcon icon="building"/>} label={t({ comment: 'E-signing party company label', message: 'Company' })} text={label}/>
      {isSigning && (<SectionAccordionListItem icon={<Falcon icon="fingerprint"/>} label={t({
                comment: 'E-signing party signing method label',
                message: 'Signing method',
            })} text={getAuthenticationModeLabel(authenticationMode)}/>)}
      <SectionAccordionListItem icon={<Falcon icon="message-lines"/>} label={t({
            comment: 'E-signing party personal message label',
            message: 'Personal message',
        })} text={message}/>
      <SectionAccordionListItem icon={<Falcon icon="messages"/>} label={t({ comment: 'E-signing party group message label', message: 'Group message' })} text={groupMessage}/>
    </SectionAccordionList>);
}
