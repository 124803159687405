import { gql } from '@apollo/client';
import { AccountFragment } from 'shared/domains/users/utils/fragments';
export const accountsQuery = gql `
  query account {
    accounts @client {
      ...AccountFragment @unmask
    }
  }
  ${AccountFragment}
`;
