import { Falcon } from '@pocketlaw/tetris/macro';
import { SupportedFileMimeTypes } from 'shared/domains/filesystem';
export const getDownloadIcon = (type) => {
    switch (type) {
        case SupportedFileMimeTypes.PDF:
            return <Falcon icon="file-pdf"/>;
        case SupportedFileMimeTypes.DOCX:
            return <Falcon icon="file-word"/>;
        default:
            return <Falcon icon="file-arrow-down"/>;
    }
};
