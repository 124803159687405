var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useUnmount } from 'react-use';
import { useS3FileUpload } from 'app/domains/filesystem';
import { useAutoTagDocuments } from 'app/domains/metadata';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { bulkUploadMutation } from './mutation';
import { usePollUploadJob } from './usePollUploadJob';
import { transformResultsToInputs } from './utils';
import { useUpload } from '../UploadProvider';
export const useUploadDocuments = (callback) => {
    const isUnmountedRef = useRef(false);
    const autoTagJob = useAutoTagDocuments();
    const { autoTag } = useUpload();
    const { data: accountData } = useAccount();
    const { companyId } = accountData;
    const { upload } = useS3FileUpload();
    const [loading, setLoading] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { pollUploadJob } = usePollUploadJob((_a) => __awaiter(void 0, [_a], void 0, function* ({ status, entries = [] }) {
        const entryIds = entries.map((entry) => entry.id);
        if (status === 'success' && autoTag && entryIds.length > 0) {
            yield autoTagJob.mutate(entryIds);
        }
        if (status === 'success') {
            callback({ type: 'success', entryIds });
        }
        if (status === 'error') {
            callback({ type: 'error', entryIds: [] });
        }
        setLoading(false);
    }));
    const [handler] = useMutation(bulkUploadMutation);
    const handleProgress = (progress) => setUploadProgress(progress);
    const handleError = () => {
        callback({ type: 'error', entryIds: [] });
        setUploading(false);
        setLoading(false);
    };
    const uploadFolder = (files, parentId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            setUploading(true);
            const results = yield upload(files, handleProgress);
            setUploading(false);
            const uploads = transformResultsToInputs(results);
            const input = { companyId, parentId, uploads };
            // If the user cancels the upload during the s3 request we don't want to continue
            if (isUnmountedRef.current) {
                return;
            }
            const { data } = yield handler({ variables: { input } });
            const jobId = data === null || data === void 0 ? void 0 : data.uploadFileSystemDocumentsAsync.id;
            if (jobId) {
                pollUploadJob(jobId);
            }
            else {
                handleError();
            }
        }
        catch (_b) {
            handleError();
        }
    });
    useUnmount(() => {
        isUnmountedRef.current = true;
    });
    return { uploadFolder, loading, uploading, uploadProgress };
};
