import { useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { Dialog, DialogContent, DialogHeader, DialogFooter, Button, Loader, Box, Alert } from '@pocketlaw/tetris';
import { ReloadIndicator } from './ReloadIndicator';
import { useFlushDocument } from '../useFlushDocument';
/**
 * This component will flush all document sessions on mount.
 * To be used before entering a document and loading editor.
 * The component will not check for any pending actions before flushing.
 */
export function FlushDocumentSession(props) {
    const { id } = props;
    const [flushDocument, state] = useFlushDocument();
    const { loading, error, data } = state;
    // eslint-disable-next-line no-underscore-dangle
    const hasSuccess = Boolean(!loading && !error && (data === null || data === void 0 ? void 0 : data.flushDocumentInCcs));
    useEffect(() => {
        flushDocument(id);
    }, [flushDocument, id]);
    const handleReloadPage = () => {
        window.location.reload();
    };
    return (<Dialog>
      <DialogHeader title={t({
            message: 'New editor available',
            comment: 'Document editor version mismatch title',
        })}/>
      <DialogContent>
        <Box mb="lg">
          <Trans comment="Document editor version mismatch message">
            There is a new editor version available. Please wait while we initialize the new version.
          </Trans>
        </Box>

        {hasSuccess && <ReloadIndicator />}

        {error && (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please try again by clicking the button below.',
                comment: 'Document editor version mismatch error message',
            })}>
            <Button appearance="secondary" size="small" onClick={() => flushDocument(id)}>
              <Trans comment="Document editor version mismatch button">Click here to try again</Trans>
            </Button>
          </Alert>)}
      </DialogContent>

      <DialogFooter>
        <Button disabled={loading} onClick={handleReloadPage}>
          <Trans comment="Document editor version mismatch button">Force reload</Trans>
          {loading && <Loader />}
        </Button>
      </DialogFooter>
    </Dialog>);
}
