import { Trans, t } from '@lingui/macro';
import { Box, Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory, useRouteMatch } from 'react-router-dom';
export function ApprovalRequestState() {
    const history = useHistory();
    const { url } = useRouteMatch();
    const handleClick = () => {
        history.push(`${url}/approval`);
    };
    return (<Box px="2xl">
      <EmptyState withAvatar icon={<Falcon icon="eye"/>} title={t({
            comment: 'Esigning sidebar - Approval required title',
            message: 'Approval is required',
        })} description={t({
            comment: 'Esigning sidebar - Approval required description',
            message: 'This document requires approval before being sent for eSigning.',
        })} actions={<Button size="small" onClick={handleClick}>
            <Trans comment="Esigning sidebar - Approval required - Go to approval button label">Go to approval</Trans>
          </Button>}/>
    </Box>);
}
