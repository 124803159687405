import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Avatar, AvatarLetter, AvatarWithLabel, Box, Button, IconButton, Tooltip, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { stringToReact } from 'app/domains/assistant';
import { useAccount } from 'shared/domains/users';
import { usePromptLibrary } from '../../../PromptLibraryProvider';
import { Container, CardContent, CardBody } from './styled';
export function Item(props) {
    var _a, _b, _c;
    const { onSelect, setCurrentPromptID, setView } = usePromptLibrary();
    const { prompt } = props;
    const account = useAccount();
    const { userId } = account.data;
    const promptUserName = ((_b = (_a = prompt.createdByUser) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.split(' ')[0]) || '';
    const promptUserFirstLetter = promptUserName.charAt(0).toUpperCase() || '';
    const EXTRA_PADDING_NOT_TO_BREAK_TEXT_IN_THE_MIDDLE_IN_PX = 8;
    const handleClick = (evt) => {
        evt.preventDefault();
        onSelect(prompt);
    };
    const [showViewMore, setShowViewMore] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const handleViewMoreClick = () => {
        setExpanded(!expanded);
    };
    const cardBodyRef = useRef(null);
    const content = useMemo(() => stringToReact(prompt.content), [prompt.content]);
    useEffect(() => {
        const cardBodyElement = cardBodyRef.current;
        if (!cardBodyElement)
            return;
        const observer = new ResizeObserver(() => {
            const { scrollHeight, clientHeight } = cardBodyElement;
            if (scrollHeight > clientHeight + EXTRA_PADDING_NOT_TO_BREAK_TEXT_IN_THE_MIDDLE_IN_PX) {
                setShowViewMore(true);
            }
        });
        observer.observe(cardBodyElement);
        // eslint-disable-next-line consistent-return
        return () => {
            observer.disconnect();
        };
    }, [prompt.content]);
    return (<Container role="button">
      <CardContent>
        <CardBody ref={cardBodyRef} onClick={handleClick} $expanded={expanded}>
          <Typography $fontSize="large" $fontWeight="bold">
            {prompt.name}
          </Typography>

          <Typography $fontSize="small" $fontWeight="regular">
            {content}
          </Typography>
        </CardBody>

        <Box m="xl" display="flex" justifyContent="space-between" alignItems="center">
          <Box justifyContent="flex-start" alignItems="flex-start">
            {showViewMore && (<Button appearance="secondary" size="xs" onClick={handleViewMoreClick}>
                {expanded ? (<Trans comment="Prompt library - show more expanded">View less</Trans>) : (<Trans comment="Prompt library - show more contracted">View more</Trans>)}
              </Button>)}
          </Box>
          <Box justifyContent="flex-end" alignItems="center" gap="sm">
            {userId === ((_c = prompt.createdByUser) === null || _c === void 0 ? void 0 : _c.id) && (<Fragment>
                <Tooltip title={t({ message: 'Edit prompt', comment: 'Edit prompt buttontooltip' })}>
                  <IconButton appearance="ghost" $round size="xs" onClick={() => {
                setCurrentPromptID(prompt.id);
                setView('edit');
            }} aria-label={t({ message: 'Edit prompt', comment: 'Edit prompt dialog button' })}>
                    <Falcon icon="edit"/>
                  </IconButton>
                </Tooltip>
                <Tooltip title={t({ message: 'Delete prompt', comment: 'Delete prompt button tooltip' })}>
                  <IconButton appearance="ghost" $round size="xs" onClick={() => {
                setCurrentPromptID(prompt.id);
                setView('confirmDelete');
            }} aria-label={t({
                message: 'Delete prompt',
                comment: 'Delete prompt dialog button',
            })}>
                    <Falcon icon="trash"/>
                  </IconButton>
                </Tooltip>
              </Fragment>)}
            {prompt.isSystemPrompt && (<Avatar size="xs" solid appearance="secondary">
                <Falcon icon="book-open"/>
              </Avatar>)}
            {!prompt.isSystemPrompt && (<AvatarWithLabel title={promptUserName} size="xs" solid>
                <AvatarLetter>{promptUserFirstLetter}</AvatarLetter>
              </AvatarWithLabel>)}
          </Box>
        </Box>
      </CardContent>
    </Container>);
}
