var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import * as Types from 'shared/domains/apollo/generated/types';
import { permissionQuery } from './query';
export function useDocumentPermission(options) {
    var _a;
    const { userId, documentId } = options || {};
    const shouldSkip = !documentId || !userId;
    const _b = useQuery(permissionQuery, {
        skip: shouldSkip,
        fetchPolicy: 'cache-and-network',
        variables: {
            documentId: documentId,
            userId: userId,
        },
    }), { data } = _b, rest = __rest(_b, ["data"]);
    const permissions = (_a = data === null || data === void 0 ? void 0 : data.fileSystemEntryACL) === null || _a === void 0 ? void 0 : _a.actions;
    const canRead = !!(permissions === null || permissions === void 0 ? void 0 : permissions.find((action) => action === Types.AclAction.Read));
    return Object.assign({ data: shouldSkip ? undefined : canRead }, rest);
}
