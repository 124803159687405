export const DocumentAiFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentAiFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentAi' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'disabledReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                ],
            },
        },
    ],
};
export const GetDocumentAiDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetDocumentAi' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documentAi' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'documentId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'DocumentAiFragment' },
                                    directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'unmask' } }],
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DocumentAiFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DocumentAi' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'disabledReason' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                ],
            },
        },
    ],
};
