import { useEffect, useState } from 'react';
import { usePrevious } from 'shared/domains/common-ui';
export function useTransitionState(nextValue, delay) {
    const previousValue = usePrevious(nextValue);
    const [state, setState] = useState('hidden');
    useEffect(() => {
        if (previousValue && nextValue) {
            setState('entering');
        }
        if (previousValue === false && nextValue === true) {
            setState('entering');
        }
        if (previousValue === true && nextValue === false) {
            setState('leaving');
        }
        const finalState = setTimeout(() => {
            setState((currentState) => (currentState === 'entering' ? 'visible' : 'hidden'));
        }, delay);
        return () => {
            clearTimeout(finalState);
        };
    }, [nextValue, delay, previousValue]);
    return state;
}
