import { t, Trans } from '@lingui/macro';
import { Typography, List, ListItem, ListHeader, Select } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMobile } from 'shared/domains/common-ui';
import { useComposerData } from '../../../ComposerDataProvider';
import { AlignRight } from './styled';
export function Templates() {
    const { templatesAmount, templatesFilter, setTemplatesFilter } = useComposerData();
    const isMobile = useMobile();
    const filters = [
        {
            label: <Trans comment="Select template dialog - Browse company templates filter label">My company&apos;s</Trans>,
            icon: <Falcon icon="building"/>,
            filterKey: 'templates:company',
        },
        {
            label: <Trans comment="Select template dialog - Browse all templates filter label">Browse all</Trans>,
            icon: <Falcon icon="search"/>,
            filterKey: 'templates:all',
        },
        {
            label: (<Trans comment="Select template dialog - Browse only included plans filter label">Included in my plan</Trans>),
            icon: <Falcon icon="circle-check"/>,
            filterKey: 'templates:included',
        },
    ];
    const title = t({
        message: 'Templates',
        comment: 'Select template dialog - Templates label',
    });
    if (isMobile) {
        return (<Select value={templatesFilter} onChange={(e) => setTemplatesFilter(e.target.value)}>
        {filters.map(({ label, filterKey }) => (<option selected={templatesFilter === filterKey} key={filterKey} value={filterKey}>
            {label}
          </option>))}
      </Select>);
    }
    return (<section>
      <ListHeader title={title}/>
      <List as="nav">
        {filters.map(({ icon, label, filterKey }) => (<ListItem as="button" type="button" key={filterKey} $selected={templatesFilter === filterKey} onClick={() => setTemplatesFilter(filterKey)}>
            {icon}
            {label}
            <AlignRight>
              <Typography $fontSize="small" $appearance="100">
                {templatesAmount[filterKey]}
              </Typography>
            </AlignRight>
          </ListItem>))}
      </List>
    </section>);
}
