import { useEffect, useRef } from 'react';
import { t } from '@lingui/macro';
import { useMount } from 'react-use';
import { Chat as ChatComponent, ChatWrapper, ChatInput } from 'shared/domains/common-ui';
import { AssistantMessage } from './AssistantMessage';
import { ChatActions } from './ChatActions';
import { UserMessage } from './UserMessage';
import { usePlai } from '../../PlaiProvider';
import { Container } from './styled';
export function Chat(props) {
    const { feedback, send, messages, actions } = props;
    const { feedbackHighlightsData } = usePlai();
    const { documentHighlights = [] } = feedbackHighlightsData || {};
    const anchorRef = useRef(null);
    const isStreaming = Boolean(messages.find(({ writing }) => writing));
    const isRewriteAvailable = documentHighlights.length > 0;
    const sendRewriteMessage = () => {
        if (!isRewriteAvailable) {
            return;
        }
        const message = `Please write me rewrite suggestions for the most relevant clauses related to this issue. 
    \n${documentHighlights.map((highlight) => `${`<clause><hid>${highlight.id}</hid>\n${highlight.text.trim()}\n</clause>`}`).join('\n')}`;
        send(message);
    };
    useEffect(() => {
        if (anchorRef.current) {
            anchorRef.current.scrollIntoView();
        }
    }, [messages, actions]);
    useMount(() => {
        sendRewriteMessage();
    });
    const plaiLabel = t({
        message: 'Plai',
        comment: 'Contract review chat - message sender label - Plai',
    });
    const youLabel = t({
        message: 'You',
        comment: 'Contract review chat - message sender label - You',
    });
    const inputPlaceholder = t({
        message: 'Ask a question',
        comment: 'Contract review chat - input placeholder',
    });
    const mappedMessages = messages.map((message) => {
        if (message.role === 'assistant') {
            return {
                id: message.messageId,
                sender: plaiLabel,
                timestamp: new Date().toString(),
                markdown: message.message,
                role: message.role,
                streaming: message.writing,
            };
        }
        return {
            id: message.messageId,
            sender: youLabel,
            timestamp: new Date().toString(),
            text: message.message,
            role: message.role,
            streaming: false,
        };
    });
    const startMessage = {
        id: feedback.feedback.id,
        sender: plaiLabel,
        timestamp: new Date().toString(),
        text: feedback.feedback.content,
        role: 'assistant',
        streaming: false,
    };
    return (<Container>
      <ChatWrapper chat={<ChatComponent messages={[startMessage, ...mappedMessages]} renderMessage={(message) => message.role === 'assistant' ? (<AssistantMessage key={message.id} message={message} actions={actions[message.id]} streaming={message.streaming}/>) : (<UserMessage key={message.id} message={message}/>)}/>} input={<ChatInput showPlaiDisclaimerText placeholder={inputPlaceholder} send={{
                disabled: isStreaming,
                onSend: send,
            }} actions={<ChatActions feedback={feedback} isStreaming={isStreaming} onRewriteClick={sendRewriteMessage}/>}/>}/>
    </Container>);
}
