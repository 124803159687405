import { useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import { Dropzone, useToast } from '@pocketlaw/tetris';
import T from 'prop-types';
import styled from 'styled-components';

import {
  FILE_EXTENSION_MAP,
  FILE_MIME_TYPE_MAP,
  UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES,
} from 'shared/domains/filesystem';

const StyledDropzone = styled(Dropzone)`
  z-index: 1;
`;

const getFileExtensionFromName = (name) => name.substr(name.lastIndexOf('.'));

export function DropUpload({ name, onDropUpload }) {
  const { addToast } = useToast();

  const handleUpload = useCallback(
    (files) => {
      onDropUpload(files);
    },
    [onDropUpload],
  );

  const handleChange = useCallback(
    (event) => {
      const files = Array.from(event.target?.files || []);

      const allowedFiles = files.filter(
        (file) =>
          UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.includes(file.type) ||
          UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.includes(FILE_MIME_TYPE_MAP[getFileExtensionFromName(files[0].name)]),
      );

      const declinedFiles = files.filter(
        (file) =>
          !UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.includes(file.type) &&
          !UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.includes(FILE_MIME_TYPE_MAP[getFileExtensionFromName(files[0].name)]),
      );

      if (allowedFiles.length > 0) {
        handleUpload(allowedFiles);
      }

      if (allowedFiles.length < 1 && declinedFiles.length > 0) {
        addToast({
          // eslint-disable-next-line lingui/no-expression-in-message
          title: t`Incorrect file format. Upload ${UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.map(
            (mimeType) => FILE_EXTENSION_MAP[mimeType],
          ).join(', ')} only`,
          appearance: 'danger',
        });
      }

      // eslint-disable-next-line no-param-reassign
      event.target.value = ''; // reset input value so there's no reference to it for future events (hover, focus)
    },
    [handleUpload, addToast],
  );

  return (
    <StyledDropzone
      description={<Trans>Drop documents inside the dotted area to upload them</Trans>}
      label={<Trans>Upload document</Trans>}
      multiple
      name={name.toLowerCase()}
      onChange={handleChange}
      readOnly
      size="large"
      tabIndex="-1"
    />
  );
}

DropUpload.propTypes = {
  name: T.string,
  onDropUpload: T.func,
};

DropUpload.defaultProps = {
  name: 'dropped-files',
  onDropUpload: undefined,
};
