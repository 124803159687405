import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { TemplateDrawer, TemplateDrawerContent } from 'app/domains/esigning/components/TemplateDrawer';
import { useEsigningProviders } from 'app/domains/esigning/hooks/useEsigningProviders';
import { useSignatoryFieldFallbacks } from 'app/domains/esigning/utils';
import { DefaultSignatoriesForm } from './DefaultSignatoriesForm';
import { LoadErrorAlert } from './LoadErrorAlert';
import { useComposers } from './useComposers';
import { isComposerDefaultSignatoriesEdit, isComposerDefaultSignatoriesEqual } from './utils';
export function TemplateDefaultSignatoriesDrawer(props) {
    var _a, _b;
    const { composerIds, onClose } = props;
    const { composers, error, loading: loadingComposers } = useComposers(composerIds);
    const { loading: loadingProvider } = useEsigningProviders();
    const { getFieldFallbacksForSignatories } = useSignatoryFieldFallbacks();
    const { addToast } = useToast();
    const composerSignatories = composers.map(({ defaultSignatories }) => defaultSignatories);
    const loading = loadingComposers || loadingProvider;
    const firstComposerName = ((_b = (_a = composers[0]) === null || _a === void 0 ? void 0 : _a.metadata[0]) === null || _b === void 0 ? void 0 : _b.name) || '';
    const subheader = composerIds.length === 1
        ? firstComposerName
        : t({
            comment: 'Manage default signing parties drawer - subheader',
            message: `${composerIds.length} selected templates`,
        });
    const isEdit = isComposerDefaultSignatoriesEdit(composerSignatories);
    const isEqual = isComposerDefaultSignatoriesEqual(composerSignatories);
    const signatories = isEqual && composerSignatories.length > 0 ? composerSignatories[0] : [];
    const initialSignatories = getFieldFallbacksForSignatories(signatories);
    const handleSubmit = () => {
        onClose();
        addToast({
            appearance: 'success',
            title: t({
                message: 'Successfully saved default signing parties',
                comment: 'Success message for saving default signing parties toast',
            }),
        });
    };
    return (<TemplateDrawer header={t({
            comment: 'Manage default signing parties drawer - header',
            message: 'Default signing parties',
        })} subheader={subheader} loading={loading} onClose={onClose}>
      {error ? (<TemplateDrawerContent>
          <LoadErrorAlert />
        </TemplateDrawerContent>) : (<DefaultSignatoriesForm isEdit={isEdit} isEqual={isEqual} composerIds={composerIds} 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: temporary while we sort this out
        initialSignatories={initialSignatories} onSubmit={handleSubmit}/>)}
    </TemplateDrawer>);
}
