import { usePreview } from 'shared/domains/documents';
export const useCompanyLogoUrl = () => {
    const { company, isFreeText, isComposed } = usePreview();
    const { logoPath, logoUploaded } = company || {};
    const available = Boolean(logoUploaded && logoPath && (isFreeText || isComposed));
    if (!available) {
        return null;
    }
    return `${process.env.CDN_URL}/${logoPath}`;
};
