import { Command, uid } from 'ckeditor5';
import { getTextFromSelection, getTextFromRange } from './utils';
export const MAX_SELECTION_CHARACTER_COUNT = 4096;
/**
 * This commands allows us to add the `AssistantSelectionHighlight` to the current `Selection`.
 */
export class AssistantSelectionCommand extends Command {
    static get pluginName() {
        return 'assistantSelection';
    }
    constructor(editor, state) {
        super(editor);
        this.state = state;
    }
    refresh() {
        const { editor, state } = this;
        const { document } = editor.model;
        const selectionText = getTextFromSelection(document.selection);
        this.isEnabled = selectionText.length > 0 && selectionText.length <= MAX_SELECTION_CHARACTER_COUNT;
        if (state.marker) {
            const markerRange = state.marker.getRange();
            const markerText = getTextFromRange(markerRange);
            this.value = markerText;
        }
        else {
            this.value = null;
        }
    }
    execute(options) {
        const { editor } = this;
        const { model } = editor;
        const { selection } = model.document;
        const { onlyRemove = false } = options !== null && options !== void 0 ? options : {};
        const selectionRange = selection.getFirstRange();
        if (!selectionRange) {
            return;
        }
        model.enqueueChange((writer) => {
            this.state.clear(model);
            if (!onlyRemove) {
                const marker = writer.addMarker(`assistant:${uid()}`, {
                    usingOperation: false,
                    affectsData: false,
                    range: selectionRange,
                });
                writer.setSelection(marker.getEnd());
                this.state.set('marker', marker);
            }
        });
    }
}
