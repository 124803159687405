import { themeGet } from '@pocketlaw/tetris';
import styled, { css } from 'styled-components';
export const Container = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content; center;
`;
export const CloudIconContainer = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 175ms ease-in-out;

  [data-icon='falcon'] {
    color: ${(props) => (props.$draggingOver ? themeGet('colors.blue.500') : themeGet('colors.primary.800'))};
    transition: color 175ms ease-in-out;
  }

  ${(props) => props.$draggingOver &&
    css `
      transform: scale(1.1);
    `}
`;
