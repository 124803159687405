export const FileSystemDocumentFragmentFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FileSystemDocumentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FileSystemDocument' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                    { kind: 'Field', alias: { kind: 'Name', value: 'folderId' }, name: { kind: 'Name', value: 'parentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'draftId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'acl' },
                        name: { kind: 'Name', value: 'acls' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'htmlConversionStatus' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                                { kind: 'Field', alias: { kind: 'Name', value: 'type' }, name: { kind: 'Name', value: 'mimeType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signed' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'name' },
                                            value: { kind: 'StringValue', value: 'preview', block: false },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastSignRequest' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'createdAt' },
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'document' },
                                    name: { kind: 'Name', value: 'file_system_document' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastApprovalRequest' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'draft' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'composerId' },
                                    name: { kind: 'Name', value: 'composer_id' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'templateId' },
                                    name: { kind: 'Name', value: 'template_version_template_id' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'templateVersion' },
                                    name: { kind: 'Name', value: 'unstable__templateVersion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'templateId' } },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'source' },
                                                name: { kind: 'Name', value: 'body' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'template' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'marketLocale' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'locale' } }],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tree' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'outdated' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export const GetDocumentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getDocument' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'esigningProvider' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'esigningProviderId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'expiresAt' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'logoPath' },
                                    name: { kind: 'Name', value: 'logo_path' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'logoUploaded' },
                                    name: { kind: 'Name', value: 'logo_uploaded' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'esigningProviders' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'ancestors' },
                        name: { kind: 'Name', value: 'fileSystemAncestors' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'document' },
                        name: { kind: 'Name', value: 'fileSystemDocument' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'FileSystemDocumentFragment' },
                                    directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'unmask' } }],
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'users' },
                        name: { kind: 'Name', value: 'companyUsers' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'company' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'id' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_eq' },
                                                                    value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'user' },
                                                    value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'name' },
                                                                value: { kind: 'EnumValue', value: 'asc' },
                                                            },
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'email' },
                                                                value: { kind: 'EnumValue', value: 'asc' },
                                                            },
                                                        ],
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'entry' },
                        name: { kind: 'Name', value: 'fileSystemEntry' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'acl' },
                                    name: { kind: 'Name', value: 'acls' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actions' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'user' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'companyFeatures' },
                        name: { kind: 'Name', value: 'fileSystemCompanyFeatures' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'companyId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'eq' },
                                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'companyId' } },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'feature' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'documentAi' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'documentId' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'disabledReason' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FileSystemDocumentFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FileSystemDocument' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'categoryId' } },
                    { kind: 'Field', alias: { kind: 'Name', value: 'folderId' }, name: { kind: 'Name', value: 'parentId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'draftId' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'acl' },
                        name: { kind: 'Name', value: 'acls' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'htmlConversionStatus' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'files' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'path' } },
                                { kind: 'Field', alias: { kind: 'Name', value: 'type' }, name: { kind: 'Name', value: 'mimeType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'signed' },
                                    arguments: [
                                        {
                                            kind: 'Argument',
                                            name: { kind: 'Name', value: 'name' },
                                            value: { kind: 'StringValue', value: 'preview', block: false },
                                        },
                                    ],
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastSignRequest' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'createdAt' },
                                    name: { kind: 'Name', value: 'created_at' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'document' },
                                    name: { kind: 'Name', value: 'file_system_document' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastApprovalRequest' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'draft' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'composerId' },
                                    name: { kind: 'Name', value: 'composer_id' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'templateId' },
                                    name: { kind: 'Name', value: 'template_version_template_id' },
                                },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'templateVersion' },
                                    name: { kind: 'Name', value: 'unstable__templateVersion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'templateId' } },
                                            {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'source' },
                                                name: { kind: 'Name', value: 'body' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'template' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'marketLocale' },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'locale' } }],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tree' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'outdated' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
