var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { formatTemplate } from './formatTemplate';
import { createMutation, removeMutation } from './mutations';
import { diffArraysByKey } from '../utils';
export function useTemplateAttachment() {
    const [createAttachments, upsertState] = useMutation(createMutation);
    const [removeAttachments, removeState] = useMutation(removeMutation);
    const isLoading = upsertState.loading || removeState.loading;
    const hasError = upsertState.error || removeState.error;
    const mutation = (options) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const { data, initialValues } = options;
        const dataAttachments = (_a = data.attachments) === null || _a === void 0 ? void 0 : _a.filter((attachment) => attachment.type === 'template');
        const initialValuesAttachments = (_b = initialValues === null || initialValues === void 0 ? void 0 : initialValues.attachments) === null || _b === void 0 ? void 0 : _b.filter((attachment) => attachment.type === 'template');
        const attachmentsToRemove = diffArraysByKey(initialValuesAttachments, dataAttachments, 'id');
        const attachmentsToAdd = dataAttachments === null || dataAttachments === void 0 ? void 0 : dataAttachments.filter((attachment) => !attachment.id);
        if (attachmentsToRemove.length) {
            const ids = attachmentsToRemove.map((attachment) => attachment.id);
            yield removeAttachments({
                variables: {
                    where: {
                        id: { _in: ids },
                    },
                },
            });
        }
        if (attachmentsToAdd === null || attachmentsToAdd === void 0 ? void 0 : attachmentsToAdd.length) {
            yield createAttachments({
                variables: {
                    objects: attachmentsToAdd.map(formatTemplate(data === null || data === void 0 ? void 0 : data.id)),
                },
            });
        }
    });
    return {
        loading: isLoading,
        error: hasError,
        mutation,
    };
}
