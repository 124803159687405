import { FlagState } from './types';
/**
 * All allowed feature flags and their default values
 */
export const features = {
    feature_dark_mode: {
        value: FlagState.DISABLED,
        force: false,
    },
    feature_pleditor_sandbox: {
        value: FlagState.DISABLED,
        force: false,
    },
    feature_pleditor_dev_banner: {
        value: FlagState.DISABLED,
        force: false,
    },
    feature_shared_with_me: {
        value: FlagState.DISABLED,
        force: false,
    },
    feature_prompt_library: {
        value: FlagState.ENABLED,
        force: true,
    },
    feature_customer_success: {
        value: FlagState.DISABLED,
        force: false,
    },
    feature_compare_documents_upload: {
        value: FlagState.DISABLED,
        force: false,
    },
    feature_plai_quotes: {
        value: FlagState.DISABLED,
        force: false,
    },
};
export const featureFlagKeys = Object.keys(features);
