import { gql } from '@apollo/client';
export const metadataFilesystemEntriesSearch = gql `
  query MetadataFilesystemEntriesSearch($query: String!) {
    search(
      query: $query
      limit: 100
      where: { entityType: { in: [folder, document_uploaded, document_freetext, document_composed] } }
    ) {
      results {
        id: entityId
        name
        type: entityType
      }
    }
  }
`;
