import { TextArea, pxToRem, scrollbar, themeGet, themeGetColor } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Wrapper = styled.div `
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: ${themeGet('colors.background.50')};

  ${(props) => props.$paddingBottom &&
    `
    padding-bottom: ${themeGet('spacing.2xl')};
  `}
`;
export const InputContainer = styled.div `
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: ${themeGetColor('background.50')};
`;
export const ActionsContainer = styled.div `
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background-color: ${themeGetColor('background.50')};
`;
export const PrependElement = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${themeGet('spacing.md')};
  padding: ${themeGet('spacing.sm')} ${themeGet('spacing.sm')} ${themeGet('spacing.sm')} ${themeGet('spacing.md')};
  border-radius: ${themeGet('borderRadius.md')};
  border: solid ${pxToRem(1)} ${themeGet('colors.primary.1000')};
  background: ${themeGet('colors.primary.900')};
`;
export const PrependIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};

  [data-icon='falcon'] {
    color: ${themeGet('colors.gray.100')};
  }
`;
export const Container = styled.div `
  position: relative;
  z-index: 1;
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  border-radius: ${themeGet('borderRadius.lg')};

  transition: all 175ms ease-out;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 20%;
    left: 2%;
    width: 96%;
    height: 80%;
    border-radius: inherit;
    box-shadow: ${themeGet('dropShadow.400')};
    opacity: 0.45;
    transition: opacity 175ms ease-out;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  ${(props) => props.$focus &&
    `
    border-color: ${themeGet('colors.blue.400')};

    &:before {
      opacity: 1;
    }
  `}
`;
export const StyledTextArea = styled(TextArea) `
  ${scrollbar()};
`;
