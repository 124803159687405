import { Fragment, useId } from 'react';
import { Trans } from '@lingui/macro';
import { MenuList, MenuSubheader } from '@pocketlaw/tetris';
import { MenuMultiSelectItem } from './MenuMultiSelectItem';
import { SearchMenuEmptyState } from './SearchMenuEmptyState';
import { useSearchableMenu } from './SearchableMenuContext';
export function SearchableMenuMultiSelectList(props) {
    const { selected = [], renderItem, onSelect, emptyComponent } = props;
    const { items, sourceItems, searching, hasResult, open, setOpen } = useSearchableMenu();
    const selectedItems = (selected || [])
        .map((sId) => sourceItems.find((item) => item.id === sId))
        .filter(Boolean);
    const hasSelectedItems = Boolean(selectedItems.length);
    const displaySelectedItems = selected && hasSelectedItems;
    const htmlId = useId();
    const renderItemHelpers = {
        open,
        setOpen,
    };
    const emptyStateComponent = emptyComponent || <SearchMenuEmptyState />;
    return (<Fragment>
      {displaySelectedItems && (<Fragment>
          <MenuSubheader id={`${htmlId}-selected-items`}>
            <Trans comment="Searchable menu multiselect list: Header">Selected</Trans>
          </MenuSubheader>

          <MenuList aria-labelledby={`${htmlId}-selected-items`} as="nav" role="menu">
            {selectedItems.map((item) => (<MenuMultiSelectItem key={item.id} selected onClick={() => onSelect(item, renderItemHelpers)}>
                {renderItem(item, renderItemHelpers)}
              </MenuMultiSelectItem>))}
          </MenuList>
        </Fragment>)}

      {hasResult && (<MenuSubheader id={`${htmlId}-items`}>
          {searching ? (<Trans comment="Searchable menu multiselect list: Subheader when filtered items is visible">
              Filtered items
            </Trans>) : (<Trans comment="Searchable menu multiselect list: Subheader when all items is visible">All items</Trans>)}
        </MenuSubheader>)}

      {hasResult && (<MenuList aria-labelledby={`${htmlId}-items`} as="nav" role="menu">
          {items
                .filter((item) => !selected.includes(item.id))
                .map((item) => (<MenuMultiSelectItem key={item.id} onClick={() => onSelect(item, { open, setOpen })}>
                {renderItem(item, renderItemHelpers)}
              </MenuMultiSelectItem>))}
        </MenuList>)}

      {!hasResult && emptyComponent !== false && emptyStateComponent}
    </Fragment>);
}
