import { marginRight, unit, SectionHeading, LabelHeading, pxToRem, themeGet } from '@pocketlaw/tetris';
import { breakpoint } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
export const Actions = styled.div `
  display: flex;
  align-items: center;
  gap: ${pxToRem(12)};
  ${marginRight(4)};

  @media ${breakpoint.palm} {
    display: none;
  }
`;
export const Topbar = styled.div `
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: ${pxToRem(80)};
  border-bottom: ${pxToRem(1)} solid ${themeGet('colors.gray.100')};
  background-color: ${themeGet('colors.gray.0')};

  @media ${breakpoint.palm} {
    position: sticky;
    top: 0;
    z-index: 2;
    height: ${pxToRem(60)};
  }
`;
export const Heading = styled.div `
  flex: 1;
  min-width: 0;
  padding: 0 ${unit('xl')};
`;
export const Truncate = styled.span `
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const Title = styled(SectionHeading).attrs(() => ({
    noMargin: true,
})) `
  display: flex;
  align-items: baseline;

  @media ${breakpoint.palm} {
    font-size: 100%;
  }
`;
export const Subtitle = styled(LabelHeading) `
  margin: 0;
  color: ${themeGet('colors.gray.700')};
  font-weight: normal;
  line-height: 1;
`;
