import { TableRowCollapse, TableBodyCell } from '@pocketlaw/tetris';
import { useFormatDate } from 'app/domains/users';
import { UserRole, DesktopTableBodyCell, TableBodyCellText, DropDownContent, ContextCell } from './components';
import { getTableHeaders } from '../../utils/tableHeaders';
import { UserAvatar } from '../UserAvatar';
export function UserTableRow(props) {
    const tableHeaders = getTableHeaders();
    const { id, name, email, createdAt, role, isSelf } = props;
    const date = useFormatDate(createdAt);
    return (<TableRowCollapse expandibleHeaders={tableHeaders.dropDown.id} colSpan={3} dropdownContent={<DropDownContent role={role} id={id} isSelf={isSelf} createdAt={date}/>} hideDropdownOnBreakpoint="sm">
      <TableBodyCell headers={tableHeaders.name.id}>
        <UserAvatar title={name} subTitle={email}/>
      </TableBodyCell>
      <DesktopTableBodyCell headers={tableHeaders.createdAt.id}>
        <TableBodyCellText $fontSize="medium" $appearance="100">
          {date}
        </TableBodyCellText>
      </DesktopTableBodyCell>
      <DesktopTableBodyCell headers={tableHeaders.role.id}>
        <UserRole role={role} id={id} isSelf={isSelf}/>
      </DesktopTableBodyCell>
      <ContextCell stickyColumn="right" role={role} id={id} name={name} isSelf={isSelf}/>
    </TableRowCollapse>);
}
