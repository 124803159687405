var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createContext, useMemo, useEffect, useState, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import * as Sentry from 'shared/domains/sentry';
import { useHighlightTexts } from './useHighlightTexts';
import { usePDF } from './usePDF';
const SCALE = 1.36;
const SPACING = 16;
export const PDFDataContext = createContext(null);
export function PDFDataProvider(props) {
    const { children } = props;
    const pdf = usePDF();
    const listRef = useRef(null);
    const [pdfPages, setPDFPages] = useState([]);
    const pageMap = useMemo(() => pdfPages.reduce((acc, page) => Object.assign(acc, { [page.pageNumber]: page }), {}), [pdfPages]);
    const [pageRefMap, setPageRefMap] = useState({});
    const [scrollDistance, setScrollDistance] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const nrOfPages = useMemo(() => { var _a; return ((_a = pdf.data) === null || _a === void 0 ? void 0 : _a.numPages) || 0; }, [pdf.data]);
    const scale = useMemo(() => SCALE, []);
    const spacing = useMemo(() => SPACING, []);
    const loadingPages = useMemo(() => !pdf.data || Object.keys(pageMap).length !== nrOfPages, [pdf.data, nrOfPages, pageMap]);
    const isPageInViewport = (pageRef) => {
        if (listRef.current && pageRef) {
            const { top: listTop, bottom: listBottom } = listRef.current.getBoundingClientRect();
            const { top: pageTop, bottom: pageBottom } = pageRef.getBoundingClientRect();
            return ((pageTop >= listTop && pageTop <= listBottom && pageBottom >= listBottom) ||
                (pageTop <= listBottom && pageBottom >= listTop));
        }
        return false;
    };
    const getMostVisiblePage = (pages) => {
        const getVisiblePageHeight = (pageRef) => {
            if (listRef.current && pageRef) {
                const { top: listTop, bottom: listBottom } = listRef.current.getBoundingClientRect();
                const { top: pageTop, bottom: pageBottom } = pageRef.getBoundingClientRect();
                if (pageTop <= listTop) {
                    return pageBottom - listTop;
                }
                return listBottom - pageTop;
            }
            return 0;
        };
        const sortedByHeight = pages
            .map(({ pageNumber, pageRef }) => ({
            pageNumber,
            visibleHeight: getVisiblePageHeight(pageRef),
        }))
            .sort((a, b) => b.visibleHeight - a.visibleHeight);
        return Number(sortedByHeight[0].pageNumber);
    };
    useEffect(() => {
        const pages = Object.entries(pageRefMap)
            .map(([pageNumber, pageRef]) => ({ pageNumber, pageRef }))
            .filter(({ pageRef }) => isPageInViewport(pageRef));
        if (pages.length === 0) {
            return;
        }
        if (pages.length > 1) {
            setCurrentPage(getMostVisiblePage(pages));
        }
        else {
            setCurrentPage(Number(pages[0].pageNumber));
        }
    }, [scrollDistance, pageRefMap]);
    useEffect(() => {
        const pdfDocument = pdf.data;
        if (pdfDocument) {
            const initialize = () => __awaiter(this, void 0, void 0, function* () {
                const loadPage = (pageNumber) => __awaiter(this, void 0, void 0, function* () { return pdfDocument.getPage(pageNumber); });
                try {
                    const pageNumbers = Array.from(Array(pdfDocument.numPages).keys()).map((pageNo) => pageNo + 1);
                    const loadedPages = yield Promise.all(pageNumbers.map((pageNumber) => loadPage(pageNumber)));
                    setPDFPages(loadedPages);
                }
                catch (error) {
                    Sentry.captureException(error);
                }
            });
            initialize();
        }
    }, [pdf.data]);
    const getPage = (pageNumber) => pageMap[pageNumber];
    const scrollToPage = useDebouncedCallback((pageNumber) => {
        const pageRef = pageRefMap[pageNumber];
        if (listRef.current && pageRef) {
            const { top: listTop } = listRef.current.getBoundingClientRect();
            const { top: pageTop } = pageRef.getBoundingClientRect();
            const top = pageTop - listTop + listRef.current.scrollTop - spacing * 2;
            listRef.current.scrollTo({ top, behavior: 'smooth' });
        }
    }, 100, { leading: false });
    const setPageRef = (pageNumber, ref) => {
        setPageRefMap((prev) => (Object.assign(Object.assign({}, prev), { [pageNumber]: ref })));
    };
    const { fuzzySearchTextNodes, isTextNodeHighlighted, isFirstHighlightedTextNode } = useHighlightTexts(pdfPages);
    const { error, passwordProtected, permissions } = pdf;
    const loading = pdf.loading || loadingPages;
    // If permissions are not null, then the pdf have at least one restriction which is not supported for eSigning
    const hasRestrictedPermissions = permissions !== null;
    const value = {
        scale,
        spacing,
        pageMap,
        error,
        loading,
        passwordProtected,
        hasRestrictedPermissions,
        nrOfPages,
        listRef,
        currentPage,
        getPage,
        scrollToPage,
        setScrollDistance,
        setPageRef,
        fuzzySearchTextNodes,
        isTextNodeHighlighted,
        isFirstHighlightedTextNode,
    };
    return <PDFDataContext.Provider value={value}>{children}</PDFDataContext.Provider>;
}
