var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { t } from '@lingui/macro';
import { Box, LoaderOverlay, useToast } from '@pocketlaw/tetris';
import { useFormikContext } from 'formik';
import { widgetValuesValidation } from '../../utils/widgetValuesValidation';
import { GenerateWidget } from '../InsightsChat';
import { useGenerateInsightWidget } from '../InsightsChat/useGenerateInsightsWidget';
import { Visualization } from '../Visualization';
import { VisualizationContainer } from './styled';
export function Preview(props) {
    const { initialQuery } = props;
    const { values, setValues } = useFormikContext();
    const { aggregations, type, filters, colorScheme, isHorizontal } = values;
    const [query, setQuery] = useState(initialQuery || '');
    const { error, loading, generate } = useGenerateInsightWidget();
    const { addToast } = useToast();
    const handleGenerate = (prompt) => __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield generate(prompt);
            setValues(widgetValuesValidation(response));
        }
        catch (_a) {
            addToast({
                title: t({
                    message: 'An error occurred generating insights',
                    comment: 'Insights chat - error toast',
                }),
                appearance: 'danger',
            });
        }
    });
    const handleSelectPrompt = (prompt) => __awaiter(this, void 0, void 0, function* () {
        setQuery(prompt);
        handleGenerate(prompt);
    });
    return (<Fragment>
      <Box mb="lg" display="block">
        <GenerateWidget query={query} error={error} loading={loading} onChange={(event) => setQuery(event.target.value)} onSubmit={() => handleGenerate(query)} handleSelectPrompt={handleSelectPrompt}/>
      </Box>
      <VisualizationContainer>
        <LoaderOverlay enabled={loading}>
          <Visualization type={type} aggregations={aggregations} filters={filters} colorScheme={colorScheme} isHorizontal={isHorizontal}/>
        </LoaderOverlay>
      </VisualizationContainer>
    </Fragment>);
}
