var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Trans, t } from '@lingui/macro';
import { Button, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SearchableMenu, SearchableMenuMultiSelectList } from 'shared/domains/common-ui';
import { metadataConfig } from 'shared/domains/metadata';
import { useMetadataActions } from '../../hooks/useMetadataActions';
import { useDocumentMetadata } from '../DocumentMetadataProvider';
export function AddMetadataList() {
    const { data, toggleActiveEntry, activeEntries } = useDocumentMetadata();
    const actions = useMetadataActions();
    const getLabel = (name) => {
        const { label } = metadataConfig.get(name) || {};
        const fallback = t({ message: 'Unknown', comment: 'Metadata: Unknown metadata property' });
        return label || fallback;
    };
    const searchableItems = data.properties.map(({ property, pair }) => ({
        id: property.id,
        label: getLabel(property.name),
        name: property.name,
        pairId: pair.id,
    }));
    const selected = activeEntries
        .map((propertyName) => { var _a; return (_a = data.properties.find((property) => property.property.name === propertyName)) === null || _a === void 0 ? void 0 : _a.property.id; })
        .filter(Boolean);
    const handleOnSelect = (item, helpers) => __awaiter(this, void 0, void 0, function* () {
        const isSelected = selected.includes(item.id);
        if (isSelected && item.pairId) {
            yield actions.delete(item.pairId);
        }
        toggleActiveEntry(item.name);
        helpers.setOpen(false);
    });
    return (<SearchableMenu items={searchableItems} keys={['label']} renderTarget={({ setOpen, open }) => (<Button size="small" $stretch appearance="secondary" onClick={() => setOpen(!open)}>
          <Trans id="metadata.list.action.show" comment="Metadata actions: Button to show list of available metadata tags to add">
            Add
          </Trans>
          <Falcon icon="plus"/>
        </Button>)}>
      <MenuBody>
        <SearchableMenuMultiSelectList selected={selected} renderItem={({ label }) => label} onSelect={handleOnSelect} emptyComponent={false}/>
      </MenuBody>
    </SearchableMenu>);
}
