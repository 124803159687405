import { Trans, t } from '@lingui/macro';
import { TableHead, TableRow, TableHeadCell, TableHeaderText, TableBody, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTemplateBrowserContext } from 'app/domains/templates';
import { Row } from './Row';
import { SkeletonRow } from './SkeletonRow';
import { PickerContent } from '../PickerContent';
import { StyledTable } from './styled';
export function PickerTableTemplates() {
    const { templates, loading } = useTemplateBrowserContext();
    const loadingTemplates = Array(50)
        .fill(null)
        .map((_, index) => index);
    const availableTemplates = templates.filter((template) => !template.locked);
    if (templates.length === 0) {
        return (<PickerContent>
        <EmptyState withAvatar icon={<Falcon icon="search"/>} title={t({
                comment: 'When a search does not return any results - Title',
                message: 'No results',
            })} description={t({
                comment: 'When a search does not return any results - Description',
                message: 'We could not find any results for your search.',
            })}/>
      </PickerContent>);
    }
    return (<PickerContent>
      <StyledTable dense>
        <colgroup>
          <col width="auto"/>
          <col width="180"/>
          <col width="40"/>
        </colgroup>
        <TableHead>
          <TableRow>
            <TableHeadCell id="cell:name">
              <TableHeaderText>
                <Trans comment="Table column header: Title">Title</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:language">
              <TableHeaderText>
                <Trans comment="Table column header: Language">Language</Trans>
              </TableHeaderText>
            </TableHeadCell>
            <TableHeadCell id="cell:select"/>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading
            ? loadingTemplates.map((index) => <SkeletonRow key={index}/>)
            : availableTemplates.map((template) => <Row key={template.id} template={template}/>)}
        </TableBody>
      </StyledTable>
    </PickerContent>);
}
