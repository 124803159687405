import { Trans } from '@lingui/macro';
import { Typography, List, ListItem, Select, Box } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useMobile } from 'shared/domains/common-ui';
import { usePromptLibrary } from '../../../PromptLibraryProvider';
const filters = [
    {
        label: <Trans comment="All prompts">All </Trans>,
        icon: <Falcon icon="list-tree"/>,
        filterKey: 'shortcuts:all',
    },
    {
        label: <Trans comment="Prompt Library Dialog - Browse company prompts filter label">My Company</Trans>,
        icon: <Falcon icon="building"/>,
        filterKey: 'shortcuts:company',
    },
    {
        label: <Trans comment="Prompt Library Dialog - Browse from Pocketlaw filter label">From Pocketlaw</Trans>,
        icon: <Falcon icon="book-open"/>,
        filterKey: 'shortcuts:system',
    },
    {
        label: <Trans comment="Prompt Library Dialog - Browse my prompts filter label">My Prompts</Trans>,
        icon: <Falcon icon="circle-user"/>,
        filterKey: 'shortcuts:my-prompts',
    },
    {
        label: <Trans comment="Prompt Library Dialog - Browse recently used filter label">Recently Used</Trans>,
        icon: <Falcon icon="clock-rotate-left"/>,
        filterKey: 'shortcuts:recently-used',
    },
];
export function Shortcuts() {
    const isMobile = useMobile();
    const { shortcutsAmount, shortcutsFilter, setShortcutsFilter } = usePromptLibrary();
    if (isMobile) {
        return (<Select value={shortcutsFilter} onChange={(e) => setShortcutsFilter(e.target.value)}>
        {filters.map(({ label, filterKey }) => (<option selected={shortcutsFilter === filterKey} key={filterKey} value={filterKey}>
            {label}
          </option>))}
      </Select>);
    }
    return (<section>
      <List as="nav">
        {filters.map(({ icon, label, filterKey }) => (<ListItem as="button" type="button" key={filterKey} $selected={shortcutsFilter === filterKey} onClick={() => setShortcutsFilter(filterKey)}>
            {icon}
            {label}
            <Box ml="auto">
              <Typography $fontSize="small" $appearance="100">
                {shortcutsAmount[filterKey]}
              </Typography>
            </Box>
          </ListItem>))}
      </List>
    </section>);
}
