import { t, Trans } from '@lingui/macro';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Typography, Button, Box } from '@pocketlaw/tetris';
export function MetadataReasoning(props) {
    const { reasoning, citation, onClose } = props;
    const noReasoningText = t({
        message: 'No reasoning available',
        comment: 'Metadata: Reasoning dialog - no reasoning available text',
    });
    const noCitationText = t({
        message: 'No citation available',
        comment: 'Metadata: Reasoning dialog - no citation available text',
    });
    return (<Dialog onClose={onClose}>
      <DialogHeader title={t({ message: 'Reasoning', comment: 'Metadata: Reasoning dialog header' })}/>
      <DialogContent>
        <Box flexDirection="column" gap="lg">
          <Typography>{reasoning || noReasoningText}</Typography>
          <Typography $fontWeight="bold">
            <Trans comment="Metadata: Reasoning dialog - citation title">Citation</Trans>
          </Typography>
          <Typography>{citation || noCitationText}</Typography>
        </Box>
      </DialogContent>
      <DialogFooter>
        <Button appearance="primary" onClick={onClose}>
          <Trans comment="Metadata: Reasoning dialog - close button">Close</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
