import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { TableBodyCell, Menu, IconButton, MenuList, MenuItem, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ConfirmDeleteUserDialog } from 'app/domains/users/components/ConfirmDeleteUserDialog';
import { useRole } from 'app/domains/users/hooks/useRole';
import { getTableHeaders } from '../../../../utils/tableHeaders';
export function ContextCell(props) {
    const tableHeaders = getTableHeaders();
    const { stickyColumn, id, name, isSelf, role } = props;
    const userRole = useRole();
    const [open, setOpen] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const hasLowerRoleLevel = role === 'owner' && userRole.has('admin');
    const isDisabled = !userRole.can('invite:user') || isSelf || hasLowerRoleLevel;
    if (!userRole.can('delete:user')) {
        return null;
    }
    return (<Fragment>
      <TableBodyCell stickyColumn={stickyColumn} headers={tableHeaders.action.id}>
        <Menu open={open} closeOnOutsideClick onClose={() => setOpen(false)} target={<IconButton disabled={isDisabled} onClick={() => setOpen(!open)} $round appearance="ghost" size="small">
              <Falcon icon="ellipsis-vertical"/>
            </IconButton>}>
          <MenuBody>
            <MenuList>
              <MenuItem onClick={() => {
            setConfirmDialog(true);
            setOpen(false);
        }}>
                <Falcon icon="trash"/>
                <Trans comment="users table dropdown menu - delete user list item">Delete user</Trans>
              </MenuItem>
            </MenuList>
          </MenuBody>
        </Menu>
      </TableBodyCell>
      {confirmDialog && <ConfirmDeleteUserDialog onClose={() => setConfirmDialog(false)} id={id} name={name}/>}
    </Fragment>);
}
