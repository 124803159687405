import { padding, unit, marginTop, themeGet } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: ${({ $incoming }) => ($incoming ? 'flex-start' : 'flex-end')};

  &:not(:first-of-type) {
    ${({ $hideTimestamp }) => ($hideTimestamp ? marginTop(2) : marginTop(4))};
  }
`;
export const StyledText = styled(Text) `
  line-height: 1;
  color: ${({ $incoming }) => ($incoming ? themeGet('colors.green.800') : themeGet('colors.primary.400'))};
`;
export const MessageBubble = styled.div `
  ${padding(2, 4)};
  position: relative;
  max-width: 80%;
  display: inline-block;
  border-radius: ${unit(2)};
  background-color: ${({ $incoming }) => ($incoming ? themeGet('colors.gray.50') : themeGet('colors.primary.50'))};
`;
export const MessageNotification = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: ${unit(3)};
  height: ${unit(3)};
  border-radius: 100%;
  background-color: ${themeGet('colors.red.500')};
`;
