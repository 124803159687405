import { Fragment, useState } from 'react';
import { AvatarWithLabel } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { useHistory } from 'react-router-dom';
import { useGetTemplatesData } from './useGetTemplatesData';
import { AttachmentBrowser } from '../../AttachmentBrowser';
import { AttachmentDropDown } from '../../AttachmentDropDown';
import { LinkButton, AttachmentContainer } from '../styled';
export function TemplateField(props) {
    var _a;
    const { attachment, index, remove, replace } = props;
    const history = useHistory();
    const [completedField] = useField('isCompleted');
    const attachmentData = useGetTemplatesData(attachment.attachmentId);
    const [displayBrowser, setDisplayBrowser] = useState(false);
    const disabled = attachmentData.loading || completedField.value;
    const attachmentName = (_a = attachmentData.data) === null || _a === void 0 ? void 0 : _a.name;
    const handleRemove = () => {
        remove(index);
    };
    const handleReplace = (newAttachment) => {
        replace(index, {
            attachmentId: newAttachment.id,
            type: newAttachment.type,
        });
        setDisplayBrowser(false);
    };
    return (<Fragment>
      <AttachmentContainer>
        <LinkButton type="button" onClick={() => history.push(`/contracts?id=${attachment.attachmentId}`)} disabled={disabled}>
          <AvatarWithLabel title={attachmentName} solid size="sm" truncate={false}>
            <Falcon icon="file"/>
          </AvatarWithLabel>
        </LinkButton>
        {!disabled && <AttachmentDropDown onEdit={() => setDisplayBrowser(true)} onDelete={handleRemove}/>}
      </AttachmentContainer>
      {displayBrowser && <AttachmentBrowser onAdd={handleReplace} onCancel={() => setDisplayBrowser(false)}/>}
    </Fragment>);
}
