import { Box } from '@pocketlaw/tetris';
import { Message } from 'shared/domains/common-ui';
import { SuggestionBox } from './SuggestionBox';
import { isSuggestionBoxRewriteHighlight } from './utils';
export function AssistantMessage(props) {
    const { message, streaming, actions } = props;
    const { rewriteSuggestions = {} } = actions || {};
    const validRewriteSuggestions = Object.values(rewriteSuggestions).filter(isSuggestionBoxRewriteHighlight);
    return (<Message fullWidth message={message} align="left" appearance="secondary" showActionsDefault={message.isLatestMessage} loading={!message.markdown && !message.text} streaming={streaming}>
      <Box flexDirection="column" gap="md" mt="md">
        {validRewriteSuggestions.map((highlight) => (<SuggestionBox key={highlight.id} writing={streaming} highlight={highlight}/>))}
      </Box>
    </Message>);
}
