import { useFragment } from '@apollo/client';
import { t } from '@lingui/macro';
import { MenuItem } from '@pocketlaw/tetris';
import { UserAvatarWithLabel } from 'app/domains/users';
import { SearchableMenu } from 'shared/domains/common-ui';
import { CompanyUsersMenuFragmentDoc } from './CompanyUsersMenu.gql';
/**
 * UserMenu: A reusable component for selecting users from a searchable list.
 *
 * Utilizes GraphQL fragment composition for efficient data fetching and data
 * masking for type safety
 * Provides a searchable interface for user selection
 *
 * Usage:
 * 1. Import the `CompanyUsersMenu` fragment where the GraphQL query is made
 * 2. Pass the fragment to the `from` prop of this component
 * 3. Provide `onSelect` callback to handle user selection
 * 4. Use `renderTarget` to customize the menu trigger element
 *
 * @param {Props} props - Component properties
 * @returns {React.ReactElement} Rendered UserMenu component
 */
export function CompanyUsersMenu(props) {
    const { from, onSelect, renderTarget } = props;
    const { complete, data } = useFragment({
        from,
        fragment: CompanyUsersMenuFragmentDoc,
        fragmentName: 'CompanyUsersMenu',
    });
    if (!complete) {
        return null;
    }
    const users = data.users.map((user) => ({
        id: user.id || '',
        email: user.email || '',
        name: user.name || t({ message: 'Unknown', comment: 'Unknown user name' }),
    }));
    return (<SearchableMenu items={users} keys={['name', 'email']} placeholder={t({ message: 'Search users', comment: 'Select user dropdown' })} renderTarget={renderTarget}>
      {({ items, setOpen }) => items.map((user) => (<MenuItem key={user.id} onClick={() => {
                onSelect(user);
                setOpen(false);
            }}>
            <UserAvatarWithLabel title={user.name} subtitle={user.email} size="sm"/>
          </MenuItem>))}
    </SearchableMenu>);
}
