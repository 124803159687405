import { Box, Typography } from '@pocketlaw/tetris';
import { Item } from './styled';
export function Legend(props) {
    const { data, onSelect } = props;
    return (<Box flexDirection="column">
      {data.map((item) => (<Item key={item.name} onMouseEnter={() => onSelect(item)} onMouseLeave={() => onSelect()}>
          <Typography>{item.name}</Typography>
        </Item>))}
    </Box>);
}
