import { Button, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  width: 100%;
  & > * {
    width: 100%;
  }
`;
export const ButtonTab = styled(Button).attrs((props) => ({
    appearance: props.$active ? 'secondary' : 'ghost',
    size: 'xs',
    type: 'button',
})) `
  ${(props) => props.$active && `box-shadow: ${themeGet('dropShadow.100')}`};}
`;
