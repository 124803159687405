import { CKEditor } from '@ckeditor/ckeditor5-react';
import { DecoupledEditor } from 'shared/domains/pleditor@next';
import * as Sentry from 'shared/domains/sentry';
import { useIsLayoutReady } from '../hooks/useIsLayoutReady';
import { PREVIEW_PLUGINS } from '../previewPlugins';
import { BasePleditorStyles } from '../styled';
import { Container } from './styled';
export function TemplatePreview(props) {
    const { html } = props;
    const isLayoutReady = useIsLayoutReady();
    const config = {
        extraPlugins: [...PREVIEW_PLUGINS],
    };
    return (<Container>
      <BasePleditorStyles />
      {isLayoutReady && (<CKEditor data={html} config={config} editor={DecoupledEditor} onReady={(editor) => editor.enableReadOnlyMode('templatePreview')} onError={(err) => Sentry.captureException(err, { tags: { feature: 'pleditor' } })}/>)}
    </Container>);
}
