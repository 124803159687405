import React from 'react';
import { t, select } from '@lingui/macro';
import { Card, CardContent, CardHeader } from '@pocketlaw/tetris';
import { useAccessByComposers } from '../../hooks/useAccessByComposers';
import { AccessMenuButton } from '../AccessMenuButton';
import { TeamAccessList } from '../TeamAccessList';
import { UpdateTeamAccessMenuItem } from '../UpdateTeamAccessMenuItem';
export function TeamAccessSection(props) {
    var _a;
    const { accessDropdownPermission } = props;
    const { accessTypeTeams } = useAccessByComposers();
    const teams = (_a = accessTypeTeams[accessDropdownPermission]) !== null && _a !== void 0 ? _a : [];
    return (<Card size="small">
      <CardHeader title={t({
            comment: 'Contract team access Card header: Title',
            message: 'Teams',
        })} subtitle={t({
            comment: 'Contract access Card header: subTitle',
            message: select(accessDropdownPermission, {
                access: 'With access to selected templates',
                partialAccess: 'With partial access to selected templates',
                noAccess: 'With no access to selected templates',
                other: 'With no access to selected templates',
            }),
        })} action={teams.length > 0 && (<AccessMenuButton>
              {accessDropdownPermission === 'access' && (<UpdateTeamAccessMenuItem type="remove" teamIds={accessTypeTeams.access.map((team) => team.id)} withSubtitle/>)}
              {accessDropdownPermission === 'partialAccess' && (<React.Fragment>
                  <UpdateTeamAccessMenuItem type="add" teamIds={accessTypeTeams.partialAccess.map((team) => team.id)} withSubtitle/>
                  <UpdateTeamAccessMenuItem type="remove" teamIds={accessTypeTeams.partialAccess.map((team) => team.id)} withSubtitle/>
                </React.Fragment>)}
              {accessDropdownPermission === 'noAccess' && (<UpdateTeamAccessMenuItem type="add" teamIds={accessTypeTeams.noAccess.map((team) => team.id)} withSubtitle/>)}
            </AccessMenuButton>)}/>
      <CardContent>
        <TeamAccessList teams={teams} accessDropdownPermission={accessDropdownPermission}/>
      </CardContent>
    </Card>);
}
