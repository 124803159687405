export const GetSignatoryValuesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetSignatoryValues' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'esigningSignatoryValues' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isSigning' } },
                                {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'documentId' },
                                    name: { kind: 'Name', value: 'fileSystemDocumentId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
