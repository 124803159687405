import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Box, Button, H4, IconButton } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { dashboardTemplates, useInsightsTemplates } from './Context';
import generalDashboard from '../../assets/general_dashboard.svg';
import { HeaderContainer } from './styled';
export function OverviewHeader() {
    const { selected, setSelected, createDashboard } = useInsightsTemplates();
    const { _ } = useLingui();
    const template = dashboardTemplates.find((mock) => mock.id === selected);
    if (!template) {
        return null;
    }
    return (<HeaderContainer bg={generalDashboard}>
      <Box alignItems="center" width="100%" gap="md">
        <IconButton $round appearance="ghost" size="small" onClick={() => setSelected(undefined)}>
          <Falcon icon="chevron-left"/>
        </IconButton>
        <H4>{_(template.label)}</H4>

        <Box ml="auto">
          <Button onClick={createDashboard}>
            <Trans comment="Insights: Add dashboard button">Add dashboard</Trans>
          </Button>
        </Box>
      </Box>
    </HeaderContainer>);
}
