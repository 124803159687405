import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button, Typography } from '@pocketlaw/tetris';
import { useField, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { DocumentAttachments } from 'shared/domains/documents';
import { FormItem } from './FormItem';
import { OverrideAlert } from './OverrideAlert';
import { TemplateDrawerContent, TemplateDrawerFooter } from '../../TemplateDrawer';
export function FormContent(props) {
    const { showOverrideAlert } = props;
    const { handleSubmit, dirty, isValid, isSubmitting } = useFormikContext();
    const [{ value: attachments }, , { setValue }] = useField('attachments');
    const handleAddAttachments = (newAttachments) => {
        const normalizedAttachments = newAttachments.map((attachment) => (Object.assign(Object.assign({}, attachment), { id: uuidv4() })));
        setValue([...attachments, ...normalizedAttachments]);
    };
    const handleSave = () => handleSubmit();
    return (<Fragment>
      <TemplateDrawerContent>
        <Box flexDirection="column" gap="2xl">
          {showOverrideAlert && <OverrideAlert />}
          <Box flexDirection="column" gap="sm">
            {attachments.length > 0 && (<Typography $fontSize="small" $appearance="100">
                <Trans comment="Manage default attachments drawer - added attachments">Added attachments</Trans>
              </Typography>)}
            <DocumentAttachments disableAdd={isSubmitting} attachments={attachments} onAddAttachments={handleAddAttachments} render={(attachment) => <FormItem key={attachment.id} attachment={attachment} disabled={isSubmitting}/>}/>
          </Box>
        </Box>
      </TemplateDrawerContent>
      <TemplateDrawerFooter>
        <Typography $fontSize="small" $appearance="100">
          <Trans comment="Manage default attachments drawer - informative paragraph">
            Attachments added to templates will automatically be attached to documents drafted from it.
          </Trans>
        </Typography>
        <Button $stretch size="small" type="submit" appearance="primary" disabled={!dirty || !isValid || isSubmitting} onClick={handleSave}>
          <Trans comment="Manage default attachments drawer - Save button label">Save</Trans>
        </Button>
      </TemplateDrawerFooter>
    </Fragment>);
}
