import { useCallback } from 'react';
import { FileBrowserProvider, ROOT } from 'app/domains/filesystem';
import { EntryKind } from 'shared/domains/apollo/generated/types';
import { useEntries } from './useEntries';
import { FileDialog } from '../FileDialog';
/**
 * Generic file picker dialog with multiselect support.
 * @param props
 * @returns
 */
export function FilePickerDialog(props) {
    const { selfId, initialFolderId = ROOT, includeDocumentAi = false, initialEntryIds, multiselect, onSubmit } = props;
    const { entries, setEntries, loading } = useEntries({ initialEntryIds });
    const handleSelectEntry = useCallback((entry) => {
        if (!multiselect) {
            setEntries([entry]);
        }
        else {
            setEntries((currentState) => {
                const diff = currentState === null || currentState === void 0 ? void 0 : currentState.filter((selectedEntry) => selectedEntry.id !== entry.id);
                if (diff.length === currentState.length) {
                    return [...currentState, entry];
                }
                return diff;
            });
        }
    }, [multiselect, setEntries]);
    const handleSubmit = () => {
        if (multiselect === true) {
            onSubmit(entries);
        }
        if (multiselect === false) {
            const [singleEntry] = entries;
            if (!singleEntry) {
                return;
            }
            onSubmit(singleEntry);
        }
    };
    const selectable = (entry) => entry.kind === EntryKind.Document;
    const fileBrowserProviderProps = {
        customLoadingState: true,
        showSignRequestStatus: true,
        parentId: initialFolderId,
        selected: entries,
        onSelect: handleSelectEntry,
        selectable,
        includeDocumentAi,
        selfId,
        controlledLoading: loading,
    };
    return (<FileBrowserProvider {...fileBrowserProviderProps}>
      <FileDialog {...props} onSubmit={handleSubmit} multiselect={multiselect}/>
    </FileBrowserProvider>);
}
