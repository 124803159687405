import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { usePrevious } from 'shared/domains/common-ui';
import { mutation } from './mutation';
export const useCurrentCollaborators = (options) => {
    var _a;
    const { userId, documentId, versionNumber = 0 } = options;
    const [mutate, state] = useMutation(mutation);
    const currentCollaborators = ((_a = state.data) === null || _a === void 0 ? void 0 : _a.synchronizeFileSystemDocumentCollaborationSession.collaborators) || [];
    const previousCollaborators = usePrevious(currentCollaborators);
    const collaborators = currentCollaborators.length > 0 ? currentCollaborators : previousCollaborators;
    const isAnotherUserEditing = Boolean(collaborators === null || collaborators === void 0 ? void 0 : collaborators.find((collaborator) => collaborator.userId !== userId));
    const currentCollaboratorsAmount = currentCollaborators.length;
    useEffect(() => {
        mutate({ variables: { documentId, versionNumber } });
    }, [documentId, mutate, versionNumber]);
    return { isAnotherUserEditing, currentCollaboratorsAmount };
};
