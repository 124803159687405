import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { TableBodyCell, Menu, MenuList, MenuItem, IconButton, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { Link } from 'react-router-dom';
import { DownloadDocumentDialog } from 'app/domains/filesystem/components/DownloadDocumentDialog';
import { useMobile } from 'shared/domains/common-ui';
export function ContextMenuCell(props) {
    const { id, openUrl, editUrl, download, onDuplicate, onMove, onRename, onDelete, onEditAccess } = props;
    const [open, setOpen] = useState(false);
    const [downloadDialog, setDownloadDialog] = useState(false);
    const isMobile = useMobile();
    const stickyColumn = isMobile ? 'right' : undefined;
    const handleClick = (callback) => () => {
        setOpen(false);
        return callback();
    };
    const handleDownload = () => {
        setOpen(false);
        setDownloadDialog(true);
    };
    return (<TableBodyCell headers="filesystemtable-contextmenu" stickyColumn={stickyColumn}>
      <Menu open={open} onClose={() => setOpen(false)} target={<IconButton $round appearance="ghost" size="small" onClick={() => setOpen(!open)}>
            <Falcon icon="ellipsis-h"/>
          </IconButton>}>
        <MenuBody>
          <MenuList>
            {Boolean(openUrl) && (<MenuItem as={Link} to={openUrl}>
                <Falcon icon="eye"/>
                <Trans comment="Filesystem: Context menu item">View</Trans>
              </MenuItem>)}
            {Boolean(editUrl) && (<MenuItem as={Link} to={editUrl}>
                <Falcon icon="pen-to-square"/>
                <Trans comment="Filesystem: Context menu item">Edit</Trans>
              </MenuItem>)}
            {onDuplicate && (<MenuItem onClick={handleClick(onDuplicate)}>
                <Falcon icon="copy"/>
                <Trans comment="Filesystem: Context menu item">Copy</Trans>
              </MenuItem>)}
            {onEditAccess && (<MenuItem onClick={handleClick(onEditAccess)}>
                <Falcon icon="lock"/>
                <Trans comment="Filesystem: Context menu item">Manage access</Trans>
              </MenuItem>)}
            {onMove && (<MenuItem onClick={handleClick(onMove)}>
                <Falcon icon="folder"/>
                <Trans comment="Filesystem: Context menu item">Move to folder</Trans>
              </MenuItem>)}
            {onRename && (<MenuItem onClick={handleClick(onRename)}>
                <Falcon icon="pen-to-square"/>
                <Trans comment="Filesystem: Context menu item">Rename</Trans>
              </MenuItem>)}
            {download && (<MenuItem onClick={handleDownload}>
                <Falcon icon="download"/>
                <Trans comment="Filesystem: Context menu item">Download</Trans>
              </MenuItem>)}
            {onDelete && (<MenuItem onClick={handleClick(onDelete)}>
                <Falcon icon="trash"/>
                <Trans comment="Filesystem: Context menu item">Delete</Trans>
              </MenuItem>)}
          </MenuList>
        </MenuBody>
      </Menu>
      {downloadDialog && id && <DownloadDocumentDialog id={id} onClose={() => setDownloadDialog(false)}/>}
    </TableBodyCell>);
}
