var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState } from 'react';
import { Button } from '@pocketlaw/tetris';
import { SSORestricted } from 'shared/domains/users/components/SSORestricted';
import { useRole } from '../../hooks/useRole';
import { InviteUserModal } from '../InviteUserModal';
export function InviteUserButton(props) {
    const { onInviteCreated } = props, restProps = __rest(props, ["onInviteCreated"]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const role = useRole();
    if (!role.can('invite:user')) {
        return null;
    }
    return (<SSORestricted>
      <Button {...restProps} onClick={() => setDisplayDialog(true)}/>
      {displayDialog && <InviteUserModal onInviteCreated={onInviteCreated} onClose={() => setDisplayDialog(false)}/>}
    </SSORestricted>);
}
