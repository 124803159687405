var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { useActiveDocument } from 'shared/domains/documents';
import { getDocumentFeatures } from './query';
export function useGetDocumentFeatures() {
    const { company } = useActiveDocument();
    const { id: companyId } = company || {};
    const _a = useQuery(getDocumentFeatures, {
        fetchPolicy: 'network-only',
        skip: !companyId,
        variables: {
            companyId: companyId || '',
        },
    }), { data } = _a, rest = __rest(_a, ["data"]);
    const { fileSystemCompanyFeatures = [] } = data || {};
    const suggestionFeature = fileSystemCompanyFeatures.find((companyFeature) => companyFeature.feature === 'SUGGESTIONS');
    const documentFeatures = {
        suggestions: Boolean(suggestionFeature),
    };
    return Object.assign({ documentFeatures }, rest);
}
