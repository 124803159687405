import { SearchInsightsWidgetTypeEnum } from 'shared/domains/apollo/generated/types';
function stringGuard(value) {
    if (typeof value === 'string') {
        return value;
    }
    return '';
}
function arrayGuard(value) {
    if (Array.isArray(value)) {
        return value;
    }
    return [];
}
function typeGuard(value) {
    if (!value) {
        return SearchInsightsWidgetTypeEnum.Barchart;
    }
    const typesArr = Object.values(SearchInsightsWidgetTypeEnum);
    if (typesArr.includes(value)) {
        return value;
    }
    return SearchInsightsWidgetTypeEnum.Barchart;
}
function colorSchemeGuard(value) {
    if (!value) {
        return 'mono';
    }
    const colorArray = ['mono', 'mono-shaded', 'blue', 'blue-shaded', 'two-color', 'multi-color'];
    if (!colorArray.includes(value)) {
        return value;
    }
    return 'mono';
}
function filtersGuard(value) {
    if (typeof value === 'object') {
        return value;
    }
    return {};
}
export function widgetValuesValidation(initialValues) {
    return {
        label: stringGuard(initialValues === null || initialValues === void 0 ? void 0 : initialValues.label),
        description: stringGuard(initialValues === null || initialValues === void 0 ? void 0 : initialValues.description),
        aggregations: arrayGuard(initialValues === null || initialValues === void 0 ? void 0 : initialValues.aggregations),
        type: typeGuard(initialValues === null || initialValues === void 0 ? void 0 : initialValues.type),
        colorScheme: colorSchemeGuard(initialValues === null || initialValues === void 0 ? void 0 : initialValues.colorScheme),
        filters: filtersGuard(initialValues === null || initialValues === void 0 ? void 0 : initialValues.filters),
        isHorizontal: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.isHorizontal) || false,
        sourceId: initialValues === null || initialValues === void 0 ? void 0 : initialValues.sourceId,
    };
}
