var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { IconButton, Menu, MenuBody, MenuDivider, MenuItem, MenuList, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { DuplicateDocumentDialog, RenameDocumentDialog } from 'app/domains/documents';
import { DeleteDocumentDialog, ROOT } from 'app/domains/filesystem';
import { DocumentType } from 'shared/domains/apollo/generated/types';
import { documentFlush, usePreview } from 'shared/domains/documents';
import { DownloadDocumentMenuItems } from './DownloadDocumentMenuItems';
import { DuplicatingFreetextLoader } from './DuplicatingFreetextLoader';
import { ImportDocumentMenuItem } from './ImportDocumentMenuItem';
import { KeepRedlinesModal } from './KeepRedlinesModal';
import { useGetDocument } from './useGetDocument';
export function Dropdown() {
    const [renderMenu, setShowMenu] = useState(false);
    const [renderDeleteDialog, setShowDeleteDialog] = useState(false);
    const [renderRenameDialog, setShowRenameDialog] = useState(false);
    const [renderDuplicateDialog, setShowDuplicateDialog] = useState(false);
    const [renderDuplicateFreetextLoader, setRenderDuplicateFreetextLoader] = useState(false);
    const [keepRedlinesState, setKeepRedlinesState] = useState({
        render: false,
    });
    const { addToast } = useToast();
    const history = useHistory();
    const { data } = useGetDocument();
    const { id, name, type, folderId } = data || {};
    const { isRtcAvailable } = usePreview();
    const toggleMenu = () => setShowMenu((value) => !value);
    const hideMenu = () => setShowMenu(false);
    const showDeleteDialog = () => {
        hideMenu();
        setShowDeleteDialog(true);
    };
    const hideDeleteDialog = () => setShowDeleteDialog(false);
    const showRenameDialog = () => {
        hideMenu();
        setShowRenameDialog(true);
    };
    const hideRenameDialog = () => setShowRenameDialog(false);
    const showDuplicateDialog = () => {
        hideMenu();
        setShowDuplicateDialog(true);
    };
    const showDuplicateFreetextLoader = () => {
        hideMenu();
        setRenderDuplicateFreetextLoader(true);
    };
    const hideDuplicateDialog = () => setShowDuplicateDialog(false);
    const hideDuplicateFreetextLoader = () => setRenderDuplicateFreetextLoader(false);
    const onDeleted = () => {
        if (isRtcAvailable) {
            documentFlush({ state: 'initialized' });
        }
        if (folderId && folderId !== ROOT) {
            return history.push(`/files/${folderId}`);
        }
        return history.push(`/files`);
    };
    const onDuplicated = (draft) => {
        history.push(`/draft/${draft.id}`);
    };
    const handleDuplicate = () => __awaiter(this, void 0, void 0, function* () {
        try {
            if (!id) {
                throw Error();
            }
            if (type === DocumentType.Freetext) {
                showDuplicateFreetextLoader();
            }
            else {
                showDuplicateDialog();
            }
        }
        catch (error) {
            addToast({ title: t `Could not create copy.`, appearance: 'danger' });
        }
    });
    const handleHideKeepRedlinesModal = () => setKeepRedlinesState({ render: false });
    const handleShowKeepRedlinesModal = (downloadFallback) => {
        hideMenu();
        setKeepRedlinesState({ render: true, download: downloadFallback });
    };
    const handleDownload = () => {
        hideMenu();
        addToast({
            appearance: 'info',
            title: t({
                comment: 'Toast message when the document download has been started',
                message: `Downloading ${name}`,
            }),
        });
    };
    return (<Fragment>
      <Menu open={renderMenu} onClose={hideMenu} target={<IconButton aria-label={t `More options`} appearance="ghost" size="medium" onClick={toggleMenu} $round>
            <Falcon icon="ellipsis-v"/>
          </IconButton>}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={showRenameDialog}>
              <Falcon icon="pen-to-square"/>
              <Trans comment="Dropdown label for renaming document">Edit name</Trans>
            </MenuItem>
            <DownloadDocumentMenuItems onDownload={handleDownload} onShowKeepRedlinesModal={handleShowKeepRedlinesModal}/>
            <ImportDocumentMenuItem onClick={hideMenu}/>
            <MenuItem onClick={handleDuplicate}>
              <Falcon icon="copy"/>
              <Trans comment="Dropdown label for duplicating document">Duplicate</Trans>
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={showDeleteDialog}>
              <Falcon icon="trash-can"/>
              <Trans comment="Dropdown label for deleting document">Delete</Trans>
            </MenuItem>
          </MenuList>
        </MenuBody>
      </Menu>
      {renderDeleteDialog && id && <DeleteDocumentDialog id={id} onDeleted={onDeleted} onClose={hideDeleteDialog}/>}
      {renderRenameDialog && <RenameDocumentDialog onClose={hideRenameDialog}/>}
      {renderDuplicateDialog && id && (<DuplicateDocumentDialog id={id} onDuplicated={onDuplicated} onClose={hideDuplicateDialog}/>)}
      {renderDuplicateFreetextLoader && id && (<DuplicatingFreetextLoader documentId={id} documentName={name} onClose={hideDuplicateFreetextLoader}/>)}
      {keepRedlinesState.render && (<KeepRedlinesModal downloadFallback={keepRedlinesState.download} onClose={handleHideKeepRedlinesModal} onSubmit={handleDownload}/>)}
    </Fragment>);
}
