import { useRef } from 'react';
import { t } from '@lingui/macro';
import { ChipAction, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { SearchAggregationMetricType, } from 'shared/domains/apollo/generated/types';
import { ChipDropdown } from 'shared/domains/common-ui';
import { getMetricTypeTranslations, allowedPropertiesTranslations } from './translations';
import { SelectDataPoint } from '../SelectDataPoint';
import { FieldInputContainer } from '../styled';
export function MetricInput(props) {
    const { pathBase, remove } = props;
    const currentPath = `${pathBase}.metricInput`;
    const [field, , helpers] = useField(currentPath);
    const configurationDropdownRef = useRef();
    const handleChangeType = (val) => {
        var _a;
        helpers.setValue(Object.assign(Object.assign({}, field.value), { type: val }));
        (_a = configurationDropdownRef.current) === null || _a === void 0 ? void 0 : _a.toggleDropdown();
    };
    return (<FieldInputContainer>
      <SelectDataPoint label={t({ message: 'Metric', comment: 'Insights widget form: Metric field' })} selectedValue={allowedPropertiesTranslations(field.value.field)} name={pathBase} action={<ChipAction onClick={remove}>
            <Falcon icon="close"/>
          </ChipAction>}/>

      <ChipDropdown label={t({ message: 'Config', comment: 'Insights: Metric configuration' })} secondary={getMetricTypeTranslations(field.value.type)} ref={configurationDropdownRef} appearance="secondary" variant="outline" dropdownContent={<MenuBody>
            <MenuList>
              {Object.values(SearchAggregationMetricType).map((val) => (<MenuItem key={val} onClick={() => handleChangeType(val)} $selected={val === field.value.type}>
                  {getMetricTypeTranslations(val)}
                </MenuItem>))}
            </MenuList>
          </MenuBody>}/>
    </FieldInputContainer>);
}
