import { t } from '@lingui/macro';
import { DocumentMetadataPropertyTypeEnum } from 'shared/domains/apollo/generated/types';
export const metadataConfigObject = {
    ContractOwner: () => ({
        name: 'ContractOwner',
        label: t({ message: 'ContractOwner', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Reference,
    }),
    AutomaticRenewal: () => ({
        name: 'AutomaticRenewal',
        label: t({ message: 'AutomaticRenewal', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    TotalContractValue: () => ({
        name: 'TotalContractValue',
        label: t({ message: 'TotalContractValue', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Number,
    }),
    Notes: () => ({
        name: 'Notes',
        label: t({ message: 'Notes', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.MultiLineText,
    }),
    MaterialAgreement: () => ({
        name: 'MaterialAgreement',
        label: t({ message: 'Material Agreement', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    GoverningLaw: () => ({
        name: 'GoverningLaw',
        label: t({ message: 'GoverningLaw', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.List,
        allowedValues: [
            {
                text: t({ message: 'Afghanistan', comment: 'Metadata allowed value' }),
                value: 'AF',
            },
            {
                text: t({ message: 'Åland Islands', comment: 'Metadata allowed value' }),
                value: 'AX',
            },
            {
                text: t({ message: 'Albania', comment: 'Metadata allowed value' }),
                value: 'AL',
            },
            {
                text: t({ message: 'Algeria', comment: 'Metadata allowed value' }),
                value: 'DZ',
            },
            {
                text: t({ message: 'American Samoa', comment: 'Metadata allowed value' }),
                value: 'AS',
            },
            {
                text: t({ message: 'Andorra', comment: 'Metadata allowed value' }),
                value: 'AD',
            },
            {
                text: t({ message: 'Angola', comment: 'Metadata allowed value' }),
                value: 'AO',
            },
            {
                text: t({ message: 'Anguilla', comment: 'Metadata allowed value' }),
                value: 'AI',
            },
            {
                text: t({ message: 'Antarctica', comment: 'Metadata allowed value' }),
                value: 'AQ',
            },
            {
                text: t({ message: 'Antigua and Barbuda', comment: 'Metadata allowed value' }),
                value: 'AG',
            },
            {
                text: t({ message: 'Argentina', comment: 'Metadata allowed value' }),
                value: 'AR',
            },
            {
                text: t({ message: 'Armenia', comment: 'Metadata allowed value' }),
                value: 'AM',
            },
            {
                text: t({ message: 'Aruba', comment: 'Metadata allowed value' }),
                value: 'AW',
            },
            {
                text: t({ message: 'Australia', comment: 'Metadata allowed value' }),
                value: 'AU',
            },
            {
                text: t({ message: 'Austria', comment: 'Metadata allowed value' }),
                value: 'AT',
            },
            {
                text: t({ message: 'Azerbaijan', comment: 'Metadata allowed value' }),
                value: 'AZ',
            },
            {
                text: t({ message: 'Bahamas', comment: 'Metadata allowed value' }),
                value: 'BS',
            },
            {
                text: t({ message: 'Bahrain', comment: 'Metadata allowed value' }),
                value: 'BH',
            },
            {
                text: t({ message: 'Bangladesh', comment: 'Metadata allowed value' }),
                value: 'BD',
            },
            {
                text: t({ message: 'Barbados', comment: 'Metadata allowed value' }),
                value: 'BB',
            },
            {
                text: t({ message: 'Belarus', comment: 'Metadata allowed value' }),
                value: 'BY',
            },
            {
                text: t({ message: 'Belgium', comment: 'Metadata allowed value' }),
                value: 'BE',
            },
            {
                text: t({ message: 'Belize', comment: 'Metadata allowed value' }),
                value: 'BZ',
            },
            {
                text: t({ message: 'Benin', comment: 'Metadata allowed value' }),
                value: 'BJ',
            },
            {
                text: t({ message: 'Bermuda', comment: 'Metadata allowed value' }),
                value: 'BM',
            },
            {
                text: t({ message: 'Bhutan', comment: 'Metadata allowed value' }),
                value: 'BT',
            },
            {
                text: t({ message: 'Bolivia', comment: 'Metadata allowed value' }),
                value: 'BO',
            },
            {
                text: t({ message: 'Bonaire, Sint Eustatius and Saba', comment: 'Metadata allowed value' }),
                value: 'BQ',
            },
            {
                text: t({ message: 'Bosnia and Herzegovina', comment: 'Metadata allowed value' }),
                value: 'BA',
            },
            {
                text: t({ message: 'Botswana', comment: 'Metadata allowed value' }),
                value: 'BW',
            },
            {
                text: t({ message: 'Bouvet Island', comment: 'Metadata allowed value' }),
                value: 'BV',
            },
            {
                text: t({ message: 'Brazil', comment: 'Metadata allowed value' }),
                value: 'BR',
            },
            {
                text: t({ message: 'British Indian Ocean Territory', comment: 'Metadata allowed value' }),
                value: 'IO',
            },
            {
                text: t({ message: 'Brunei Darussalam', comment: 'Metadata allowed value' }),
                value: 'BN',
            },
            {
                text: t({ message: 'Bulgaria', comment: 'Metadata allowed value' }),
                value: 'BG',
            },
            {
                text: t({ message: 'Burkina Faso', comment: 'Metadata allowed value' }),
                value: 'BF',
            },
            {
                text: t({ message: 'Burundi', comment: 'Metadata allowed value' }),
                value: 'BI',
            },
            {
                text: t({ message: 'Cabo Verde', comment: 'Metadata allowed value' }),
                value: 'CV',
            },
            {
                text: t({ message: 'Cambodia', comment: 'Metadata allowed value' }),
                value: 'KH',
            },
            {
                text: t({ message: 'Cameroon', comment: 'Metadata allowed value' }),
                value: 'CM',
            },
            {
                text: t({ message: 'Canada', comment: 'Metadata allowed value' }),
                value: 'CA',
            },
            {
                text: t({ message: 'Cayman Islands', comment: 'Metadata allowed value' }),
                value: 'KY',
            },
            {
                text: t({ message: 'Central African Republic', comment: 'Metadata allowed value' }),
                value: 'CF',
            },
            {
                text: t({ message: 'Chad', comment: 'Metadata allowed value' }),
                value: 'TD',
            },
            {
                text: t({ message: 'Chile', comment: 'Metadata allowed value' }),
                value: 'CL',
            },
            {
                text: t({ message: 'China', comment: 'Metadata allowed value' }),
                value: 'CN',
            },
            {
                text: t({ message: 'Christmas Island', comment: 'Metadata allowed value' }),
                value: 'CX',
            },
            {
                text: t({ message: 'Cocos (Keeling) Islands', comment: 'Metadata allowed value' }),
                value: 'CC',
            },
            {
                text: t({ message: 'Colombia', comment: 'Metadata allowed value' }),
                value: 'CO',
            },
            {
                text: t({ message: 'Comoros', comment: 'Metadata allowed value' }),
                value: 'KM',
            },
            {
                text: t({ message: 'Congo', comment: 'Metadata allowed value' }),
                value: 'CD',
            },
            {
                text: t({ message: 'Congo', comment: 'Metadata allowed value' }),
                value: 'CG',
            },
            {
                text: t({ message: 'Cook Islands', comment: 'Metadata allowed value' }),
                value: 'CK',
            },
            {
                text: t({ message: 'Costa Rica', comment: 'Metadata allowed value' }),
                value: 'CR',
            },
            {
                text: t({ message: "Côte d'Ivoire", comment: 'Metadata allowed value' }),
                value: 'CI',
            },
            {
                text: t({ message: 'Croatia', comment: 'Metadata allowed value' }),
                value: 'HR',
            },
            {
                text: t({ message: 'Cuba', comment: 'Metadata allowed value' }),
                value: 'CU',
            },
            {
                text: t({ message: 'Curaçao', comment: 'Metadata allowed value' }),
                value: 'CW',
            },
            {
                text: t({ message: 'Cyprus', comment: 'Metadata allowed value' }),
                value: 'CY',
            },
            {
                text: t({ message: 'Czechia', comment: 'Metadata allowed value' }),
                value: 'CZ',
            },
            {
                text: t({ message: 'Denmark', comment: 'Metadata allowed value' }),
                value: 'DK',
            },
            {
                text: t({ message: 'Djibouti', comment: 'Metadata allowed value' }),
                value: 'DJ',
            },
            {
                text: t({ message: 'Dominica', comment: 'Metadata allowed value' }),
                value: 'DM',
            },
            {
                text: t({ message: 'Dominican Republic', comment: 'Metadata allowed value' }),
                value: 'DO',
            },
            {
                text: t({ message: 'Ecuador', comment: 'Metadata allowed value' }),
                value: 'EC',
            },
            {
                text: t({ message: 'Egypt', comment: 'Metadata allowed value' }),
                value: 'EG',
            },
            {
                text: t({ message: 'El Salvador', comment: 'Metadata allowed value' }),
                value: 'SV',
            },
            {
                text: t({ message: 'Equatorial Guinea', comment: 'Metadata allowed value' }),
                value: 'GQ',
            },
            {
                text: t({ message: 'Eritrea', comment: 'Metadata allowed value' }),
                value: 'ER',
            },
            {
                text: t({ message: 'Estonia', comment: 'Metadata allowed value' }),
                value: 'EE',
            },
            {
                text: t({ message: 'Eswatini', comment: 'Metadata allowed value' }),
                value: 'SZ',
            },
            {
                text: t({ message: 'Ethiopia', comment: 'Metadata allowed value' }),
                value: 'ET',
            },
            {
                text: t({ message: 'Falkland Islands', comment: 'Metadata allowed value' }),
                value: 'FK',
            },
            {
                text: t({ message: 'Faroe Islands', comment: 'Metadata allowed value' }),
                value: 'FO',
            },
            {
                text: t({ message: 'Fiji', comment: 'Metadata allowed value' }),
                value: 'FJ',
            },
            {
                text: t({ message: 'Finland', comment: 'Metadata allowed value' }),
                value: 'FI',
            },
            {
                text: t({ message: 'France', comment: 'Metadata allowed value' }),
                value: 'FR',
            },
            {
                text: t({ message: 'French Guiana', comment: 'Metadata allowed value' }),
                value: 'GF',
            },
            {
                text: t({ message: 'French Polynesia', comment: 'Metadata allowed value' }),
                value: 'PF',
            },
            {
                text: t({ message: 'French Southern Territories', comment: 'Metadata allowed value' }),
                value: 'TF',
            },
            {
                text: t({ message: 'Gabon', comment: 'Metadata allowed value' }),
                value: 'GA',
            },
            {
                text: t({ message: 'Gambia', comment: 'Metadata allowed value' }),
                value: 'GM',
            },
            {
                text: t({ message: 'Georgia', comment: 'Metadata allowed value' }),
                value: 'GE',
            },
            {
                text: t({ message: 'Germany', comment: 'Metadata allowed value' }),
                value: 'DE',
            },
            {
                text: t({ message: 'Ghana', comment: 'Metadata allowed value' }),
                value: 'GH',
            },
            {
                text: t({ message: 'Gibraltar', comment: 'Metadata allowed value' }),
                value: 'GI',
            },
            {
                text: t({ message: 'Greece', comment: 'Metadata allowed value' }),
                value: 'GR',
            },
            {
                text: t({ message: 'Greenland', comment: 'Metadata allowed value' }),
                value: 'GL',
            },
            {
                text: t({ message: 'Grenada', comment: 'Metadata allowed value' }),
                value: 'GD',
            },
            {
                text: t({ message: 'Guadeloupe', comment: 'Metadata allowed value' }),
                value: 'GP',
            },
            {
                text: t({ message: 'Guam', comment: 'Metadata allowed value' }),
                value: 'GU',
            },
            {
                text: t({ message: 'Guatemala', comment: 'Metadata allowed value' }),
                value: 'GT',
            },
            {
                text: t({ message: 'Guernsey', comment: 'Metadata allowed value' }),
                value: 'GG',
            },
            {
                text: t({ message: 'Guinea', comment: 'Metadata allowed value' }),
                value: 'GN',
            },
            {
                text: t({ message: 'Guinea-Bissau', comment: 'Metadata allowed value' }),
                value: 'GW',
            },
            {
                text: t({ message: 'Guyana', comment: 'Metadata allowed value' }),
                value: 'GY',
            },
            {
                text: t({ message: 'Haiti', comment: 'Metadata allowed value' }),
                value: 'HT',
            },
            {
                text: t({
                    message: 'Heard Island and McDonald Islands',
                    comment: 'Metadata allowed value',
                }),
                value: 'HM',
            },
            {
                text: t({ message: 'Holy See', comment: 'Metadata allowed value' }),
                value: 'VA',
            },
            {
                text: t({ message: 'Honduras', comment: 'Metadata allowed value' }),
                value: 'HN',
            },
            {
                text: t({ message: 'Hong Kong', comment: 'Metadata allowed value' }),
                value: 'HK',
            },
            {
                text: t({ message: 'Hungary', comment: 'Metadata allowed value' }),
                value: 'HU',
            },
            {
                text: t({ message: 'Iceland', comment: 'Metadata allowed value' }),
                value: 'IS',
            },
            {
                text: t({ message: 'India', comment: 'Metadata allowed value' }),
                value: 'IN',
            },
            {
                text: t({ message: 'Indonesia', comment: 'Metadata allowed value' }),
                value: 'ID',
            },
            {
                text: t({ message: 'Iran', comment: 'Metadata allowed value' }),
                value: 'IR',
            },
            {
                text: t({ message: 'Iraq', comment: 'Metadata allowed value' }),
                value: 'IQ',
            },
            {
                text: t({ message: 'Ireland', comment: 'Metadata allowed value' }),
                value: 'IE',
            },
            {
                text: t({ message: 'Isle of Man', comment: 'Metadata allowed value' }),
                value: 'IM',
            },
            {
                text: t({ message: 'Israel', comment: 'Metadata allowed value' }),
                value: 'IL',
            },
            {
                text: t({ message: 'Italy', comment: 'Metadata allowed value' }),
                value: 'IT',
            },
            {
                text: t({ message: 'Jamaica', comment: 'Metadata allowed value' }),
                value: 'JM',
            },
            {
                text: t({ message: 'Japan', comment: 'Metadata allowed value' }),
                value: 'JP',
            },
            {
                text: t({ message: 'Jersey', comment: 'Metadata allowed value' }),
                value: 'JE',
            },
            {
                text: t({ message: 'Jordan', comment: 'Metadata allowed value' }),
                value: 'JO',
            },
            {
                text: t({ message: 'Kazakhstan', comment: 'Metadata allowed value' }),
                value: 'KZ',
            },
            {
                text: t({ message: 'Kenya', comment: 'Metadata allowed value' }),
                value: 'KE',
            },
            {
                text: t({ message: 'Kiribati', comment: 'Metadata allowed value' }),
                value: 'KI',
            },
            {
                text: t({ message: 'Kuwait', comment: 'Metadata allowed value' }),
                value: 'KW',
            },
            {
                text: t({ message: 'Kyrgyzstan', comment: 'Metadata allowed value' }),
                value: 'KG',
            },
            {
                text: t({ message: "Lao People's Democratic Republic", comment: 'Metadata allowed value' }),
                value: 'LA',
            },
            {
                text: t({ message: 'Latvia', comment: 'Metadata allowed value' }),
                value: 'LV',
            },
            {
                text: t({ message: 'Lebanon', comment: 'Metadata allowed value' }),
                value: 'LB',
            },
            {
                text: t({ message: 'Lesotho', comment: 'Metadata allowed value' }),
                value: 'LS',
            },
            {
                text: t({ message: 'Liberia', comment: 'Metadata allowed value' }),
                value: 'LR',
            },
            {
                text: t({ message: 'Libya', comment: 'Metadata allowed value' }),
                value: 'LY',
            },
            {
                text: t({ message: 'Liechtenstein', comment: 'Metadata allowed value' }),
                value: 'LI',
            },
            {
                text: t({ message: 'Lithuania', comment: 'Metadata allowed value' }),
                value: 'LT',
            },
            {
                text: t({ message: 'Luxembourg', comment: 'Metadata allowed value' }),
                value: 'LU',
            },
            {
                text: t({ message: 'Macao', comment: 'Metadata allowed value' }),
                value: 'MO',
            },
            {
                text: t({ message: 'Madagascar', comment: 'Metadata allowed value' }),
                value: 'MG',
            },
            {
                text: t({ message: 'Malawi', comment: 'Metadata allowed value' }),
                value: 'MW',
            },
            {
                text: t({ message: 'Malaysia', comment: 'Metadata allowed value' }),
                value: 'MY',
            },
            {
                text: t({ message: 'Maldives', comment: 'Metadata allowed value' }),
                value: 'MV',
            },
            {
                text: t({ message: 'Mali', comment: 'Metadata allowed value' }),
                value: 'ML',
            },
            {
                text: t({ message: 'Malta', comment: 'Metadata allowed value' }),
                value: 'MT',
            },
            {
                text: t({ message: 'Marshall Islands', comment: 'Metadata allowed value' }),
                value: 'MH',
            },
            {
                text: t({ message: 'Martinique', comment: 'Metadata allowed value' }),
                value: 'MQ',
            },
            {
                text: t({ message: 'Mauritania', comment: 'Metadata allowed value' }),
                value: 'MR',
            },
            {
                text: t({ message: 'Mauritius', comment: 'Metadata allowed value' }),
                value: 'MU',
            },
            {
                text: t({ message: 'Mayotte', comment: 'Metadata allowed value' }),
                value: 'YT',
            },
            {
                text: t({ message: 'Mexico', comment: 'Metadata allowed value' }),
                value: 'MX',
            },
            {
                text: t({ message: 'Micronesia', comment: 'Metadata allowed value' }),
                value: 'FM',
            },
            {
                text: t({ message: 'Moldova', comment: 'Metadata allowed value' }),
                value: 'MD',
            },
            {
                text: t({ message: 'Monaco', comment: 'Metadata allowed value' }),
                value: 'MC',
            },
            {
                text: t({ message: 'Mongolia', comment: 'Metadata allowed value' }),
                value: 'MN',
            },
            {
                text: t({ message: 'Montenegro', comment: 'Metadata allowed value' }),
                value: 'ME',
            },
            {
                text: t({ message: 'Montserrat', comment: 'Metadata allowed value' }),
                value: 'MS',
            },
            {
                text: t({ message: 'Morocco', comment: 'Metadata allowed value' }),
                value: 'MA',
            },
            {
                text: t({ message: 'Mozambique', comment: 'Metadata allowed value' }),
                value: 'MZ',
            },
            {
                text: t({ message: 'Myanmar', comment: 'Metadata allowed value' }),
                value: 'MM',
            },
            {
                text: t({ message: 'Namibia', comment: 'Metadata allowed value' }),
                value: 'NA',
            },
            {
                text: t({ message: 'Nauru', comment: 'Metadata allowed value' }),
                value: 'NR',
            },
            {
                text: t({ message: 'Nepal', comment: 'Metadata allowed value' }),
                value: 'NP',
            },
            {
                text: t({ message: 'Netherlands', comment: 'Metadata allowed value' }),
                value: 'NL',
            },
            {
                text: t({ message: 'New Caledonia', comment: 'Metadata allowed value' }),
                value: 'NC',
            },
            {
                text: t({ message: 'New Zealand', comment: 'Metadata allowed value' }),
                value: 'NZ',
            },
            {
                text: t({ message: 'Nicaragua', comment: 'Metadata allowed value' }),
                value: 'NI',
            },
            {
                text: t({ message: 'Niger', comment: 'Metadata allowed value' }),
                value: 'NE',
            },
            {
                text: t({ message: 'Nigeria', comment: 'Metadata allowed value' }),
                value: 'NG',
            },
            {
                text: t({ message: 'Niue', comment: 'Metadata allowed value' }),
                value: 'NU',
            },
            {
                text: t({ message: 'Norfolk Island', comment: 'Metadata allowed value' }),
                value: 'NF',
            },
            {
                text: t({ message: 'North Korea', comment: 'Metadata allowed value' }),
                value: 'KP',
            },
            {
                text: t({ message: 'North Macedonia', comment: 'Metadata allowed value' }),
                value: 'MK',
            },
            {
                text: t({ message: 'Northern Mariana Islands', comment: 'Metadata allowed value' }),
                value: 'MP',
            },
            {
                text: t({ message: 'Norway', comment: 'Metadata allowed value' }),
                value: 'NO',
            },
            {
                text: t({ message: 'Oman', comment: 'Metadata allowed value' }),
                value: 'OM',
            },
            {
                text: t({ message: 'Pakistan', comment: 'Metadata allowed value' }),
                value: 'PK',
            },
            {
                text: t({ message: 'Palau', comment: 'Metadata allowed value' }),
                value: 'PW',
            },
            {
                text: t({ message: 'Palestine, State of', comment: 'Metadata allowed value' }),
                value: 'PS',
            },
            {
                text: t({ message: 'Panama', comment: 'Metadata allowed value' }),
                value: 'PA',
            },
            {
                text: t({ message: 'Papua New Guinea', comment: 'Metadata allowed value' }),
                value: 'PG',
            },
            {
                text: t({ message: 'Paraguay', comment: 'Metadata allowed value' }),
                value: 'PY',
            },
            {
                text: t({ message: 'Peru', comment: 'Metadata allowed value' }),
                value: 'PE',
            },
            {
                text: t({ message: 'Philippines', comment: 'Metadata allowed value' }),
                value: 'PH',
            },
            {
                text: t({ message: 'Pitcairn', comment: 'Metadata allowed value' }),
                value: 'PN',
            },
            {
                text: t({ message: 'Poland', comment: 'Metadata allowed value' }),
                value: 'PL',
            },
            {
                text: t({ message: 'Portugal', comment: 'Metadata allowed value' }),
                value: 'PT',
            },
            {
                text: t({ message: 'Puerto Rico', comment: 'Metadata allowed value' }),
                value: 'PR',
            },
            {
                text: t({ message: 'Qatar', comment: 'Metadata allowed value' }),
                value: 'QA',
            },
            {
                text: t({ message: 'Réunion', comment: 'Metadata allowed value' }),
                value: 'RE',
            },
            {
                text: t({ message: 'Romania', comment: 'Metadata allowed value' }),
                value: 'RO',
            },
            {
                text: t({ message: 'Russian Federation', comment: 'Metadata allowed value' }),
                value: 'RU',
            },
            {
                text: t({ message: 'Rwanda', comment: 'Metadata allowed value' }),
                value: 'RW',
            },
            {
                text: t({ message: 'Saint Barthélemy', comment: 'Metadata allowed value' }),
                value: 'BL',
            },
            {
                text: t({
                    message: 'Saint Helena, Ascension and Tristan da Cunha',
                    comment: 'Metadata allowed value',
                }),
                value: 'SH',
            },
            {
                text: t({ message: 'Saint Kitts and Nevis', comment: 'Metadata allowed value' }),
                value: 'KN',
            },
            {
                text: t({ message: 'Saint Lucia', comment: 'Metadata allowed value' }),
                value: 'LC',
            },
            {
                text: t({ message: 'Saint Martin (French part)', comment: 'Metadata allowed value' }),
                value: 'MF',
            },
            {
                text: t({ message: 'Saint Pierre and Miquelon', comment: 'Metadata allowed value' }),
                value: 'PM',
            },
            {
                text: t({ message: 'Saint Vincent and the Grenadines', comment: 'Metadata allowed value' }),
                value: 'VC',
            },
            {
                text: t({ message: 'Samoa', comment: 'Metadata allowed value' }),
                value: 'WS',
            },
            {
                text: t({ message: 'San Marino', comment: 'Metadata allowed value' }),
                value: 'SM',
            },
            {
                text: t({ message: 'Sao Tome and Principe', comment: 'Metadata allowed value' }),
                value: 'ST',
            },
            {
                text: t({ message: 'Saudi Arabia', comment: 'Metadata allowed value' }),
                value: 'SA',
            },
            {
                text: t({ message: 'Senegal', comment: 'Metadata allowed value' }),
                value: 'SN',
            },
            {
                text: t({ message: 'Serbia', comment: 'Metadata allowed value' }),
                value: 'RS',
            },
            {
                text: t({ message: 'Seychelles', comment: 'Metadata allowed value' }),
                value: 'SC',
            },
            {
                text: t({ message: 'Sierra Leone', comment: 'Metadata allowed value' }),
                value: 'SL',
            },
            {
                text: t({ message: 'Singapore', comment: 'Metadata allowed value' }),
                value: 'SG',
            },
            {
                text: t({ message: 'Sint Maarten (Dutch part)', comment: 'Metadata allowed value' }),
                value: 'SX',
            },
            {
                text: t({ message: 'Slovakia', comment: 'Metadata allowed value' }),
                value: 'SK',
            },
            {
                text: t({ message: 'Slovenia', comment: 'Metadata allowed value' }),
                value: 'SI',
            },
            {
                text: t({ message: 'Solomon Islands', comment: 'Metadata allowed value' }),
                value: 'SB',
            },
            {
                text: t({ message: 'Somalia', comment: 'Metadata allowed value' }),
                value: 'SO',
            },
            {
                text: t({ message: 'South Africa', comment: 'Metadata allowed value' }),
                value: 'ZA',
            },
            {
                text: t({
                    message: 'South Georgia and the South Sandwich Islands',
                    comment: 'Metadata allowed value',
                }),
                value: 'GS',
            },
            {
                text: t({ message: 'South Sudan', comment: 'Metadata allowed value' }),
                value: 'SS',
            },
            {
                text: t({ message: 'South Korea', comment: 'Metadata allowed value' }),
                value: 'KR',
            },
            {
                text: t({ message: 'Spain', comment: 'Metadata allowed value' }),
                value: 'ES',
            },
            {
                text: t({ message: 'Sri Lanka', comment: 'Metadata allowed value' }),
                value: 'LK',
            },
            {
                text: t({ message: 'Sudan', comment: 'Metadata allowed value' }),
                value: 'SD',
            },
            {
                text: t({ message: 'Suriname', comment: 'Metadata allowed value' }),
                value: 'SR',
            },
            {
                text: t({ message: 'Svalbard and Jan Mayen', comment: 'Metadata allowed value' }),
                value: 'SJ',
            },
            {
                text: t({ message: 'Sweden', comment: 'Metadata allowed value' }),
                value: 'SE',
            },
            {
                text: t({ message: 'Switzerland', comment: 'Metadata allowed value' }),
                value: 'CH',
            },
            {
                text: t({ message: 'Syrian Arab Republic', comment: 'Metadata allowed value' }),
                value: 'SY',
            },
            {
                text: t({ message: 'Taiwan (Province of China)', comment: 'Metadata allowed value' }),
                value: 'TW',
            },
            {
                text: t({ message: 'Tajikistan', comment: 'Metadata allowed value' }),
                value: 'TJ',
            },
            {
                text: t({ message: 'Tanzania', comment: 'Metadata allowed value' }),
                value: 'TZ',
            },
            {
                text: t({ message: 'Thailand', comment: 'Metadata allowed value' }),
                value: 'TH',
            },
            {
                text: t({ message: 'Timor-Leste', comment: 'Metadata allowed value' }),
                value: 'TL',
            },
            {
                text: t({ message: 'Togo', comment: 'Metadata allowed value' }),
                value: 'TG',
            },
            {
                text: t({ message: 'Tokelau', comment: 'Metadata allowed value' }),
                value: 'TK',
            },
            {
                text: t({ message: 'Tonga', comment: 'Metadata allowed value' }),
                value: 'TO',
            },
            {
                text: t({ message: 'Trinidad and Tobago', comment: 'Metadata allowed value' }),
                value: 'TT',
            },
            {
                text: t({ message: 'Tunisia', comment: 'Metadata allowed value' }),
                value: 'TN',
            },
            {
                text: t({ message: 'Türkiye', comment: 'Metadata allowed value' }),
                value: 'TR',
            },
            {
                text: t({ message: 'Turkmenistan', comment: 'Metadata allowed value' }),
                value: 'TM',
            },
            {
                text: t({ message: 'Turks and Caicos Islands', comment: 'Metadata allowed value' }),
                value: 'TC',
            },
            {
                text: t({ message: 'Tuvalu', comment: 'Metadata allowed value' }),
                value: 'TV',
            },
            {
                text: t({ message: 'Uganda', comment: 'Metadata allowed value' }),
                value: 'UG',
            },
            {
                text: t({ message: 'Ukraine', comment: 'Metadata allowed value' }),
                value: 'UA',
            },
            {
                text: t({ message: 'United Arab Emirates', comment: 'Metadata allowed value' }),
                value: 'AE',
            },
            {
                text: t({ message: 'United Kingdom', comment: 'Metadata allowed value' }),
                value: '',
                groupValues: [
                    {
                        text: t({ message: 'England and Wales', comment: 'Metadata allowed value' }),
                        value: 'GB|EAW',
                    },
                    {
                        text: t({ message: 'Northern Ireland', comment: 'Metadata allowed value' }),
                        value: 'GB|NIR',
                    },
                    {
                        text: t({ message: 'Scotland', comment: 'Metadata allowed value' }),
                        value: 'GB|SCT',
                    },
                    {
                        text: t({ message: 'United Kingdom', comment: 'Metadata allowed value' }),
                        value: 'GB',
                    },
                ],
            },
            {
                text: t({
                    message: 'United States Minor Outlying Islands',
                    comment: 'Metadata allowed value',
                }),
                value: 'UM',
            },
            {
                text: t({ message: 'United States of America', comment: 'Metadata allowed value' }),
                value: '',
                groupValues: [
                    {
                        text: t({ message: 'Alabama', comment: 'Metadata allowed value' }),
                        value: 'US|AL',
                    },
                    {
                        text: t({ message: 'Alaska', comment: 'Metadata allowed value' }),
                        value: 'US|AK',
                    },
                    {
                        text: t({ message: 'Arizona', comment: 'Metadata allowed value' }),
                        value: 'US|AZ',
                    },
                    {
                        text: t({ message: 'Arkansas', comment: 'Metadata allowed value' }),
                        value: 'US|AR',
                    },
                    {
                        text: t({ message: 'California', comment: 'Metadata allowed value' }),
                        value: 'US|CA',
                    },
                    {
                        text: t({ message: 'Colorado', comment: 'Metadata allowed value' }),
                        value: 'US|CO',
                    },
                    {
                        text: t({ message: 'Connecticut', comment: 'Metadata allowed value' }),
                        value: 'US|CT',
                    },
                    {
                        text: t({ message: 'Delaware', comment: 'Metadata allowed value' }),
                        value: 'US|DE',
                    },
                    {
                        text: t({ message: 'District of Columbia', comment: 'Metadata allowed value' }),
                        value: 'US|DC',
                    },
                    {
                        text: t({ message: 'Florida', comment: 'Metadata allowed value' }),
                        value: 'US|FL',
                    },
                    {
                        text: t({ message: 'Georgia', comment: 'Metadata allowed value' }),
                        value: 'US|GA',
                    },
                    {
                        text: t({ message: 'Hawaii', comment: 'Metadata allowed value' }),
                        value: 'US|HI',
                    },
                    {
                        text: t({ message: 'Idaho', comment: 'Metadata allowed value' }),
                        value: 'US|ID',
                    },
                    {
                        text: t({ message: 'Illinois', comment: 'Metadata allowed value' }),
                        value: 'US|IL',
                    },
                    {
                        text: t({ message: 'Indiana', comment: 'Metadata allowed value' }),
                        value: 'US|IN',
                    },
                    {
                        text: t({ message: 'Iowa', comment: 'Metadata allowed value' }),
                        value: 'US|IA',
                    },
                    {
                        text: t({ message: 'Kansas', comment: 'Metadata allowed value' }),
                        value: 'US|KS',
                    },
                    {
                        text: t({ message: 'Kentucky', comment: 'Metadata allowed value' }),
                        value: 'US|KY',
                    },
                    {
                        text: t({ message: 'Louisiana', comment: 'Metadata allowed value' }),
                        value: 'US|LA',
                    },
                    {
                        text: t({ message: 'Maine', comment: 'Metadata allowed value' }),
                        value: 'US|ME',
                    },
                    {
                        text: t({ message: 'Maryland', comment: 'Metadata allowed value' }),
                        value: 'US|MD',
                    },
                    {
                        text: t({ message: 'Massachusetts', comment: 'Metadata allowed value' }),
                        value: 'US|MA',
                    },
                    {
                        text: t({ message: 'Michigan', comment: 'Metadata allowed value' }),
                        value: 'US|MI',
                    },
                    {
                        text: t({ message: 'Minnesota', comment: 'Metadata allowed value' }),
                        value: 'US|MN',
                    },
                    {
                        text: t({ message: 'Mississippi', comment: 'Metadata allowed value' }),
                        value: 'US|MS',
                    },
                    {
                        text: t({ message: 'Missouri', comment: 'Metadata allowed value' }),
                        value: 'US|MO',
                    },
                    {
                        text: t({ message: 'Montana', comment: 'Metadata allowed value' }),
                        value: 'US|MT',
                    },
                    {
                        text: t({ message: 'Nebraska', comment: 'Metadata allowed value' }),
                        value: 'US|NE',
                    },
                    {
                        text: t({ message: 'Nevada', comment: 'Metadata allowed value' }),
                        value: 'US|NV',
                    },
                    {
                        text: t({ message: 'New Hampshire', comment: 'Metadata allowed value' }),
                        value: 'US|NH',
                    },
                    {
                        text: t({ message: 'New Jersey', comment: 'Metadata allowed value' }),
                        value: 'US|NJ',
                    },
                    {
                        text: t({ message: 'New Mexico', comment: 'Metadata allowed value' }),
                        value: 'US|NM',
                    },
                    {
                        text: t({ message: 'New York', comment: 'Metadata allowed value' }),
                        value: 'US|NY',
                    },
                    {
                        text: t({ message: 'North Carolina', comment: 'Metadata allowed value' }),
                        value: 'US|NC',
                    },
                    {
                        text: t({ message: 'North Dakota', comment: 'Metadata allowed value' }),
                        value: 'US|ND',
                    },
                    {
                        text: t({ message: 'Ohio', comment: 'Metadata allowed value' }),
                        value: 'US|OH',
                    },
                    {
                        text: t({ message: 'Oklahoma', comment: 'Metadata allowed value' }),
                        value: 'US|OK',
                    },
                    {
                        text: t({ message: 'Oregon', comment: 'Metadata allowed value' }),
                        value: 'US|OR',
                    },
                    {
                        text: t({ message: 'Pennsylvania', comment: 'Metadata allowed value' }),
                        value: 'US|PA',
                    },
                    {
                        text: t({ message: 'Rhode Island', comment: 'Metadata allowed value' }),
                        value: 'US|RI',
                    },
                    {
                        text: t({ message: 'South Carolina', comment: 'Metadata allowed value' }),
                        value: 'US|SC',
                    },
                    {
                        text: t({ message: 'South Dakota', comment: 'Metadata allowed value' }),
                        value: 'US|SD',
                    },
                    {
                        text: t({ message: 'Tennessee', comment: 'Metadata allowed value' }),
                        value: 'US|TN',
                    },
                    {
                        text: t({ message: 'Texas', comment: 'Metadata allowed value' }),
                        value: 'US|TX',
                    },
                    {
                        text: t({ message: 'Utah', comment: 'Metadata allowed value' }),
                        value: 'US|UT',
                    },
                    {
                        text: t({ message: 'Vermont', comment: 'Metadata allowed value' }),
                        value: 'US|VT',
                    },
                    {
                        text: t({ message: 'Virginia', comment: 'Metadata allowed value' }),
                        value: 'US|VA',
                    },
                    {
                        text: t({ message: 'Washington', comment: 'Metadata allowed value' }),
                        value: 'US|WA',
                    },
                    {
                        text: t({ message: 'West Virginia', comment: 'Metadata allowed value' }),
                        value: 'US|WV',
                    },
                    {
                        text: t({ message: 'Wisconsin', comment: 'Metadata allowed value' }),
                        value: 'US|WI',
                    },
                    {
                        text: t({ message: 'Wyoming', comment: 'Metadata allowed value' }),
                        value: 'US|WY',
                    },
                ],
            },
            {
                text: t({ message: 'Uruguay', comment: 'Metadata allowed value' }),
                value: 'UY',
            },
            {
                text: t({ message: 'Uzbekistan', comment: 'Metadata allowed value' }),
                value: 'UZ',
            },
            {
                text: t({ message: 'Vanuatu', comment: 'Metadata allowed value' }),
                value: 'VU',
            },
            {
                text: t({ message: 'Venezuela', comment: 'Metadata allowed value' }),
                value: 'VE',
            },
            {
                text: t({ message: 'Vietnam', comment: 'Metadata allowed value' }),
                value: 'VN',
            },
            {
                text: t({ message: 'Virgin Islands (British)', comment: 'Metadata allowed value' }),
                value: 'VG',
            },
            {
                text: t({ message: 'Virgin Islands (U.S.)', comment: 'Metadata allowed value' }),
                value: 'VI',
            },
            {
                text: t({ message: 'Wallis and Futuna', comment: 'Metadata allowed value' }),
                value: 'WF',
            },
            {
                text: t({ message: 'Western Sahara', comment: 'Metadata allowed value' }),
                value: 'EH',
            },
            {
                text: t({ message: 'Yemen', comment: 'Metadata allowed value' }),
                value: 'YE',
            },
            {
                text: t({ message: 'Zambia', comment: 'Metadata allowed value' }),
                value: 'ZM',
            },
            {
                text: t({ message: 'Zimbabwe', comment: 'Metadata allowed value' }),
                value: 'ZW',
            },
        ],
    }),
    Active: () => ({
        name: 'Active',
        label: t({ message: 'Active', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    DeviateFromStandard: () => ({
        name: 'DeviateFromStandard',
        label: t({ message: 'DeviateFromStandard', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    TerminationDate: () => ({
        name: 'TerminationDate',
        label: t({ message: 'Termination Date', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Date,
    }),
    Currency: () => ({
        name: 'Currency',
        label: t({ message: 'Currency', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.List,
        allowedValues: [
            {
                text: t({ message: 'EUR', comment: 'Metadata allowed value' }),
                value: 'EUR',
            },
            {
                text: t({ message: 'GBP', comment: 'Metadata allowed value' }),
                value: 'GBP',
            },
            {
                text: t({ message: 'USD', comment: 'Metadata allowed value' }),
                value: 'USD',
            },
            {
                text: t({ message: 'AUD', comment: 'Metadata allowed value' }),
                value: 'AUD',
            },
            {
                text: t({ message: 'BRL', comment: 'Metadata allowed value' }),
                value: 'BRL',
            },
            {
                text: t({ message: 'CAD', comment: 'Metadata allowed value' }),
                value: 'CAD',
            },
            {
                text: t({ message: 'CHF', comment: 'Metadata allowed value' }),
                value: 'CHF',
            },
            {
                text: t({ message: 'CNY', comment: 'Metadata allowed value' }),
                value: 'CNY',
            },
            {
                text: t({ message: 'CZK', comment: 'Metadata allowed value' }),
                value: 'CZK',
            },
            {
                text: t({ message: 'DKK', comment: 'Metadata allowed value' }),
                value: 'DKK',
            },
            {
                text: t({ message: 'HKD', comment: 'Metadata allowed value' }),
                value: 'HKD',
            },
            {
                text: t({ message: 'HUF', comment: 'Metadata allowed value' }),
                value: 'HUF',
            },
            {
                text: t({ message: 'IDR', comment: 'Metadata allowed value' }),
                value: 'IDR',
            },
            {
                text: t({ message: 'INR', comment: 'Metadata allowed value' }),
                value: 'INR',
            },
            {
                text: t({ message: 'ISK', comment: 'Metadata allowed value' }),
                value: 'ISK',
            },
            {
                text: t({ message: 'JPY', comment: 'Metadata allowed value' }),
                value: 'JPY',
            },
            {
                text: t({ message: 'KRW', comment: 'Metadata allowed value' }),
                value: 'KRW',
            },
            {
                text: t({ message: 'KWD', comment: 'Metadata allowed value' }),
                value: 'KWD',
            },
            {
                text: t({ message: 'MAD', comment: 'Metadata allowed value' }),
                value: 'MAD',
            },
            {
                text: t({ message: 'MXN', comment: 'Metadata allowed value' }),
                value: 'MXN',
            },
            {
                text: t({ message: 'MYR', comment: 'Metadata allowed value' }),
                value: 'MYR',
            },
            {
                text: t({ message: 'NOK', comment: 'Metadata allowed value' }),
                value: 'NOK',
            },
            {
                text: t({ message: 'NZD', comment: 'Metadata allowed value' }),
                value: 'NZD',
            },
            {
                text: t({ message: 'PLN', comment: 'Metadata allowed value' }),
                value: 'PLN',
            },
            {
                text: t({ message: 'RUB', comment: 'Metadata allowed value' }),
                value: 'RUB',
            },
            {
                text: t({ message: 'SAR', comment: 'Metadata allowed value' }),
                value: 'SAR',
            },
            {
                text: t({ message: 'SEK', comment: 'Metadata allowed value' }),
                value: 'SEK',
            },
            {
                text: t({ message: 'SGD', comment: 'Metadata allowed value' }),
                value: 'SGD',
            },
            {
                text: t({ message: 'THB', comment: 'Metadata allowed value' }),
                value: 'THB',
            },
            {
                text: t({ message: 'ZAR', comment: 'Metadata allowed value' }),
                value: 'ZAR',
            },
        ],
    }),
    NoticePeriodMonths: () => ({
        name: 'NoticePeriodMonths',
        label: t({ message: 'NoticePeriodMonths', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Number,
    }),
    TotalContractCost: () => ({
        name: 'TotalContractCost',
        label: t({ message: 'TotalContractCost', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Number,
    }),
    NoticeDate: () => ({
        name: 'NoticeDate',
        label: t({ message: 'Notice Date', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Date,
    }),
    StartDate: () => ({
        name: 'StartDate',
        label: t({ message: 'StartDate', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Date,
    }),
    RenewalTermMonths: () => ({
        name: 'RenewalTermMonths',
        label: t({ message: 'RenewalTermMonths', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Number,
    }),
    DocumentType: () => ({
        name: 'DocumentType',
        label: t({ message: 'Document Type', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.List,
        allowedValues: [
            {
                text: t({ message: 'Ad Tech Supplier Agreement', comment: 'Metadata allowed value' }),
                value: 'ad_tech_supplier_agreement',
            },
            {
                text: t({ message: 'Advisory Agreement', comment: 'Metadata allowed value' }),
                value: 'advisory_agreement',
            },
            {
                text: t({ message: 'Annual General Meeting (AGM) Minutes', comment: 'Metadata allowed value' }),
                value: 'annual_general_meeting_agm_minutes',
            },
            {
                text: t({ message: 'Articles of Association', comment: 'Metadata allowed value' }),
                value: 'articles_of_association',
            },
            {
                text: t({ message: 'Assignment Agreement', comment: 'Metadata allowed value' }),
                value: 'assignment_agreement',
            },
            {
                text: t({ message: 'Board Meeting Minutes', comment: 'Metadata allowed value' }),
                value: 'board_meeting_minutes',
            },
            {
                text: t({ message: 'Board Resolution', comment: 'Metadata allowed value' }),
                value: 'board_resolution',
            },
            {
                text: t({ message: 'Certificate', comment: 'Metadata allowed value' }),
                value: 'certificate',
            },
            {
                text: t({ message: 'Collaboration Agreement', comment: 'Metadata allowed value' }),
                value: 'collaboration_agreement',
            },
            {
                text: t({ message: 'Consent (GDPR)', comment: 'Metadata allowed value' }),
                value: 'consent_gdpr',
            },
            {
                text: t({ message: 'Consultancy Agreement', comment: 'Metadata allowed value' }),
                value: 'consultancy_agreement',
            },
            {
                text: t({ message: 'Cookie Policy', comment: 'Metadata allowed value' }),
                value: 'cookie_policy',
            },
            {
                text: t({ message: 'Data Processing Agreement (DPA)', comment: 'Metadata allowed value' }),
                value: 'data_processing_agreement_dpa',
            },
            {
                text: t({ message: 'Debt Financing Document', comment: 'Metadata allowed value' }),
                value: 'debt_financing_document',
            },
            {
                text: t({ message: 'Director Loan Agreement', comment: 'Metadata allowed value' }),
                value: 'director_loan_agreement',
            },
            {
                text: t({ message: 'Director Resignation Letter', comment: 'Metadata allowed value' }),
                value: 'director_resignation_letter',
            },
            {
                text: t({ message: 'Distribution/Reseller Agreement', comment: 'Metadata allowed value' }),
                value: 'distribution_reseller_agreement',
            },
            {
                text: t({ message: 'Employment Agreement', comment: 'Metadata allowed value' }),
                value: 'employment_agreement',
            },
            {
                text: t({ message: 'Engagement Letter', comment: 'Metadata allowed value' }),
                value: 'engagement_letter',
            },
            {
                text: t({ message: 'Framework Agreement', comment: 'Metadata allowed value' }),
                value: 'framework_agreement',
            },
            {
                text: t({ message: 'Franchise Agreement', comment: 'Metadata allowed value' }),
                value: 'franchise_agreement',
            },
            {
                text: t({ message: 'HR Document', comment: 'Metadata allowed value' }),
                value: 'hr_document',
            },
            {
                text: t({ message: 'Investment Agreement', comment: 'Metadata allowed value' }),
                value: 'investment_agreement',
            },
            {
                text: t({ message: 'Investment Management Agreement', comment: 'Metadata allowed value' }),
                value: 'investment_management_agreement',
            },
            {
                text: t({ message: 'Joint Venture Agreement', comment: 'Metadata allowed value' }),
                value: 'joint_venture_agreement',
            },
            {
                text: t({ message: 'Lease Agreement', comment: 'Metadata allowed value' }),
                value: 'lease_agreement',
            },
            {
                text: t({ message: 'Letter of Intent (LOI)', comment: 'Metadata allowed value' }),
                value: 'letter_of_intent_loi',
            },
            {
                text: t({ message: 'License Agreement', comment: 'Metadata allowed value' }),
                value: 'license_agreement',
            },
            {
                text: t({ message: 'Limited Partnership Agreement (LPA)', comment: 'Metadata allowed value' }),
                value: 'limited_partnership_agreement_lpa',
            },
            {
                text: t({ message: 'Loan Agreement', comment: 'Metadata allowed value' }),
                value: 'loan_agreement',
            },
            {
                text: t({ message: 'Marketing Agreement', comment: 'Metadata allowed value' }),
                value: 'marketing_agreement',
            },
            {
                text: t({ message: 'Master Services Agreement (MSA)', comment: 'Metadata allowed value' }),
                value: 'master_services_agreement_msa',
            },
            {
                text: t({ message: 'Non-disclosure Agreement (NDA)', comment: 'Metadata allowed value' }),
                value: 'non_disclosure_agreement_nda',
            },
            {
                text: t({ message: 'Order Form', comment: 'Metadata allowed value' }),
                value: 'order_form',
            },
            {
                text: t({ message: 'Partnership Agreement', comment: 'Metadata allowed value' }),
                value: 'partnership_agreement',
            },
            {
                text: t({ message: 'Podcast Agreement', comment: 'Metadata allowed value' }),
                value: 'podcast_agreement',
            },
            {
                text: t({ message: 'Policy', comment: 'Metadata allowed value' }),
                value: 'policy',
            },
            {
                text: t({ message: 'Power of Attorney', comment: 'Metadata allowed value' }),
                value: 'power_of_attorney',
            },
            {
                text: t({ message: 'Privacy Policy', comment: 'Metadata allowed value' }),
                value: 'privacy_policy',
            },
            {
                text: t({ message: 'Private Placement Memorandum (PPM)', comment: 'Metadata allowed value' }),
                value: 'private_placement_memorandum_ppm',
            },
            {
                text: t({ message: 'Property Purchase Agreement', comment: 'Metadata allowed value' }),
                value: 'property_purchase_agreement',
            },
            {
                text: t({ message: 'Regulatory Filing Document', comment: 'Metadata allowed value' }),
                value: 'regulatory_filing_document',
            },
            {
                text: t({ message: 'Sales Agreement', comment: 'Metadata allowed value' }),
                value: 'sales_agreement',
            },
            {
                text: t({ message: 'Service Level Agreement (SLA)', comment: 'Metadata allowed value' }),
                value: 'service_level_agreement_sla',
            },
            {
                text: t({ message: 'Settlement Agreement', comment: 'Metadata allowed value' }),
                value: 'settlement_agreement',
            },
            {
                text: t({ message: 'Share Incentive Document', comment: 'Metadata allowed value' }),
                value: 'share_incentive_document',
            },
            {
                text: t({ message: 'Share Purchase Agreement (SPA)', comment: 'Metadata allowed value' }),
                value: 'share_purchase_agreement_spa',
            },
            {
                text: t({ message: 'Shareholder Resolution', comment: 'Metadata allowed value' }),
                value: 'shareholder_resolution',
            },
            {
                text: t({ message: 'Shareholders Agreement', comment: 'Metadata allowed value' }),
                value: 'shareholders_agreement',
            },
            {
                text: t({ message: 'Side Letter', comment: 'Metadata allowed value' }),
                value: 'side_letter',
            },
            {
                text: t({ message: 'Sponsorship Agreement', comment: 'Metadata allowed value' }),
                value: 'sponsorship_agreement',
            },
            {
                text: t({ message: 'Statement of Work (SoW)', comment: 'Metadata allowed value' }),
                value: 'statement_of_work_sow',
            },
            {
                text: t({ message: 'Subscription Agreement', comment: 'Metadata allowed value' }),
                value: 'subscription_agreement',
            },
            {
                text: t({ message: 'Supplier Agreement', comment: 'Metadata allowed value' }),
                value: 'supplier_agreement',
            },
            {
                text: t({ message: 'Term Sheet', comment: 'Metadata allowed value' }),
                value: 'term_sheet',
            },
            {
                text: t({ message: 'Termination Agreement', comment: 'Metadata allowed value' }),
                value: 'termination_agreement',
            },
            {
                text: t({ message: 'Terms & Conditions', comment: 'Metadata allowed value' }),
                value: 'terms_and_conditions',
            },
            {
                text: t({ message: 'Trademark Document', comment: 'Metadata allowed value' }),
                value: 'trademark_document',
            },
            {
                text: t({ message: 'Warranty Agreement', comment: 'Metadata allowed value' }),
                value: 'warranty_agreement',
            },
            {
                text: t({ message: 'Other', comment: 'Metadata allowed value' }),
                value: 'other',
            },
        ],
    }),
    OriginalTermMonths: () => ({
        name: 'OriginalTermMonths',
        label: t({ message: 'OriginalTermMonths', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Number,
    }),
    RelatedDocuments: () => ({
        name: 'RelatedDocuments',
        label: t({ message: 'RelatedDocuments', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Reference,
    }),
    ProcessingOfPersonalData: () => ({
        name: 'ProcessingOfPersonalData',
        label: t({ message: 'ProcessingOfPersonalData', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.List,
        allowedValues: [
            {
                text: t({ message: 'Controller', comment: 'Metadata allowed value' }),
                value: 'controller',
            },
            {
                text: t({ message: 'Processor', comment: 'Metadata allowed value' }),
                value: 'processor',
            },
            {
                text: t({ message: 'Joint Controller', comment: 'Metadata allowed value' }),
                value: 'joint_controller',
            },
        ],
    }),
    Outsourcing: () => ({
        name: 'Outsourcing',
        label: t({ message: 'Outsourcing', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    NoticePeriodDays: () => ({
        name: 'NoticePeriodDays',
        label: t({ message: 'NoticePeriodDays', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Number,
    }),
    LimitationOfLiability: () => ({
        name: 'LimitationOfLiability',
        label: t({ message: 'Limitation Of Liability', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    LiabilityCap: () => ({
        name: 'LiabilityCap',
        label: t({ message: 'Liability Cap', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Number,
    }),
    LiabilityCapScope: () => ({
        name: 'LiabilityCapScope',
        label: t({ message: 'Liability Cap Scope', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.List,
        allowedValues: [
            {
                text: t({ message: 'Capped', comment: 'Metadata allowed value' }),
                value: 'capped',
            },
            {
                text: t({ message: 'Uncapped', comment: 'Metadata allowed value' }),
                value: 'uncapped',
            },
            {
                text: t({ message: 'Conditionally Capped', comment: 'Metadata allowed value' }),
                value: 'conditionally_capped',
            },
        ],
    }),
    Indemnity: () => ({
        name: 'Indemnity',
        label: t({ message: 'Indemnity', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    TerminationForConvenience: () => ({
        name: 'TerminationForConvenience',
        label: t({ message: 'Termination For Convenience', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    TerminationForCause: () => ({
        name: 'TerminationForCause',
        label: t({ message: 'Termination For Cause', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    PaymentTermsDaysFromInvoiceDate: () => ({
        name: 'PaymentTermsDaysFromInvoiceDate',
        label: t({
            message: 'Payment Terms Days From Invoice Date',
            comment: 'Metadata property label',
        }),
        type: DocumentMetadataPropertyTypeEnum.Number,
    }),
    ProbationaryPeriod: () => ({
        name: 'ProbationaryPeriod',
        label: t({ message: 'Probationary Period', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    ProbationaryPeriodExpirationDate: () => ({
        name: 'ProbationaryPeriodExpirationDate',
        label: t({
            message: 'Probationary Period Expiration Date',
            comment: 'Metadata property label',
        }),
        type: DocumentMetadataPropertyTypeEnum.Date,
    }),
    Discount: () => ({
        name: 'Discount',
        label: t({ message: 'Discount', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    FeeAdjustmentClause: () => ({
        name: 'FeeAdjustmentClause',
        label: t({ message: 'Fee Adjustment Clause', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    PostTerminationObligations: () => ({
        name: 'PostTerminationObligations',
        label: t({ message: 'Post Termination Obligations', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.Boolean,
    }),
    DocumentCategory: () => ({
        name: 'DocumentCategory',
        label: t({ message: 'Document Category', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.List,
        allowedValues: [
            {
                text: t({ message: 'HR & Employment', comment: 'Metadata allowed value' }),
                value: 'hr_and_employment',
            },
            {
                text: t({ message: 'Compliance', comment: 'Metadata allowed value' }),
                value: 'compliance',
            },
            {
                text: t({ message: 'Data Protection', comment: 'Metadata allowed value' }),
                value: 'data_protection',
            },
            {
                text: t({ message: 'Commercial', comment: 'Metadata allowed value' }),
                value: 'commercial',
            },
            {
                text: t({ message: 'Property', comment: 'Metadata allowed value' }),
                value: 'property',
            },
            {
                text: t({ message: 'Intellectual Property Rights', comment: 'Metadata allowed value' }),
                value: 'intellectual_property_rights',
            },
            {
                text: t({ message: 'Insurance', comment: 'Metadata allowed value' }),
                value: 'insurance',
            },
            {
                text: t({ message: 'Disputes', comment: 'Metadata allowed value' }),
                value: 'disputes',
            },
            {
                text: t({ message: 'Finance & Accounting', comment: 'Metadata allowed value' }),
                value: 'finance_and_accounting',
            },
            {
                text: t({ message: 'Marketing', comment: 'Metadata allowed value' }),
                value: 'marketing',
            },
            {
                text: t({ message: 'Corporate, Investments and M&A', comment: 'Metadata allowed value' }),
                value: 'corporate_investments_and_ma',
            },
            {
                text: t({ message: 'Other', comment: 'Metadata allowed value' }),
                value: 'other',
            },
        ],
    }),
    Parties: () => ({
        name: 'Parties',
        label: t({ message: 'Parties', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.List,
    }),
    Country: () => ({
        name: 'Country',
        label: t({ message: 'Country', comment: 'Metadata property label' }),
        type: DocumentMetadataPropertyTypeEnum.List,
        allowedValues: [
            {
                text: t({ message: 'Afghanistan', comment: 'Metadata allowed value' }),
                value: 'AF',
            },
            {
                text: t({ message: 'Åland Islands', comment: 'Metadata allowed value' }),
                value: 'AX',
            },
            {
                text: t({ message: 'Albania', comment: 'Metadata allowed value' }),
                value: 'AL',
            },
            {
                text: t({ message: 'Algeria', comment: 'Metadata allowed value' }),
                value: 'DZ',
            },
            {
                text: t({ message: 'American Samoa', comment: 'Metadata allowed value' }),
                value: 'AS',
            },
            {
                text: t({ message: 'Andorra', comment: 'Metadata allowed value' }),
                value: 'AD',
            },
            {
                text: t({ message: 'Angola', comment: 'Metadata allowed value' }),
                value: 'AO',
            },
            {
                text: t({ message: 'Anguilla', comment: 'Metadata allowed value' }),
                value: 'AI',
            },
            {
                text: t({ message: 'Antarctica', comment: 'Metadata allowed value' }),
                value: 'AQ',
            },
            {
                text: t({ message: 'Antigua and Barbuda', comment: 'Metadata allowed value' }),
                value: 'AG',
            },
            {
                text: t({ message: 'Argentina', comment: 'Metadata allowed value' }),
                value: 'AR',
            },
            {
                text: t({ message: 'Armenia', comment: 'Metadata allowed value' }),
                value: 'AM',
            },
            {
                text: t({ message: 'Aruba', comment: 'Metadata allowed value' }),
                value: 'AW',
            },
            {
                text: t({ message: 'Australia', comment: 'Metadata allowed value' }),
                value: 'AU',
            },
            {
                text: t({ message: 'Austria', comment: 'Metadata allowed value' }),
                value: 'AT',
            },
            {
                text: t({ message: 'Azerbaijan', comment: 'Metadata allowed value' }),
                value: 'AZ',
            },
            {
                text: t({ message: 'Bahamas', comment: 'Metadata allowed value' }),
                value: 'BS',
            },
            {
                text: t({ message: 'Bahrain', comment: 'Metadata allowed value' }),
                value: 'BH',
            },
            {
                text: t({ message: 'Bangladesh', comment: 'Metadata allowed value' }),
                value: 'BD',
            },
            {
                text: t({ message: 'Barbados', comment: 'Metadata allowed value' }),
                value: 'BB',
            },
            {
                text: t({ message: 'Belarus', comment: 'Metadata allowed value' }),
                value: 'BY',
            },
            {
                text: t({ message: 'Belgium', comment: 'Metadata allowed value' }),
                value: 'BE',
            },
            {
                text: t({ message: 'Belize', comment: 'Metadata allowed value' }),
                value: 'BZ',
            },
            {
                text: t({ message: 'Benin', comment: 'Metadata allowed value' }),
                value: 'BJ',
            },
            {
                text: t({ message: 'Bermuda', comment: 'Metadata allowed value' }),
                value: 'BM',
            },
            {
                text: t({ message: 'Bhutan', comment: 'Metadata allowed value' }),
                value: 'BT',
            },
            {
                text: t({ message: 'Bolivia', comment: 'Metadata allowed value' }),
                value: 'BO',
            },
            {
                text: t({ message: 'Bonaire, Sint Eustatius and Saba', comment: 'Metadata allowed value' }),
                value: 'BQ',
            },
            {
                text: t({ message: 'Bosnia and Herzegovina', comment: 'Metadata allowed value' }),
                value: 'BA',
            },
            {
                text: t({ message: 'Botswana', comment: 'Metadata allowed value' }),
                value: 'BW',
            },
            {
                text: t({ message: 'Bouvet Island', comment: 'Metadata allowed value' }),
                value: 'BV',
            },
            {
                text: t({ message: 'Brazil', comment: 'Metadata allowed value' }),
                value: 'BR',
            },
            {
                text: t({ message: 'British Indian Ocean Territory', comment: 'Metadata allowed value' }),
                value: 'IO',
            },
            {
                text: t({ message: 'Brunei Darussalam', comment: 'Metadata allowed value' }),
                value: 'BN',
            },
            {
                text: t({ message: 'Bulgaria', comment: 'Metadata allowed value' }),
                value: 'BG',
            },
            {
                text: t({ message: 'Burkina Faso', comment: 'Metadata allowed value' }),
                value: 'BF',
            },
            {
                text: t({ message: 'Burundi', comment: 'Metadata allowed value' }),
                value: 'BI',
            },
            {
                text: t({ message: 'Cabo Verde', comment: 'Metadata allowed value' }),
                value: 'CV',
            },
            {
                text: t({ message: 'Cambodia', comment: 'Metadata allowed value' }),
                value: 'KH',
            },
            {
                text: t({ message: 'Cameroon', comment: 'Metadata allowed value' }),
                value: 'CM',
            },
            {
                text: t({ message: 'Canada', comment: 'Metadata allowed value' }),
                value: 'CA',
            },
            {
                text: t({ message: 'Cayman Islands', comment: 'Metadata allowed value' }),
                value: 'KY',
            },
            {
                text: t({ message: 'Central African Republic', comment: 'Metadata allowed value' }),
                value: 'CF',
            },
            {
                text: t({ message: 'Chad', comment: 'Metadata allowed value' }),
                value: 'TD',
            },
            {
                text: t({ message: 'Chile', comment: 'Metadata allowed value' }),
                value: 'CL',
            },
            {
                text: t({ message: 'China', comment: 'Metadata allowed value' }),
                value: 'CN',
            },
            {
                text: t({ message: 'Christmas Island', comment: 'Metadata allowed value' }),
                value: 'CX',
            },
            {
                text: t({ message: 'Cocos (Keeling) Islands', comment: 'Metadata allowed value' }),
                value: 'CC',
            },
            {
                text: t({ message: 'Colombia', comment: 'Metadata allowed value' }),
                value: 'CO',
            },
            {
                text: t({ message: 'Comoros', comment: 'Metadata allowed value' }),
                value: 'KM',
            },
            {
                text: t({ message: 'Congo', comment: 'Metadata allowed value' }),
                value: 'CD',
            },
            {
                text: t({ message: 'Congo', comment: 'Metadata allowed value' }),
                value: 'CG',
            },
            {
                text: t({ message: 'Cook Islands', comment: 'Metadata allowed value' }),
                value: 'CK',
            },
            {
                text: t({ message: 'Costa Rica', comment: 'Metadata allowed value' }),
                value: 'CR',
            },
            {
                text: t({ message: "Côte d'Ivoire", comment: 'Metadata allowed value' }),
                value: 'CI',
            },
            {
                text: t({ message: 'Croatia', comment: 'Metadata allowed value' }),
                value: 'HR',
            },
            {
                text: t({ message: 'Cuba', comment: 'Metadata allowed value' }),
                value: 'CU',
            },
            {
                text: t({ message: 'Curaçao', comment: 'Metadata allowed value' }),
                value: 'CW',
            },
            {
                text: t({ message: 'Cyprus', comment: 'Metadata allowed value' }),
                value: 'CY',
            },
            {
                text: t({ message: 'Czechia', comment: 'Metadata allowed value' }),
                value: 'CZ',
            },
            {
                text: t({ message: 'Denmark', comment: 'Metadata allowed value' }),
                value: 'DK',
            },
            {
                text: t({ message: 'Djibouti', comment: 'Metadata allowed value' }),
                value: 'DJ',
            },
            {
                text: t({ message: 'Dominica', comment: 'Metadata allowed value' }),
                value: 'DM',
            },
            {
                text: t({ message: 'Dominican Republic', comment: 'Metadata allowed value' }),
                value: 'DO',
            },
            {
                text: t({ message: 'Ecuador', comment: 'Metadata allowed value' }),
                value: 'EC',
            },
            {
                text: t({ message: 'Egypt', comment: 'Metadata allowed value' }),
                value: 'EG',
            },
            {
                text: t({ message: 'El Salvador', comment: 'Metadata allowed value' }),
                value: 'SV',
            },
            {
                text: t({ message: 'Equatorial Guinea', comment: 'Metadata allowed value' }),
                value: 'GQ',
            },
            {
                text: t({ message: 'Eritrea', comment: 'Metadata allowed value' }),
                value: 'ER',
            },
            {
                text: t({ message: 'Estonia', comment: 'Metadata allowed value' }),
                value: 'EE',
            },
            {
                text: t({ message: 'Eswatini', comment: 'Metadata allowed value' }),
                value: 'SZ',
            },
            {
                text: t({ message: 'Ethiopia', comment: 'Metadata allowed value' }),
                value: 'ET',
            },
            {
                text: t({ message: 'Falkland Islands', comment: 'Metadata allowed value' }),
                value: 'FK',
            },
            {
                text: t({ message: 'Faroe Islands', comment: 'Metadata allowed value' }),
                value: 'FO',
            },
            {
                text: t({ message: 'Fiji', comment: 'Metadata allowed value' }),
                value: 'FJ',
            },
            {
                text: t({ message: 'Finland', comment: 'Metadata allowed value' }),
                value: 'FI',
            },
            {
                text: t({ message: 'France', comment: 'Metadata allowed value' }),
                value: 'FR',
            },
            {
                text: t({ message: 'French Guiana', comment: 'Metadata allowed value' }),
                value: 'GF',
            },
            {
                text: t({ message: 'French Polynesia', comment: 'Metadata allowed value' }),
                value: 'PF',
            },
            {
                text: t({ message: 'French Southern Territories', comment: 'Metadata allowed value' }),
                value: 'TF',
            },
            {
                text: t({ message: 'Gabon', comment: 'Metadata allowed value' }),
                value: 'GA',
            },
            {
                text: t({ message: 'Gambia', comment: 'Metadata allowed value' }),
                value: 'GM',
            },
            {
                text: t({ message: 'Georgia', comment: 'Metadata allowed value' }),
                value: 'GE',
            },
            {
                text: t({ message: 'Germany', comment: 'Metadata allowed value' }),
                value: 'DE',
            },
            {
                text: t({ message: 'Ghana', comment: 'Metadata allowed value' }),
                value: 'GH',
            },
            {
                text: t({ message: 'Gibraltar', comment: 'Metadata allowed value' }),
                value: 'GI',
            },
            {
                text: t({ message: 'Greece', comment: 'Metadata allowed value' }),
                value: 'GR',
            },
            {
                text: t({ message: 'Greenland', comment: 'Metadata allowed value' }),
                value: 'GL',
            },
            {
                text: t({ message: 'Grenada', comment: 'Metadata allowed value' }),
                value: 'GD',
            },
            {
                text: t({ message: 'Guadeloupe', comment: 'Metadata allowed value' }),
                value: 'GP',
            },
            {
                text: t({ message: 'Guam', comment: 'Metadata allowed value' }),
                value: 'GU',
            },
            {
                text: t({ message: 'Guatemala', comment: 'Metadata allowed value' }),
                value: 'GT',
            },
            {
                text: t({ message: 'Guernsey', comment: 'Metadata allowed value' }),
                value: 'GG',
            },
            {
                text: t({ message: 'Guinea', comment: 'Metadata allowed value' }),
                value: 'GN',
            },
            {
                text: t({ message: 'Guinea-Bissau', comment: 'Metadata allowed value' }),
                value: 'GW',
            },
            {
                text: t({ message: 'Guyana', comment: 'Metadata allowed value' }),
                value: 'GY',
            },
            {
                text: t({ message: 'Haiti', comment: 'Metadata allowed value' }),
                value: 'HT',
            },
            {
                text: t({
                    message: 'Heard Island and McDonald Islands',
                    comment: 'Metadata allowed value',
                }),
                value: 'HM',
            },
            {
                text: t({ message: 'Holy See', comment: 'Metadata allowed value' }),
                value: 'VA',
            },
            {
                text: t({ message: 'Honduras', comment: 'Metadata allowed value' }),
                value: 'HN',
            },
            {
                text: t({ message: 'Hong Kong', comment: 'Metadata allowed value' }),
                value: 'HK',
            },
            {
                text: t({ message: 'Hungary', comment: 'Metadata allowed value' }),
                value: 'HU',
            },
            {
                text: t({ message: 'Iceland', comment: 'Metadata allowed value' }),
                value: 'IS',
            },
            {
                text: t({ message: 'India', comment: 'Metadata allowed value' }),
                value: 'IN',
            },
            {
                text: t({ message: 'Indonesia', comment: 'Metadata allowed value' }),
                value: 'ID',
            },
            {
                text: t({ message: 'Iran', comment: 'Metadata allowed value' }),
                value: 'IR',
            },
            {
                text: t({ message: 'Iraq', comment: 'Metadata allowed value' }),
                value: 'IQ',
            },
            {
                text: t({ message: 'Ireland', comment: 'Metadata allowed value' }),
                value: 'IE',
            },
            {
                text: t({ message: 'Isle of Man', comment: 'Metadata allowed value' }),
                value: 'IM',
            },
            {
                text: t({ message: 'Israel', comment: 'Metadata allowed value' }),
                value: 'IL',
            },
            {
                text: t({ message: 'Italy', comment: 'Metadata allowed value' }),
                value: 'IT',
            },
            {
                text: t({ message: 'Jamaica', comment: 'Metadata allowed value' }),
                value: 'JM',
            },
            {
                text: t({ message: 'Japan', comment: 'Metadata allowed value' }),
                value: 'JP',
            },
            {
                text: t({ message: 'Jersey', comment: 'Metadata allowed value' }),
                value: 'JE',
            },
            {
                text: t({ message: 'Jordan', comment: 'Metadata allowed value' }),
                value: 'JO',
            },
            {
                text: t({ message: 'Kazakhstan', comment: 'Metadata allowed value' }),
                value: 'KZ',
            },
            {
                text: t({ message: 'Kenya', comment: 'Metadata allowed value' }),
                value: 'KE',
            },
            {
                text: t({ message: 'Kiribati', comment: 'Metadata allowed value' }),
                value: 'KI',
            },
            {
                text: t({ message: 'Kuwait', comment: 'Metadata allowed value' }),
                value: 'KW',
            },
            {
                text: t({ message: 'Kyrgyzstan', comment: 'Metadata allowed value' }),
                value: 'KG',
            },
            {
                text: t({ message: "Lao People's Democratic Republic", comment: 'Metadata allowed value' }),
                value: 'LA',
            },
            {
                text: t({ message: 'Latvia', comment: 'Metadata allowed value' }),
                value: 'LV',
            },
            {
                text: t({ message: 'Lebanon', comment: 'Metadata allowed value' }),
                value: 'LB',
            },
            {
                text: t({ message: 'Lesotho', comment: 'Metadata allowed value' }),
                value: 'LS',
            },
            {
                text: t({ message: 'Liberia', comment: 'Metadata allowed value' }),
                value: 'LR',
            },
            {
                text: t({ message: 'Libya', comment: 'Metadata allowed value' }),
                value: 'LY',
            },
            {
                text: t({ message: 'Liechtenstein', comment: 'Metadata allowed value' }),
                value: 'LI',
            },
            {
                text: t({ message: 'Lithuania', comment: 'Metadata allowed value' }),
                value: 'LT',
            },
            {
                text: t({ message: 'Luxembourg', comment: 'Metadata allowed value' }),
                value: 'LU',
            },
            {
                text: t({ message: 'Macao', comment: 'Metadata allowed value' }),
                value: 'MO',
            },
            {
                text: t({ message: 'Madagascar', comment: 'Metadata allowed value' }),
                value: 'MG',
            },
            {
                text: t({ message: 'Malawi', comment: 'Metadata allowed value' }),
                value: 'MW',
            },
            {
                text: t({ message: 'Malaysia', comment: 'Metadata allowed value' }),
                value: 'MY',
            },
            {
                text: t({ message: 'Maldives', comment: 'Metadata allowed value' }),
                value: 'MV',
            },
            {
                text: t({ message: 'Mali', comment: 'Metadata allowed value' }),
                value: 'ML',
            },
            {
                text: t({ message: 'Malta', comment: 'Metadata allowed value' }),
                value: 'MT',
            },
            {
                text: t({ message: 'Marshall Islands', comment: 'Metadata allowed value' }),
                value: 'MH',
            },
            {
                text: t({ message: 'Martinique', comment: 'Metadata allowed value' }),
                value: 'MQ',
            },
            {
                text: t({ message: 'Mauritania', comment: 'Metadata allowed value' }),
                value: 'MR',
            },
            {
                text: t({ message: 'Mauritius', comment: 'Metadata allowed value' }),
                value: 'MU',
            },
            {
                text: t({ message: 'Mayotte', comment: 'Metadata allowed value' }),
                value: 'YT',
            },
            {
                text: t({ message: 'Mexico', comment: 'Metadata allowed value' }),
                value: 'MX',
            },
            {
                text: t({ message: 'Micronesia', comment: 'Metadata allowed value' }),
                value: 'FM',
            },
            {
                text: t({ message: 'Moldova', comment: 'Metadata allowed value' }),
                value: 'MD',
            },
            {
                text: t({ message: 'Monaco', comment: 'Metadata allowed value' }),
                value: 'MC',
            },
            {
                text: t({ message: 'Mongolia', comment: 'Metadata allowed value' }),
                value: 'MN',
            },
            {
                text: t({ message: 'Montenegro', comment: 'Metadata allowed value' }),
                value: 'ME',
            },
            {
                text: t({ message: 'Montserrat', comment: 'Metadata allowed value' }),
                value: 'MS',
            },
            {
                text: t({ message: 'Morocco', comment: 'Metadata allowed value' }),
                value: 'MA',
            },
            {
                text: t({ message: 'Mozambique', comment: 'Metadata allowed value' }),
                value: 'MZ',
            },
            {
                text: t({ message: 'Myanmar', comment: 'Metadata allowed value' }),
                value: 'MM',
            },
            {
                text: t({ message: 'Namibia', comment: 'Metadata allowed value' }),
                value: 'NA',
            },
            {
                text: t({ message: 'Nauru', comment: 'Metadata allowed value' }),
                value: 'NR',
            },
            {
                text: t({ message: 'Nepal', comment: 'Metadata allowed value' }),
                value: 'NP',
            },
            {
                text: t({ message: 'Netherlands', comment: 'Metadata allowed value' }),
                value: 'NL',
            },
            {
                text: t({ message: 'New Caledonia', comment: 'Metadata allowed value' }),
                value: 'NC',
            },
            {
                text: t({ message: 'New Zealand', comment: 'Metadata allowed value' }),
                value: 'NZ',
            },
            {
                text: t({ message: 'Nicaragua', comment: 'Metadata allowed value' }),
                value: 'NI',
            },
            {
                text: t({ message: 'Niger', comment: 'Metadata allowed value' }),
                value: 'NE',
            },
            {
                text: t({ message: 'Nigeria', comment: 'Metadata allowed value' }),
                value: 'NG',
            },
            {
                text: t({ message: 'Niue', comment: 'Metadata allowed value' }),
                value: 'NU',
            },
            {
                text: t({ message: 'Norfolk Island', comment: 'Metadata allowed value' }),
                value: 'NF',
            },
            {
                text: t({ message: 'North Korea', comment: 'Metadata allowed value' }),
                value: 'KP',
            },
            {
                text: t({ message: 'North Macedonia', comment: 'Metadata allowed value' }),
                value: 'MK',
            },
            {
                text: t({ message: 'Northern Mariana Islands', comment: 'Metadata allowed value' }),
                value: 'MP',
            },
            {
                text: t({ message: 'Norway', comment: 'Metadata allowed value' }),
                value: 'NO',
            },
            {
                text: t({ message: 'Oman', comment: 'Metadata allowed value' }),
                value: 'OM',
            },
            {
                text: t({ message: 'Pakistan', comment: 'Metadata allowed value' }),
                value: 'PK',
            },
            {
                text: t({ message: 'Palau', comment: 'Metadata allowed value' }),
                value: 'PW',
            },
            {
                text: t({ message: 'Palestine, State of', comment: 'Metadata allowed value' }),
                value: 'PS',
            },
            {
                text: t({ message: 'Panama', comment: 'Metadata allowed value' }),
                value: 'PA',
            },
            {
                text: t({ message: 'Papua New Guinea', comment: 'Metadata allowed value' }),
                value: 'PG',
            },
            {
                text: t({ message: 'Paraguay', comment: 'Metadata allowed value' }),
                value: 'PY',
            },
            {
                text: t({ message: 'Peru', comment: 'Metadata allowed value' }),
                value: 'PE',
            },
            {
                text: t({ message: 'Philippines', comment: 'Metadata allowed value' }),
                value: 'PH',
            },
            {
                text: t({ message: 'Pitcairn', comment: 'Metadata allowed value' }),
                value: 'PN',
            },
            {
                text: t({ message: 'Poland', comment: 'Metadata allowed value' }),
                value: 'PL',
            },
            {
                text: t({ message: 'Portugal', comment: 'Metadata allowed value' }),
                value: 'PT',
            },
            {
                text: t({ message: 'Puerto Rico', comment: 'Metadata allowed value' }),
                value: 'PR',
            },
            {
                text: t({ message: 'Qatar', comment: 'Metadata allowed value' }),
                value: 'QA',
            },
            {
                text: t({ message: 'Réunion', comment: 'Metadata allowed value' }),
                value: 'RE',
            },
            {
                text: t({ message: 'Romania', comment: 'Metadata allowed value' }),
                value: 'RO',
            },
            {
                text: t({ message: 'Russian Federation', comment: 'Metadata allowed value' }),
                value: 'RU',
            },
            {
                text: t({ message: 'Rwanda', comment: 'Metadata allowed value' }),
                value: 'RW',
            },
            {
                text: t({ message: 'Saint Barthélemy', comment: 'Metadata allowed value' }),
                value: 'BL',
            },
            {
                text: t({
                    message: 'Saint Helena, Ascension and Tristan da Cunha',
                    comment: 'Metadata allowed value',
                }),
                value: 'SH',
            },
            {
                text: t({ message: 'Saint Kitts and Nevis', comment: 'Metadata allowed value' }),
                value: 'KN',
            },
            {
                text: t({ message: 'Saint Lucia', comment: 'Metadata allowed value' }),
                value: 'LC',
            },
            {
                text: t({ message: 'Saint Martin (French part)', comment: 'Metadata allowed value' }),
                value: 'MF',
            },
            {
                text: t({ message: 'Saint Pierre and Miquelon', comment: 'Metadata allowed value' }),
                value: 'PM',
            },
            {
                text: t({ message: 'Saint Vincent and the Grenadines', comment: 'Metadata allowed value' }),
                value: 'VC',
            },
            {
                text: t({ message: 'Samoa', comment: 'Metadata allowed value' }),
                value: 'WS',
            },
            {
                text: t({ message: 'San Marino', comment: 'Metadata allowed value' }),
                value: 'SM',
            },
            {
                text: t({ message: 'Sao Tome and Principe', comment: 'Metadata allowed value' }),
                value: 'ST',
            },
            {
                text: t({ message: 'Saudi Arabia', comment: 'Metadata allowed value' }),
                value: 'SA',
            },
            {
                text: t({ message: 'Senegal', comment: 'Metadata allowed value' }),
                value: 'SN',
            },
            {
                text: t({ message: 'Serbia', comment: 'Metadata allowed value' }),
                value: 'RS',
            },
            {
                text: t({ message: 'Seychelles', comment: 'Metadata allowed value' }),
                value: 'SC',
            },
            {
                text: t({ message: 'Sierra Leone', comment: 'Metadata allowed value' }),
                value: 'SL',
            },
            {
                text: t({ message: 'Singapore', comment: 'Metadata allowed value' }),
                value: 'SG',
            },
            {
                text: t({ message: 'Sint Maarten (Dutch part)', comment: 'Metadata allowed value' }),
                value: 'SX',
            },
            {
                text: t({ message: 'Slovakia', comment: 'Metadata allowed value' }),
                value: 'SK',
            },
            {
                text: t({ message: 'Slovenia', comment: 'Metadata allowed value' }),
                value: 'SI',
            },
            {
                text: t({ message: 'Solomon Islands', comment: 'Metadata allowed value' }),
                value: 'SB',
            },
            {
                text: t({ message: 'Somalia', comment: 'Metadata allowed value' }),
                value: 'SO',
            },
            {
                text: t({ message: 'South Africa', comment: 'Metadata allowed value' }),
                value: 'ZA',
            },
            {
                text: t({
                    message: 'South Georgia and the South Sandwich Islands',
                    comment: 'Metadata allowed value',
                }),
                value: 'GS',
            },
            {
                text: t({ message: 'South Sudan', comment: 'Metadata allowed value' }),
                value: 'SS',
            },
            {
                text: t({ message: 'South Korea', comment: 'Metadata allowed value' }),
                value: 'KR',
            },
            {
                text: t({ message: 'Spain', comment: 'Metadata allowed value' }),
                value: 'ES',
            },
            {
                text: t({ message: 'Sri Lanka', comment: 'Metadata allowed value' }),
                value: 'LK',
            },
            {
                text: t({ message: 'Sudan', comment: 'Metadata allowed value' }),
                value: 'SD',
            },
            {
                text: t({ message: 'Suriname', comment: 'Metadata allowed value' }),
                value: 'SR',
            },
            {
                text: t({ message: 'Svalbard and Jan Mayen', comment: 'Metadata allowed value' }),
                value: 'SJ',
            },
            {
                text: t({ message: 'Sweden', comment: 'Metadata allowed value' }),
                value: 'SE',
            },
            {
                text: t({ message: 'Switzerland', comment: 'Metadata allowed value' }),
                value: 'CH',
            },
            {
                text: t({ message: 'Syrian Arab Republic', comment: 'Metadata allowed value' }),
                value: 'SY',
            },
            {
                text: t({ message: 'Taiwan (Province of China)', comment: 'Metadata allowed value' }),
                value: 'TW',
            },
            {
                text: t({ message: 'Tajikistan', comment: 'Metadata allowed value' }),
                value: 'TJ',
            },
            {
                text: t({ message: 'Tanzania', comment: 'Metadata allowed value' }),
                value: 'TZ',
            },
            {
                text: t({ message: 'Thailand', comment: 'Metadata allowed value' }),
                value: 'TH',
            },
            {
                text: t({ message: 'Timor-Leste', comment: 'Metadata allowed value' }),
                value: 'TL',
            },
            {
                text: t({ message: 'Togo', comment: 'Metadata allowed value' }),
                value: 'TG',
            },
            {
                text: t({ message: 'Tokelau', comment: 'Metadata allowed value' }),
                value: 'TK',
            },
            {
                text: t({ message: 'Tonga', comment: 'Metadata allowed value' }),
                value: 'TO',
            },
            {
                text: t({ message: 'Trinidad and Tobago', comment: 'Metadata allowed value' }),
                value: 'TT',
            },
            {
                text: t({ message: 'Tunisia', comment: 'Metadata allowed value' }),
                value: 'TN',
            },
            {
                text: t({ message: 'Türkiye', comment: 'Metadata allowed value' }),
                value: 'TR',
            },
            {
                text: t({ message: 'Turkmenistan', comment: 'Metadata allowed value' }),
                value: 'TM',
            },
            {
                text: t({ message: 'Turks and Caicos Islands', comment: 'Metadata allowed value' }),
                value: 'TC',
            },
            {
                text: t({ message: 'Tuvalu', comment: 'Metadata allowed value' }),
                value: 'TV',
            },
            {
                text: t({ message: 'Uganda', comment: 'Metadata allowed value' }),
                value: 'UG',
            },
            {
                text: t({ message: 'Ukraine', comment: 'Metadata allowed value' }),
                value: 'UA',
            },
            {
                text: t({ message: 'United Arab Emirates', comment: 'Metadata allowed value' }),
                value: 'AE',
            },
            {
                text: t({ message: 'United Kingdom', comment: 'Metadata allowed value' }),
                value: 'GB',
            },
            {
                text: t({
                    message: 'United States Minor Outlying Islands',
                    comment: 'Metadata allowed value',
                }),
                value: 'UM',
            },
            {
                text: t({ message: 'United States of America', comment: 'Metadata allowed value' }),
                value: 'US',
            },
            {
                text: t({ message: 'Uruguay', comment: 'Metadata allowed value' }),
                value: 'UY',
            },
            {
                text: t({ message: 'Uzbekistan', comment: 'Metadata allowed value' }),
                value: 'UZ',
            },
            {
                text: t({ message: 'Vanuatu', comment: 'Metadata allowed value' }),
                value: 'VU',
            },
            {
                text: t({ message: 'Venezuela', comment: 'Metadata allowed value' }),
                value: 'VE',
            },
            {
                text: t({ message: 'Vietnam', comment: 'Metadata allowed value' }),
                value: 'VN',
            },
            {
                text: t({ message: 'Virgin Islands (British)', comment: 'Metadata allowed value' }),
                value: 'VG',
            },
            {
                text: t({ message: 'Virgin Islands (U.S.)', comment: 'Metadata allowed value' }),
                value: 'VI',
            },
            {
                text: t({ message: 'Wallis and Futuna', comment: 'Metadata allowed value' }),
                value: 'WF',
            },
            {
                text: t({ message: 'Western Sahara', comment: 'Metadata allowed value' }),
                value: 'EH',
            },
            {
                text: t({ message: 'Yemen', comment: 'Metadata allowed value' }),
                value: 'YE',
            },
            {
                text: t({ message: 'Zambia', comment: 'Metadata allowed value' }),
                value: 'ZM',
            },
            {
                text: t({ message: 'Zimbabwe', comment: 'Metadata allowed value' }),
                value: 'ZW',
            },
        ],
    }),
};
/**
 * Function to create a config for metadata.
 */
function makeMetadataConfig() {
    return {
        get: (name) => {
            const property = metadataConfigObject[name];
            if (!property) {
                return undefined;
            }
            return property();
        },
        getAll() {
            const configArray = Object.values(metadataConfigObject).map((property) => property());
            return configArray;
        },
    };
}
export const metadataConfig = makeMetadataConfig();
