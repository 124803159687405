import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
const HEIGHT = pxToRem(48);
const ATTACHMENT_SELECTOR = '.document-attachment';
const ATTACHMENT_WRAPPER_SELECTOR = '.document-attachment__react-wrapper';
const SELECT_ATTACHMENT_SELECTOR = '.select-document-attachment';
const SELECT_ATTACHMENT_WRAPPER_SELECTOR = '.select-document-attachment__react-wrapper';
const fadeIn = keyframes `
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;
export const AttachmentPlaceholder = styled.div `
  width: 100%;
  height: ${HEIGHT};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${themeGet('colors.gray.50')};
`;
export const AttachmentContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEIGHT};
  padding: ${themeGet('spacing.lg')};
  border-radius: ${themeGet('borderRadius.md')};
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};
  box-shadow: ${themeGet('dropShadow.100')};

  ${(props) => props.$isEmbedded &&
    css `
      user-select: none;
    `}
`;
export const attachmentStyles = css `
  ${ATTACHMENT_WRAPPER_SELECTOR},
  ${SELECT_ATTACHMENT_WRAPPER_SELECTOR} {
    padding: ${themeGet('spacing.md')};
  }

  ${ATTACHMENT_SELECTOR},
  ${SELECT_ATTACHMENT_SELECTOR} {
    margin: ${themeGet('spacing.xl')} 0;
    animation: ${fadeIn} 250ms ease;
  }
`;
