import { Trans } from '@lingui/macro';
import { SearchResultsListItem } from '../../../SearchResultsListItem';
import { useUniversalSearch } from '../../useUniversalSearch';
import { Container, UppercaseText } from './styled';
export function Mobile() {
    var _a;
    const { data, closeSearchPopup } = useUniversalSearch();
    const { results = [] } = (_a = data === null || data === void 0 ? void 0 : data.search) !== null && _a !== void 0 ? _a : {};
    return (<Container>
      <UppercaseText $appearance="100" $fontSize="tiny" $fontWeight="semibold">
        <Trans comment="Global search: results header text">Results</Trans>
      </UppercaseText>
      {results.map((result) => (<SearchResultsListItem key={result.id} result={result} onClick={closeSearchPopup}/>))}
    </Container>);
}
