import { t } from '@lingui/macro';
import { FilterButton, MenuBody } from '@pocketlaw/tetris';
import { SearchableMenu, SearchableMenuSingleSelectList } from 'shared/domains/common-ui';
import { useFilter } from '../useFilter';
export function SingleSelectOperator(props) {
    var _a;
    const { name, items, selectedId = '' } = props;
    const filter = useFilter(name);
    const selectedTitle = (_a = items.find((item) => item.id === selectedId)) === null || _a === void 0 ? void 0 : _a.title;
    const handleOnChange = (item) => {
        filter.setValue({ [filter.operator]: item.value });
    };
    const fallbackTitle = t({
        message: 'Select value',
        comment: 'Search filter: Single select placeholder',
    });
    return (<SearchableMenu items={items} keys={['title']} placeholder={t({ message: 'Search', comment: 'Single select search input placeholder' })} initiallyOpen={!filter.hasValue} renderTarget={({ setOpen, open }) => (<FilterButton type="button" onClick={() => setOpen(!open)} appearance={filter.isTouched ? 'draft' : 'default'}>
          {selectedTitle || fallbackTitle}
        </FilterButton>)}>
      <MenuBody>
        <SearchableMenuSingleSelectList selected={selectedId} renderItem={({ title }) => title} onSelect={handleOnChange}/>
      </MenuBody>
    </SearchableMenu>);
}
