import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { useUploadFileSystemDocument, UploadFileSystemDocument } from 'app/domains/filesystem';
export function UploadButton() {
    const { uploadDocument } = useUploadFileSystemDocument();
    const history = useHistory();
    const navigateToDocument = (documents) => {
        var _a;
        const id = (_a = documents[0]) === null || _a === void 0 ? void 0 : _a.id;
        history.push(`/document/${id}/esigning`);
    };
    return (<Fragment>
      <Button appearance="secondary" onClick={uploadDocument}>
        <Trans comment="Dashboard: send for e-signing modal - upload button">Upload</Trans>
      </Button>
      <UploadFileSystemDocument onCreated={navigateToDocument} withRedirect={false}/>
    </Fragment>);
}
