var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { z } from 'zod';
import { useActiveDocument } from 'shared/domains/documents';
import { UpdateChatMessageContentFragment } from '../fragments';
import { useChatWithDocument } from '../useChatWithDocument';
import { GetDocumentChatsDocument } from '../useGetChats/query.gql';
const zServerSentMessage = z.tuple([z.string(), z.tuple([z.string(), z.string()])]);
export const useAddChatComment = () => {
    const { id: documentId } = useActiveDocument();
    const { cache } = useApolloClient();
    const [sendPromptToAssistant, { loading: sendingComment }] = useChatWithDocument();
    const [getLatestChatMessage] = useLazyQuery(GetDocumentChatsDocument);
    const [streamingResponse, setStreamingResponse] = useState(false);
    const handleStreamResponse = (assistantMessageId, streamToken) => {
        const CHAT_URL = process.env.ASSISTANT_CHAT_STREAM_URL;
        const evtSource = new EventSource(`${CHAT_URL}/chat/${streamToken}`);
        evtSource.onmessage = (event) => {
            setStreamingResponse(true);
            const [, [kind, content]] = zServerSentMessage.parse(JSON.parse(event.data));
            if (kind === 'MSG') {
                const cacheId = cache.identify({
                    __typename: 'ChatMessage',
                    id: assistantMessageId,
                });
                cache.updateFragment({
                    id: cacheId,
                    fragment: UpdateChatMessageContentFragment,
                }, (fragmentData) => (Object.assign(Object.assign({}, fragmentData), { content: !(fragmentData === null || fragmentData === void 0 ? void 0 : fragmentData.content) ? content : fragmentData.content + content })));
            }
        };
        // The server will close the connection after sending the end token, this is treated as
        // an error state by the EventSource, and we thus close the connection.
        evtSource.onerror = () => {
            evtSource.close();
            getLatestChatMessage({
                fetchPolicy: 'network-only',
                variables: {
                    documentId,
                    messagesLimit: 1,
                    messagesWhere: {
                        id: { in: [assistantMessageId] },
                    },
                },
            });
            setStreamingResponse(false);
        };
    };
    const addChatComment = (options) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const { chatId, input } = options;
        const { type, data, message, documentSelection } = input;
        const response = yield sendPromptToAssistant({
            data,
            type,
            chatId,
            message,
            selection: documentSelection,
        });
        const assistantMessage = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.lastChat.messages[0]) !== null && _b !== void 0 ? _b : null;
        if ((assistantMessage === null || assistantMessage === void 0 ? void 0 : assistantMessage.id) && assistantMessage.streamToken) {
            handleStreamResponse(assistantMessage.id, assistantMessage.streamToken);
        }
    });
    return { addChatComment, streamingResponse, sendingComment };
};
