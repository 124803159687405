import { RHContainer, RHContentContainer, RHContentInnerContainer, RHEditor, RHSidebar } from './styled';
export function RevisionHistoryEditor(props) {
    const { containerRef, editorRef, sidebarRef } = props;
    return (<RHContainer ref={containerRef}>
      <RHContentContainer>
        <RHContentInnerContainer>
          <RHEditor ref={editorRef}/>
          <RHSidebar ref={sidebarRef}/>
        </RHContentInnerContainer>
      </RHContentContainer>
    </RHContainer>);
}
