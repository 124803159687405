import { Children, Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ListContainer, ListTitle, StyledButton } from './styled';
const MAX_CHILDREN = 3;
export function SectionList(props) {
    const { title, children, endElement, hideTooManyItems = false } = props;
    const [showAll, setShowAll] = useState(false);
    const toggleShowAll = () => setShowAll((value) => !value);
    const childArray = Children.toArray(children);
    const childAmount = childArray.length;
    const strippedChildren = childArray.filter((_, index) => index < MAX_CHILDREN);
    const useNestedLayout = hideTooManyItems && childAmount > MAX_CHILDREN;
    return (<ListContainer>
      <ListTitle>
        <Typography $appearance="300">{title}</Typography>
        {endElement}
      </ListTitle>
      {useNestedLayout ? (<Fragment>
          {showAll ? children : strippedChildren}
          <StyledButton appearance="ghost" onClick={toggleShowAll}>
            {showAll ? (<Fragment>
                <Trans comment="Signatory accordion list showing all signing parties - Show less call to action button label">
                  Show less
                </Trans>
                <Falcon icon="chevron-up"/>
              </Fragment>) : (<Fragment>
                <Trans comment="Signatory accordion list showing a few signing parties - Show all call to action button label">
                  Show all ({childAmount})
                </Trans>
                <Falcon icon="chevron-down"/>
              </Fragment>)}
          </StyledButton>
        </Fragment>) : (children)}
    </ListContainer>);
}
