import { Trans, t } from '@lingui/macro';
import { Box, Typography } from '@pocketlaw/tetris';
import { ActivityEntry, ActivityEntryText } from 'shared/domains/common-ui';
import { Signatory } from './Signatory';
import { Viewer } from './Viewer';
import { getIcon } from './utils';
export function SignRequestCreated(props) {
    const { actor, timestamp, signatories, viewers } = props;
    const fallbackName = t({
        message: 'Unknown',
        comment: 'Fallback name when user does not exist',
    });
    const userName = (actor === null || actor === void 0 ? void 0 : actor.name) || fallbackName;
    function removeNull(value) {
        if (value === null) {
            return undefined;
        }
        return value;
    }
    const mappedSignatories = signatories
        .map((signatory) => {
        var _a;
        return ({
            name: (_a = signatory.name) !== null && _a !== void 0 ? _a : fallbackName,
            email: removeNull(signatory.email),
            mobile: removeNull(signatory.mobile),
            message: removeNull(signatory.message),
            authenticationMode: removeNull(signatory.authenticationMode),
            order: removeNull(signatory.order),
        });
    })
        .sort((a, b) => {
        if (a.order === undefined || b.order === undefined) {
            return 0;
        }
        return a.order - b.order;
    });
    const mappedViewers = viewers.map((viewer) => {
        var _a;
        return ({
            name: (_a = viewer.name) !== null && _a !== void 0 ? _a : fallbackName,
            email: removeNull(viewer.email),
            message: removeNull(viewer.message),
        });
    });
    return (<ActivityEntry appearance="warning" icon={getIcon('warning')} timestamp={timestamp} text={<ActivityEntryText>
          <Trans comment="Esigning request - User sent the document for eSigning">
            <b>{userName}</b> sent the document for eSigning
          </Trans>
        </ActivityEntryText>}>
      <Box flexDirection="column" gap="sm">
        <Typography $fontSize="tiny" $appearance="100">
          <Trans comment="Approval activity - Requested approval from">Signing parties:</Trans>
        </Typography>
        {mappedSignatories.map((signatory) => (<Signatory key={signatory.email} {...signatory}/>))}
        {mappedViewers.length > 0 && (<Typography $fontSize="tiny" $appearance="100">
            <Trans comment="Approval activity - Requested approval from">Non signing parties:</Trans>
          </Typography>)}
        {mappedViewers.map((viewer) => (<Viewer key={viewer.email} {...viewer}/>))}
      </Box>
    </ActivityEntry>);
}
