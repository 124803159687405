import { Fragment } from 'react';
import { Trans } from '@lingui/macro';
import { Loader } from '@pocketlaw/tetris';
import { Text } from '@pocketlaw/tetris/deprecated';
import { useRole } from 'app/domains/users/hooks/useRole';
import { getSortedProviders } from './utils';
import { EsigningProviderButton } from '../EsigningProviderButton';
import { MemberAlert } from '../MemberAlert';
import { NoProvidersAlert } from '../NoProvidersAlert';
export function EsigningProviderList(props) {
    const { loading, connecting, expired, providers, connectedProvider, selectedProviderId, onSelectProvider } = props;
    const role = useRole();
    if (loading) {
        return <Loader size={12}/>;
    }
    if (!role.can('manage:settings')) {
        return <MemberAlert expired={expired}/>;
    }
    if (!providers.length) {
        return <NoProvidersAlert />;
    }
    if (connectedProvider && connectedProvider.expired) {
        return (<EsigningProviderButton connected id={connectedProvider.id} disabled={connecting} selected={connectedProvider.id === selectedProviderId} onSelect={onSelectProvider}/>);
    }
    const sortedProviders = getSortedProviders(providers);
    return (<Fragment>
      <Text weight="bold">
        <Trans comment="E-signing providers label">Providers</Trans>
      </Text>
      {sortedProviders.map(({ id }) => (<EsigningProviderButton key={id} id={id} disabled={connecting} selected={id === selectedProviderId} connected={id === (connectedProvider === null || connectedProvider === void 0 ? void 0 : connectedProvider.id)} onSelect={onSelectProvider}/>))}
    </Fragment>);
}
