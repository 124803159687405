import { ChipAction } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useGetUsers } from 'app/domains/tasks/hooks';
import { ChipDropdown } from 'shared/domains/common-ui';
import { UserList } from './components/UserList';
import { formatLabel } from './utils/formatLabel';
export function UserFilter(props) {
    const { onChange, clear, label, value = [] } = props;
    const { users, loading, error } = useGetUsers();
    const handleSelect = (user, selected) => {
        if (selected) {
            const filteredUsers = value.filter((id) => user.id !== id);
            onChange(filteredUsers);
        }
        else {
            onChange([...value, user.id]);
        }
    };
    if (loading || error) {
        return null;
    }
    const selectedUsers = value
        .map((id) => users.find((user) => user.id === id))
        .filter((user) => !!user);
    return (<ChipDropdown loading={loading} label={formatLabel(label, selectedUsers)} action={Boolean(value.length) && (<ChipAction onClick={clear}>
            <Falcon icon="remove"/>
          </ChipAction>)} dropdownContent={<UserList selected={selectedUsers} users={users} onSelect={handleSelect}/>}/>);
}
