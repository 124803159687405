import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
const ICON_CONTAINER_SIZE = 32;
const getColorFromAppearance = (props) => {
    switch (props.$appearance) {
        case 'info':
            return themeGet('colors.blue.500');
        case 'warning':
            return themeGet('colors.orange.500');
        case 'success':
            return themeGet('colors.green.500');
        case 'danger':
            return themeGet('colors.red.500');
        case 'secondary':
        default:
            return themeGet('colors.gray.500');
    }
};
export const EntryLine = styled.div `
  flex: 1 1 auto;
  width: ${pxToRem(1)};
  height: 100%;
  background-color: ${themeGet('colors.border.100')};
`;
export const Container = styled.div `
  width: 100%;
  display: flex;
  gap: ${themeGet('spacing.md')};

  &:last-of-type {
    ${EntryLine} {
      display: none;
    }
  }
`;
export const LeftColumn = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const RightColumn = styled.div `
  max-width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: ${themeGet('spacing.2xl')};
`;
export const IconContainer = styled.div `
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(ICON_CONTAINER_SIZE)};
  height: ${pxToRem(ICON_CONTAINER_SIZE)};
  border-radius: ${themeGet('borderRadius.circle')};
  border: solid ${pxToRem(1)} ${themeGet('colors.border.100')};

  [data-icon='falcon'] {
    color: ${(props) => getColorFromAppearance(props)};
  }
`;
export const TextContainer = styled.div `
  display: flex;
  align-items: center;
  min-height: ${pxToRem(ICON_CONTAINER_SIZE)};
`;
export const MessageContainer = styled.div `
  line-height: 130%;
  width: fit-content;
  padding: ${themeGet('spacing.sm')} ${themeGet('spacing.lg')};
  border-radius: ${themeGet('borderRadius.md')};
  background-color: ${themeGet('colors.gray.50')};
`;
