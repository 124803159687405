import { pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const OuterPage = styled.div `
  position: relative;

  ${(props) => `
    width: ${pxToRem(props.$width)};
    height: ${pxToRem(props.$height)};
    margin: ${pxToRem(props.$spacing)} 0;
  `}
`;
export const Page = styled.div `
  display: flex;
  position: relative;
  transform-origin: 0 0;

  ${(props) => `
    transform: scale(${props.$zoom});
  `}
`;
