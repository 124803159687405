const splitRegexp = /\.|\[(\d+)\]/;

export default class Store {
  constructor(data) {
    this.cache = {};
    this.data = data;
  }

  get(key) {
    const cached = this.cache[key];

    if (typeof cached !== 'undefined') {
      return cached;
    }

    const path = key.split(splitRegexp).filter((value) => value && value.length);
    let value = this.data;

    for (let i = 0; i < path.length; i += 1) {
      value = value[path[i]];

      if (!value) {
        break;
      }
    }

    this.cache[key] = value;

    return value;
  }
}
