import { useQuery } from '@apollo/client';
import { useComposersTable } from 'app/domains/templates';
import { GetAccessByComposersDocument } from './GetAccessByComposers.gql';
import { filterUsersByAccess } from './utils';
import { filterTeamsByAccess } from './utils/filterUsersByAccess/filterByAccess';
function groupBy(array, key) {
    const group = array.reduce((acc, item) => {
        const groupKey = item[key];
        (acc[groupKey] = acc[groupKey] || []).push(item);
        return acc;
    }, {});
    return group;
}
export function useAccessByComposers() {
    var _a, _b;
    const { drawerData } = useComposersTable();
    const composerIds = (drawerData === null || drawerData === void 0 ? void 0 : drawerData.composerIds) || [];
    const query = useQuery(GetAccessByComposersDocument, {
        variables: { composerIds },
        fetchPolicy: 'no-cache',
    });
    const data = query.data || query.previousData;
    const { countComposers, companyUsers, composerPermissions, composerTeamPermissions, teams = [] } = data || {};
    const mappedComposerPermissions = Object.values(groupBy(composerPermissions !== null && composerPermissions !== void 0 ? composerPermissions : [], 'composerId'));
    const mappedComposerTeamPermissions = Object.values(groupBy(composerTeamPermissions !== null && composerTeamPermissions !== void 0 ? composerTeamPermissions : [], 'composerId'));
    const users = companyUsers === null || companyUsers === void 0 ? void 0 : companyUsers.map((companyUser) => {
        var _a;
        return ({
            id: companyUser.userId || '',
            name: ((_a = companyUser.user) === null || _a === void 0 ? void 0 : _a.name) || '',
            role: companyUser.role,
        });
    });
    const otherRoles = (users === null || users === void 0 ? void 0 : users.filter((user) => user.role !== 'owner')) || [];
    const ownerRoles = (users === null || users === void 0 ? void 0 : users.filter((user) => user.role === 'owner')) || [];
    const filteredUsersOnAccess = filterUsersByAccess({
        users: otherRoles,
        composers: mappedComposerPermissions,
        countComposers: (_a = countComposers === null || countComposers === void 0 ? void 0 : countComposers.count) !== null && _a !== void 0 ? _a : 0,
    });
    const filteredTeamsOnAccess = filterTeamsByAccess({
        teams,
        composers: mappedComposerTeamPermissions,
        countComposers: (_b = countComposers === null || countComposers === void 0 ? void 0 : countComposers.count) !== null && _b !== void 0 ? _b : 0,
    });
    return {
        owners: ownerRoles,
        accessTypeUsers: filteredUsersOnAccess,
        accessTypeTeams: filteredTeamsOnAccess,
        loading: query.loading,
        error: Boolean(query.error),
    };
}
