var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DocumentType } from 'shared/domains/apollo/generated/types';
import { ACTIVE_STATUSES } from 'shared/domains/documents';
import { SupportedFileMimeTypes } from 'shared/domains/filesystem';
import * as Sentry from 'shared/domains/sentry';
import { useDocxToPdf } from './useDocxToPdf';
import { useEntryLastSignRequestStatus } from './useEntryLastSignRequestStatus';
import { useRenderPlastToDocx } from './useRenderPlastToDocx';
export function useGetEntryFile() {
    const docxToPdf = useDocxToPdf();
    const renderPlastToDocx = useRenderPlastToDocx();
    const getEntryLastSignRequestStatus = useEntryLastSignRequestStatus();
    const handler = (entry) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f;
        const { id, name, type, mimeType } = entry;
        const status = yield getEntryLastSignRequestStatus(id);
        const isSentForEsigning = Boolean(status && ACTIVE_STATUSES.includes(status));
        switch (type) {
            case DocumentType.Composed:
            case DocumentType.Freetext:
                if (isSentForEsigning) {
                    const pdf = (_a = entry.files) === null || _a === void 0 ? void 0 : _a.find((file) => file.mimeType === SupportedFileMimeTypes.PDF);
                    const docx = (_b = entry.files) === null || _b === void 0 ? void 0 : _b.find((file) => file.mimeType === SupportedFileMimeTypes.DOCX);
                    if (pdf) {
                        return pdf;
                    }
                    if (docx) {
                        return docx;
                    }
                    const mimeTypes = (_c = entry.files) === null || _c === void 0 ? void 0 : _c.map((file) => file.mimeType).join(', ');
                    Sentry.captureMessage(`E-signing attachment: Composed fileSystemDocument (id: ${id}) has been sent to e-signing but we couldn't find any supported files attached to it. Found mimeTypes: ${mimeTypes}`);
                }
                return renderPlastToDocx({
                    name,
                    id,
                })
                    .then((files) => files === null || files === void 0 ? void 0 : files.find((file) => (file === null || file === void 0 ? void 0 : file.mimeType) === SupportedFileMimeTypes.DOCX))
                    .then((file) => docxToPdf(file))
                    .then((files) => files === null || files === void 0 ? void 0 : files[0])
                    .catch(Sentry.captureException);
            case DocumentType.Uploaded: {
                if (mimeType === SupportedFileMimeTypes.DOCX) {
                    const pdf = (_d = entry.files) === null || _d === void 0 ? void 0 : _d.find((file) => file.mimeType === SupportedFileMimeTypes.PDF);
                    const docx = (_e = entry.files) === null || _e === void 0 ? void 0 : _e.find((file) => file.mimeType === SupportedFileMimeTypes.DOCX);
                    if (pdf || isSentForEsigning) {
                        return pdf;
                    }
                    if (!docx) {
                        throw new Error("E-signing attachment: Uploaded Docx fileSystemDocument doesn't have any docx nor pdf files attached to it");
                    }
                    return docxToPdf(docx).then((files) => files === null || files === void 0 ? void 0 : files[0]);
                }
                if (mimeType === SupportedFileMimeTypes.PDF) {
                    return (_f = entry.files) === null || _f === void 0 ? void 0 : _f[0];
                }
                throw new Error(`E-signing attachment: Could not select entry in Attachment Browser as the following MIME type is not supported for Uploaded documents: ${mimeType}`);
            }
            default:
                throw new Error(`E-signing attachment: Could not select entry in Attachment Browser as the following DocumentType is not supported: ${type}`);
        }
    });
    return handler;
}
