import { useMutation } from '@apollo/client';
import { CHAT_WITH_DOCUMENT_REVIEW_MUTATION } from './mutation';
import { useChat } from '../useChat';
// Specialized chat hooks for review chat
export const useChatWithDocumentReview = (feedbackId) => {
    const [chatWithDocumentReview] = useMutation(CHAT_WITH_DOCUMENT_REVIEW_MUTATION);
    return useChat((messages) => chatWithDocumentReview({
        variables: {
            feedbackId,
            messages: messages.map(({ role, message }) => ({ role, message })),
        },
    }).then((res) => { var _a, _b, _c; return (_c = (_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.chatWithDocumentReview) === null || _b === void 0 ? void 0 : _b.token) !== null && _c !== void 0 ? _c : ''; }));
};
