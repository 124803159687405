import { useState } from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button } from '@pocketlaw/tetris';
import { CancelApprovalRequestDialog, useApprovalWorkflow } from 'app/domains/approval';
import { useSendReminderToApprovers } from 'app/domains/approval/hooks/useSendReminderToApprovers';
import { ApproverAlert } from './ApproverAlert';
import { DefaultAlert } from './DefaultAlert';
import { StatusBadge } from './StatusBadge';
import { Approver } from '../Approver';
import { ApproversHeader } from '../ApproversHeader';
import { Content } from '../Content';
export function ApprovalPending(props) {
    const { approvers } = props;
    const { sendReminder, loading: sendingReminder } = useSendReminderToApprovers();
    const { currentUser } = useApprovalWorkflow();
    const [renderDialog, setRenderDialog] = useState(false);
    const approversToRender = approvers.filter((approver) => approver.status !== null);
    const showDialog = () => setRenderDialog(true);
    const hideDialog = () => setRenderDialog(false);
    return (<Box flexDirection="column" height="100%">
      <Content>
        <Box flexDirection="column" gap="md">
          <ApproversHeader />
          {approversToRender.map((approver) => (<Approver key={approver.userId} name={approver.name} subtitle={approver.email} endElement={<StatusBadge status={approver.status}/>}/>))}
        </Box>
      </Content>
      <Box p="2xl" pt="0" mt="auto" flexDirection="column" gap="md">
        {currentUser.isApprover ? <ApproverAlert /> : <DefaultAlert />}
        <Box gap="md">
          <Button $stretch appearance="secondary" size="small" onClick={showDialog}>
            <Trans comment="Cancel approval request button label">Cancel request</Trans>
          </Button>
          <Button $stretch appearance="secondary" size="small" disabled={sendingReminder} onClick={sendReminder}>
            <Trans comment="Send approval request reminder button label">Send reminder</Trans>
          </Button>
        </Box>
      </Box>
      {renderDialog && <CancelApprovalRequestDialog origin="sidebar" onClose={hideDialog}/>}
    </Box>);
}
