export var SupportedFileMimeTypes;
(function (SupportedFileMimeTypes) {
    SupportedFileMimeTypes["PDF"] = "application/pdf";
    SupportedFileMimeTypes["DOCX"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    SupportedFileMimeTypes["PNG"] = "image/png";
    SupportedFileMimeTypes["JPEG"] = "image/jpeg";
})(SupportedFileMimeTypes || (SupportedFileMimeTypes = {}));
export var SupportedFileExtensions;
(function (SupportedFileExtensions) {
    SupportedFileExtensions["PDF"] = ".pdf";
    SupportedFileExtensions["DOCX"] = ".docx";
    SupportedFileExtensions["PNG"] = ".png";
    SupportedFileExtensions["JPEG"] = ".jpeg";
})(SupportedFileExtensions || (SupportedFileExtensions = {}));
