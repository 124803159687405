import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
const activeEsigningStates = [
    SignRequestStatus.Cancelling,
    SignRequestStatus.Creating,
    SignRequestStatus.Opened,
    SignRequestStatus.Pending,
    SignRequestStatus.Signed,
];
export function isSentForEsigning(docStatus) {
    if (!docStatus) {
        return false;
    }
    const match = activeEsigningStates.find((status) => docStatus === status);
    return Boolean(match);
}
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent#description
const UNSUPPORTED_NON_ESCAPED_CHARACTERS = ["'", '!', '~', '*', '(', ')'];
export const filenameRegexp = new RegExp(`[${UNSUPPORTED_NON_ESCAPED_CHARACTERS.join('')}]+`, 'g');
export const getFilename = (name) => name ? encodeURIComponent(name.replace(filenameRegexp, '')) : 'untitled';
