var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { SetDefaultAttachmentsDocument } from './mutation.gql';
export const useSaveDefaultAttachments = () => {
    const [mutate] = useMutation(SetDefaultAttachmentsDocument);
    const saveDefaultAttachments = (input) => __awaiter(void 0, void 0, void 0, function* () {
        return mutate({
            variables: {
                input: Object.assign(Object.assign({}, input), { attachments: input.attachments.map((attachment) => ({
                        path: attachment.path,
                        name: attachment.name,
                        type: attachment.type,
                    })) }),
            },
        });
    });
    return { saveDefaultAttachments };
};
