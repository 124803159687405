import { useQuery } from '@apollo/client';
import { query } from './query';
export function useCategories() {
    const { data, loading, error } = useQuery(query);
    const { categories } = data || {};
    const transformedCategories = categories === null || categories === void 0 ? void 0 : categories.map((category) => {
        const { metadata, id } = category;
        const [localized] = metadata || [];
        const { title } = localized;
        return {
            text: title,
            value: id,
        };
    });
    return { categories: transformedCategories || [], loading, error };
}
