import { useState } from 'react';
import { t } from '@lingui/macro';
import { AvatarWithLabel, Box, Loader, Menu, MenuBody, MenuItem, MenuList, pxToRem } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useGetPlaybooks } from './useGetPlaybooks';
import { StyledButton } from './styled';
export function SelectPlaybook(props) {
    const { selectedPlaybookId, onSelectPlaybook } = props;
    const { playbooks, loading } = useGetPlaybooks();
    const [open, setOpen] = useState(false);
    const selectedPlaybook = playbooks.find((playbook) => playbook.id === selectedPlaybookId);
    const handleOpenMenu = () => {
        if (!loading) {
            setOpen(true);
        }
    };
    const handleCloseMenu = () => setOpen(false);
    const handleSelectPlaybook = (id) => {
        handleCloseMenu();
        onSelectPlaybook(id);
    };
    const targetElement = (<StyledButton $stretch size="large" appearance="secondary" disabled={loading} onClick={handleOpenMenu}>
      <Box width="100%" alignItems="center" justifyContent="space-between">
        {selectedPlaybook ? (<AvatarWithLabel size="md" appearance="primary" title={selectedPlaybook.name} subtitle={t({
                comment: 'Document contract review - Review not started - Selected playbook label',
                message: 'Selected playbook',
            })}>
            <Falcon icon="book"/>
          </AvatarWithLabel>) : (<AvatarWithLabel size="md" appearance="secondary" title={t({
                message: 'No playbook selected',
                comment: 'Document contract review - Review not started - No playbook selected label',
            })}>
            <Falcon icon="book"/>
          </AvatarWithLabel>)}
        <Box width={pxToRem(32)} justifyContent="center">
          {loading ? <Loader size={8}/> : <Falcon icon="chevron-down"/>}
        </Box>
      </Box>
    </StyledButton>);
    return (<Box flexDirection="column" gap="md">
      <Menu closeOnOutsideClick open={open} onClose={handleCloseMenu} target={targetElement}>
        <MenuBody>
          <MenuList>
            {playbooks.map((playbook) => (<MenuItem key={playbook.id} onClick={() => handleSelectPlaybook(playbook.id)} $selected={selectedPlaybookId === playbook.id}>
                {playbook.name}
              </MenuItem>))}
          </MenuList>
        </MenuBody>
      </Menu>
    </Box>);
}
