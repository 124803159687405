import { ReviewFeedbackCriticality, ReviewFeedbackStatus } from 'shared/domains/apollo/generated/types';
export const getFeedbackType = (feedback) => {
    if (feedback.status === ReviewFeedbackStatus.Accepted) {
        return 'resolved';
    }
    if (feedback.status === ReviewFeedbackStatus.Discarded) {
        return 'rejected';
    }
    if (feedback.criticality === ReviewFeedbackCriticality.Critical) {
        return 'critical';
    }
    if (feedback.criticality === ReviewFeedbackCriticality.Notable) {
        return 'notable';
    }
    return 'minor';
};
