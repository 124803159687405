import { Fragment, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { plural } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { SelectFolder } from 'shared/domains/common-ui';
import { MAX_BULK_UPLOAD_FILES_AMOUNT, MAX_FILE_UPLOAD_SIZE_BYTES } from 'shared/domains/filesystem';
import { Preview } from './Preview';
import { UnsupportedPreview } from './UnsupportedPreview';
import { UploadAlert } from './UploadAlert';
import { getPreviewItems, isFolder, isFile, getAllFolderItems, isNotUndefined, updateFile } from './utils';
import { DropZone } from '../../DropZone';
import { useUpload } from '../UploadProvider';
import { Container, FilesContainer } from './styled';
export const FilesPreviewContext = createContext(null);
export function FilesPreview() {
    const { uploadError, setUploadError, setSelectedFiles, selectedFolder, updateSelectedFolder } = useUpload();
    const [fileMap, setFileMap] = useState({});
    const [folderMap, setFolderMap] = useState({});
    const [fileIdBeingEdited, setFileIdBeingEdited] = useState('');
    const previewFiles = Object.values(fileMap)
        .filter(isNotUndefined)
        .filter((file) => !file.parentId);
    const previewFolders = Object.values(folderMap)
        .filter(isNotUndefined)
        .filter((folder) => !folder.parentId);
    const previewItems = [...previewFiles, ...previewFolders];
    const allFiles = Object.values(fileMap).filter(isNotUndefined);
    const supportedFiles = allFiles.filter((file) => file.supported);
    const unsupportedFiles = allFiles.filter((file) => !file.supported);
    const updateSelectedFiles = (latestFileMap) => {
        const files = Object.values(latestFileMap)
            .filter(isNotUndefined)
            .filter((file) => file.supported)
            .map((file) => updateFile(file))
            .map((file) => file.originalFile);
        setSelectedFiles(files);
    };
    const totalFileSize = useMemo(() => {
        let totalSize = 0;
        allFiles.forEach((file) => {
            totalSize += file.size;
        });
        return totalSize;
    }, [allFiles]);
    const getUploadRestriction = useCallback(() => {
        if (totalFileSize > MAX_FILE_UPLOAD_SIZE_BYTES) {
            return 'files:size';
        }
        if (supportedFiles.length > MAX_BULK_UPLOAD_FILES_AMOUNT) {
            return 'files:amount';
        }
        return null;
    }, [supportedFiles.length, totalFileSize]);
    useEffect(() => {
        const uploadRestriction = getUploadRestriction();
        if (uploadRestriction !== uploadError) {
            setUploadError(uploadRestriction);
        }
    }, [getUploadRestriction, setUploadError, uploadError]);
    const handleChange = (files) => {
        const newMaps = getPreviewItems(files);
        const newFileMap = Object.assign(Object.assign({}, fileMap), newMaps.fileMap);
        setFileMap(newFileMap);
        updateSelectedFiles(newFileMap);
        setFolderMap((prevFolderMap) => (Object.assign(Object.assign({}, prevFolderMap), newMaps.folderMap)));
    };
    const getFile = (fileId) => fileMap[fileId];
    const getFolder = (folderId) => folderMap[folderId];
    const updateFileName = (fileId, updatedName) => {
        const file = getFile(fileId);
        if (!file) {
            return;
        }
        const updatedFile = updateFile(file, updatedName);
        const newFileMap = Object.assign(Object.assign({}, fileMap), { [fileId]: updatedFile });
        setFileMap(newFileMap);
        updateSelectedFiles(newFileMap);
    };
    const getFilteredMap = (map, idsToRemove) => Object.values(map).reduce((acc, item) => {
        if (!item) {
            return acc;
        }
        if (idsToRemove.includes(item.id)) {
            return acc;
        }
        return Object.assign(Object.assign({}, acc), { [item.id]: item });
    }, {});
    const removeFiles = (fileIds) => {
        const newFileMap = getFilteredMap(fileMap, fileIds);
        setFileMap(newFileMap);
        updateSelectedFiles(newFileMap);
    };
    const removeFolders = (folderIds) => {
        setFolderMap(getFilteredMap(folderMap, folderIds));
    };
    const removeItemFromParentFolder = (item) => {
        const parentFolder = getFolder(item.parentId || '');
        if (!parentFolder) {
            return;
        }
        const filteredChildren = parentFolder.children.filter((child) => child.id !== item.id);
        if (filteredChildren.length === 0) {
            removeFolders([parentFolder.id]);
        }
        else {
            setFolderMap((prevFolderMap) => (Object.assign(Object.assign({}, prevFolderMap), { [parentFolder.id]: Object.assign(Object.assign({}, parentFolder), { children: filteredChildren }) })));
        }
    };
    const removeFile = (fileId) => {
        const file = getFile(fileId);
        if (!file) {
            return;
        }
        if (file.parentId) {
            removeItemFromParentFolder(file);
        }
        removeFiles([file.id]);
    };
    const removeFolder = (folderId) => {
        const folder = getFolder(folderId);
        if (!folder) {
            return;
        }
        const allItems = getAllFolderItems(folder);
        const fileIds = allItems.filter(isFile).map(({ id }) => id);
        const folderIds = allItems.filter(isFolder).map(({ id }) => id);
        removeFiles(fileIds);
        removeFolders([folderId, ...folderIds]);
        removeItemFromParentFolder(folder);
    };
    const value = {
        fileIdBeingEdited,
        setFileIdBeingEdited,
        updateFileName,
        getFile,
        getFolder,
        removeFile,
        removeFolder,
    };
    const documentsAddedLabel = plural(supportedFiles.length, {
        one: '# document added',
        other: '# documents added',
    });
    return (<FilesPreviewContext.Provider value={value}>
      <Container>
        <DropZone onChange={handleChange}/>
        {previewItems.length > 0 && (<Fragment>
            <UploadAlert />
            <SelectFolder folderName={selectedFolder === null || selectedFolder === void 0 ? void 0 : selectedFolder.name} initialFolderId={selectedFolder === null || selectedFolder === void 0 ? void 0 : selectedFolder.id} onSelect={updateSelectedFolder}/>
            <FilesContainer>
              <Typography $fontSize="small" $fontWeight="medium">
                {documentsAddedLabel}
              </Typography>
              {previewItems.map((previewItem) => (<Preview key={previewItem.id} item={previewItem}/>))}
              <UnsupportedPreview files={unsupportedFiles}/>
            </FilesContainer>
          </Fragment>)}
      </Container>
    </FilesPreviewContext.Provider>);
}
