import { t, Trans } from '@lingui/macro';
import { ConfirmDialog, Avatar, Typography, useToast } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useUpload } from '../UploadProvider';
export function ConfirmCancelUploadDialog() {
    const { closeDialog, setRenderConfirmCancelDialog } = useUpload();
    const { addToast } = useToast();
    const handleCancel = () => setRenderConfirmCancelDialog(false);
    const handleConfirm = () => {
        closeDialog(true);
        addToast({
            appearance: 'info',
            title: t({
                comment: 'Cancel upload documents dialog - Toast title',
                message: 'Upload cancelled',
            }),
            message: t({
                comment: 'Cancel upload documents dialog - Toast message',
                message: 'The upload was cancelled',
            }),
        });
    };
    return (<ConfirmDialog media={<Avatar appearance="danger" shape="square" size="lg">
          <Falcon icon="xmark"/>
        </Avatar>} appearance="danger" size="sm" heading={t({
            comment: 'Cancel upload documents dialog - Header text',
            message: 'Cancel upload',
        })} confirm={t({
            comment: 'Cancel upload documents dialog - Confirm label',
            message: 'Cancel upload',
        })} cancel={t({ comment: 'Cancel upload documents dialog - Cancel label', message: 'Close' })} onConfirm={handleConfirm} onCancel={handleCancel}>
      <Typography $appearance="200">
        <Trans comment="Cancel upload documents dialog - Body text">Are you sure you want to cancel the upload?</Trans>
      </Typography>
    </ConfirmDialog>);
}
