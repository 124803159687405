var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { FileSystemDocumentStatus, FileSystemDocumentStatusExplicitEvents, } from 'shared/domains/apollo/generated/types';
import { DocumentStatusMutationDocument } from './DocumentStateMutation.gql';
const newStates = {
    [FileSystemDocumentStatusExplicitEvents.ExplicitlySetAgreed]: FileSystemDocumentStatus.AgreedStageAgreedForm,
    [FileSystemDocumentStatusExplicitEvents.ExplicitlySetSigned]: FileSystemDocumentStatus.SigningStageSigned,
    [FileSystemDocumentStatusExplicitEvents.ExplicitlySetDraft]: FileSystemDocumentStatus.DraftStage,
};
export function useSetDocumentStatus() {
    const { addToast } = useToast();
    const [mutate, { loading, error }] = useMutation(DocumentStatusMutationDocument);
    const setStatus = (data) => __awaiter(this, void 0, void 0, function* () {
        const dataArray = Array.isArray(data) ? data : [data];
        const response = yield Promise.allSettled(dataArray.map(({ event, documentId, cacheId, typename }) => {
            const newState = newStates[event];
            const selectedTypename = typename || 'FileSystemDocument';
            const cacheField = selectedTypename === 'FileSystemDocument' ? 'status' : 'metadataDocumentStatus';
            if (!newState) {
                return undefined;
            }
            return mutate({
                variables: { documentId, event },
                update(cache) {
                    cache.modify({
                        id: cache.identify({ id: cacheId || documentId, __typename: selectedTypename }),
                        fields: {
                            [cacheField]() {
                                return newState;
                            },
                        },
                    });
                },
            });
        }));
        const rejectedCount = response.filter((result) => result.status === 'rejected').length;
        const resolvedCount = response.filter((result) => result.status === 'fulfilled').length;
        if (rejectedCount) {
            addToast({
                title: t({
                    message: `Failed to update status for ${rejectedCount} document(s). Document status updated for ${resolvedCount} document(s)`,
                    comment: 'Toast message when document status update fails',
                }),
                appearance: 'danger',
            });
        }
        else {
            addToast({
                title: t({
                    message: `Document status updated for ${resolvedCount} document(s)`,
                    comment: 'Toast message when document status is updated',
                }),
                appearance: 'success',
            });
        }
    });
    return { setStatus, loading, error };
}
