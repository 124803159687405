import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { useLeaveDocumentPrompt } from 'app/domains/esigning/hooks/useLeaveDocumentPrompt';
import { SignRequestStatus } from 'shared/domains/apollo/generated/types';
import { documentFlush, usePreview } from 'shared/domains/documents';
import { removeSignRequestMutation } from './mutation';
import { GetDocumentAttachmentKindsDocument } from '../../queries';
export const useRemoveSignRequest = () => {
    const { lastSignRequest } = useLastSignRequest();
    const { id, status } = lastSignRequest !== null && lastSignRequest !== void 0 ? lastSignRequest : {};
    const { addToast } = useToast();
    const { isRtcAvailable, document } = usePreview();
    const [mutate, { error, loading }] = useMutation(removeSignRequestMutation, {
        variables: {
            id: id || '',
        },
        onError() {
            if (isRtcAvailable) {
                documentFlush({ state: 'not-initialized' });
            }
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Could not cancel e-signing request.',
                    comment: 'Message for when a user tried to cancel an e-signing request but something went wrong',
                }),
            });
        },
    });
    useLeaveDocumentPrompt({
        busy: loading,
        message: t({
            message: 'If you leave now your document might not be cancelled',
            comment: 'Message for when the users leaves a document sent for e-signing while it is being cancelled',
        }),
    });
    const removeSignRequest = () => {
        if (isRtcAvailable) {
            documentFlush({ state: 'initialized' });
        }
        return mutate({
            refetchQueries: [{ query: GetDocumentAttachmentKindsDocument, variables: { documentId: document.id } }],
        });
    };
    return {
        removeSignRequest,
        loading,
        error: Boolean(error || status === SignRequestStatus.Failed),
    };
};
