import { v4 } from 'uuid';
import { UNASSIGNED_SIGNING_ORDER } from '.';
export function nullToUndefined(value, fallbackValue) {
    if (value !== null && value !== undefined) {
        return value;
    }
    if (fallbackValue !== undefined) {
        return fallbackValue;
    }
    return value === null ? undefined : value;
}
export function getFormSignatoryFromDefaultSignatory(signatory) {
    const { id, email, firstName, lastName, title, label, mobile, authenticationMode } = signatory;
    const key = v4();
    return {
        id,
        key,
        email,
        name: `${firstName} ${lastName}`,
        firstName,
        lastName,
        label: nullToUndefined(label),
        title: nullToUndefined(title, ''),
        mobile: nullToUndefined(mobile),
        authenticationMode,
        isSigning: true,
        saveSignee: false,
        isDefault: true,
        order: UNASSIGNED_SIGNING_ORDER,
    };
}
export function getFormSignatoryFromDefaultCcRecipient(ccRecipient) {
    const { firstName, lastName, email, label } = ccRecipient;
    const key = v4();
    return {
        key,
        email,
        name: `${firstName} ${lastName}`,
        firstName: nullToUndefined(firstName),
        lastName: nullToUndefined(lastName),
        label: nullToUndefined(label),
        title: undefined,
        isSigning: false,
        saveSignee: false,
        isDefault: true,
    };
}
export function getFormSignatoryFromSignatory(signatory) {
    const { name, firstName, lastName, email, title, label, mobile, message, isDefault, authenticationMode, order } = signatory;
    const key = v4();
    return {
        key,
        email,
        name,
        firstName,
        lastName,
        label: nullToUndefined(label),
        title: nullToUndefined(title, ''),
        message: nullToUndefined(message),
        mobile: nullToUndefined(mobile),
        order: nullToUndefined(order),
        authenticationMode,
        isSigning: true,
        saveSignee: false,
        isDefault,
    };
}
export function getFormSignatoryFromCcRecipient(ccRecipient) {
    const { name, firstName, lastName, email, label, message, isDefault } = ccRecipient;
    const key = v4();
    return {
        key,
        email,
        name,
        firstName,
        lastName,
        label: nullToUndefined(label),
        message: nullToUndefined(message),
        title: undefined,
        isSigning: false,
        saveSignee: false,
        isDefault,
    };
}
