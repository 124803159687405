import { useState } from 'react';
import { t } from '@lingui/macro';
import { Box, FilterButton, Menu, MenuBody, TextInput } from '@pocketlaw/tetris';
import { useFilter } from '../useFilter';
export function NumberEqualsOperator(props) {
    var _a, _b;
    const { name } = props;
    const filter = useFilter(name);
    const [open, setOpen] = useState(filter.hasValue);
    const handleSetField = (ev) => {
        const { value } = ev.target;
        const numberValue = parseInt(value, 10);
        filter.setValue({
            eq: numberValue,
        });
    };
    return (<Menu open={open} onClose={() => setOpen(!open)} target={<FilterButton type="button" onClick={() => setOpen(!open)} appearance={filter.isTouched ? 'draft' : 'default'}>
          {((_a = filter.value) === null || _a === void 0 ? void 0 : _a.eq) || t({ message: 'Select value', comment: 'Search filter: Select value' })}
        </FilterButton>}>
      <MenuBody>
        <Box gap="md">
          <TextInput placeholder={t({ message: 'Equals', comment: 'Insights: Number range filter: Equals' })} type="number" value={((_b = filter.value) === null || _b === void 0 ? void 0 : _b.eq) || ''} onChange={handleSetField}/>
        </Box>
      </MenuBody>
    </Menu>);
}
