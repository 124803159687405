import { Trans } from '@lingui/macro';
import { Box, Button } from '@pocketlaw/tetris';
import { useMobile } from 'shared/domains/common-ui';
import { Labels } from './Labels';
import { Search } from './Search';
import { Shortcuts } from './Shortcuts';
import { usePromptLibrary } from '../../PromptLibraryProvider';
import { Container } from './styled';
export function Filters() {
    const isMobile = useMobile();
    const { setView } = usePromptLibrary();
    return (<Container>
      <Box flexDirection="column" gap="xl" p="2xl">
        <Button onClick={() => setView('edit')} appearance="primary" $stretch>
          <Trans comment="Open new prompt dialog">New prompt</Trans>
        </Button>
        <Search />
        <Shortcuts />
        {!isMobile && <Labels />}
      </Box>
    </Container>);
}
