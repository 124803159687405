import { pxToRem } from '@pocketlaw/tetris';
import { css } from 'styled-components';
const INDENT_WIDTH = pxToRem(80);
const olStartAttributeSelectors = Array.from(Array(20).keys())
    .map((i) => `ol[start='${i}'] { counter-set: item ${i - 1} }`)
    .join(',');
export const listStyles = css `
  .ck :is(ul, ol, li, menu, dl, dd) {
    margin: revert;
    padding: revert;
  }

  .ck.ck-content {
    ${olStartAttributeSelectors};

    > ol {
      padding-left: ${INDENT_WIDTH};
    }

    ol {
      list-style: none;
      list-style-position: outside;
      counter-reset: item;

      ol {
        padding-left: 0;

        > li {
          &:before {
            content: counters(item, '.');
          }
        }
      }

      & > li {
        position: relative;
        counter-increment: item;

        &:has(> h1:first-child)::before {
          font-size: ${pxToRem(24)};
          font-weight: bold;
        }

        &:has(> h2:first-child)::before {
          font-size: ${pxToRem(20)};
          font-weight: bold;
        }

        &:has(> h3:first-child)::before {
          font-size: ${pxToRem(16)};
          font-weight: bold;
        }

        &:has(> h4:first-child)::before {
          font-size: ${pxToRem(14)};
          font-weight: bold;
        }

        &:has(> span:first-child)::before,
        &:has(> p:first-child)::before {
          font-size: ${pxToRem(14)};
        }

        &:before {
          position: absolute;
          width: ${INDENT_WIDTH};
          display: block;
          transform: translateX(-${INDENT_WIDTH});
          content: counters(item, '.') '. ';
        }
      }

      table ol {
        counter-reset: nestedItem;

        > li {
          &:before {
            content: counters(nestedItem, '.') '.';
            counter-increment: nestedItem;
          }
        }
      }
    }
  }
`;
