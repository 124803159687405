import { useState } from 'react';
export function useItemSelect(items) {
    const [selectedItems, setSelectedItems] = useState([]);
    const selectItem = (id) => {
        const selectedItem = items.find((item) => item.id === id);
        if (selectedItem && !selectedItems.some((item) => item.id === id)) {
            setSelectedItems([...selectedItems, selectedItem]);
        }
    };
    const unselectItem = (id) => {
        setSelectedItems(selectedItems.filter((item) => item.id !== id));
    };
    const unselectedItems = items.filter((item) => !selectedItems.some(({ id }) => id === item.id));
    // return array instead of object to be able to name the variables as we want
    return [selectedItems, unselectedItems, selectItem, unselectItem];
}
