export function usePagination(options) {
    const { offset, limit, count, setOffset } = options;
    const padding = 3;
    const totalPages = Math.ceil(count / limit) || 1;
    const current = Math.floor(offset / limit);
    const allPages = [...Array(totalPages)]
        .map((_, index) => index)
        .map((index) => ({
        index,
        offset: index * limit,
        number: index + 1,
    }));
    const cursorStart = current - padding <= 0 ? 0 : current - padding;
    const cursorEnd = cursorStart + padding * 2 > totalPages ? totalPages : cursorStart + padding * 2;
    const pages = allPages.slice(cursorStart, cursorEnd);
    const gotoPage = (index) => {
        const { offset: newOffset } = allPages[index] || {};
        if (newOffset === undefined) {
            return;
        }
        setOffset(newOffset);
    };
    const gotoFirstPage = () => {
        setOffset(0);
    };
    const gotoLastPage = () => {
        if (count === 0 || count <= limit) {
            return;
        }
        gotoPage(allPages.length - 1);
    };
    const goto = (page) => {
        if (typeof page === 'number') {
            gotoPage(page);
        }
        if (page === 'first') {
            gotoFirstPage();
        }
        if (page === 'last') {
            gotoLastPage();
        }
    };
    return { totalPages, pages, goto, current };
}
