import { useId } from 'react';
import { Trans, t } from '@lingui/macro';
import { AvatarWithLabel, Button, Switch, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAutotagFeatureAvailability } from 'app/domains/metadata/hooks/useAutotagFeatureAvailability';
import { useGetLinks } from 'shared/domains/urls';
import { Container, Copy, Notice, Row, SwitchContainer } from './styled';
/**
 * Auto tag enable/disable UI component.
 * To be used in forms where a user can enable/disable auto tagging on save.
 */
export function AutoTagSwitchField(props) {
    const { enabled, onChange } = props;
    const { contact } = useGetLinks();
    const featureAvailability = useAutotagFeatureAvailability();
    const id = useId();
    const label = enabled
        ? t({ message: 'Enabled', comment: 'Metadata: Auto tag enabled on document save' })
        : t({ message: 'Disabled', comment: 'Metadata: Auto tag disabled on document save' });
    return (<Container $selected={enabled}>
      <Row as="label">
        <Copy>
          <Typography $fontSize="medium" $fontWeight="medium">
            <Trans comment="Save file: Auto tagging title">Auto tagging</Trans>
          </Typography>
          <Typography $fontSize="small" $appearance="200">
            <Trans comment="Save file: Auto tagging description" id={id} aria-hidden="true">
              Extract vital metadata to instantly categorize and smartly organize your content
            </Trans>
          </Typography>
        </Copy>

        <SwitchContainer>
          <Typography $fontSize="small" $fontWeight="medium" aria-hidden="true">
            {label}
          </Typography>
          <Switch role="switch" checked={enabled} aria-labelledby={id} disabled={!featureAvailability.data} onCheckedChange={onChange}/>
        </SwitchContainer>
      </Row>

      {!featureAvailability.data && (<Notice>
          <AvatarWithLabel appearance="assistant" title={t({
                message: 'Upgrade',
                comment: 'Metadata: Auto tag save composer upgrade CTA',
            })} subtitle={t({
                message: 'This is an enterprise feature.',
                comment: 'Metadata: Auto tag save composer feature availability subtitle',
            })} truncate={false}>
            <Falcon icon="circle-up"/>
          </AvatarWithLabel>

          <Button appearance="primary" size="small" as="a" href={contact} target="_blank" rel="noopener noreferrer">
            <Trans comment="Save document auto tag feature: Button triggering chat">Get feature</Trans>
          </Button>
        </Notice>)}
    </Container>);
}
