import { AvatarLetter, AvatarList } from '@pocketlaw/tetris';
import { useDraftPreview } from '../../../DraftPreviewProvider';
import { AvatarContainer } from './styled';
export function MetadataApprovers() {
    const { approvers: users, teamApprovers: teams } = useDraftPreview();
    const userAvatars = users.map((user) => {
        var _a, _b;
        return ({
            id: (_a = user === null || user === void 0 ? void 0 : user.id) !== null && _a !== void 0 ? _a : '',
            tooltip: { title: user === null || user === void 0 ? void 0 : user.name },
            component: <AvatarLetter>{((_b = user === null || user === void 0 ? void 0 : user.name) === null || _b === void 0 ? void 0 : _b[0]) || ''}</AvatarLetter>,
            appearance: 'cyan',
        });
    });
    const teamAvatars = teams.map((team) => {
        var _a, _b;
        return ({
            id: (_a = team === null || team === void 0 ? void 0 : team.id) !== null && _a !== void 0 ? _a : '',
            tooltip: { title: team === null || team === void 0 ? void 0 : team.name },
            component: <AvatarLetter>{((_b = team === null || team === void 0 ? void 0 : team.name) === null || _b === void 0 ? void 0 : _b[0]) || ''}</AvatarLetter>,
            appearance: 'magenta',
        });
    });
    const avatars = [...userAvatars, ...teamAvatars];
    if (avatars.length === 0) {
        return null;
    }
    return (<AvatarContainer>
      <AvatarList avatars={avatars} limit={10}/>
    </AvatarContainer>);
}
