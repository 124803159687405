import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { ConfirmDialog, IconButton, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useHistory } from 'react-router-dom';
import { useTeamRole } from 'app/domains/teams';
import { useRole } from 'app/domains/users';
import { useDeleteTeam } from '../../hooks/useDeleteTeam';
export function DeleteTeamButton(props) {
    const buttonLabel = t({
        message: 'Delete team',
        comment: 'Delete team button label (screen readers)',
    });
    const cancelLabel = t({
        message: 'Cancel',
        comment: 'delete team modal: Cancel button label',
    });
    const confirmLabel = t({
        message: 'Delete team',
        comment: 'delete team modal: delete button label',
    });
    const dialogTitle = t({
        message: 'Delete team',
        comment: 'delete team modal: title',
    });
    const { teamId } = props;
    const { removeTeam, loading } = useDeleteTeam();
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const role = useRole();
    const teamRole = useTeamRole(teamId);
    const canDeleteTeam = role.can('delete:team') || teamRole.can('delete:team');
    const handleConfirm = () => {
        removeTeam(teamId).then(({ errors }) => {
            if (!errors) {
                history.push('/users-teams?tab=teams');
            }
        });
    };
    if (!canDeleteTeam) {
        return null;
    }
    return (<Fragment>
      <IconButton aria-label={buttonLabel} appearance="secondary" onClick={() => setOpen(!open)}>
        <Falcon icon="trash"/>
      </IconButton>
      {open && (<ConfirmDialog cancel={cancelLabel} confirm={confirmLabel} onConfirm={handleConfirm} onCancel={() => setOpen(false)} loading={loading} heading={dialogTitle} size="sm" appearance="danger">
          <Typography>
            <Trans comment="Delete team dialog: copy">Are you sure you want to delete the team?</Trans>
          </Typography>
        </ConfirmDialog>)}
    </Fragment>);
}
