import { themeGet, fontSize, fontWeight } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Text = styled.span `
  display: inline-block;
  color: ${(props) => props.color || themeGet('colors.blue.500')};
  ${fontSize('tiny', 1.6)};
  ${fontWeight('bold')};

  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
`;
