import { useCompanyFeatures } from 'app/domains/companies';
import { ApprovalRequestApproverStatus, ApprovalRequestStatus, ApprovalType, } from 'shared/domains/apollo/generated/types';
import { Context } from './Context';
import { useDocumentApproval } from '../../hooks/useDocumentApproval';
import { useIsCurrentUserApprover } from '../../hooks/useIsCurrentUserApprover';
import { useLastApprovalRequest } from '../../hooks/useLastApprovalRequest';
const NOT_REQUESTED_STATUSES = [ApprovalRequestStatus.Cancelled, ApprovalRequestStatus.Rejected];
export function ApprovalWorkflowProvider(props) {
    var _a, _b;
    const { documentId, children } = props;
    const { documentApproval, loading: loadingApproval } = useDocumentApproval(documentId);
    const { lastApprovalRequest, loading: loadingLastRequest } = useLastApprovalRequest(documentId);
    const { isFeatureAvailable } = useCompanyFeatures();
    const currentUser = useIsCurrentUserApprover(documentId);
    const documentRequiresApproval = Boolean(documentApproval);
    const approvalWorkflowAvailable = isFeatureAvailable('approval');
    const approvalRequired = approvalWorkflowAvailable && documentRequiresApproval;
    const approvalRequestId = lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.id;
    const status = approvalRequired ? (lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.status) || null : null;
    // If a document does not require approval but has a pending approval request,
    // it means that the document is pending voluntary approval
    const approvalPendingVoluntary = !documentRequiresApproval &&
        Boolean(lastApprovalRequest) &&
        (lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.status) === ApprovalRequestStatus.Pending;
    // If a document does not require approval but has an approved approval request,
    // it means that the document is approved voluntarily
    const approvalApprovedVoluntary = !documentRequiresApproval &&
        Boolean(lastApprovalRequest) &&
        (lastApprovalRequest === null || lastApprovalRequest === void 0 ? void 0 : lastApprovalRequest.status) === ApprovalRequestStatus.Approved;
    // If a document does not require approval but has been submitted for voluntary approval,
    // it means that the document has an approval request type
    const approvalTypeVoluntary = approvalPendingVoluntary ? (_a = lastApprovalRequest.type) !== null && _a !== void 0 ? _a : undefined : undefined;
    const approvalType = ((_b = documentApproval === null || documentApproval === void 0 ? void 0 : documentApproval.type) !== null && _b !== void 0 ? _b : undefined) || approvalTypeVoluntary;
    const approvalPending = status === ApprovalRequestStatus.Pending || approvalPendingVoluntary;
    const approvalApproved = status === ApprovalRequestStatus.Approved || approvalApprovedVoluntary;
    const approvalLoading = loadingApproval || loadingLastRequest;
    const approvalNotRequested = approvalRequired && (status === null || NOT_REQUESTED_STATUSES.includes(status));
    const approvalRequested = approvalPending || approvalApproved;
    const { approvers = [], type } = lastApprovalRequest || {};
    const approvedApprovers = approvers.filter((approver) => approver && approver.status === ApprovalRequestApproverStatus.Approved);
    const approvalAmount = {
        current: approvedApprovers.length,
        max: type === ApprovalType.All ? approvers.length : 1,
    };
    const value = {
        approvalWorkflowAvailable,
        approvalNotRequested,
        approvalRequested,
        approvalPending,
        approvalApproved,
        approvalLoading,
        approvalRequestId,
        approvalType,
        approvalAmount,
        currentUser,
        documentId,
    };
    return <Context.Provider value={value}>{children}</Context.Provider>;
}
