import { Fragment, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Card, Box, Typography, Button } from '@pocketlaw/tetris';
import { PickFolderDialog } from '../pickers';
export function SelectFolder(props) {
    const { folderName, initialFolderId, disabled, onSelect } = props;
    const [renderDialog, setRenderDialog] = useState(false);
    const showDialog = () => setRenderDialog(true);
    const hideDialog = () => setRenderDialog(false);
    const handleSubmit = (folder) => {
        onSelect(folder);
        hideDialog();
    };
    return (<Fragment>
      <Card>
        <Box alignItems="center" p="xl">
          <Box flexDirection="column" mr="auto">
            <Typography $fontSize="medium" $appearance="300" $fontWeight="medium">
              <Trans comment="Select folder label: Location">Location</Trans>
            </Typography>
            <Typography $fontSize="small" $appearance="200">
              {folderName || (<Trans comment="Select folder description: No folder selected">No folder has been selected</Trans>)}
            </Typography>
          </Box>
          <Button size="small" type="button" appearance={folderName ? 'secondary' : 'primary'} disabled={disabled} onClick={showDialog}>
            {folderName ? (<Trans comment="Select folder: Change folder button label">Change folder</Trans>) : (<Trans comment="Select folder: Select folder button label">Select folder</Trans>)}
          </Button>
        </Box>
      </Card>
      {renderDialog && (<PickFolderDialog dialogTitle={t({
                comment: 'Choose folder dialog - Choose folder title',
                message: 'Choose folder',
            })} dialogSubtitle={t({
                comment: 'Choose folder dialog - Choose folder description',
                message: 'Choose where you want to save the document',
            })} submitLabel={t({
                comment: 'Choose folder dialog - Save here button',
                message: 'Save here',
            })} initialFolderId={initialFolderId} onSubmit={handleSubmit} onClose={hideDialog}/>)}
    </Fragment>);
}
