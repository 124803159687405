import { useCallback } from 'react';
import { produce } from 'immer';
import { useParams } from 'react-router-dom';

import { ROOT } from 'app/domains/filesystem';

export default function useCacheHandlers(updateEntries) {
  const { workspaceId, folderId = ROOT } = useParams();

  const createEntries = useCallback(
    (createdEntries) => {
      updateEntries(({ entries: cachedEntries }) => {
        const createdEntriesInPath = createdEntries.filter((createdEntry) => createdEntry.parentId === folderId);

        return {
          entries: createdEntriesInPath.concat(cachedEntries),
          touchedEntries: createdEntriesInPath.map((createdEntry) => createdEntry.id),
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateEntries, workspaceId, folderId],
  );

  const renameEntries = useCallback(
    (renamedEntries) => {
      updateEntries(({ entries: cachedEntries }) => ({
        touchedEntries: renamedEntries.map((renamedEntry) => renamedEntry.id),
        entries: produce(cachedEntries, (draft) => {
          renamedEntries.forEach((renamedEntry) => {
            const cachedEntry = draft.find(({ id }) => id === renamedEntry.id);
            cachedEntry.name = renamedEntry.name;
          });
        }),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateEntries],
  );

  const deleteEntries = useCallback(
    (deletedEntries) => {
      updateEntries(({ entries: cachedEntries }) => ({
        entries: cachedEntries.filter(
          (cachedEntry) => !deletedEntries.some((deletedEntry) => deletedEntry.id === cachedEntry.id),
        ),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateEntries],
  );

  return { createEntries, renameEntries, deleteEntries };
}
