var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { UNASSIGNED_SIGNING_ORDER } from 'app/domains/esigning/utils';
import { EsigningProviderAuthenticationModeSlug } from 'shared/domains/apollo/generated/types';
export const removeAuthenticationModeKey = (signatory) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { authenticationMode } = signatory, rest = __rest(signatory, ["authenticationMode"]);
    return rest;
};
export function removeNameFields(signatory, nameFormat) {
    const { name, firstName, lastName } = signatory, rest = __rest(signatory, ["name", "firstName", "lastName"]);
    if (nameFormat === 'fullName') {
        return Object.assign(Object.assign({}, rest), { name });
    }
    return Object.assign(Object.assign({}, rest), { firstName, lastName });
}
export const removeDefaultSignatory = (signatory) => !signatory.isDefault;
export const keepSigningDefaultSignatory = (signatory) => signatory.isDefault && signatory.isSigning;
export const transformDefaultSignatory = (signatory) => {
    const { id = '', order = -1 } = signatory;
    return { id, order };
};
export function cleanDataSignatory(user) {
    const { name, firstName, lastName, email, label, title, order, authenticationMode, mobile, message } = user;
    const data = {
        name,
        firstName,
        lastName,
        email,
        label,
        title,
        order,
        message,
        authenticationMode,
    };
    if (authenticationMode === EsigningProviderAuthenticationModeSlug.SmsOtp) {
        Object.assign(data, { mobile });
    }
    return data;
}
export function cleanDataCcRecipient(user) {
    const { name, firstName, lastName, email, label, message } = user;
    const data = {
        name,
        firstName,
        lastName,
        email,
        label,
        message,
    };
    return data;
}
export function transformSignatoryValue(signatory, nameFormat) {
    const { name, firstName, lastName, email, label, title, mobile, 
    // eslint-disable-next-line camelcase
    isSigning: is_signing, } = signatory;
    // eslint-disable-next-line camelcase
    const data = { email, label, title, is_signing, mobile };
    if (nameFormat === 'firstNameLastName') {
        return Object.assign(Object.assign({}, data), { firstname: firstName, lastname: lastName, name: `${firstName} ${lastName}` });
    }
    return Object.assign(Object.assign({}, data), { name });
}
export const getInitialSigningOrder = (signatories) => signatories
    .filter((s) => s.isSigning)
    .some((s) => s.order !== undefined && s.order !== null && (s === null || s === void 0 ? void 0 : s.order) > UNASSIGNED_SIGNING_ORDER);
