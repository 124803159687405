var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { Trans, t } from '@lingui/macro';
import { Alert, Box, Button, Dialog, Loader, Typography } from '@pocketlaw/tetris';
import { useFlushDocument } from 'shared/domains/documents';
import { useActiveDocument } from 'shared/domains/documents/hooks/usePreview';
import { usePleditor } from '../../../PleditorProvider';
import { useDocumentState } from '../../../documentState';
/**
 * This component will flush all document sessions on mount.
 * All pending actions will be saved before flushing.
 * To be used inside the editor.
 */
export function DocumentVersionFlush() {
    var _a;
    const { id } = useActiveDocument();
    const documentState = useDocumentState();
    const { pleditor } = usePleditor();
    const [flushDocument, flushMutationState] = useFlushDocument();
    const forceReload = () => {
        window.location.reload();
    };
    useEffect(() => {
        if (documentState !== 'out-of-date') {
            return undefined;
        }
        const countdownInterval = setInterval(() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const hasPendingActions = (_a = pleditor === null || pleditor === void 0 ? void 0 : pleditor.plugins.get('PendingActions')) === null || _a === void 0 ? void 0 : _a.hasAny;
            if (!hasPendingActions) {
                clearInterval(countdownInterval);
                yield flushDocument(id);
            }
        }), 1000);
        return () => clearInterval(countdownInterval);
    }, [documentState, flushDocument, id, pleditor === null || pleditor === void 0 ? void 0 : pleditor.plugins]);
    if (documentState !== 'out-of-date') {
        return null;
    }
    const hasPendingActions = (_a = pleditor === null || pleditor === void 0 ? void 0 : pleditor.plugins.get('PendingActions')) === null || _a === void 0 ? void 0 : _a.hasAny;
    return (<Dialog size="sm">
      <Box alignItems="center" justifyContent="center" p="xl" flexDirection="column" gap="md">
        <Loader size={14}/>
        <Typography>
          <Trans comment="Document editor version flush - Information">
            A new editor version is available. Please wait while we verify the editor.
          </Trans>
        </Typography>
        <Typography>
          {hasPendingActions && (<Trans comment="Document editor version flush - Saving document message">Saving document...</Trans>)}
          {flushMutationState.loading && (<Trans comment="Document editor version flush - Verifying editor message">Verifying editor...</Trans>)}
          {flushMutationState.error && (<Alert appearance="danger" message={t({
                message: 'Something went wrong, please click the button below to reload the page.',
                comment: 'Document editor version flush - Error message',
            })}/>)}
        </Typography>

        <Button appearance="secondary" size="small" onClick={forceReload}>
          <Trans>Force reload</Trans>
        </Button>
      </Box>
    </Dialog>);
}
