import { t } from '@lingui/macro';
import { TableHead, TableRow, TableHeaderText, VisuallyHidden } from '@pocketlaw/tetris';
import { StyledTableHeadCell } from '../../styled';
export function TeamMembersTableHead(props) {
    const { canRemoveTeamMembers } = props;
    return (<TableHead>
      <TableRow>
        <StyledTableHeadCell $showMobileOnly id="dropdown">
          <VisuallyHidden>
            {t({
            message: 'Expand',
            comment: 'Team members table header column name (expand tablerow mobile only. screen reader only)',
        })}
          </VisuallyHidden>
        </StyledTableHeadCell>
        <StyledTableHeadCell id="name">
          <TableHeaderText>{t({ message: 'Name', comment: 'Team members table header column name' })}</TableHeaderText>
        </StyledTableHeadCell>
        <StyledTableHeadCell $hideMobile id="userRole">
          <TableHeaderText>
            {t({ message: 'User role', comment: 'Team members table header column user role' })}
          </TableHeaderText>
        </StyledTableHeadCell>
        <StyledTableHeadCell $hideMobile id="teamrole">
          <TableHeaderText>
            {t({ message: 'Team role', comment: 'Team members table header column team role' })}
          </TableHeaderText>
        </StyledTableHeadCell>
        {canRemoveTeamMembers && (<StyledTableHeadCell id="actions">
            <VisuallyHidden>
              {t({
                message: 'Actions',
                comment: 'Team members table header column menu - screen reader text',
            })}
            </VisuallyHidden>
          </StyledTableHeadCell>)}
      </TableRow>
    </TableHead>);
}
