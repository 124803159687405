import React, { Fragment, useEffect, useRef } from 'react';
import { Trans } from '@lingui/macro';
import { Typography } from '@pocketlaw/tetris';
import { getSearchEntityLink } from 'app/domains/global-search/utils/getSearchEntityLink';
import { Date } from './Date';
import { Match } from './Match';
import { Type } from './Type';
import { useUniversalSearch } from '../../useUniversalSearch';
import { Container, StyledLink } from './styled';
export function Desktop() {
    var _a;
    const { data, closeSearchPopup, focusIndex, searchText, setFocusIndex } = useUniversalSearch();
    const { results = [] } = (_a = data === null || data === void 0 ? void 0 : data.search) !== null && _a !== void 0 ? _a : {};
    const sanitizedString = (searchText === null || searchText === void 0 ? void 0 : searchText.trim()) || '';
    const containerRef = useRef(null);
    const itemRefs = useRef([]);
    const handleScrollIntoView = (index) => {
        const container = containerRef.current;
        const item = itemRefs.current[index];
        if (container && item) {
            container.style.pointerEvents = 'none';
            item.scrollIntoView({
                behavior: 'auto',
                block: 'nearest',
                inline: 'nearest',
            });
            setTimeout(() => {
                container.style.pointerEvents = 'auto';
            }, 100);
        }
    };
    useEffect(() => {
        if (focusIndex !== null) {
            handleScrollIntoView(focusIndex);
        }
    }, [focusIndex]);
    const renderStyledLink = (index, item, isShowAll = false) => {
        const { id, entityId, name, createdAt, entityType, meta } = item || {};
        return (<StyledLink ref={(el) => {
                itemRefs.current[index] = el;
            }} key={id || 'show-all'} onMouseMove={() => {
                var _a;
                if (((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.style.pointerEvents) !== 'none') {
                    setFocusIndex(index);
                }
            }} onMouseLeave={() => {
                var _a;
                if (((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.style.pointerEvents) !== 'none') {
                    setFocusIndex(null);
                }
            }} to={isShowAll ? `/search?query=${sanitizedString}` : getSearchEntityLink(entityType, entityId)} onClick={closeSearchPopup} $highlighted={focusIndex === index}>
        {item && (<Fragment>
            <Match aria-describedby="search-dropdown-result" name={name} type={entityType} highlight={meta === null || meta === void 0 ? void 0 : meta.highlight} createdAt={createdAt}/>
            <Type aria-describedby="search-column-type" type={entityType}/>
            <Date aria-describedby="search-column-date" createdAt={createdAt}/>
          </Fragment>)}
        {isShowAll && (<Typography $fontWeight="medium" $appearance="300">
            <Trans comment="Global search: Show all results link">Show all results</Trans>
          </Typography>)}
      </StyledLink>);
    };
    return (<Container ref={containerRef}>
      {results.map((item, index) => renderStyledLink(index, item))}
      {renderStyledLink(results.length, null, true)}
    </Container>);
}
