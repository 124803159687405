import { useLingui } from '@lingui/react';
import { getUserRoleName } from 'app/domains/users';
import { AclAction } from 'shared/domains/apollo/generated/types';
import useAccount from 'shared/domains/users/hooks/useAccount';
export function useNormalizeUser(params) {
    const { userAcls, parentUserAcls } = params;
    const account = useAccount();
    const currentUserId = account.data.userId || '';
    const { i18n } = useLingui();
    // eslint-disable-next-line no-underscore-dangle
    const _i18n = i18n;
    const normalizeUser = (companyUser) => {
        var _a, _b;
        const userAcl = userAcls.find((acl) => acl.userId === companyUser.userId);
        const isSelf = currentUserId === companyUser.userId;
        const { role, canManageMember } = companyUser;
        const untranslatedRoleName = getUserRoleName({ role, canManageMember });
        return {
            id: companyUser.userId || '',
            name: (_a = companyUser.user) === null || _a === void 0 ? void 0 : _a.name,
            email: (_b = companyUser.user) === null || _b === void 0 ? void 0 : _b.email,
            role: companyUser.role,
            roleName: _i18n._(untranslatedRoleName),
            companyUserId: companyUser.id,
            aclId: isSelf ? undefined : userAcl === null || userAcl === void 0 ? void 0 : userAcl.id,
            hasAccessToParentFolder: parentUserAcls.some((acl) => acl.userId === companyUser.userId && !acl.actions.includes(AclAction.Traverse)),
            hasTraverseAccess: userAcl === null || userAcl === void 0 ? void 0 : userAcl.actions.includes(AclAction.Traverse),
            isSelf,
        };
    };
    return { normalizeUser };
}
