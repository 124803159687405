import { createContext } from 'react';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { useGetCompanyFeatures } from './useGetCompanyFeatures';
export const CompanyFeaturesContext = createContext(null);
export function CompanyFeaturesProvider(props) {
    const { children } = props;
    const { data } = useAccount();
    const { companyId } = data || {};
    const { features, loading, error } = useGetCompanyFeatures(companyId);
    const isFeatureAvailable = (feature) => features.includes(feature);
    const value = {
        error,
        loading,
        features,
        isFeatureAvailable,
    };
    return <CompanyFeaturesContext.Provider value={value}>{children}</CompanyFeaturesContext.Provider>;
}
