import { themeGetColor } from '@pocketlaw/tetris';
const COLORS = [
    'gray',
    'blue',
    'indigo',
    'purple',
    'magenta',
    'red',
    'orange',
    'brown',
    'yellow',
    'green',
    'teal',
    'cyan',
];
const VALUES = ['100', '200', '300', '400', '500', '600', '700'];
const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);
const getComputedColor = (varKey) => {
    const style = getComputedStyle(document.body);
    const color = varKey.replace('var(', '').replace(')', '');
    const value = style.getPropertyValue(color);
    return value;
};
export const getColorPickerColors = () => VALUES.flatMap((value, index) => COLORS.map((color) => ({
    color: getComputedColor(themeGetColor(`${color}.${value}`)),
    label: `${capitalizeFirstLetter(color)} ${index + 1}`,
})));
