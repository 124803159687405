import { TeamMemberRole } from 'shared/domains/apollo/generated/types';
const actions = new Map([
    [
        TeamMemberRole.Manager,
        ['delete:team', 'invite:team_member', 'update:team', 'update:team_member', 'delete:team_member'],
    ],
    [TeamMemberRole.Member, []],
]);
export function getTeamRoleActions(teamRole) {
    if (!teamRole) {
        return [];
    }
    return actions.get(teamRole);
}
