import { t } from '@lingui/macro';
import { object, array, string, boolean } from 'yup';

import { useGetSignatorySchema } from 'app/domains/esigning/components/ESignSignatoryFormDialog';
import { UNASSIGNED_SIGNING_ORDER } from 'app/domains/esigning/utils';

export const INVALID_SIGNING_ORDER_ERROR = 'invalid-signing-order-error';

const settings = object().shape({
  useSigningOrder: boolean(),
  useGroupMessage: boolean(),
});

const globals = object().shape({
  groupMessage: string(),
});

const makeSignatories = (signatorySchema) =>
  array()
    .of(signatorySchema)
    .min(1, t`You must add at least one signatory`)
    .test('min-signatory', t`You must add at least one signing party`, (signatories) => {
      const hasSigningParty = signatories.some(({ isSigning }) => isSigning);

      return hasSigningParty;
    })
    .test('invalid-order', INVALID_SIGNING_ORDER_ERROR, (signatories, context) => {
      const hasValidOrderForSigningParties = signatories
        .filter(({ isSigning }) => isSigning)
        .every(({ order }) => order > UNASSIGNED_SIGNING_ORDER);

      const ignoreSigningOrder = !context.parent.settings.useSigningOrder;

      return ignoreSigningOrder || hasValidOrderForSigningParties;
    });

export function useMakeValidationSchema() {
  const signatorySchema = useGetSignatorySchema();
  const signatories = makeSignatories(signatorySchema);

  return object().shape({ settings, signatories, globals });
}
