import { useMemo, useState, createElement, memo, Fragment, useRef } from 'react';
import { t, Trans } from '@lingui/macro';
import { Tabs, Tab, LoaderOverlay } from '@pocketlaw/tetris';
import rehype2react from 'rehype-react';
import rehypeStringify from 'rehype-stringify';
import { unified } from 'unified';
import { Div, Root, Section, Span, useBranchValues, usePreviewWorker } from 'app/domains/templates';
import { useMobile } from 'shared/domains/common-ui';
import { TemplatePreview } from 'shared/domains/documents';
import { highlight, generateVariableValues, useDeepFieldRefs } from './utils';
import { useDraftPreview } from '../../../DraftPreviewProvider';
import { getHtmlFromMarkdown } from '../../../utils';
import { TemplateData } from '../../TemplateData';
import { MetadataEntry } from '../../TemplateData/MetadataEntry';
import { StyledTabContent, StyledTabNav } from './styled';
const getProcessor = () => unified()
    .use(highlight)
    // things left after js -> ts conversion, feel free to type correctly
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    .use(rehype2react, {
    Fragment: Root,
    createElement,
    components: {
        section: Section,
        div: Div,
        span: Span,
        h5: 'h4',
        h6: 'h4',
        'pl-highlight': 'pl-highlight',
    },
});
export const PreviewTabs = memo(() => {
    const { selectedTemplate, composer, loading } = useDraftPreview();
    const { descriptionBody, tree } = composer;
    const { fields } = tree !== null && tree !== void 0 ? tree : {};
    const { latestVersion } = selectedTemplate || {};
    const { astBody: templateBody } = latestVersion || {};
    const keys = useDeepFieldRefs(fields);
    const variableValues = useMemo(() => generateVariableValues(keys), [keys]);
    const values = useBranchValues(tree, variableValues);
    const isMobile = useMobile();
    const processor = useRef(getProcessor());
    const workerInput = useMemo(() => ({
        keys,
        values,
        astBody: templateBody,
    }), [keys, templateBody, values]);
    const worker = usePreviewWorker(workerInput);
    const html = useMemo(() => (worker.data ? processor.current().runSync(worker.data) : null), [worker.data, processor]);
    const stringifyProcessor = unified().use(rehypeStringify);
    const htmlString = html ? stringifyProcessor().stringify(html) : '';
    const [activeTab, setActiveTab] = useState('preview:description');
    const handleSelectTab = (tab) => {
        setActiveTab(tab);
    };
    return (<Tabs activeTab={activeTab} onSelect={handleSelectTab}>
      <StyledTabNav>
        <Tab id="preview:description">
          <Trans comment="Label for template description tab">Description</Trans>
        </Tab>
        <Tab id="preview:preview">
          <Trans comment="Label for template preview tab">Preview</Trans>
        </Tab>
      </StyledTabNav>
      <StyledTabContent tabId="preview:description">
        {isMobile && (<Fragment>
            <TemplateData />
            <MetadataEntry label={t({
                comment: 'Preview template - Description label (mobile only)',
                message: 'Description',
            })} component={null}/>
          </Fragment>)}
        <LoaderOverlay enabled={loading}>
          {descriptionBody && <TemplatePreview html={getHtmlFromMarkdown(descriptionBody)}/>}
        </LoaderOverlay>
      </StyledTabContent>
      <StyledTabContent tabId="preview:preview">
        <LoaderOverlay enabled={loading}>{htmlString && <TemplatePreview html={htmlString}/>}</LoaderOverlay>
      </StyledTabContent>
    </Tabs>);
});
