import { pxToRem } from '@pocketlaw/tetris';
import styled from 'styled-components';
import { TREE_BRANCH_COLOR, TREE_NODE_ICON_SIZE, TREE_NODE_MARGIN, TREE_NODE_MARGIN_HALF } from '../constants';
export const HorizontalLine = styled.div `
  position: absolute;
  left: calc(${TREE_NODE_ICON_SIZE} / 2);
  background-color: ${TREE_BRANCH_COLOR};
  top: calc(${TREE_NODE_ICON_SIZE} / 2);
  height: ${pxToRem(1)};
  width: calc(${TREE_NODE_ICON_SIZE} / 4 + ${TREE_NODE_MARGIN});
`;
export const Branch = styled.div `
  position: absolute;
  margin: ${TREE_NODE_MARGIN_HALF};
  width: ${TREE_NODE_ICON_SIZE};
  height: ${TREE_NODE_ICON_SIZE};
  left: -${TREE_NODE_ICON_SIZE};
  top: 0;
  display: none;

  ul ul & {
    display: block;
  }
`;
