import { useMemo } from 'react';
import { t } from '@lingui/macro';
import { CONTINUE, visit } from 'unist-util-visit';
const isTopLevelGroupField = (field) => 
// eslint-disable-next-line no-underscore-dangle
(field === null || field === void 0 ? void 0 : field.__typename) === 'GroupField' && 'fields' in field;
const isMultipleChoiceField = (field) => 
// eslint-disable-next-line no-underscore-dangle
field.__typename === 'MultipleChoiceField';
const getChildFieldRefs = (choice, parent) => `${parent.refId}.${choice.refId}`;
const deepPluckFieldRefs = (field) => {
    if (isTopLevelGroupField(field)) {
        return field.fields.flatMap((childField) => {
            if (isMultipleChoiceField(childField)) {
                return childField.choices.map((choice) => getChildFieldRefs(choice, childField));
            }
            return childField.refId;
        });
    }
    if (!(field === null || field === void 0 ? void 0 : field.refId)) {
        return [];
    }
    return [field.refId];
};
export const useDeepFieldRefs = (fields) => useMemo(() => (fields === null || fields === void 0 ? void 0 : fields.flatMap((field) => deepPluckFieldRefs(field))) || [], [fields]);
const isHighlightNode = (node) => Boolean(node &&
    typeof node === 'object' &&
    'properties' in node &&
    node.properties &&
    typeof node.properties === 'object' &&
    'data-markstache-key' in node.properties);
export function highlight() {
    return function transform(root) {
        function visitor(node) {
            if (isHighlightNode(node)) {
                // eslint-disable-next-line no-param-reassign
                node.properties['data-highlight'] = `${node.properties['data-markstache-key']}:variable`;
                // eslint-disable-next-line no-param-reassign
                delete node.properties['data-markstache-key'];
                // eslint-disable-next-line no-param-reassign
                node.tagName = 'pl-highlight';
            }
            return CONTINUE;
        }
        visit(root, 'element', visitor);
    };
}
export const generateVariableValues = (keys) => {
    const userInputPlaceholder = t({
        message: '[user input]',
        comment: 'Template modal preview variable text',
    });
    const variableValues = {};
    keys.forEach((key) => {
        const keySplitByDots = key.split('.');
        if (key.includes('.') && !variableValues[keySplitByDots[0]]) {
            variableValues[keySplitByDots[0]] = {
                // Always pick the first encountered condition to be true
                // This way we simulate the user to have answered the first available answer
                [keySplitByDots[1]]: true,
            };
        }
        else {
            variableValues[key] = userInputPlaceholder;
        }
    });
    return variableValues;
};
