import { PageHeading, margin, padding, fontSize, fontWeight, marginBottom, pxToRem, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  ${padding(6, 6, 8)};
  background-color: ${themeGet('colors.gray.0')};
  box-shadow: 0 -${pxToRem(8)} ${pxToRem(10)} 0 rgba(0, 0, 0, 0.06);
`;
export const Content = styled.div `
  width: 100%;
  max-width: ${pxToRem(500)};
  margin: auto;
`;
export const Header = styled.div `
  display: flex;
  align-items: center;
  ${marginBottom(1)};
`;
export const Title = styled(PageHeading) `
  ${fontSize('infrequent', 1.28)}
  ${fontWeight('semibold')};
`;
export const Copy = styled.p `
  ${margin(0, 0, 6)};
  ${fontSize('small', 1.7)};

  a {
    color: currentColor;

    &:hover {
      color: ${themeGet('colors.primary.500')};
    }
  }
`;
