import { marginRight, themeGet, unit } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  position: relative;
  color: ${(props) => (props.$answered ? 'inherit' : themeGet('colors.gray.100'))};

  &::before {
    ${marginRight(1)};
    position: absolute;
    content: '';
    top: 0;
    right: 100%;
    width: ${unit(2)};
    border-radius: ${unit(1)};
    height: 100%;
    background-color: ${(props) => (props.$answered ? themeGet('colors.yellow.200') : themeGet('colors.gray.50'))};
  }
`;
