import { useQuery } from '@apollo/client';
import { AclAction, CompanyRoleEnum } from 'shared/domains/apollo/generated/types';
import { useActiveDocument } from 'shared/domains/documents';
import { QUERY } from './query';
import { userIdRegex } from './utils';
export function useGetUserMentionsWithoutAccess() {
    const { id } = useActiveDocument();
    const { data, loading } = useQuery(QUERY, {
        variables: {
            id,
        },
    });
    const { entry, companyUsers = [], comments = [] } = data || {};
    const { acls = [] } = entry || {};
    const openComments = comments.filter(({ resolved }) => !resolved);
    const ownersUserIds = companyUsers.filter(({ role }) => role === CompanyRoleEnum.Owner).map(({ user }) => user === null || user === void 0 ? void 0 : user.id);
    const otherCompanyUserIdsWithAccess = acls
        .filter(({ actions }) => actions.includes(AclAction.Read))
        .map(({ userId }) => userId);
    const allCompanyUsersIdsWithAccess = [...ownersUserIds, ...otherCompanyUserIdsWithAccess];
    const companyUserIdsMentionned = [
        ...new Set(openComments
            .flatMap(({ body, replies }) => [
            ...(body.match(userIdRegex) || []),
            ...replies.flatMap(({ body: replyBody }) => replyBody.match(userIdRegex)),
        ])
            .filter(Boolean)),
    ];
    const companyUserIdsMentionedWithoutAccess = companyUserIdsMentionned.filter((userId) => userId && !allCompanyUsersIdsWithAccess.includes(userId));
    const companyUsersMentionedWithoutAccess = companyUsers.filter(({ user }) => (user === null || user === void 0 ? void 0 : user.id) && companyUserIdsMentionedWithoutAccess.includes(user.id));
    return { loading, usersMentionedWithoutAccess: companyUsersMentionedWithoutAccess };
}
