export function makeConfig() {
    const locales = new Map([]);
    const defaultLocale = 'en-gb';
    let activeLocale;
    const addLocale = (locale) => {
        const { code } = locale;
        locales.set(code, locale);
    };
    const getLocaleByCode = (code) => {
        const fallback = locales.get(defaultLocale);
        const selected = locales.get(code || '');
        if (!selected) {
            return fallback;
        }
        return selected;
    };
    const getLocaleByLanguageCode = (languageCode) => {
        const localesArray = Array.from(locales.values());
        return localesArray.find((locale) => locale.languageCode === languageCode);
    };
    const getLocale = (option) => {
        const { code, languageCode } = option;
        if (code) {
            return getLocaleByCode(code);
        }
        if (languageCode) {
            return getLocaleByLanguageCode(languageCode);
        }
        return locales.get(defaultLocale);
    };
    const setActiveLocale = (code) => {
        const locale = getLocaleByCode(code).code;
        activeLocale = locale;
    };
    const getActiveLocale = () => getLocaleByCode(activeLocale);
    return {
        addLocale,
        getLocale,
        setActiveLocale,
        getActiveLocale,
    };
}
