import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentType, EntryKind } from 'shared/domains/apollo/generated/types';
import { SupportedFileMimeTypes } from 'shared/domains/filesystem';
export function KindIcon(props) {
    const { kind, documentType, mimeType } = props;
    switch (kind) {
        case 'WORKSPACE':
        case EntryKind.Folder:
            return <Falcon icon="folder"/>;
        case EntryKind.Document: {
            switch (documentType) {
                case DocumentType.Uploaded: {
                    switch (mimeType) {
                        case SupportedFileMimeTypes.PDF:
                            return <Falcon icon="file-pdf"/>;
                        case SupportedFileMimeTypes.DOCX:
                            return <Falcon icon="file-word"/>;
                        default:
                            return <Falcon icon="file-upload"/>;
                    }
                }
                case DocumentType.Freetext:
                    return <Falcon icon="file-edit"/>;
                case DocumentType.Composed:
                    return <Falcon icon="file-alt"/>;
                default:
                    return <Falcon icon="file-lines"/>;
            }
        }
        default:
            return null;
    }
}
