export const UpdateDashboardSlotMutationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'UpdateDashboardSlotMutation' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateInsightsDashboardSlotInput' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateSearchInsightsDashboardSlot' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'slotId' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'widgetId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dashboardId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'x' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'w' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'h' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'widget' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'InsightsWidgetFragment' },
                                                directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'unmask' } }],
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InsightsWidgetFragment' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchInsightsWidget' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'colorScheme' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'filters' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'aggregations' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'isHorizontal' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                ],
            },
        },
    ],
};
