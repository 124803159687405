var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { SaveCustomSearchViewDocument } from './mutation.gql';
export function useSaveViewMutation() {
    const [mutate, { data, loading, error }] = useMutation(SaveCustomSearchViewDocument);
    const saveView = (viewName, customViewInput) => __awaiter(this, void 0, void 0, function* () {
        const { query, filters, orderBy, columns } = customViewInput;
        const columnIds = columns.filter((col) => col.active).map((col) => col.id);
        return mutate({
            variables: {
                input: {
                    query,
                    name: viewName,
                    where: filters || {},
                    orderBy: orderBy ? [orderBy] : undefined,
                    columns: columnIds,
                },
            },
            update: (cache, result) => {
                var _a;
                const newView = (_a = result.data) === null || _a === void 0 ? void 0 : _a.createSearchCustomView;
                cache.modify({
                    fields: {
                        searchCustomViews(existing = []) {
                            return [...existing, newView];
                        },
                    },
                });
            },
        });
    });
    return {
        saveView,
        data,
        loading,
        error,
    };
}
