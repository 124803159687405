import { gql } from '@apollo/client';
export const docxToPdfMutation = gql `
  mutation RenderDOCXToPDF($companyId: UUID!, $path: String!, $mimeType: String!, $size: Int!, $name: String) {
    files: docxToPdfByFilePath(input: { companyId: $companyId, path: $path, mimeType: $mimeType, size: $size }) {
      id
      mimeType
      signed(name: $name) {
        url
      }
    }
  }
`;
