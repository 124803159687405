import { gql } from '@apollo/client';
export const htmlToPdfMutation = gql `
  mutation htmlToPdf($companyId: String!, $content: Base64String!, $logoUrl: String, $name: String) {
    response: htmlToPdf(input: { companyId: $companyId, content: $content, logoUrl: $logoUrl, title: $name }) {
      id
      signed(name: $name) {
        url
      }
    }
  }
`;
