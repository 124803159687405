import { Trans } from '@lingui/macro';
import { ConfirmDialog, useToast } from '@pocketlaw/tetris';
import { usePromptLibrary } from '../../PromptLibraryProvider';
import { useDeletePrompt } from '../useDeletePrompt';
export function DeletePromptDialog() {
    const { setCurrentPromptID, currentPromptID, prompts, setView } = usePromptLibrary();
    const { addToast } = useToast();
    const currentPrompt = prompts.find((prompt) => prompt.id === currentPromptID);
    const { deletePrompt } = useDeletePrompt();
    const handleConfirm = () => {
        if (currentPrompt === null || currentPrompt === void 0 ? void 0 : currentPrompt.id) {
            deletePrompt(currentPrompt === null || currentPrompt === void 0 ? void 0 : currentPrompt.id);
        }
        addToast({ title: 'Prompt deleted', appearance: 'success' });
        setView('library');
    };
    const handleCancel = () => {
        setCurrentPromptID(undefined);
        setView('library');
    };
    return (<ConfirmDialog heading={<Trans comment="Title for delete prompt dialog">Delete prompt: {currentPrompt === null || currentPrompt === void 0 ? void 0 : currentPrompt.name} ?</Trans>} appearance="danger" size="sm" confirm={<Trans comment="Prompt Library - Delete confirmation button">Delete</Trans>} cancel={<Trans comment="Prompt Library - Delete cancellation button">Cancel</Trans>} onConfirm={handleConfirm} onCancel={handleCancel}>
      <Trans comment="Prompt Library - Body text for confirmation before deleting a prompt">
        Are you sure you want to delete this prompt? This action is irreversible.
      </Trans>
    </ConfirmDialog>);
}
