import { ObservableMixin, Collection } from 'ckeditor5';
export class HighlightState extends ObservableMixin() {
    constructor() {
        super();
        this.set('highlights', new Collection());
        this.set('anchor', null);
    }
    clear(model) {
        const { highlights } = this;
        if (!highlights) {
            return;
        }
        model.change((writer) => {
            for (const { marker } of highlights) {
                writer.removeMarker(marker);
            }
        });
        highlights.clear();
    }
}
