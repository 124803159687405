var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useQuery } from '@apollo/client';
import { getTasksQuery } from './query';
import { buildTaskQueryFilters } from './utils';
function useGetTasks(inputs) {
    const { filter, sortBy, documentId } = inputs;
    const variables = buildTaskQueryFilters({
        sortBy,
        documentId,
        filter,
    });
    const { data, error, loading, refetch, previousData, called } = useQuery(getTasksQuery, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        variables,
    });
    const tasks = (called && loading ? previousData === null || previousData === void 0 ? void 0 : previousData.tasks : data === null || data === void 0 ? void 0 : data.tasks) || [];
    const formatTasks = tasks.map((task) => {
        const { taskDocuments, taskTemplates, reminders } = task, restTask = __rest(task, ["taskDocuments", "taskTemplates", "reminders"]);
        const [reminder] = reminders || [];
        const formatedDocuments = taskDocuments.map((_a) => {
            var { documentId: id } = _a, document = __rest(_a, ["documentId"]);
            return (Object.assign(Object.assign({}, document), { attachmentId: id, type: 'document' }));
        });
        const formatedTemplates = taskTemplates.map((_a) => {
            var { templateId } = _a, templates = __rest(_a, ["templateId"]);
            return (Object.assign(Object.assign({}, templates), { attachmentId: templateId, type: 'template' }));
        });
        const transformedTask = Object.assign(Object.assign({}, restTask), { attachments: [...formatedDocuments, ...formatedTemplates] });
        if (reminder) {
            Object.assign(transformedTask, { reminder });
        }
        return transformedTask;
    });
    return {
        data: formatTasks,
        error,
        refetch: () => refetch(variables),
        loading: loading && formatTasks.length < 1,
    };
}
export { useGetTasks };
