import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import typeDefs from 'shared/domains/apollo/config/typeDefs';
import httpLink from 'shared/domains/apollo/link';
import authHeaders from './authHeaders';
const cache = new InMemoryCache();
const client = new ApolloClient({
    cache,
    typeDefs,
    link: from([authHeaders, httpLink]),
    version: process.env.APP_VERSION,
    name: 'external-sharing',
    dataMasking: true,
    connectToDevTools: process.env.APP_ENV !== 'production',
});
export default client;
