import { SearchEntityTypeEnum } from 'shared/domains/apollo/generated/types';
export const getSearchEntityLink = (type, entityId) => {
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
        case SearchEntityTypeEnum.DocumentFreetext:
        case SearchEntityTypeEnum.DocumentUploaded:
            return `/document/${entityId}`;
        case SearchEntityTypeEnum.Folder:
            return `/files/${entityId}`;
        case SearchEntityTypeEnum.Template:
            return `/contracts?id=${entityId}`;
        case SearchEntityTypeEnum.User:
            return '/users-teams?tab=users';
        case SearchEntityTypeEnum.Draft:
            return `/draft/${entityId}`;
        case SearchEntityTypeEnum.InboxFile:
            return '';
        // todo to add a /tasks/ url in the near future
        case SearchEntityTypeEnum.Task:
            return `/dashboard?task=${entityId}`;
        default:
            return '';
    }
};
