import { Fragment, useRef } from 'react';
import { t } from '@lingui/macro';
import { ChipAction, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useField } from 'formik';
import { ChipDropdown } from 'shared/domains/common-ui';
import { allowedIntervals } from './allowedIntervals';
import { allowedPropertiesTranslations, intervalTranslations } from './translations';
import { Breakdown } from '../Breakdown';
import { SelectDataPoint } from '../SelectDataPoint';
import { FieldInputContainer } from '../styled';
export function DateHistogramInput(props) {
    const { pathBase, remove, isSubAggregation = false } = props;
    const currentPath = `${pathBase}.dateHistogramInput`;
    const [field, , helpers] = useField(currentPath);
    const intervalDropdownRef = useRef();
    const handleChangeInterval = (val) => {
        var _a;
        helpers.setValue(Object.assign(Object.assign({}, field.value), { interval: val }));
        (_a = intervalDropdownRef.current) === null || _a === void 0 ? void 0 : _a.toggleDropdown();
    };
    return (<Fragment>
      <FieldInputContainer>
        <SelectDataPoint label={t({
            message: 'Date period',
            comment: 'Insights widget form: Date historam field',
        })} selectedValue={allowedPropertiesTranslations(field.value.field)} name={pathBase} action={<ChipAction onClick={remove}>
              <Falcon icon="close"/>
            </ChipAction>}/>

        <ChipDropdown label={t({
            message: 'Config',
            comment: 'Insights: Date histogram interval configuration',
        })} secondary={intervalTranslations(field.value.interval)} appearance="secondary" variant="outline" ref={intervalDropdownRef} dropdownContent={<MenuBody>
              <MenuList>
                {allowedIntervals().map((interval) => (<MenuItem key={interval.value} onClick={() => handleChangeInterval(interval.value)}>
                    {interval.label}
                  </MenuItem>))}
              </MenuList>
            </MenuBody>}/>
      </FieldInputContainer>
      {!isSubAggregation && <Breakdown pathBase={currentPath}/>}
    </Fragment>);
}
