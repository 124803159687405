import { Trans, t } from '@lingui/macro';
import { Box, Checkbox, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ApprovalRequestApproverStatus } from 'shared/domains/apollo/generated/types';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { Approver } from '../Approver';
import { IconContainer } from './styled';
export function ApprovalApproved(props) {
    const { approvers } = props;
    const approvedApprovers = approvers.filter((approver) => approver.status === ApprovalRequestApproverStatus.Approved);
    const getSubtitle = (date) => {
        if (!date) {
            return undefined;
        }
        const formattedDate = localizedFormatDate(date, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        });
        return t({
            comment: 'Approval sidebar - Approved on date',
            message: `Approved on  ${formattedDate}`,
        });
    };
    return (<Box flexDirection="column" height="100%" gap="2xl" p="2xl">
      <Box flexDirection="column" gap="md" alignItems="center">
        <IconContainer>
          <Falcon icon="thumbs-up" $size={20}/>
        </IconContainer>
        <Box flexDirection="column" style={{ textAlign: 'center' }}>
          <Typography $fontSize="large" $fontWeight="semibold" $appearance="300">
            <Trans comment="Approval sidebar - Document fully approved label">Fully approved</Trans>
          </Typography>
          <Typography $fontSize="small" $appearance="200" $lineHeight="neutralized">
            <Trans comment="Approval sidebar - Document fully approved description">
              This document has been approved by all assigned approvers. Any edits will require a new approval process.
            </Trans>
          </Typography>
        </Box>
      </Box>
      <Box flexDirection="column" gap="md">
        <Typography $fontSize="small" $appearance="100">
          <Trans comment="Approval sidebar - Approved by label">Approved by</Trans>
        </Typography>
        {approvedApprovers.map((approver) => (<Approver key={approver.userId} name={approver.name} subtitle={getSubtitle(approver.updatedAt)} endElement={<Checkbox checked disabled/>}/>))}
      </Box>
    </Box>);
}
