import { Fragment, useCallback } from 'react';
import { t } from '@lingui/macro';
import { Chip, MenuItem, MenuSubheader } from '@pocketlaw/tetris';
import { useField } from 'formik';
import { SearchAggregationType } from 'shared/domains/apollo/generated/types';
import { SearchableMenu } from 'shared/domains/common-ui';
import { allowedProperties, isHeader, isItem } from './utils';
import { getDefaultDateHistogramValue } from '../DateHistogramInput/getDefaultDateHistogramValue';
import { getDefaultDateRangeValue } from '../DateRangeInput/getDefaultDateRangeValue';
import { getDefaultMetricValue } from '../MetricInput/getDefaultMetricValue';
import { getDefaultTermsValue } from '../TermsInput/getDefaultTermsValue';
import { getDefaultTopHitsValue } from '../TopHitsInput/getDefaultTopHitValue';
export function SelectDataPoint(props) {
    const { name, label, icon, action, selectedValue } = props;
    const [, , helpers] = useField(name);
    const selectValue = useCallback((aggregationType, value) => {
        const setValue = (data) => {
            helpers.setTouched(true);
            helpers.setValue(data);
        };
        const getDefaultValueFunctions = {
            [SearchAggregationType.DateHistogram]: getDefaultDateHistogramValue,
            [SearchAggregationType.DateRange]: getDefaultDateRangeValue,
            [SearchAggregationType.Metric]: getDefaultMetricValue,
            [SearchAggregationType.Terms]: getDefaultTermsValue,
            [SearchAggregationType.TopHits]: getDefaultTopHitsValue,
        };
        const getDefaultValue = getDefaultValueFunctions[aggregationType];
        if (getDefaultValue) {
            setValue(getDefaultValue(value));
        }
    }, [helpers]);
    return (<SearchableMenu items={allowedProperties()} keys={['value']} placeholder={t({
            message: 'Search',
            comment: 'Insights widget form: Data point selection search input',
        })} renderTarget={({ setOpen, open }) => (<Chip label={label} secondary={selectedValue} onClick={() => setOpen(!open)} size="large" icon={icon} appearance={selectedValue ? 'info' : 'secondary'} action={action}/>)}>
      {({ items }) => items.map((item) => (<Fragment key={item.id}>
            {isHeader(item) && <MenuSubheader key={item.id}>{item.label}</MenuSubheader>}

            {isItem(item) && (<MenuItem key={item.value} onClick={() => selectValue(item.type, item.value)}>
                {item.label}
              </MenuItem>)}
          </Fragment>))}
    </SearchableMenu>);
}
