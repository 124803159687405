import { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useEditorDraft } from 'app/domains/templates';
import { PageLoader } from 'shared/domains/common-ui';
import { AppError, NotFoundError } from 'shared/domains/errors';
import * as Sentry from 'shared/domains/sentry';
export function RedirectToChapter() {
    var _a, _b, _c, _d;
    const { id } = useParams();
    const query = useEditorDraft();
    const field = (_d = (_c = (_b = (_a = query.data) === null || _a === void 0 ? void 0 : _a.draft) === null || _b === void 0 ? void 0 : _b.tree) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d[0];
    useEffect(() => {
        if (field && !field.refId) {
            Sentry.withScope((scope) => {
                scope.setLevel('warning');
                Sentry.captureMessage('Trying to access Draft where the first field does not have a `ref`.');
            });
        }
    }, [field]);
    if (query.loading) {
        return <PageLoader />;
    }
    if (query.error) {
        return <AppError />;
    }
    if (field && field.refId) {
        return <Redirect to={`/draft/${id}/chapter/${field.refId}`}/>;
    }
    return <NotFoundError />;
}
