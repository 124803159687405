import { SupportedFileExtensions, SupportedFileMimeTypes } from './types';
/**
 * Maps mime types to extensions
 * @example `FILE_EXTENSION_MAP['application/pdf']` // => '.pdf'
 */
export const FILE_EXTENSION_MAP = {
    [SupportedFileMimeTypes.PDF]: SupportedFileExtensions.PDF,
    [SupportedFileMimeTypes.DOCX]: SupportedFileExtensions.DOCX,
    [SupportedFileMimeTypes.PNG]: SupportedFileExtensions.PNG,
    [SupportedFileMimeTypes.JPEG]: SupportedFileExtensions.JPEG,
};
/**
 * Maps extensions to mime types
 * @example `FILE_MIME_TYPE_MAP['.pdf']` // => 'application/pdf'
 */
export const FILE_MIME_TYPE_MAP = {
    [SupportedFileExtensions.DOCX]: SupportedFileMimeTypes.DOCX,
    [SupportedFileExtensions.PDF]: SupportedFileMimeTypes.PDF,
    [SupportedFileExtensions.PNG]: SupportedFileMimeTypes.PNG,
    [SupportedFileExtensions.JPEG]: SupportedFileMimeTypes.JPEG,
};
