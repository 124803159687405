import { gql } from '@apollo/client';
import { ProfileFragment, AccountFragment } from 'shared/domains/users/utils/fragments';
export const initQuery = gql `
  query init($userId: String!) {
    storedAccountId @client
    profile @client {
      ...ProfileFragment @unmask
      userId @export(as: "userId")
    }
    account @client {
      ...AccountFragment @unmask
    }
    accounts @client {
      ...AccountFragment @unmask
    }
    companyUsers(where: { user_id: { _eq: $userId } }) {
      ...AccountFragment @unmask
    }
  }
  ${ProfileFragment}
  ${AccountFragment}
`;
