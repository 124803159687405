import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import apolloClient from 'app/domains/apollo';
import { getDocumentStatusTranslations } from 'app/domains/documents';
import { getMarketByCode } from 'app/domains/markets/config';
import { SearchEntityTypeEnum, SignRequestStatus } from 'shared/domains/apollo/generated/types';
import { localizedFormatDate } from 'shared/domains/common-utils';
import { localeConfig } from 'shared/domains/languages';
import { metadataConfig } from 'shared/domains/metadata';
import { InsightsDisplayValueQueryDocument } from './InsightsDisplayValueQuery.gql';
export const stringFormatter = (value) => {
    if (typeof value === 'number') {
        return value.toString();
    }
    return value ? `${value}` : t({ message: 'Unknown', comment: 'Unknown field value' });
};
export function entityTypeFormatter(type) {
    switch (type) {
        case SearchEntityTypeEnum.DocumentComposed:
            return t({ message: 'Document (composed)', comment: 'Insights: Document (composed)' });
        case SearchEntityTypeEnum.DocumentUploaded:
            return t({ message: 'Document (uploaded)', comment: 'Insights: Document (uploaded)' });
        case SearchEntityTypeEnum.DocumentFreetext:
            return t({ message: 'Document (freetext)', comment: 'Insights: Document (freetext)' });
        default:
            return stringFormatter(type);
    }
}
export function booleanFormatter(value) {
    if (typeof value !== 'string') {
        return stringFormatter(value);
    }
    const yes = t({ message: 'Yes', comment: 'Insights: Boolean value: Yes' });
    const no = t({ message: 'No', comment: 'Insights: Boolean value: No' });
    return value === 'true' ? yes : no;
}
export function marketFormatter(value) {
    var _a;
    if (typeof value !== 'string') {
        return stringFormatter(value);
    }
    return ((_a = getMarketByCode(value)) === null || _a === void 0 ? void 0 : _a.getName()) || stringFormatter(value);
}
export function marketLocaleFormatter(value) {
    var _a;
    if (typeof value !== 'string') {
        return stringFormatter(value);
    }
    return ((_a = localeConfig.getLocale({ code: value })) === null || _a === void 0 ? void 0 : _a.getName()) || stringFormatter(value);
}
export function categoryFormatter(categoryId) {
    var _a, _b;
    const { categories } = apolloClient.readQuery({
        query: InsightsDisplayValueQueryDocument,
    }) || {};
    const category = categories === null || categories === void 0 ? void 0 : categories.find(({ id }) => id === categoryId);
    return ((_b = (_a = category === null || category === void 0 ? void 0 : category.metadata) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.title) || stringFormatter(categoryId);
}
export function userFormatter(userId) {
    var _a;
    const { companyUsers } = apolloClient.readQuery({
        query: InsightsDisplayValueQueryDocument,
    }) || {};
    const user = (_a = companyUsers === null || companyUsers === void 0 ? void 0 : companyUsers.find((companyUser) => { var _a; return ((_a = companyUser.user) === null || _a === void 0 ? void 0 : _a.id) === userId; })) === null || _a === void 0 ? void 0 : _a.user;
    return (user === null || user === void 0 ? void 0 : user.name) || t({ message: 'Unknown', comment: 'Insights: Unknown user name, possibly deleted' });
}
export function dateFormatter(value) {
    if (typeof value !== 'string') {
        return stringFormatter(value);
    }
    const formattedDate = localizedFormatDate(value, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
    return formattedDate || stringFormatter(value);
}
export function esigningStatusFormatter(value) {
    if (typeof value !== 'string') {
        return stringFormatter(value);
    }
    const statusMap = {
        [SignRequestStatus.Failed]: t({
            message: 'Failed',
            comment: 'Insights: Esigning status: Failed',
        }),
        [SignRequestStatus.Opened]: t({
            message: 'Opened',
            comment: 'Insights: Esigning status: Opened',
        }),
        [SignRequestStatus.Pending]: t({
            message: 'Pending',
            comment: 'Insights: Esigning status: Pending',
        }),
        [SignRequestStatus.Rejected]: t({
            message: 'Rejected',
            comment: 'Insights: Esigning status: Rejected',
        }),
        [SignRequestStatus.Signed]: t({
            message: 'Signed',
            comment: 'Insights: Esigning status: Signed',
        }),
        [SignRequestStatus.Cancelled]: t({
            message: 'Cancelled',
            comment: 'Insights: Esigning status: Cancelled',
        }),
        [SignRequestStatus.Cancelling]: t({
            message: 'Cancelling',
            comment: 'Insights: Esigning status: Cancelling',
        }),
        [SignRequestStatus.Creating]: t({
            message: 'Creating',
            comment: 'Insights: Esigning status: Creating',
        }),
        [SignRequestStatus.Unknown]: t({
            message: 'Unknown',
            comment: 'Insights: Esigning status: Unknown',
        }),
    };
    const status = statusMap[value];
    if (status) {
        return status;
    }
    return stringFormatter(value);
}
export function metadataValueFormatter(field, value) {
    if (typeof value !== 'string') {
        return stringFormatter(value);
    }
    const metadata = metadataConfig.get(field);
    if (!metadata) {
        return stringFormatter(value);
    }
    const { allowedValues } = metadata;
    const { text } = (allowedValues === null || allowedValues === void 0 ? void 0 : allowedValues.find((type) => type.value === value)) || {};
    return text || stringFormatter(value);
}
export function documentStatusFormatter(value) {
    if (typeof value !== 'string') {
        return stringFormatter(value);
    }
    return i18n._(getDocumentStatusTranslations(value));
}
