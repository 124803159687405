import 'shared/domains/pleditor@next/styles';
import { themeGet, pxToRem } from '@pocketlaw/tetris';
import styled, { createGlobalStyle, css } from 'styled-components';
import { attachmentStyles } from './plugins/Attachments/styled';
import { assistantHighlightStyles } from './styles/assistantStyles';
import { balloonToolbarStyles } from './styles/balloonToolbarStyles';
import { commentErrorMessageStyles } from './styles/comment';
import { commentsArchiveStyles } from './styles/commentsArchiveStyles';
import { customBlockStyles } from './styles/customBlock';
import { dropdownStyles } from './styles/dropdownStyles';
import { highlightStyles } from './styles/highlightStyles';
import { listStyles } from './styles/list';
import { appMentionBadgeThemeStyles, externalMentionBadgeThemeStyles, mentionStyles } from './styles/mentions';
import { reviewHighlightStyles } from './styles/reviewRedlineHighlightStyles';
import { revisionHistoryToolbarStyles } from './styles/revisionHistoryStyles';
import { editorSidebarStyles } from './styles/sidebar';
import { styleReset } from './styles/styleReset';
import { suggestionStyles } from './styles/suggestionStyles';
import { tableWithoutHandleStyles, tableStyles } from './styles/tables';
import { themeStyles } from './styles/theme';
import { toolbarStyles } from './styles/toolbar';
import { userAvatar } from './styles/userAvatar';
import { DocumentPageContainer } from '../../styled';
const LOGO_RATIO_WIDTH = 20;
const LOGO_RATIO_HEIGHT = 9;
const LOGO_SIZE = 1.4; // Adjust to match DOCX/PDF logo size (visual comparison)
export const PleditorWrapper = styled(DocumentPageContainer) `
  margin-bottom: 0;
  line-height: 1.5;
`;
export const PleditorToolbarContainer = styled.div `
  z-index: 2;
  position: sticky;
  top: 0;
  margin: ${(props) => (props.$noMargin ? '0' : `0 ${themeGet('spacing.xl')}`)};

  ${toolbarStyles}
  ${revisionHistoryToolbarStyles};
`;
PleditorToolbarContainer.defaultProps = {
    id: 'pleditor-toolbar',
};
export const PleditorContainer = styled.div `
  z-index: 0;
  width: auto;
  display: flex;
  flex: 1;
  justify-content: center;
  gap: ${themeGet('spacing.lg')};

  .ck.ck-content.ck-editor__editable:not(.ck-comment__input .ck.ck-content.ck-editor__editable) {
    ${styleReset};

    flex: 1;
    max-width: ${pxToRem(800)};
    padding: ${themeGet('spacing.2xl')};
    background-color: ${themeGet('colors.background.50')};

    ${({ $companyLogoUrl }) => $companyLogoUrl &&
    css `
        --logo-width: ${LOGO_RATIO_WIDTH * LOGO_SIZE}mm;
        --logo-height: ${LOGO_RATIO_HEIGHT * LOGO_SIZE}mm;
        padding-top: calc(${themeGet('spacing.2xl')} + ${themeGet('spacing.2xl')} + var(--logo-height));
        background-image: url(${$companyLogoUrl});
        background-position-y: ${themeGet('spacing.2xl')};
        background-position-x: ${themeGet('spacing.2xl')};
        background-size: var(--logo-width) var(--logo-height);
        background-repeat: no-repeat;
      `}

    &:not(.ck-editor__nested-editable).ck-rounded-corners {
      border-radius: ${themeGet('borderRadius.lg')};

      &.ck-focused {
        box-shadow: none;
        border: ${(props) => props.$isDocumentPreview
    ? `solid ${pxToRem(1)} transparent`
    : `solid ${pxToRem(1)} ${themeGet('colors.gray.100')}`};
      }
    }
  }
`;
export const PleditorSidebar = styled.div `
  width: 25%;
  transition: all 225ms ease-in-out;

  ${editorSidebarStyles}
  ${suggestionStyles}
`;
export const ExternalPleditorStyles = createGlobalStyle `
  ${externalMentionBadgeThemeStyles};
`;
export const AppPleditorStyles = createGlobalStyle `
  ${appMentionBadgeThemeStyles};
`;
export const ComposedPleditorStyles = createGlobalStyle `
  ${tableWithoutHandleStyles};
`;
export const BasePleditorStyles = createGlobalStyle `
  .ck {
    ${themeStyles};
  }
  
  ${commentErrorMessageStyles};
  ${listStyles};
  ${customBlockStyles};
  ${mentionStyles};
  ${userAvatar};
  ${tableStyles};
  ${balloonToolbarStyles};
  ${assistantHighlightStyles};
  ${reviewHighlightStyles};
  ${dropdownStyles};
  ${commentsArchiveStyles};
  ${attachmentStyles};
  ${highlightStyles};
`;
