import { gql } from '@apollo/client';
import { TaskFragment } from '../../utils/fragments';
export const UPDATE_TASK_STATUS = gql `
  mutation updateTaskStatus($id: UUID!, $status: TaskStatusEnumEnum!, $completed: Boolean!) {
    updateTask(where: { id: { _eq: $id } }, _set: { status: $status, is_completed: $completed }) {
      returning {
        ...TaskFragment @unmask
      }
    }

    ${TaskFragment}
  }
`;
