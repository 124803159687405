import { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@pocketlaw/tetris';
import { MessageProvider } from './MessageContext/MessageProvider';
import { MessageHeader } from './MessageHeader';
import { MessageLoader } from './MessageLoader';
import { markdownToReact } from './markdownToReact';
import { MessageActions, MessageBadge, MessageBubble, MessageContainer } from './styled';
export function Message(props) {
    const { message, align, appearance, avatar, loading = false, streaming = false, actions = [], fullWidth = false, showActionsDefault = false, components, children, badge, onFootnoteClick, } = props;
    const { sender, timestamp, text, markdown, isFirst } = message;
    const [showActions, setShowActions] = useState(false);
    const handleMouseOver = () => setShowActions(true);
    const handleMouseLeave = () => setShowActions(false);
    const isMessageReady = Boolean(text || markdown) && !loading && !streaming;
    const renderActions = actions.length > 0 && (showActions || showActionsDefault) && isMessageReady;
    return (<MessageProvider onFootnoteClick={onFootnoteClick}>
      <MessageContainer $align={align} $fullWidth={fullWidth} $appearance={appearance} $showActions={renderActions} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        {isFirst && <MessageHeader align={align} avatar={avatar} sender={sender} timestamp={timestamp}/>}
        <MessageLoader show={loading}/>
        <MessageBubble $streaming={streaming && !loading}>
          {text && (<Typography $fontSize="medium" $appearance="300">
              {text}
            </Typography>)}
          {markdown && markdownToReact({ markdown, components })}
          {isMessageReady && children}
        </MessageBubble>
        {actions.length > 0 && (<MessageActions>
            {actions.map((action) => (<IconButton key={action.tooltip} size="xs" appearance="ghost" disabled={action.disabled} aria-label={action.tooltip} onClick={action.onClick}>
                {action.icon}
              </IconButton>))}
          </MessageActions>)}
        {badge && (<Tooltip title={badge.tooltip}>
            <MessageBadge>{badge.icon}</MessageBadge>
          </Tooltip>)}
      </MessageContainer>
    </MessageProvider>);
}
