import { Trans } from '@lingui/macro';
import { Card, Tabs } from '@pocketlaw/tetris';
import { WidgetLoader } from 'app/domains/common-ui';
import { AddTask, EditTask, PreviewTask, TasksError, useTasks, TaskTabs, WidgetHeader, } from 'app/domains/tasks';
import { DocumentDrawerHeader } from 'shared/domains/documents';
import { DrawerContainer } from '../../styled';
import { getTitle } from '../../utils';
import { StyledWidgetBody, Container } from './styled';
export function Tasks(props) {
    const { onClose, onGoBack } = props;
    const { tasks, activeFilter, setActiveFilter } = useTasks();
    const title = getTitle('tasks');
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={title} onClose={onClose} onGoBack={onGoBack}/>
      <Container>
        <Card data-testid="task-widget">
          <Tabs activeTab={activeFilter} onSelect={(id) => {
            const state = id;
            setActiveFilter(state);
        }}>
            <WidgetHeader size="md" title={<Trans>Tasks &amp; Reminders</Trans>} hideLink/>

            <StyledWidgetBody>
              {tasks.loading && <WidgetLoader />}

              <TasksError />
              <AddTask />
              <EditTask />
              <PreviewTask />
              <TaskTabs />
            </StyledWidgetBody>
          </Tabs>
        </Card>
      </Container>
    </DrawerContainer>);
}
