/* eslint-disable lingui/text-restrictions */
import { t } from '@lingui/macro';
import { pxToRem, themeGet, unit } from '@pocketlaw/tetris';
import { css } from 'styled-components';
const pleditorMentionsListHeader = css `
  pointer-events: none;
  display: block;
  width: 100%;
  height: ${pxToRem(40)};
  display: flex;
  align-items: center;
  padding-left: ${unit('lg')};
  color: ${themeGet('colors.text.200')};
  font-size: ${themeGet('fontSize.small')};
`;
export const mentionStyles = css `
  .ck-mentions {
    .ck-list__item {
      .ck-custom-mention-item {
        height: ${pxToRem(40)};
        display: flex;
        align-items: center;
        color: ${themeGet('colors.text.300')};
        font-size: ${themeGet('fontSize.medium')};

        &.ck-button {
          padding-left: ${unit('lg')};
        }
      }

      &:has(.ck-custom-mention-first-item-with-access)::before {
        ${pleditorMentionsListHeader};

        content: '${() => t({
    comment: 'Pleditor comment mention user list header - Users with access',
    message: 'With access',
})}';
      }

      &:has(.ck-custom-mention-first-item-without-access)::before {
        ${pleditorMentionsListHeader};

        content: '${() => t({
    comment: 'Pleditor comment mention user list header - Users without access',
    message: 'Without access',
})}';
      }

      &:has(.ck-custom-mention-first-item-external)::before {
        ${pleditorMentionsListHeader};

        content: '${() => t({
    comment: 'Pleditor comment mention user list header - Users with external access',
    message: 'External access',
})}';
      }

      /* Adds a border above the users without access header if we also render users with access or external */

      &:has(.ck-custom-mention-first-item-without-access):not(:first-child)::before,
      &:has(.ck-custom-mention-first-item-external):not(:first-child)::before {
        border-top: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};
      }
    }
  }

  .ck .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
  }
`;
const commonMentionStyles = css `
  background-color: transparent;
  font-weight: ${themeGet('fontWeight.bold')};
`;
const defaultMentionStyles = css `
  ${commonMentionStyles};
  color: ${themeGet('colors.primary.500')};
`;
export const externalMentionBadgeThemeStyles = css `
  .ck-comment,
  .ck.ck-content {
    .mention[data-user-id] {
      ${defaultMentionStyles};
    }
  }
`;
const mentionExternalWithoutAccessStyles = css `
  ${commonMentionStyles};
  color: ${themeGet('colors.text.300')};
`;
const mentionWithAccessStyles = css `
  ${commonMentionStyles};
  color: ${themeGet('colors.primary.500')};
`;
const commonBadgeStyles = css `
  &:after {
    display: inline-block;
    padding: ${themeGet('spacing.sm')};
    margin-left: ${themeGet('spacing.sm')};
    border-radius: ${themeGet('spacing.xs')};
    font-size: ${pxToRem(8)};
    font-weight: ${themeGet('fontWeight.bold')};
    text-transform: uppercase;
    line-height: 1;
    pointer-events: none;
  }
`;
const mentionWithoutAccessStyles = css `
  ${commonMentionStyles};
  ${commonBadgeStyles};
  color: ${themeGet('colors.text.300')};

  &:after {
    background-color: ${themeGet('colors.yellow.500')};
    content: '${() => t({
    comment: 'Badge for mentioned user in comment without access to the document',
    message: 'No access',
})}';
  }
`;
const mentionExternalWithAccessStyles = css `
  ${commonMentionStyles};
  ${commonBadgeStyles};
  color: ${themeGet('colors.primary.500')};

  &:after {
    color: ${themeGet('colors.primary.500')};
    background-color: ${themeGet('colors.primary.50')};
    content: '${() => t({
    comment: 'Badge for mentioned user in comment with external access to the document',
    message: 'External',
})}';
  }
`;
export const appMentionBadgeThemeStyles = css `
  .ck-comment,
  .ck.ck-content {
    .mention[data-user-id^='email|'] {
      ${mentionExternalWithoutAccessStyles}
    }

    ${({ $usersWithAccess }) => $usersWithAccess === null || $usersWithAccess === void 0 ? void 0 : $usersWithAccess.map((id) => css `
          .mention[data-user-id='${id}'] {
            ${mentionWithAccessStyles};
          }
        `)}

    ${({ $usersWithoutAccess }) => $usersWithoutAccess === null || $usersWithoutAccess === void 0 ? void 0 : $usersWithoutAccess.map((id) => css `
          .mention[data-user-id='${id}'] {
            ${mentionWithoutAccessStyles};
          }
        `)}

  ${({ $usersExternal }) => $usersExternal === null || $usersExternal === void 0 ? void 0 : $usersExternal.map((id) => css `
          .mention[data-user-id='${id}'] {
            ${mentionExternalWithAccessStyles};
          }
        `)}
  }
`;
