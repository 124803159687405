import { TableBody, TableRow } from '@pocketlaw/tetris';
import { SearchResultsTableCell } from './SearchResultsTableCell';
import { useTableContext } from './TableContext';
import { canChangeDocumentStatus, canMoveDocument } from './utils';
import { useSearchContext } from '../SearchProvider';
export function SearchResultTableBody() {
    const { values } = useSearchContext();
    const { activeAction, results, selected, setSelected, actions } = useTableContext();
    const { columns } = values;
    const selectedIds = selected.map((item) => item.entityId);
    const isDisabled = (row) => {
        const isSelected = selectedIds.includes(row.entityId);
        if (!activeAction) {
            return false;
        }
        if (activeAction && !isSelected) {
            return true;
        }
        switch (activeAction.name) {
            case 'add-status': {
                return !canChangeDocumentStatus(row);
            }
            case 'move-document': {
                return !canMoveDocument(row);
            }
            default:
                return true;
        }
    };
    const isRowSelected = (row) => !(activeAction && isDisabled(row)) && selectedIds.includes(row.entityId);
    const onSelect = (row) => () => setSelected({ type: 'select', payload: row });
    const visibleColumns = columns.filter((col) => col.active);
    return (<TableBody>
      {results.map((result) => (<TableRow key={result.id} disabled={isDisabled(result)} $selected={isRowSelected(result)}>
          {visibleColumns.map((column) => (<SearchResultsTableCell key={column.id} column={column} result={result} onSelect={actions ? onSelect(result) : undefined} selected={isRowSelected(result)}/>))}
        </TableRow>))}
    </TableBody>);
}
