var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Alert, Box, Button, Dialog, DialogContent, DialogFooter, DialogHeader, Loader, LoaderOverlay, TextArea, Typography, } from '@pocketlaw/tetris';
import { useCreateVoluntaryApprovalRequest } from 'app/domains/approval/hooks/useCreateVoluntaryApprovalRequest';
import { useDocumentApproversPermissions } from 'app/domains/approval/hooks/useDocumentApproversPermissions';
import { useLastApprovalRequest } from 'app/domains/approval/hooks/useLastApprovalRequest';
import { ApprovalErrorSubcodes } from 'app/domains/esigning/utils';
import { documentFlush, usePreview } from 'shared/domains/documents';
import { useApprovalWorkflow } from '../../../ApprovalWorkflowProvider';
import { ApproversPermissionList } from '../ApproversPermissionList';
const APPROVAL_ERROR_SUBCODES = [ApprovalErrorSubcodes.AlreadyPending, ApprovalErrorSubcodes.AlreadyApproved];
export function SendForVoluntaryApprovalModal(props) {
    const { approvalType, selectedApprovers, onClose } = props;
    const { documentId } = useApprovalWorkflow();
    const { createVoluntaryApprovalRequest } = useCreateVoluntaryApprovalRequest();
    const { refetch } = useLastApprovalRequest(documentId);
    const { isRtcAvailable } = usePreview();
    const selectedApproverUserIds = selectedApprovers.map((approver) => approver.userId);
    const { userIdsWithoutPermission, loading: loadingUserIdsWithoutPermission } = useDocumentApproversPermissions(selectedApproverUserIds);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
    const [alert, setAlert] = useState(null);
    const [isApprovalStatusChanged, setIsApprovalStatusChanged] = useState(false);
    const filteredApproversWithoutPermission = selectedApprovers.filter(({ userId }) => userId && (userIdsWithoutPermission === null || userIdsWithoutPermission === void 0 ? void 0 : userIdsWithoutPermission.includes(userId)));
    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };
    const handleClose = () => {
        if (isApprovalStatusChanged) {
            refetch();
        }
        onClose();
    };
    const handleError = (error) => {
        var _a, _b;
        setLoading(false);
        if (error instanceof ApolloError) {
            const subcode = (_b = (_a = error.graphQLErrors[0]) === null || _a === void 0 ? void 0 : _a.extensions) === null || _b === void 0 ? void 0 : _b.subcode;
            if (subcode && APPROVAL_ERROR_SUBCODES.includes(subcode)) {
                setIsApprovalStatusChanged(true);
                if (subcode === ApprovalErrorSubcodes.AlreadyPending) {
                    return setAlert({
                        appearance: 'info',
                        message: t({
                            comment: 'Handle ask for approval request dialog error message - Document approval already pending error',
                            message: 'Someone has already asked for approval for this document',
                        }),
                    });
                }
                if (subcode === ApprovalErrorSubcodes.AlreadyApproved) {
                    return setAlert({
                        appearance: 'info',
                        message: t({
                            comment: 'Handle ask for approval request dialog error message - Document approval already approved error',
                            message: 'This document has already been approved.',
                        }),
                    });
                }
            }
        }
        return setAlert({
            appearance: 'danger',
            message: t({
                comment: 'Handle ask for approval request dialog error message - Generic error message',
                message: 'Something went wrong while asking for approval, please try again.',
            }),
        });
    };
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        setAlert(null);
        try {
            yield createVoluntaryApprovalRequest({
                message,
                approvalType,
                fileSystemDocumentId: documentId,
                approverIdsWithoutPermission: userIdsWithoutPermission,
                approverUserIds: selectedApproverUserIds,
            });
            if (isRtcAvailable) {
                documentFlush({ state: 'initialized' });
            }
            handleClose();
        }
        catch (error) {
            if (isRtcAvailable) {
                documentFlush({ state: 'not-initialized' });
            }
            handleError(error);
        }
    });
    return (<Dialog size="sm">
      <DialogHeader title={t({
            comment: 'Ask for approval dialog title',
            message: 'Ask for Approval',
        })}/>
      <DialogContent>
        <LoaderOverlay enabled={loadingUserIdsWithoutPermission}>
          <Box flexDirection="column" gap="xl">
            {alert && <Alert {...alert}/>}
            <Typography>
              <Trans comment="Ask for approval dialog body">
                When the assigned approvers have approved the document you will be able to send it for eSigning
              </Trans>
            </Typography>
            <TextArea label={t({
            message: 'Message',
            comment: 'Approval Drawer - Ask for approval dialog - textarea label',
        })} placeholder={t({
            message: 'Your message...',
            comment: 'Approval Drawer - Ask for approval dialog - textarea placeholder',
        })} value={message} disabled={loading} onChange={handleChangeMessage}/>
            {filteredApproversWithoutPermission.length > 0 && (<ApproversPermissionList approvers={filteredApproversWithoutPermission}/>)}
          </Box>
        </LoaderOverlay>
      </DialogContent>
      <DialogFooter>
        {isApprovalStatusChanged ? (<Button appearance="primary" onClick={handleClose}>
            <Trans comment="Ask for approval dialog close button label">Close</Trans>
          </Button>) : (<Fragment>
            <Button appearance="secondary" disabled={loading} onClick={handleClose}>
              <Trans comment="Ask for approval dialog cancel button label">Cancel</Trans>
            </Button>
            <Button appearance="primary" disabled={loading} onClick={handleSubmit}>
              <Trans comment="Ask for approval dialog send for approval button label">Send for approval</Trans>
              {loading && <Loader />}
            </Button>
          </Fragment>)}
      </DialogFooter>
    </Dialog>);
}
