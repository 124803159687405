import { Trans, t } from '@lingui/macro';
import { Table, TableHead, TableHeadCell, TableHeaderText, TableBody, TableRow, VisuallyHidden, } from '@pocketlaw/tetris';
import { AssigneeCell, DateCell, StatusCell, CompletedToggleCell, MenuCell, TitleCell } from './components';
import { TasksIds, SortIds } from '../types';
export function TasksTable(props) {
    const { tasks, columns = [], setSort, sort } = props;
    const hideCols = columns.reduce((prev, current) => {
        if (!current.active) {
            return [...prev, current.id];
        }
        return prev;
    }, []);
    const handleSort = (id, sortOrder) => {
        const sortIds = Object.values(SortIds);
        const sortId = sortIds.find((value) => value === id);
        if (sortId) {
            setSort({ sortId, sortOrder });
        }
    };
    return (<Table hideColRows={hideCols} sortId={sort.sortId} sortOrder={sort.sortOrder} onSort={handleSort}>
      <colgroup>
        <col width={1}/>
        {columns.map(({ active, id }) => active && <col width="auto" key={id}/>)}
        <col width={1}/>
      </colgroup>
      <TableHead>
        <TableRow>
          <TableHeadCell id={TasksIds.completeTask}>
            <VisuallyHidden>
              <Trans comment="hidden text, this is used to explain to screen readers this is the column to complete tasks">
                Radiobutton to complete task
              </Trans>
            </VisuallyHidden>
          </TableHeadCell>
          <TableHeadCell id={TasksIds.title}>
            <TableHeaderText sortId={TasksIds.title}>
              {t({ message: 'Title', comment: 'Title column header for task table' })}
            </TableHeaderText>
          </TableHeadCell>
          <TableHeadCell id={TasksIds.status}>
            <TableHeaderText sortId={TasksIds.status}>
              {t({ message: 'Status', comment: 'Status column header for task table' })}
            </TableHeaderText>
          </TableHeadCell>
          <TableHeadCell id={TasksIds.createdAt}>
            <TableHeaderText sortId={TasksIds.createdAt}>
              {t({ message: 'Created at', comment: 'Created at column header for task table' })}
            </TableHeaderText>
          </TableHeadCell>
          <TableHeadCell id={TasksIds.updatedAt}>
            <TableHeaderText sortId={TasksIds.updatedAt}>
              {t({ message: 'Updated at', comment: 'Updated at column header for task table' })}
            </TableHeaderText>
          </TableHeadCell>
          <TableHeadCell id={TasksIds.dueDate}>
            <TableHeaderText sortId={TasksIds.dueDate}>
              {t({ message: 'Due date', comment: 'Due date column header for task table' })}
            </TableHeaderText>
          </TableHeadCell>
          <TableHeadCell id={TasksIds.assignee}>
            <TableHeaderText>
              {t({ message: 'Assignee', comment: 'Assignee column header for task table' })}
            </TableHeaderText>
          </TableHeadCell>
          <TableHeadCell id={TasksIds.dropdownMenu}>
            <VisuallyHidden>
              <Trans comment="hidden text, this is used to explain to screen readers this is the column for dropdown menu">
                Task context menu
              </Trans>
            </VisuallyHidden>
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map(({ id, isCompleted, status, title, dueDate, assignees, createdAt, updatedAt }) => (<TableRow key={id}>
            <CompletedToggleCell completed={isCompleted} status={status} taskId={id}/>
            <TitleCell title={title} taskId={id} completed={isCompleted} status={status}/>
            <StatusCell dueDate={dueDate} status={status} taskId={id}/>
            <DateCell date={createdAt} headers={TasksIds.createdAt}/>
            <DateCell date={updatedAt} headers={TasksIds.updatedAt}/>
            <DateCell date={dueDate} headers={TasksIds.dueDate}/>
            <AssigneeCell assignees={assignees}/>
            <MenuCell taskId={id} isCompleted={isCompleted} status={status}/>
          </TableRow>))}
      </TableBody>
    </Table>);
}
