import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { ACTIVE_STATUSES } from 'shared/domains/documents';
import { FilePickerSelectedEntriesDocument } from './FilePickerSelectedEntries.gql';
/**
 * Hook to keep track of selected entries.
 * It will load initial data if entry ID's are provided.
 * NOTE: This implementation is currently lacking. The returned initial data is missing
 * some potential fields on the entry. But it's good enough for now.
 * This should preferably be replaced with a better solution.
 * @param options
 * @returns
 */
export function useEntries(options) {
    const { initialEntryIds } = options;
    const [entries, setEntries] = useState([]);
    const { error, loading } = useQuery(FilePickerSelectedEntriesDocument, {
        skip: !(initialEntryIds === null || initialEntryIds === void 0 ? void 0 : initialEntryIds.length),
        variables: {
            entryIds: initialEntryIds || [],
            limit: 100,
        },
        onCompleted: (data) => {
            const initialEntries = data === null || data === void 0 ? void 0 : data.children.map((entry) => {
                const defaultEntry = Object.assign(Object.assign({}, entry), { disabled: false, status: undefined });
                if ('lastSignRequest' in entry) {
                    const { status } = entry.lastSignRequest || {};
                    const isSentForEsigning = Boolean(status && ACTIVE_STATUSES.includes(status));
                    if (isSentForEsigning) {
                        defaultEntry.status = status;
                    }
                }
                return defaultEntry;
            });
            setEntries(initialEntries || []);
        },
    });
    return {
        setEntries,
        /**
         * Please note that this data is not complete Entry data.
         * It is a minimal representation of the Entry.
         */
        entries,
        error,
        loading,
    };
}
