import { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { Box, Chip, MenuItem, MenuList, MenuSubheader, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useSearchableMenu } from 'shared/domains/common-ui/components/SearchableMenu/SearchableMenuContext';
export function NewValue(props) {
    const { addItem } = props;
    const { searchString, resetSearch, searching, items } = useSearchableMenu();
    const handleAdd = () => {
        addItem(searchString);
        resetSearch();
    };
    const exactMatch = items.find((item) => item.id.toLowerCase().trim() === searchString.toLowerCase().trim());
    if (!searchString || !searching || exactMatch) {
        return null;
    }
    return (<Fragment>
      <MenuSubheader>
        <Trans comment="Metadata: New value header">New</Trans>
      </MenuSubheader>
      <MenuList>
        <MenuItem onClick={handleAdd}>
          <Typography>{searchString}</Typography>

          <Box ml="auto" pl="3xl">
            <Chip label={t({ message: 'Add', comment: 'Metadata: Add new value' })} appearance="info" icon={<Falcon icon="add"/>}/>
          </Box>
        </MenuItem>
      </MenuList>
    </Fragment>);
}
