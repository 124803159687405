import { ApolloClient } from '@apollo/client';
import cache from 'shared/domains/apollo/cache';
import typeDefs from 'shared/domains/apollo/config/typeDefs';
import link from './link';
function isLocalEnvironment() {
    return process.env.APP_ENV === 'local';
}
function getAppVersion() {
    if (isLocalEnvironment()) {
        return 'local';
    }
    return process.env.APP_VERSION;
}
const name = process.env.APP_NAME;
const version = getAppVersion();
export default new ApolloClient({
    name,
    version,
    link,
    cache,
    typeDefs,
    dataMasking: true,
    connectToDevTools: process.env.APP_ENV !== 'production',
});
