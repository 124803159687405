import { useField } from 'formik';
import { getSigningOrder } from 'app/domains/esigning/utils';
import { EsigningSignatoriesFormFieldContext } from './Context';
export function SignatoriesFormFieldProvider(props) {
    const { children } = props;
    const [{ value: signatories }, , helpers] = useField('signatories');
    const [{ value: useSigningOrder }] = useField('settings.useSigningOrder');
    const addSignatory = (newSignatory) => {
        const order = getSigningOrder(newSignatory, useSigningOrder);
        helpers.setValue([...signatories, Object.assign(Object.assign({}, newSignatory), { order })]);
    };
    const editSignatory = (editedSignatory) => {
        const originalSignatory = signatories.find((signatory) => signatory.key === editedSignatory.key);
        if (!originalSignatory) {
            return;
        }
        const order = getSigningOrder(editedSignatory, useSigningOrder, originalSignatory.order);
        const newSignatories = signatories.map((signatory) => {
            if (signatory.key === editedSignatory.key) {
                return Object.assign(Object.assign({}, editedSignatory), { order });
            }
            return signatory;
        });
        helpers.setValue(newSignatories);
    };
    const removeSignatory = (removedSignatory) => {
        helpers.setValue(signatories.filter((signatory) => signatory.key !== removedSignatory.key));
    };
    const value = { signatories, addSignatory, editSignatory, removeSignatory };
    return (<EsigningSignatoriesFormFieldContext.Provider value={value}>
      {children}
    </EsigningSignatoriesFormFieldContext.Provider>);
}
