import { canUseLocalStorage } from 'shared/domains/common-utils';
const INVITE_COMPANY_ID_KEY = 'pl.invite.id';
export const useCompanyInviteLocalStorage = () => {
    const canUse = canUseLocalStorage();
    const setInviteCompanyId = (inviteCompanyId) => {
        if (!canUse) {
            return;
        }
        localStorage.setItem(INVITE_COMPANY_ID_KEY, inviteCompanyId);
    };
    const getInviteCompanyId = () => {
        if (!canUse) {
            return null;
        }
        return localStorage.getItem(INVITE_COMPANY_ID_KEY);
    };
    const clearInviteCompanyId = () => {
        if (!canUse) {
            return;
        }
        localStorage.removeItem(INVITE_COMPANY_ID_KEY);
    };
    return {
        setInviteCompanyId,
        getInviteCompanyId,
        clearInviteCompanyId,
    };
};
