var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { Wizard, WizardProvider } from './components/Wizard';
import { useCreateUserInvite } from './useCreateUserInvite';
import { usePendingInvites } from './usePendingInvites';
export function InviteUserModal(props) {
    const { onClose, onInviteCreated } = props;
    const query = usePendingInvites();
    const { addToast } = useToast();
    const handleCreatedInvite = (invites) => {
        if (onInviteCreated) {
            onInviteCreated();
        }
        query.updateQuery((previousResult) => (Object.assign(Object.assign({}, previousResult), { pendingInvites: previousResult.pendingInvites.concat(invites !== null && invites !== void 0 ? invites : []) })));
    };
    const createUserInvite = useCreateUserInvite({ onCreated: handleCreatedInvite });
    const handleSubmit = (values, helpers) => __awaiter(this, void 0, void 0, function* () {
        const { email, role, composers } = values;
        const composerIds = (composers === null || composers === void 0 ? void 0 : composers.map(({ id }) => id)) || [];
        try {
            yield createUserInvite.mutate({ email: email.trim(), role, composers: composerIds });
            addToast({
                title: t({ message: 'Invite sent', comment: 'invite user modal - succes toast' }),
                appearance: 'success',
            });
            onClose();
        }
        catch (error) {
            helpers.setStatus({ mutationError: true });
            addToast({
                title: t({ message: 'Could not send invite.', comment: 'invite user modal - error toast' }),
                appearance: 'danger',
            });
        }
    });
    return (<WizardProvider values={{ onSubmit: handleSubmit, onClose, error: createUserInvite.state.error }}>
      <Wizard />
    </WizardProvider>);
}
