import { useEffect, useState } from 'react';
import rehypeParse from 'rehype-parse';
import rehypeSanitize from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';
import { unified } from 'unified';
const DEFAULT_ALLOWED_TAGS = [];
export function useSanitizeHTMLString(options) {
    const { allowedTags = DEFAULT_ALLOWED_TAGS, text } = options;
    const [content, setContent] = useState('');
    useEffect(() => {
        let shouldSetState = true;
        const sanitize = () => rehypeSanitize({
            tagNames: allowedTags,
        });
        unified()
            .use(rehypeParse, { fragment: true })
            .use(sanitize)
            .use(rehypeStringify)
            .process(text)
            .then((file) => {
            if (shouldSetState) {
                setContent(file.value);
            }
        });
        return () => {
            shouldSetState = false;
        };
    }, [allowedTags, text]);
    return content;
}
export function HtmlStringToReact(props) {
    const content = useSanitizeHTMLString(props);
    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={{ __html: content }}/>;
}
