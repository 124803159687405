import { createContext, useState } from 'react';
import { useComposerCategories } from './useComposerCategories';
import { useComposers } from './useComposers';
import { useComposersCount } from './useComposersCount';
export { useComposerData } from './hook';
export const TemplateDataContext = createContext(null);
export function ComposerDataProvider(props) {
    const { initialComposerId = null, children, onClose } = props;
    const { composersCount, loading: loadingCount, error: countError } = useComposersCount();
    const { categories, loading: loadingCategories, error: categoriesError } = useComposerCategories();
    const loading = loadingCount || loadingCategories;
    const error = Boolean(countError || categoriesError);
    const [selectedComposerId, setSelectedComposerId] = useState(initialComposerId);
    const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [templatesFilter, setTemplatesFilter] = useState(composersCount['templates:company'] !== undefined && composersCount['templates:company'] > 0
        ? 'templates:company'
        : 'templates:included');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const filterData = {
        selectedCategoryIds,
        searchQuery,
        templatesFilter,
        selectedLanguages,
    };
    const { composers, loading: loadingComposers, error: composersError } = useComposers(filterData);
    const selectComposer = (id) => setSelectedComposerId(id);
    const goBackToSelect = () => setSelectedComposerId(null);
    const addCategoryId = (id) => setSelectedCategoryIds([...selectedCategoryIds, id]);
    const removeCategoryId = (id) => setSelectedCategoryIds(selectedCategoryIds.filter((categoryId) => categoryId !== id));
    const addSelectedLanguage = (languageCode) => {
        if (!selectedLanguages.includes(languageCode)) {
            setSelectedLanguages([...selectedLanguages, languageCode]);
        }
    };
    const removeSelectedLanguage = (languageCode) => setSelectedLanguages(selectedLanguages.filter((code) => code !== languageCode));
    const value = {
        composers,
        error: Boolean(error || composersError),
        categories,
        loading: Boolean(loading || loadingComposers),
        selectedComposerId,
        selectedCategoryIds,
        searchQuery,
        templatesAmount: composersCount,
        templatesFilter,
        selectedLanguages,
        goBackToSelect,
        addCategoryId,
        removeCategoryId,
        selectComposer,
        setSearchQuery,
        setTemplatesFilter,
        addSelectedLanguage,
        removeSelectedLanguage,
        closeDialog: onClose,
    };
    return <TemplateDataContext.Provider value={value}>{children}</TemplateDataContext.Provider>;
}
