var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { useDocumentPermission } from 'app/domains/documents';
import * as Types from 'shared/domains/apollo/generated/types';
export function useDocumentPermissions(options) {
    const { ids, documentId = '' } = options || {};
    const { userId, creatorId, assigneeIds = [] } = ids;
    const [called, setCalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [idsWithoutPermission, setIdsWithoutPermission] = useState([]);
    const userHasAccess = called && userId !== undefined && !idsWithoutPermission.includes(userId);
    const { refetch } = useDocumentPermission({});
    const getUserPermissions = useCallback((idsToCheck) => __awaiter(this, void 0, void 0, function* () {
        const userPermissionMap = {};
        const userPermissions = yield Promise.all(idsToCheck.map((id) => refetch({ documentId, userId: id })));
        idsToCheck.forEach((id, index) => {
            userPermissionMap[id] = userPermissions[index].data;
        });
        return userPermissionMap;
    }), [documentId, refetch]);
    const fetchPermissions = useCallback((idsToCheck) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            const userPermissions = yield getUserPermissions(idsToCheck);
            const map = Object.entries(userPermissions).reduce((acc, [id, userPermission]) => {
                var _a;
                const permissions = (_a = userPermission === null || userPermission === void 0 ? void 0 : userPermission.fileSystemEntryACL) === null || _a === void 0 ? void 0 : _a.actions;
                const canRead = !!(permissions === null || permissions === void 0 ? void 0 : permissions.find((action) => action === Types.AclAction.Read));
                acc[id] = canRead;
                return acc;
            }, {});
            const newIdsWithoutPermission = Object.entries(map)
                .filter(([, canRead]) => !canRead)
                .map(([id]) => id);
            setIdsWithoutPermission(newIdsWithoutPermission);
        }
        catch (_a) {
            setIdsWithoutPermission(idsToCheck);
        }
        setLoading(false);
        setCalled(true);
    }), [getUserPermissions]);
    useEffect(() => {
        if (userId && documentId) {
            const idsToCheck = [userId, ...assigneeIds];
            if (creatorId) {
                idsToCheck.push(creatorId);
            }
            fetchPermissions(idsToCheck);
        }
    }, [assigneeIds, creatorId, documentId, fetchPermissions, userId]);
    return { userHasAccess, idsWithoutPermission, called, loading };
}
