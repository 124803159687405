var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState } from 'react';
import { t } from '@lingui/macro';
import { useToast } from '@pocketlaw/tetris';
import { CreateSignRequestErrors, HANDLED_ERRORS, } from 'app/domains/esigning/components/EsigningForm/types';
import { useCreateSignRequest } from 'app/domains/esigning/hooks/useCreateSignRequest';
import { useAddSignatoryValue } from '../useAddSignatoryValue';
export const useSendForEsigning = (documentId) => {
    const { createSignRequest } = useCreateSignRequest({ documentId });
    const { addSignatoryValue } = useAddSignatoryValue(documentId);
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [creationError, setCreationError] = useState();
    const [creationFailedSubcode, setCreationFailedSubcode] = useState();
    const sendForEsigning = (options) => __awaiter(void 0, void 0, void 0, function* () {
        const { signatoriesToSave } = options, variables = __rest(options, ["signatoriesToSave"]);
        setLoading(true);
        setCreationError(undefined);
        setCreationFailedSubcode(undefined);
        return createSignRequest(variables)
            .then(() => {
            addSignatoryValue(signatoriesToSave).catch((e) => e);
        })
            .catch((mutationError) => {
            addToast({
                appearance: 'danger',
                title: t({
                    message: 'Could not send document for e-signing.',
                    comment: 'Message for when a user tries to send a document for e-signing but something went wrong',
                }),
            });
            const error = mutationError;
            const { message } = error;
            if (HANDLED_ERRORS.includes(message)) {
                setCreationError(message);
            }
            else {
                setCreationError(CreateSignRequestErrors.UnexpectedError);
            }
        })
            .finally(() => {
            setLoading(false);
        });
    });
    return {
        sendForEsigning,
        loading,
        creationError,
        creationFailedSubcode,
        setCreationFailedSubcode,
    };
};
