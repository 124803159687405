import { useReactiveVar } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Button } from '@pocketlaw/tetris';
import { useGoBack } from './useErrorActions';
import { documentState } from '../../documentState';
export function ReconnectingError() {
    const state = useReactiveVar(documentState);
    const { goBack, reload } = useGoBack();
    if (state !== 'reconnecting') {
        return null;
    }
    return (<Dialog>
      <DialogHeader title={t({
            message: 'Reconnecting',
            comment: 'Pleditor: Reconnecting error title in dialog',
        })}/>
      <DialogContent>
        <Trans comment="Pleditor: Reconnecting error text in dialog">
          You lost connection to the document. We`re trying to reconnect you. You can try to reload the document by
          clicking on the reload button below.
        </Trans>
      </DialogContent>
      <DialogFooter>
        <Button appearance="secondary" onClick={goBack}>
          <Trans comment="Pleditor: Reconnecting error go back button">Go back</Trans>
        </Button>
        <Button appearance="primary" onClick={reload}>
          <Trans comment="Pleditor: Reconnecting error reload button.">Reload</Trans>
        </Button>
      </DialogFooter>
    </Dialog>);
}
