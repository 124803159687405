var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { useEsigningProviders } from 'app/domains/esigning/hooks/useEsigningProviders';
import { useLeaveDocumentPrompt } from 'app/domains/esigning/hooks/useLeaveDocumentPrompt';
import { ApprovalErrorSubcodes } from 'app/domains/esigning/utils';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { createSignRequestMutation } from './mutation';
import { normalizePdf } from './normalizePdf';
import { useDocumentForSignRequest } from './useDocumentForSignRequest';
import { useGetPdf } from './useGetPdf';
import { CreateSignRequestErrors } from '../../components/EsigningForm/types';
import { GetDocumentAttachmentKindsDocument } from '../../queries/documentAttachmentKinds/kinds.gql';
const IGNORE_CODES = ['BAD_USER_INPUT', ApprovalErrorSubcodes.LacksApproval];
export function useCreateSignRequest(options) {
    const { documentId } = options;
    const [loading, setLoading] = useState(false);
    const { data: accountData } = useAccount();
    const { companyId } = accountData;
    const { loadDocument } = useDocumentForSignRequest(documentId);
    const { connectedProvider } = useEsigningProviders();
    const { getPdf } = useGetPdf();
    const [mutate] = useMutation(createSignRequestMutation);
    useLeaveDocumentPrompt({
        busy: loading,
        message: t({
            message: 'If you leave now your document might not be sent for e-signing',
            comment: 'Message for when the users leaves a document while it is being sent for e-signing',
        }),
    });
    const createSignRequest = (_a) => __awaiter(this, [_a], void 0, function* ({ signatories, ccRecipients, message, defaultSignatories, }) {
        var _b;
        setLoading(true);
        const documentQuery = yield loadDocument();
        const { document } = (_b = documentQuery.data) !== null && _b !== void 0 ? _b : {};
        if (!document || !connectedProvider || !companyId) {
            throw Error(t({
                message: 'Failed to create sign request for e-signing',
                comment: 'Error message for when we fail to create a signing request for e-signing',
            }));
        }
        const pdfFile = yield getPdf({ companyId, document: document });
        if (!pdfFile) {
            throw new Error(CreateSignRequestErrors.NoPdfConversion);
        }
        const file = normalizePdf(Object.assign(Object.assign({}, pdfFile), { companyId, documentId: document.id }));
        return mutate({
            variables: {
                file,
                signatories,
                ccRecipients,
                message,
                defaultSignatories,
                documentId: document.id,
                esigningProviderId: connectedProvider.id,
            },
            context: {
                sentry: {
                    ignore: IGNORE_CODES,
                },
            },
            update(cache, { data }) {
                cache.modify({
                    id: cache.identify({
                        id: documentId,
                        // eslint-disable-next-line no-underscore-dangle
                        __typename: 'FileSystemDocument',
                    }),
                    fields: {
                        lastSignRequest(existing, { toReference }) {
                            // eslint-disable-next-line no-underscore-dangle
                            if ((data === null || data === void 0 ? void 0 : data.createSignRequestNew.__typename) === 'SignRequest') {
                                return toReference(data === null || data === void 0 ? void 0 : data.createSignRequestNew, true);
                            }
                            return existing;
                        },
                    },
                });
            },
            refetchQueries: [
                {
                    query: GetDocumentAttachmentKindsDocument,
                    variables: { documentId },
                },
            ],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        }).finally(() => setLoading(false));
    });
    return { createSignRequest };
}
