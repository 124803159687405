import { Trans, t } from '@lingui/macro';
import { Button, EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import 'content-visibility';
import { Item } from './Item';
import { useComposerData } from '../../ComposerDataProvider';
import { ErrorState } from '../ErrorState';
import { LoadingState } from '../LoadingState';
import { EmptyContent, GridContent, contentVisibilityStyle } from './styled';
export function Grid() {
    const { error, loading, composers, selectComposer, searchQuery, setSearchQuery } = useComposerData();
    const handleEmptyStateButtonClick = () => setSearchQuery('');
    if (loading) {
        return <LoadingState />;
    }
    if (error) {
        return <ErrorState />;
    }
    if (composers.length > 0) {
        return (<content-visibility style={contentVisibilityStyle}>
        <GridContent>
          {composers.map((composer) => (<Item key={composer.id} composer={composer} onClick={selectComposer}/>))}
        </GridContent>
      </content-visibility>);
    }
    return (<EmptyContent>
      <EmptyState icon={<Falcon icon="search"/>} title={t({
            comment: 'Select template dialog - empty search title',
            message: 'No templates found',
        })} description={t({
            comment: 'Select template dialog - empty search description',
            message: "We don't seem to have the template you're looking for",
        })} actions={searchQuery.length > 0 && (<Button appearance="secondary" onClick={handleEmptyStateButtonClick}>
              <Trans comment="Select template dialog - empty search button label">Clear search</Trans>
            </Button>)}/>
    </EmptyContent>);
}
