import { t } from '@lingui/macro';
import { useTasks } from '../../../TasksProvider';
import { DateFilter } from '../DateFilter';
import { StatusFilter } from '../StatusFilter';
import { UserFilter } from '../UserFilter';
import { Container } from './styled';
export function TasksTableFilters() {
    const { tasksFilter, setTasksFilter, activeFilter } = useTasks();
    const { dueDates, createdAtDates, creators, assignees, status } = tasksFilter;
    return (<Container>
      <DateFilter label={t({ message: 'Created At', comment: 'tasks filter - label for created at chip' })} value={createdAtDates} clear={() => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { createdAtDates: undefined }))} onSubmit={(dateFilter) => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { createdAtDates: dateFilter }))}/>
      <DateFilter label={t({ message: 'Due Date', comment: 'tasks filter - label for due date chip' })} value={dueDates} clear={() => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { dueDates: undefined }))} onSubmit={(dateFilter) => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { dueDates: dateFilter }))}/>
      <UserFilter label={t({ message: 'Creator', comment: 'tasks filter - label for creator chip' })} onChange={(userIds) => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { creators: userIds }))} clear={() => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { creators: undefined }))} value={creators}/>
      <UserFilter label={t({ message: 'Assignee', comment: 'tasks filter - label for assignee chip' })} onChange={(userIds) => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { assignees: userIds }))} clear={() => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { assignees: undefined }))} value={assignees}/>
      <StatusFilter label={t({ message: 'Status', comment: 'tasks filter - label for status chip' })} clear={() => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { status: undefined }))} onChange={(statuses) => setTasksFilter(Object.assign(Object.assign({}, tasksFilter), { status: statuses }))} value={status} tab={activeFilter}/>
    </Container>);
}
