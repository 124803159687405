import { t } from '@lingui/macro';
import { Falcon } from '@pocketlaw/tetris/macro';
import { getActions, getDescription } from 'shared/domains/documents/components/DocumentAiNotAvailable/utils';
import { StyledEmptyState } from './styled';
export function DocumentAiNotAvailable(props) {
    const { reason } = props;
    return (<StyledEmptyState withAvatar icon={<Falcon icon="sparkles"/>} title={t({
            comment: 'Document assistant drawer - when AI is currently not available on a document - subtitle',
            message: 'Document AI not available',
        })} description={getDescription(reason)} actions={getActions(reason)}/>);
}
