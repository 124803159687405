import { pxToRem, themeGet } from '@pocketlaw/tetris';
import { css } from 'styled-components';
export const styleReset = css `
  *,
  *:before,
  *:after {
    line-height: normal;
  }

  *:not(li) {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  font-family: arial;
  color: ${themeGet('colors.text.300')};

  b,
  strong {
    font-weight: bold;
  }

  h1 {
    font-size: ${pxToRem(24)};
    font-weight: bold;
    margin-top: ${themeGet('spacing.2xl')};
    margin-bottom: ${themeGet('spacing.lg')};
  }

  h2 {
    font-size: ${pxToRem(20)};
    font-weight: bold;
    margin-top: ${themeGet('spacing.2xl')};
    margin-bottom: ${themeGet('spacing.lg')};
  }

  h3 {
    font-weight: bold;
    font-size: ${pxToRem(16)};
    margin-top: ${themeGet('spacing.2xl')};
    margin-bottom: ${themeGet('spacing.lg')};
  }

  h4 {
    font-weight: bold;
    font-size: ${pxToRem(14)};
    margin-top: ${themeGet('spacing.2xl')};
    margin-bottom: ${themeGet('spacing.lg')};
  }

  p,
  li > p,
  li > span {
    font-size: ${pxToRem(14)};
    margin: ${pxToRem(12)} 0;
  }
`;
