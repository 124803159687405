import { Fragment, useState } from 'react';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { SidebarMenuCollapsible, SidebarMenuItem } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { InsightsTemplate } from 'app/domains/insights';
import { useListDashboards } from './useListDashboards';
import { useDesktopNavigation } from '../../../DesktopNavigation';
import { CollapsibleItemLink } from '../CollapsibleItemLink';
import { useLinkMatch } from '../useLinkMatch';
export function CollapsibleInsights() {
    const { collapsibleStates, toggleCollapsible } = useDesktopNavigation();
    const { data, loading } = useListDashboards();
    const [open, setOpen] = useState(false);
    const activeChild = useLinkMatch({
        matchPath: ['/insights'],
        matchExact: false,
    });
    const handleShowTemplates = () => setOpen(true);
    if (loading) {
        return null;
    }
    if (!data.length) {
        return (<Fragment>
        <SidebarMenuItem icon={<Falcon icon="chart-simple"/>} label={t({ comment: 'Sidebar menu - Insights label', message: 'Insights' })} onClick={handleShowTemplates}/>
        {open && <InsightsTemplate onClose={() => setOpen(false)}/>}
      </Fragment>);
    }
    return (<SidebarMenuCollapsible icon={<Falcon icon="chart-simple"/>} label={t({ comment: 'Sidebar menu - Insights label', message: 'Insights' })} activeChild={activeChild} collapsibleKey="insights" open={collapsibleStates.insights} onToggle={toggleCollapsible}>
      {data.map((dashboard) => (<CollapsibleItemLink key={dashboard.id} to={`/insights/${dashboard.id}`} label={i18n._(dashboard.label)} matchPath={`/insights/${dashboard.id}`}/>))}
    </SidebarMenuCollapsible>);
}
