import { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useEsigningSignatoriesFormField } from 'app/domains/esigning/components/EsigningForm';
import { UNASSIGNED_SIGNING_ORDER } from 'app/domains/esigning/utils';
import { StyledSelect } from './styled';
export function PartySigningOrder(props) {
    const { signatoryKey } = props;
    const { signatories } = useEsigningSignatoriesFormField();
    const { values, setFieldValue } = useFormikContext();
    const { order } = values.signatories.find((signatory) => signatory.key === signatoryKey) || {};
    const valueIndex = values.signatories.findIndex((signatory) => signatory.key === signatoryKey);
    const signingSignatories = signatories.filter(({ isSigning }) => isSigning);
    const { length } = signingSignatories;
    const options = Array.from({ length }, (_, i) => i);
    const setOrder = useCallback((newOrder) => {
        const field = `signatories.${valueIndex}.order`;
        setFieldValue(field, newOrder, true);
    }, [valueIndex, setFieldValue]);
    const handleChange = (event) => {
        setOrder(Number(event.target.value));
    };
    useEffect(() => {
        if (order !== undefined && order >= length) {
            setOrder(UNASSIGNED_SIGNING_ORDER);
        }
    }, [order, setOrder, length]);
    return (<StyledSelect value={order} onChange={handleChange}>
      <option value={UNASSIGNED_SIGNING_ORDER}>#0</option>
      {options.map((option) => (<option key={option} value={option}>{`#${option + 1}`}</option>))}
    </StyledSelect>);
}
