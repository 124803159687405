var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import apollo from 'app/domains/apollo';
import * as Types from 'shared/domains/apollo/generated/types';
import { useAddUserAccess } from 'shared/domains/users';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { mutation } from './mutation';
import { parseTask } from './utils';
import { TaskFragment } from '../../utils/fragments';
import { useDocumentAttachment } from '../useDocumentAttachment';
import { getTasksQuery } from '../useGetTasks/query';
import { useReminder } from '../useReminder';
import { useRemoveTaskAssignees } from '../useRemoveTaskAssignees';
import { useSetTaskAssignees } from '../useSetTaskAssignees';
import { useTemplateAttachment } from '../useTemplateAttachment';
function useUpdateTask() {
    const account = useAccount();
    const { setTaskAssignees } = useSetTaskAssignees();
    const { removeTaskAssignees } = useRemoveTaskAssignees();
    const [updateTaskMutation, { error, loading }] = useMutation(mutation);
    const reminders = useReminder();
    const documentsAttachments = useDocumentAttachment();
    const templatesAttachments = useTemplateAttachment();
    const addUserAccess = useAddUserAccess();
    const isLoading = loading || documentsAttachments.loading || templatesAttachments.loading || reminders.loading;
    const hasError = error || documentsAttachments.error || templatesAttachments.error || reminders.error;
    const userId = account.data.userId;
    const handleUpdateTask = (id, options) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const { data } = options;
        const { assignees = [], taskAssignees: newAssignees = [] } = data;
        const isCreator = userId === ((_a = data.creator) === null || _a === void 0 ? void 0 : _a.id);
        const variables = parseTask(id, data, isCreator);
        if (data.reminder) {
            yield reminders.mutation(data.reminder);
        }
        yield Promise.all([documentsAttachments.mutation(options), templatesAttachments.mutation(options)]);
        if ((_b = data.addDocumentPermission) === null || _b === void 0 ? void 0 : _b.length) {
            const documentId = (_d = (_c = data.attachments) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.attachmentId;
            yield addUserAccess.mutate(documentId, {
                users: data.addDocumentPermission,
                actions: [Types.AclAction.Read],
            });
        }
        yield updateTaskMutation({
            variables,
            update: (cache, response) => {
                const { updateOneTask } = response.data || {};
                if (updateOneTask) {
                    cache.writeFragment({
                        id: cache.identify(updateOneTask),
                        fragment: TaskFragment,
                        data: updateOneTask,
                    });
                }
            },
        });
        const assigneeIdsToRemove = assignees
            .filter(({ user }) => !newAssignees.includes(user))
            .map((assignee) => assignee.id);
        yield removeTaskAssignees({ taskId: id, assigneeIds: assigneeIdsToRemove });
        const oldAssignees = assignees.map((assignee) => assignee.user);
        const assigneesToAdd = newAssignees
            .filter((assignee) => !oldAssignees.includes(assignee))
            .map((assignee) => ({
            task_id: id,
            assignee_id: assignee.id,
        }));
        yield setTaskAssignees(assigneesToAdd);
        apollo.refetchQueries({
            include: [getTasksQuery],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    });
    return {
        mutate: handleUpdateTask,
        error: hasError,
        loading: isLoading,
    };
}
export { useUpdateTask };
