import React, { Fragment } from 'react';
import { Box, GridContainer, PageHeader } from '@pocketlaw/tetris';
import { useCompanyFeatures } from 'app/domains/companies';
import { useAccount } from 'shared/domains/users';
import { CustomerDetail } from './CustomerDetail';
export function CustomerSuccess() {
    const { data } = useAccount();
    const { name, id, marketCode } = data.company;
    const { features } = useCompanyFeatures();
    const featureLabels = features.map((feature) => feature.toLocaleLowerCase()).sort();
    return (<Fragment>
      <PageHeader breadcrumbs={[]} heading="Company details"/>
      <GridContainer>
        <Box flexDirection="column" gap="2xl">
          <CustomerDetail title="Company name" value={name}/>
          <CustomerDetail title="Company id" value={id}/>
          <CustomerDetail title="Company market" value={marketCode}/>
          <CustomerDetail title="Company features" chips={featureLabels}/>
        </Box>
      </GridContainer>
    </Fragment>);
}
