import { Fragment, useRef, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Menu, MenuBody, MenuItem, MenuList } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useAssistant } from 'app/domains/assistant';
import { FileUpload } from 'app/domains/upload';
import { ChatMessageType, DocumentType } from 'shared/domains/apollo/generated/types';
import { PickFileTemplateDialog } from 'shared/domains/common-ui';
import { useActiveDocument } from 'shared/domains/documents';
import { useFeatureFlag } from 'shared/domains/featureFlag';
import { SupportedFileMimeTypes, MAX_ATTACHMENT_UPLOAD_SIZE_BYTES } from 'shared/domains/filesystem';
import { PopButton } from '../PopButton';
const SUPPORTED_MIME_TYPES = [SupportedFileMimeTypes.DOCX, SupportedFileMimeTypes.PDF];
export function CompareButton(props) {
    const { disabled, onError } = props;
    const { id } = useActiveDocument();
    const { createNewChat } = useAssistant();
    const fileUploadRef = useRef(null);
    const [showBrowser, setShowBrowser] = useState(false);
    const [open, setOpen] = useState(false);
    const isCompareDocumentsUploadEnabled = useFeatureFlag('feature_compare_documents_upload');
    const closeMenu = () => setOpen(false);
    const toggleMenu = () => setOpen(!open);
    const handleShowBrowser = () => {
        setOpen(false);
        setShowBrowser(true);
    };
    const handleHideBrowser = () => setShowBrowser(false);
    const handleSelectFile = () => {
        var _a;
        setOpen(false);
        (_a = fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    const handleChange = (event) => {
        var _a, _b;
        const filesToUpload = (_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.files) !== null && _b !== void 0 ? _b : [];
        const file = filesToUpload[0];
        if (!file) {
            onError('NO_FILE_PROVIDED');
            return;
        }
        if (!SUPPORTED_MIME_TYPES.includes(file.type)) {
            onError('UNSUPPORTED_FILE_FORMAT');
            return;
        }
        if (file.size > MAX_ATTACHMENT_UPLOAD_SIZE_BYTES) {
            onError('FILE_SIZE_EXCEEDS_MAX');
            return;
        }
        const reader = new FileReader();
        reader.onload = function onload(evt) {
            var _a;
            const result = (_a = evt.target) === null || _a === void 0 ? void 0 : _a.result;
            if (typeof result !== 'string') {
                return;
            }
            const pattern = /data:(?<mime>.*?);base64,(?<base64>.*)/;
            const match = result.match(pattern);
            if (match === null) {
                return;
            }
            const [, , base64] = match;
            createNewChat({
                data: {
                    base64,
                    filename: file.name,
                    mimeType: file.type,
                    type: 'document-compare-with-uploaded-file',
                },
                type: ChatMessageType.DocumentCompare,
            });
        };
        reader.readAsDataURL(file);
    };
    const handleAdd = (entry) => {
        createNewChat({
            type: ChatMessageType.DocumentCompare,
            data: {
                documentId: entry.id,
                type: 'document-compare-with-pocketlaw-file',
            },
        });
    };
    const selectable = (entry) => {
        var _a;
        if (((_a = entry.documentAi) === null || _a === void 0 ? void 0 : _a.enabled) === false) {
            return false;
        }
        if (entry.type === DocumentType.Uploaded) {
            if (!entry.mimeType || !SUPPORTED_MIME_TYPES.includes(entry.mimeType)) {
                return false;
            }
        }
        return true;
    };
    return (<Fragment>
      <Menu open={open} onClose={closeMenu} target={<PopButton disabled={disabled} icon={<Falcon icon="files"/>} label={t({
                comment: 'Assistant chat dashboard - compare with button label',
                message: 'Compare with',
            })} onClick={toggleMenu}/>}>
        <MenuBody>
          <MenuList>
            <MenuItem onClick={handleShowBrowser}>
              <Falcon icon="arrow-pointer"/>
              <Trans comment="Option for plai compare document">With one of your Pocketlaw files</Trans>
            </MenuItem>
            {isCompareDocumentsUploadEnabled && (<MenuItem onClick={handleSelectFile}>
                <Falcon icon="up-from-bracket"/>
                <Trans comment="Option for plai compare upload">With uploaded file</Trans>
              </MenuItem>)}
          </MenuList>
        </MenuBody>
      </Menu>
      <FileUpload ref={fileUploadRef} onChange={handleChange}/>
      {showBrowser && (<PickFileTemplateDialog includeDocumentAi dialogTitle={t({
                comment: 'Assistant compare file browser title',
                message: 'Select the file to compare with',
            })} dialogSubtitle={t({
                comment: 'Assistant compare file browser subtitle',
                message: 'Select a file to compare with this document',
            })} submitLabel={t({
                comment: 'Assistant compare file browser submit button',
                message: 'Select file',
            })} selfId={id} customSelectable={selectable} onClose={handleHideBrowser} onSubmitDocument={handleAdd}/>)}
    </Fragment>);
}
