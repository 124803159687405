import { pxToRem, themeGet } from '@pocketlaw/tetris';
import styled, { css, keyframes } from 'styled-components';
const enterAnimation = keyframes `
  0% {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    max-height: ${pxToRem(170)};
    padding-top: ${themeGet('spacing.2xl')};
    padding-bottom: ${themeGet('spacing.2xl')};
  }
`;
export const ProgressContainer = styled.div `
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themeGet('spacing.xl')};
  padding: 0 ${themeGet('spacing.2xl')};
  border-radius: ${themeGet('borderRadius.lg')};
  border: solid ${pxToRem(1)} ${themeGet('colors.gray.100')};

  ${(props) => props.$skipAnimation
    ? css `
          padding: ${themeGet('spacing.2xl')};
        `
    : css `
          animation: ${enterAnimation} 325ms ease-in-out forwards;
        `}
`;
