var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRef, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { ChipAction, useToast, MenuItem, Chip, Menu, MenuList, MenuBody } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { DocumentMetadataPropertySetterEnum } from 'shared/domains/apollo/generated/types';
import { ChipDropdown } from 'shared/domains/common-ui';
import { metadataConfig } from 'shared/domains/metadata';
import { MetadataFieldContext } from './MetadataFieldContext';
import { MetadataForm } from './MetadataForm';
import { MetadataReasoning } from './MetadataReasoning';
import { useMetadataActions } from '../../hooks/useMetadataActions';
import { useDocumentMetadata } from '../DocumentMetadataProvider';
import { getMetadataDisplayValue } from '../getMetadataDisplayValue';
export function MetadataField(props) {
    var _a, _b;
    const { documentId, pair, property } = props;
    const { toggleActiveEntry } = useDocumentMetadata();
    const actions = useMetadataActions();
    const { addToast } = useToast();
    const contextValues = {
        documentId,
        property,
        pair,
    };
    const defaultEditMode = !pair.id && !pair.value;
    const needsVerification = pair.lastSetBy === DocumentMetadataPropertySetterEnum.Ai && !pair.approvedByUserId;
    const displayReasoning = pair.lastSetBy === DocumentMetadataPropertySetterEnum.Ai;
    const { label } = metadataConfig.get(property.name) || {};
    const [showReasoningDialog, setShowReasoningDialog] = useState(false);
    const [actionsOpen, setActionsOpen] = useState(false);
    const toggleActions = () => setActionsOpen(!actionsOpen);
    const closeActions = () => setActionsOpen(false);
    const chipDropdownRef = useRef();
    const handleShowReasoningDialog = () => {
        setActionsOpen(false);
        setShowReasoningDialog(true);
    };
    const handleRemove = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield actions.delete(pair.id);
            addToast({
                title: t({
                    message: 'Field removed',
                    comment: 'Success toast when metadata field is removed',
                }),
                appearance: 'success',
            });
            toggleActiveEntry(property.name);
            closeActions();
        }
        catch (_c) {
            addToast({
                title: t({ message: 'Error', comment: 'Metadata: Remove error title' }),
                message: t({
                    message: 'There was a error removing the field',
                    comment: 'Metadata: Remove error message',
                }),
                appearance: 'danger',
            });
        }
    });
    const handleSave = (form) => __awaiter(this, void 0, void 0, function* () {
        var _d;
        try {
            const formValue = form.value;
            const shouldUpdate = form.id;
            if (shouldUpdate) {
                yield actions.update({
                    id: pair.id,
                    value: formValue,
                });
            }
            else {
                yield actions.create({
                    propertyName: property.name,
                    value: formValue,
                    documentId,
                });
            }
            (_d = chipDropdownRef.current) === null || _d === void 0 ? void 0 : _d.toggleDropdown();
            addToast({
                title: t({
                    message: 'Metadata updated',
                    comment: 'Success toast when metadata is created/updated',
                }),
                appearance: 'success',
            });
        }
        catch (error) {
            addToast({
                title: t({
                    message: 'An problem occurred',
                    comment: 'Error toast when metadata is created/updated',
                }),
                appearance: 'danger',
            });
        }
    });
    const handleVerify = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield actions.verify({
                pairIds: [pair.id],
            });
            addToast({
                title: t({
                    message: 'Field verified',
                    comment: 'Success toast when metadata field is verified',
                }),
                appearance: 'success',
            });
            closeActions();
        }
        catch (_e) {
            addToast({
                title: t({ message: 'Error', comment: 'Metadata: Verify error title' }),
                message: t({
                    message: 'There was a error verifying the field',
                    comment: 'Metadata: Verify error message',
                }),
                appearance: 'danger',
            });
        }
    });
    if (!property || !label) {
        return (<Chip label={t({ message: 'Unknown property', comment: 'Metadata: Unknown property' })} appearance="warning" size="large"/>);
    }
    return (<MetadataFieldContext.Provider value={contextValues}>
      {showReasoningDialog && (<MetadataReasoning reasoning={(_a = pair.aiAutoTaggingReasoning) === null || _a === void 0 ? void 0 : _a.reasoning} citation={(_b = pair.aiAutoTaggingReasoning) === null || _b === void 0 ? void 0 : _b.citation} onClose={() => setShowReasoningDialog(false)}/>)}
      <ChipDropdown initiallyOpen={defaultEditMode} label={label} secondary={getMetadataDisplayValue({ property, value: pair.value })} appearance={needsVerification ? 'assistant' : 'secondary'} ref={chipDropdownRef} action={pair.id && (<Menu open={actionsOpen && !actions.loading} onClose={() => setActionsOpen(false)} target={<ChipAction onClick={toggleActions} aria-label={t({
                    message: 'Edit field',
                    comment: 'Metadata: Button to display the different editing capabilities of a field.',
                })}>
                  {actions.loading ? <Falcon icon="spinner"/> : <Falcon icon="ellipsis-vertical"/>}
                </ChipAction>}>
              <MenuBody>
                <MenuList>
                  {needsVerification && (<MenuItem as="button" type="button" onClick={handleVerify} disabled={actions.loading || !pair.id}>
                      <Falcon icon="shield-check"/>
                      <Trans comment="Metadata: Remove metadata field">Verify</Trans>
                    </MenuItem>)}
                  {displayReasoning && (<MenuItem as="button" type="button" onClick={handleShowReasoningDialog}>
                      <Falcon icon="lightbulb"/>
                      <Trans comment="Metadata: Show reasoning">Reasoning</Trans>
                    </MenuItem>)}
                  <MenuItem as="button" type="button" onClick={handleRemove} disabled={actions.loading || !pair.id}>
                    <Falcon icon="trash"/>
                    <Trans comment="Metadata: Remove metadata field">Remove</Trans>
                  </MenuItem>
                </MenuList>
              </MenuBody>
            </Menu>)} dropdownContent={<MetadataForm onSubmit={handleSave}/>}/>
    </MetadataFieldContext.Provider>);
}
