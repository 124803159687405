import { Fragment, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { MenuHeader, MenuBody, MenuList, MenuSubheader, MenuItem, Checkbox, SearchInput } from '@pocketlaw/tetris';
import { useFuse } from 'app/domains/search';
import { ListItemText } from './styled';
export function UserList(props) {
    const { selected, users, onSelect } = props;
    const [searchInput, setSearchInput] = useState('');
    const { search, result } = useFuse(users, { keys: ['name'] });
    const selectable = searchInput ? result : users;
    const unSelected = selectable.filter((user) => !selected.find(({ id }) => id === user.id));
    const showSelected = selected.length > 0;
    const showunSelected = unSelected.length > 0;
    return (<Fragment>
      <MenuHeader>
        <SearchInput stretch variant="line" value={searchInput} onReset={() => setSearchInput('')} resetLabel={t({
            message: 'Clear search',
            comment: 'tasks filter - user list search reset label',
        })} placeholder={t({
            message: 'Search',
            comment: 'tasks filter - user list search placeholder',
        })} onChange={(event) => {
            setSearchInput(event.target.value);
            search(event.target.value);
        }}/>
      </MenuHeader>
      <MenuBody>
        <MenuList>
          {showSelected && (<Fragment>
              <MenuSubheader>
                <Trans comment="tasks filter - user list selected users title">Selected users</Trans>
              </MenuSubheader>

              {selected.map((user) => (<MenuItem key={user.id} onClick={() => onSelect(user, true)}>
                  <ListItemText>{user.name || ''}</ListItemText>
                  <Checkbox checked onChange={() => onSelect(user, true)}/>
                </MenuItem>))}
            </Fragment>)}

          {showunSelected && (<Fragment>
              <MenuSubheader>
                <Trans comment="tasks filter - user list unselected users title">All users</Trans>
              </MenuSubheader>
              {unSelected.map((user) => (<MenuItem key={user.id} onClick={() => onSelect(user, false)}>
                  <ListItemText>{user.name || ''}</ListItemText>
                  <Checkbox onChange={() => onSelect(user, false)}/>
                </MenuItem>))}
            </Fragment>)}
        </MenuList>
      </MenuBody>
    </Fragment>);
}
