import { useState } from 'react';
import { t } from '@lingui/macro';
import { EmptyState } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { SidebarSectionLoader } from 'app/domains/documents';
import { ShareDocumentDialog, useActiveDocument, usePreview, useExternalSharingInvites, DocumentDrawerHeader, RemoveExternalSharingInviteDialog, } from 'shared/domains/documents';
import useGetThreads from 'shared/domains/feedback/hooks/useGetThreads';
import useAccount from 'shared/domains/users/hooks/useAccount';
import { Invitee } from './Invitee';
import { LoadErrorAlert } from './LoadErrorAlert';
import { MessageThread } from './MessageThread';
import { NotSupported } from './NotSupported';
import { PendingInvitee } from './PendingInvitee';
import { ShareButton } from './ShareButton';
import { DrawerContainer } from '../../styled';
import { getTitle } from '../../utils';
import { InnerContainer, List, OuterContainer } from './styled';
export function ExternalSharing(props) {
    var _a;
    const { onClose, onGoBack } = props;
    const account = useAccount();
    const { isNotSupported } = usePreview();
    const { id: documentId } = useActiveDocument();
    const { userId: excludeUserId } = (_a = account.data) !== null && _a !== void 0 ? _a : {};
    const { invites, loading: loadingExternalInvites, refetch: refetchExternalSharingInvites, } = useExternalSharingInvites();
    const { error, data: threads, loading: loadingThreads, refetch: refetchThreads, } = useGetThreads({ excludeUserId: excludeUserId || '', id: documentId });
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [inviteeToRemove, setInviteeToRemove] = useState(null);
    const [selectedThread, setSelectedThread] = useState(null);
    const loading = loadingThreads || loadingExternalInvites;
    const pendingInvites = invites.filter((invite) => !invite.invitee);
    const handleShowShareDialog = () => setShowShareDialog(true);
    const handleHideShareDialog = () => setShowShareDialog(false);
    const handleRemoveInvitee = (invitee) => setInviteeToRemove(invitee);
    const handleHideRemoveInviteeDialog = () => setInviteeToRemove(null);
    const handleSelectThread = (thread) => {
        const { inviteId, interlocutor } = thread;
        setSelectedThread({ inviteId, interlocutorId: interlocutor.id });
    };
    const handleCloseThread = () => setSelectedThread(null);
    const handleShareExternally = () => {
        refetchThreads();
        refetchExternalSharingInvites();
    };
    if (error) {
        return <LoadErrorAlert />;
    }
    if (isNotSupported) {
        return <NotSupported onClose={onClose} onGoBack={onGoBack}/>;
    }
    if (loading) {
        return <SidebarSectionLoader />;
    }
    if (selectedThread) {
        return <MessageThread {...selectedThread} documentId={documentId} onClose={onClose} onGoBack={handleCloseThread}/>;
    }
    return (<DrawerContainer>
      <DocumentDrawerHeader type="nested" title={getTitle('external-sharing')} onClose={onClose} onGoBack={onGoBack}/>
      <OuterContainer>
        {showShareDialog && <ShareDocumentDialog onClose={handleHideShareDialog} onSubmit={handleShareExternally}/>}
        {inviteeToRemove && (<RemoveExternalSharingInviteDialog id={inviteeToRemove.id} email={inviteeToRemove.email} onClose={handleHideRemoveInviteeDialog}/>)}
        <InnerContainer>
          {!threads.length && !pendingInvites.length ? (<EmptyState withAvatar icon={<Falcon icon="share-all"/>} title={t({
                message: 'Not shared',
                comment: 'title for when a document is not being shared with any external users',
            })} description={t({
                message: 'This document is not currently being shared',
                comment: 'Message for when a document is not being shared with any external users',
            })}/>) : (<List>
              {pendingInvites.map((invite) => (<PendingInvitee key={invite.id} id={invite.id} email={invite.inviteeEmail} onRemove={handleRemoveInvitee}/>))}
              {threads.map((thread) => (<Invitee key={thread.interlocutor.id} inviteId={thread.inviteId} thread={thread} onClick={handleSelectThread} onRemove={handleRemoveInvitee}/>))}
            </List>)}
        </InnerContainer>
        <ShareButton onClick={handleShowShareDialog}/>
      </OuterContainer>
    </DrawerContainer>);
}
