import { useState } from 'react';
import { t } from '@lingui/macro';
import { SidebarMenuFooter, SidebarMenuUser } from '@pocketlaw/tetris';
import { UserAccountMenuContent } from 'app/domains/users';
import { useMobile } from 'shared/domains/common-ui';
import { useAccount } from 'shared/domains/users';
import { StyledMenu } from './styled';
export function Footer() {
    var _a, _b;
    const { data: accountData } = useAccount();
    const [open, setOpen] = useState(false);
    const isMobile = useMobile();
    const userName = ((_a = accountData.profile) === null || _a === void 0 ? void 0 : _a.name) || '';
    const companyName = ((_b = accountData.company) === null || _b === void 0 ? void 0 : _b.name) || '';
    const minimizedTooltip = t({
        comment: 'Sidebar menu - User settings tooltip label',
        message: 'User settings',
    });
    const handleCloseMenu = () => setOpen(false);
    const handleToggleMenu = () => setOpen((prev) => !prev);
    return (<SidebarMenuFooter>
      <StyledMenu open={open} onClose={handleCloseMenu} options={{
            placement: isMobile ? 'top' : 'right',
        }} target={<SidebarMenuUser userName={userName} companyName={companyName} minimizedTooltip={minimizedTooltip} onClick={handleToggleMenu}/>}>
        <UserAccountMenuContent onClose={handleCloseMenu}/>
      </StyledMenu>
    </SidebarMenuFooter>);
}
