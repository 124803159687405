var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { captureMessage, withScope } from 'shared/domains/sentry';
import { htmlToPdfMutation } from './mutation';
import { useRenderInput } from '../usePlastRenderInput';
export const useHtmlToPdf = () => {
    const getRenderInput = useRenderInput();
    const [mutate] = useMutation(htmlToPdfMutation);
    const htmlToPdf = (options) => __awaiter(void 0, void 0, void 0, function* () {
        const { documentId, companyId, name } = options;
        const renderInput = yield getRenderInput({ companyId, documentId });
        if (!renderInput) {
            withScope((scope) => {
                scope.setTag('documentId', documentId);
                captureMessage('Unable to retrieve render input for html to pdf conversion', {
                    level: 'warning',
                });
            });
            return null;
        }
        const { content, logoUrl } = renderInput;
        const { data } = yield mutate({
            variables: {
                companyId,
                content,
                logoUrl,
                name,
            },
        });
        const files = (data === null || data === void 0 ? void 0 : data.response) || [];
        return files[0];
    });
    return { htmlToPdf };
};
