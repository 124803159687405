var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { Dialog, DialogHeader, DialogContent, DialogFooter, Button, Loader, Typography } from '@pocketlaw/tetris';
import { useHistory } from 'react-router-dom';
import { useLastApprovalRequest } from 'app/domains/approval/hooks/useLastApprovalRequest';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { CcsDocumentFlushReason } from 'shared/domains/apollo/generated/types';
import { documentFlush, useActiveDocument } from 'shared/domains/documents';
import { ReloadIndicator } from './ReloadIndicator';
import { useGetUsers } from './useGetUsers';
import { useReasonTexts } from './useReasonTexts';
import { useSetDocumentTypeToFreeText } from './useSetDocumentTypeToFreeText';
export function FlushInfoModal(props) {
    const { reason, initiatorUserId } = props;
    const { id: documentId } = useActiveDocument();
    const { setDocumentTypeToFreeText } = useSetDocumentTypeToFreeText();
    const { refetch: refetchLastApprovalRequest } = useLastApprovalRequest(documentId);
    const { refetch: refetchLastSignRequest } = useLastSignRequest();
    const { users } = useGetUsers();
    const history = useHistory();
    const currentUser = users.find((user) => user.id === initiatorUserId);
    const texts = useReasonTexts(reason, currentUser === null || currentUser === void 0 ? void 0 : currentUser.name);
    const [loading, setLoading] = useState(false);
    const showReloadIndicator = reason === CcsDocumentFlushReason.NewBundleVersion || reason === CcsDocumentFlushReason.UserForced;
    const { modalTitle, modalContent, buttonLabel } = texts || {};
    /**
     * We should only display this component if
     * - There is a text
     * AND
     * - There is a new bundle version or user forced flush
     * OR
     * - The flush was not initiated by the current user
     */
    const shouldDisplay = () => {
        if (!texts) {
            return false;
        }
        if (reason === CcsDocumentFlushReason.NewBundleVersion || reason === CcsDocumentFlushReason.UserForced) {
            return true;
        }
        return !currentUser;
    };
    const handleRefetchLastApprovalRequest = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        yield refetchLastApprovalRequest();
        setLoading(false);
        documentFlush({ state: 'not-initialized' });
    });
    const handleRefetchLastSignRequest = () => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        yield refetchLastSignRequest({ documentId });
        setLoading(false);
        documentFlush({ state: 'not-initialized' });
    });
    const handleReloadWindow = () => {
        setLoading(true);
        window.location.reload();
    };
    const handleClick = () => {
        switch (reason) {
            case CcsDocumentFlushReason.Converted:
                setDocumentTypeToFreeText(documentId);
                documentFlush({ state: 'not-initialized' });
                break;
            case CcsDocumentFlushReason.EditingWithTemplate:
            case CcsDocumentFlushReason.Deleted:
                history.push('/files');
                documentFlush({ state: 'not-initialized' });
                break;
            case CcsDocumentFlushReason.Locked:
            case CcsDocumentFlushReason.Unlocked:
                handleRefetchLastApprovalRequest();
                break;
            case CcsDocumentFlushReason.SigningStatusPending:
            case CcsDocumentFlushReason.SigningStatusCancelled:
            case CcsDocumentFlushReason.SigningStatusRejected:
                handleRefetchLastSignRequest();
                break;
            case CcsDocumentFlushReason.NewBundleVersion:
            case CcsDocumentFlushReason.UserForced:
                handleReloadWindow();
                break;
            default:
                handleReloadWindow();
                break;
        }
    };
    if (shouldDisplay() === false) {
        return null;
    }
    return (<Dialog size="sm">
      <DialogHeader title={modalTitle}/>
      <DialogContent>
        <Typography>{modalContent}</Typography>
        {showReloadIndicator && <ReloadIndicator />}
      </DialogContent>

      <DialogFooter>
        <Button appearance="primary" disabled={loading} onClick={handleClick}>
          {loading && <Loader />}
          {buttonLabel}
        </Button>
      </DialogFooter>
    </Dialog>);
}
