import { useMemo } from 'react';
import { v4 } from 'uuid';
import { useLastSignRequest } from 'app/domains/esigning/hooks/useLastSignRequest';
import { SignatoryStatus } from 'shared/domains/apollo/generated/types';
export const useCcRecipients = () => {
    const { lastSignRequest } = useLastSignRequest();
    const ccRecipients = (lastSignRequest === null || lastSignRequest === void 0 ? void 0 : lastSignRequest.ccRecipients) || [];
    return useMemo(() => ccRecipients.map((recipient) => (Object.assign(Object.assign({}, recipient), { id: v4(), status: SignatoryStatus.Pending }))), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastSignRequest]);
};
