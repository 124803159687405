import { Button, marginRight, padding, unit, SectionSubHeading, themeGet } from '@pocketlaw/tetris';
import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${themeGet('colors.gray.50')};
  ${padding(3)};
  border-radius: ${unit(2)};
`;
export const TooltipButton = styled(Button) `
  width: ${unit(5)};
  height: ${unit(5)};
  color: ${themeGet('colors.gray.600')};
  ${marginRight(4)};
`;
export const StyledSectionSubHeading = styled(SectionSubHeading) `
  margin-right: auto;
  color: ${(props) => (props.disabled ? themeGet('colors.gray.600') : themeGet('colors.gray.800'))};
`;
export const Actions = styled.div `
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;
export const StyledButton = styled(Button) `
  min-width: ${unit(37)};
`;
