import { useState } from 'react';
import { Box, Checkbox } from '@pocketlaw/tetris';
import { ApprovalType } from 'shared/domains/apollo/generated/types';
import { Approver } from '../Approver';
import { ApproversHeader } from '../ApproversHeader';
import { Content } from '../Content';
import { RejectedByApprover } from '../RejectedByApprover';
import { RequestFooter } from '../RequestFooter';
import { SendForApprovalModal } from '../SendForApprovalModal';
export function RequestOneOf(props) {
    const { approvers } = props;
    const [selectedApproverUserIds, setSelectedApproverUserIds] = useState([]);
    const [renderDialog, setRenderDialog] = useState(false);
    const noApprovers = approvers.length === 0;
    const disableSubmit = noApprovers || selectedApproverUserIds.length === 0;
    const selectedApprovers = approvers.filter((approver) => selectedApproverUserIds.includes(approver.userId));
    const hideDialog = () => setRenderDialog(false);
    const showDialog = () => setRenderDialog(true);
    const handleToggleApprover = (id) => {
        if (selectedApproverUserIds.includes(id)) {
            setSelectedApproverUserIds(selectedApproverUserIds.filter((approverId) => approverId !== id));
        }
        else {
            setSelectedApproverUserIds([...selectedApproverUserIds, id]);
        }
    };
    return (<Box flexDirection="column" height="100%">
      <Content>
        <RejectedByApprover />
        <Box flexDirection="column" gap="md">
          <ApproversHeader />
          {approvers.map((approver) => (<Approver key={approver.userId} name={approver.name} subtitle={approver.email} endElement={<Checkbox checked={selectedApproverUserIds.includes(approver.userId)} onChange={() => handleToggleApprover(approver.userId)}/>}/>))}
        </Box>
      </Content>
      <RequestFooter approvalType="one-of" showNoApproversAlert={noApprovers} disabled={disableSubmit} onSubmit={showDialog}/>
      {renderDialog && (<SendForApprovalModal approvalType={ApprovalType.OneOf} selectedApprovers={selectedApprovers} onClose={hideDialog}/>)}
    </Box>);
}
