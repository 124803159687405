import { useState } from 'react';
import { Box, Chip, ChipAction, Menu, MenuBody, MenuItem, MenuList, Typography } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useFileBrowserContext } from 'app/domains/filesystem';
export function SelectedEntries() {
    const { selected, onSelect, selectFolder } = useFileBrowserContext();
    const [showMore, setShowMore] = useState(false);
    const handleDeselect = (entry) => {
        onSelect(entry);
    };
    const handleNavigate = (entry) => {
        if (entry.parentId) {
            selectFolder(entry.parentId);
        }
    };
    if (!selected.length) {
        return null;
    }
    const entriesToDisplay = selected.slice(0, 5);
    const excludedEntries = selected.slice(5);
    const excludedAmount = excludedEntries.length;
    return (<Box flexDirection="column" px="2xl" gap="md" mt="md">
      <Typography $fontWeight="bold" $fontSize="small">
        {selected.length} selected files
      </Typography>
      <Box gap="md" style={{ flexWrap: 'wrap' }}>
        {entriesToDisplay.map((entry) => (<Chip key={entry.id} label={entry.name} size="small" appearance="secondary" onClick={() => handleNavigate(entry)} action={<ChipAction type="button" onClick={() => handleDeselect(entry)}>
                <Falcon icon="remove"/>
              </ChipAction>}/>))}
        {excludedAmount > 0 && (<Menu open={showMore} onClose={() => setShowMore(false)} target={<Chip label={`+${excludedAmount} more`} size="small" appearance="secondary" onClick={() => setShowMore(!showMore)}/>}>
            <MenuBody>
              <MenuList>
                {excludedEntries.map((entry) => (<MenuItem key={entry.id} onClick={() => handleDeselect(entry)}>
                    {entry.name}
                  </MenuItem>))}
              </MenuList>
            </MenuBody>
          </Menu>)}
      </Box>
    </Box>);
}
