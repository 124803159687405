import { produce } from 'immer';
const trimWhitespace = (draft) => ([key, value]) => {
    if (typeof value === 'string') {
        // eslint-disable-next-line no-param-reassign
        draft[key] = value.trim();
    }
};
const toRefObject = ([ref, value]) => ({ ref, value });
function formatDraftData(base) {
    return produce(base, (draft) => {
        const trim = trimWhitespace(draft);
        Object.entries(draft).forEach(trim);
    });
}
export function getFormattedValues(values) {
    const formatted = formatDraftData(values);
    return Object.entries(formatted).map(toRefObject);
}
