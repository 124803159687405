import gql from 'graphql-tag';
import { PromptLibraryFragment } from '../../PromptLibraryProvider/usePrompts/query';
export const updatePrompt = gql `
  mutation updatePrompt($id: UUID!, $input: UpdateDocumentAssistantPromptInput!) {
    documentAssistantPrompt: updateDocumentAssistantPrompt(id: $id, input: $input) {
      ...PromptLibrary @unmask
    }
  }

  ${PromptLibraryFragment}
`;
export const createPrompt = gql `
  mutation createPrompt($input: CreateDocumentAssistantPromptInput!) {
    documentAssistantPrompt: createDocumentAssistantPrompt(input: $input) {
      ...PromptLibrary @unmask
    }
  }
  ${PromptLibraryFragment}
`;
