import { gql } from '@apollo/client';
import { AutoTagEntryFragment } from './fragment';
export const extractDocumentMetadata = gql `
  mutation ExtractDocumentMetadata($documentIds: [ID!]!) {
    newQueueEntries: createDocumentMetadataAiAutoTaggingQueueEntries(
      input: { documentIds: $documentIds }
    ) {
      ... AutoTagEntryFragment @unmask
    }
    ${AutoTagEntryFragment}
  }
`;
