import { t } from '@lingui/macro';
import { FILE_EXTENSION_MAP } from './maps';
import { SupportedFileExtensions } from './types';
export const getFileTypeName = (options) => {
    const nameMap = {
        [SupportedFileExtensions.DOCX]: t({ message: 'DOCX', comment: 'Name of .docx file' }),
        [SupportedFileExtensions.PDF]: t({ message: 'PDF', comment: 'Name of .pdf file' }),
        [SupportedFileExtensions.JPEG]: t({ message: 'JPEG', comment: 'Name of .jpeg file' }),
        [SupportedFileExtensions.PNG]: t({ message: 'PNG', comment: 'Name of .png file' }),
    };
    const { mimeType, extension } = options;
    if (mimeType) {
        return nameMap[FILE_EXTENSION_MAP[mimeType]];
    }
    if (extension) {
        return nameMap[extension];
    }
    return '';
};
