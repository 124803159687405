import { v4 } from 'uuid';
import { MAX_FILE_UPLOAD_SIZE_BYTES, UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES, FILE_MIME_TYPE_MAP, } from 'shared/domains/filesystem';
const isFileTypeSupported = (type) => UPLOAD_DOCUMENT_SUPPORTED_MIME_TYPES.includes(type);
const isFileSizeSupported = (size) => size <= MAX_FILE_UPLOAD_SIZE_BYTES;
const getExtensionFromPath = (path) => `.${path.split('.').pop()}`;
const getTypeFromExtension = (extension) => FILE_MIME_TYPE_MAP[extension] || '';
const getTypeFromPath = (path) => getTypeFromExtension(getExtensionFromPath(path));
const getNameWithoutExtension = (name) => name.replace(/\.[^/.]+$/, '');
export const removeDotPrefixedFiles = (fileWrapper) => !fileWrapper.originalFile.name.match(/^\./);
export const mapFileWrapper = (file) => ({
    originalFile: file,
    fullPath: file.webkitRelativePath,
});
export const mapDropZoneFiles = (fileWrapper) => {
    const { fullPath, originalFile } = fileWrapper;
    const { name, type, size } = originalFile;
    const id = v4();
    const path = fullPath || name;
    const extension = getExtensionFromPath(path);
    const normalizedType = type || getTypeFromPath(path);
    const normalizedName = getNameWithoutExtension(name);
    const base = {
        id,
        size,
        path,
        extension,
        originalFile,
        name: normalizedName,
        type: normalizedType,
    };
    if (!isFileTypeSupported(base.type)) {
        return Object.assign(Object.assign({}, base), { supported: false, unsupportedReason: 'fileType' });
    }
    if (!isFileSizeSupported(base.size)) {
        return Object.assign(Object.assign({}, base), { supported: false, unsupportedReason: 'fileSize' });
    }
    return Object.assign(Object.assign({}, base), { supported: true });
};
