import { gql } from '@apollo/client';
import { CompanyDetailsFragment } from './fragment';
export const companyDetails = gql `
  query CompanyDetails($id: UUID!) {
    company(id: $id) {
      ...CompanyDetailsFragment @unmask
    }
    ${CompanyDetailsFragment}
  }
`;
