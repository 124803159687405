import { Fragment, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Button } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { ConfirmRemoveEsigningDialog } from 'app/domains/esigning/components/ESignPendingState/CancelSignRequestButton/ConfirmCancelEsigningDialog';
import { useEsigning } from 'app/domains/esigning/components/EsigningProvider';
export function CancelSignRequestButton() {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const { cancelSignRequest } = useEsigning();
    const hideDialog = () => setShowConfirmDialog(false);
    const showDialog = () => setShowConfirmDialog(true);
    const handleClick = () => {
        showDialog();
    };
    const handleSubmit = () => {
        hideDialog();
        cancelSignRequest();
    };
    return (<Fragment>
      {showConfirmDialog && <ConfirmRemoveEsigningDialog onCancel={hideDialog} onSubmit={handleSubmit}/>}
      <Button $stretch type="button" appearance="secondary" onClick={handleClick}>
        <Falcon icon="xmark"/>
        <Trans comment="Label for canceling sent e-signing document">Cancel eSigning</Trans>
      </Button>
    </Fragment>);
}
