import { dashboardTemplates, useInsightsTemplates } from './Context';
import { Widget } from '../Widget';
import { WidgetGridLayout } from '../WidgetGrid/WidgetGridLayout';
export function TemplatePreview() {
    const { selected } = useInsightsTemplates();
    const template = dashboardTemplates.find((mock) => mock.id === selected);
    const { slots } = template || {};
    if (!slots) {
        return null;
    }
    const layouts = {
        lg: slots.map((slot) => ({
            i: slot.id,
            x: slot.x,
            y: slot.y,
            w: slot.w,
            h: slot.h,
            widgetId: slot.widgetId,
            static: true,
        })),
    };
    return (<WidgetGridLayout layouts={layouts}>
      {slots.map((slot) => (<div key={slot.id}>
          <Widget widget={slot.widget}/>
        </div>))}
    </WidgetGridLayout>);
}
