import { useState } from 'react';
import { VoluntaryApproversContext } from './context';
export { useVoluntaryApprovers } from './hook';
export function VoluntaryApproversProvider(props) {
    const { children } = props;
    const [selectedApprovers, setSelectedApprovers] = useState([]);
    const [selectedTeamApprovers, setSelectedTeamApprovers] = useState([]);
    const [selectedApprovalType, setSelectedApprovalType] = useState();
    const getUniqueSelectedUsers = () => {
        const map = {};
        selectedApprovers.forEach((approver) => {
            map[approver.userId] = {
                name: approver.name,
                userId: approver.userId,
            };
        });
        selectedTeamApprovers.forEach((teamApprover) => {
            var _a;
            (_a = teamApprover.members) === null || _a === void 0 ? void 0 : _a.forEach((member) => {
                map[member.userId] = {
                    name: member.name,
                    userId: member.userId,
                };
            });
        });
        return Object.values(map);
    };
    const selectedUsers = getUniqueSelectedUsers();
    const addApprovers = (newApprovers) => {
        setSelectedApprovers([...selectedApprovers, ...newApprovers]);
    };
    const addTeamApprovers = (newTeamApprovers) => {
        setSelectedTeamApprovers([...selectedTeamApprovers, ...newTeamApprovers]);
    };
    const removeApprover = (userId) => {
        setSelectedApprovers(selectedApprovers.filter((approver) => approver.userId !== userId));
    };
    const removeTeamApprover = (teamId) => {
        setSelectedTeamApprovers(selectedTeamApprovers.filter((teamApprover) => teamApprover.teamId !== teamId));
    };
    const value = {
        selectedUsers,
        selectedApprovers,
        selectedTeamApprovers,
        selectedApprovalType,
        addApprovers,
        addTeamApprovers,
        removeApprover,
        removeTeamApprover,
        setSelectedApprovalType,
    };
    return <VoluntaryApproversContext.Provider value={value}>{children}</VoluntaryApproversContext.Provider>;
}
