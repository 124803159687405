import { localeConfig } from 'shared/domains/languages/config';
export function findLocalizedMetadata(data, options) {
    const { languageCode } = localeConfig.getActiveLocale();
    if (!data || !data.length) {
        return data;
    }
    const extractedData = data.map((metadataObject) => {
        const isReference = options === null || options === void 0 ? void 0 : options.isReference(metadataObject);
        if (!isReference) {
            return metadataObject;
        }
        const { __ref } = metadataObject;
        // eslint-disable-next-line no-underscore-dangle
        const extracted = options.cache.extract()[__ref];
        return extracted;
    });
    const byCode = (code) => (metadata) => metadata.market_locale_code === code || metadata.marketLocaleCode === code;
    const localizedMetadata = extractedData.find(byCode(languageCode));
    const fallbackMetadata = extractedData.find(byCode('en'));
    const match = localizedMetadata || fallbackMetadata;
    const matchedMetadata = match ? [match] : data;
    return matchedMetadata;
}
