import { useQuery } from '@apollo/client';
import { useAuth } from 'app/domains/auth/hooks/useAuth';
import { QUERY } from './query';
import { useExcludedRoutes } from './useExcludedRoutes';
import { extractFeatures } from './utils';
export const useGetCompanyFeatures = (companyId) => {
    const { data: authData } = useAuth();
    const { authenticated, profile } = authData || {};
    const excludedRoute = useExcludedRoutes();
    const { verified } = profile || {};
    const skip = !companyId || !authenticated || !verified || excludedRoute;
    const { data, loading, error } = useQuery(QUERY, {
        variables: { companyId: companyId || '' },
        skip,
    });
    const { company } = data || {};
    const { esigningFeatures = [], features = [], assistantFeatures = [], userFeatures = [], webApiFeatures = [], } = company || {};
    const companyFeatures = features !== null && features !== void 0 ? features : [];
    const transformedFeatures = [
        ...extractFeatures(companyFeatures),
        ...extractFeatures(esigningFeatures),
        ...extractFeatures(assistantFeatures),
        ...extractFeatures(userFeatures),
        ...extractFeatures(webApiFeatures),
    ];
    return {
        loading,
        error,
        features: transformedFeatures,
    };
};
