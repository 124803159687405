var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { upsertReminder, removeReminder } from './mutation';
import { getReminderOperation, ReminderOperation } from './utils';
export function useReminder() {
    const [upsertMutation, upsertState] = useMutation(upsertReminder);
    const [removeMutation, removeState] = useMutation(removeReminder);
    const isLoading = upsertState.loading || removeState.loading;
    const hasError = upsertState.error || removeState.error;
    const upsert = (task) => {
        const { id, dueDate, taskId, interval } = task;
        return upsertMutation({
            variables: {
                object: {
                    id,
                    due_date: dueDate,
                    task_id: taskId,
                    interval: interval || null,
                },
            },
        });
    };
    const mutation = (reminder) => __awaiter(this, void 0, void 0, function* () {
        const reminderOperation = getReminderOperation(reminder);
        if (reminderOperation === ReminderOperation.create || reminderOperation === ReminderOperation.update) {
            yield upsert(reminder);
        }
        if (reminderOperation === ReminderOperation.remove) {
            yield removeMutation({
                variables: {
                    id: reminder === null || reminder === void 0 ? void 0 : reminder.id,
                },
            });
        }
    });
    return {
        error: hasError,
        loading: isLoading,
        mutation,
    };
}
