import { Fragment, createContext } from 'react';
import { useField } from 'formik';
import { DefaultNonSigningSignatories } from './DefaultNonSigningSignatories';
import { DefaultSigningSignatories } from './DefaultSigningSignatories';
import { EmptySignatoriesState } from './EmptySignatoriesState';
export const DefaultSignatoriesContext = createContext(null);
export function DefaultSignatories() {
    const [{ value: signatories }, , helpers] = useField('signatories');
    const addSignatory = (addedSignatory) => {
        helpers.setValue([...signatories, addedSignatory]);
    };
    const editSignatory = (editedSignatory) => {
        helpers.setValue(signatories.map((signatory) => {
            if (signatory.key === editedSignatory.key) {
                return editedSignatory;
            }
            return signatory;
        }));
    };
    const removeSignatory = (removedSignatory) => {
        helpers.setValue(signatories.filter((signatory) => signatory.key !== removedSignatory.key));
    };
    const value = {
        signatories,
        addSignatory,
        editSignatory,
        removeSignatory,
    };
    return (<DefaultSignatoriesContext.Provider value={value}>
      {signatories.length === 0 ? (<EmptySignatoriesState />) : (<Fragment>
          <DefaultSigningSignatories />
          <DefaultNonSigningSignatories />
        </Fragment>)}
    </DefaultSignatoriesContext.Provider>);
}
