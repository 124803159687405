var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AcknowledgeDocumentFlushDocument } from './mutation.gql';
export function acknowledgeDocumentFlush(mutate, input) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield mutate({
                mutation: AcknowledgeDocumentFlushDocument,
                variables: input,
            });
            return (_a = data === null || data === void 0 ? void 0 : data.acknowledgement) !== null && _a !== void 0 ? _a : null;
        }
        catch (_b) {
            return null;
        }
    });
}
