import { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Button, LoaderOverlay, Menu } from '@pocketlaw/tetris';
import { Falcon } from '@pocketlaw/tetris/macro';
import { useTeamMembers, useTeamRole, useAddTeamMembers } from 'app/domains/teams';
import { UserAndTeamSelect, useGetCompanyUsers, useRole } from 'app/domains/users';
import { ErrorAlert } from './ErrorAlert';
import { ButtonText } from './styled';
export function AddTeamMembersButton(props) {
    const submitButtonLabel = t({
        message: 'Add to team',
        comment: 'add team members dropdown: confirm button text',
    });
    const { teamId } = props;
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const { addTeamMembers } = useAddTeamMembers();
    const role = useRole();
    const teamRole = useTeamRole(teamId);
    const canAddMembers = role.can('invite:team_member') || teamRole.can('invite:team_member');
    const { teamMembers, loading: loadingTeamMembers, error: errorTeamMembers } = useTeamMembers(teamId);
    const { companyUsers, loading: loadingCompanyUsers, error: errorCompanyUsers, } = useGetCompanyUsers({
        search,
        sort: { id: 'name', order: 'asc' },
    });
    const loading = loadingTeamMembers || loadingCompanyUsers;
    const error = errorTeamMembers || errorCompanyUsers;
    const existingUserIds = teamMembers.map(({ userId }) => userId);
    const users = companyUsers
        .map(({ userId, name }) => ({
        id: userId,
        name,
    }))
        .filter(({ id }) => !existingUserIds.includes(id));
    const handleSearch = (searchString) => {
        setSearch(searchString);
    };
    const handleSubmit = (data) => {
        const { selectedUserIds } = data;
        return addTeamMembers(teamId, selectedUserIds).then(() => {
            setOpen(false);
        });
    };
    if (!canAddMembers) {
        return null;
    }
    return (<Menu target={<Button appearance="primary" onClick={() => setOpen(!open)}>
          <Falcon icon="add"/>
          <ButtonText>
            <Trans comment="add team members dropdown button text">Add member</Trans>
          </ButtonText>
        </Button>} onClose={() => setOpen(false)} open={open}>
      <LoaderOverlay enabled={loading}>
        {error ? (<ErrorAlert />) : (<UserAndTeamSelect users={users} search={search} onSearch={handleSearch} onSubmit={handleSubmit} submitButtonLabel={submitButtonLabel}/>)}
      </LoaderOverlay>
    </Menu>);
}
