import { Box } from '@pocketlaw/tetris';
import { AddFilterButton, useSearchContext } from 'app/domains/global-search';
import { ActionContainer, FilterContainer } from './styled';
export function SearchFiltersToolbar(props) {
    const { filterItems, actions, filters, exclude } = props;
    const { activeFilters } = useSearchContext();
    const allFilters = filterItems
        .filter((item) => !(exclude === null || exclude === void 0 ? void 0 : exclude.includes(item.id)))
        .map((item) => (Object.assign(Object.assign({}, item), { active: activeFilters.includes(item.id) })));
    const filtersToRender = allFilters.filter((item) => item.active);
    const searchableItems = allFilters
        .filter((item) => !item.exclude)
        .map(({ id, name }) => ({ id, name }))
        .sort((a, b) => a.name.localeCompare(b.name));
    return (<Box flexDirection="column" gap="md">
      <ActionContainer>
        <AddFilterButton filterItems={searchableItems}/>
        {actions}
      </ActionContainer>

      <FilterContainer>
        {filters}

        {filtersToRender.map(({ id, component: Component }) => (<Component key={id}/>))}
      </FilterContainer>
    </Box>);
}
