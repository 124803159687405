var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { zAction, zServerSentMessage } from './utils';
// This implements a temporary chat system for the review chat.
// It maintains ephemeral client side chat message state which is sent to the server.
// The server is currently stateless in regards to review chat conversations.
export const useChat = (chatRequest) => {
    const [messages, setMessages] = useState([]);
    const [actions, setActions] = useState({});
    // Functions derived from setMessages
    const appendToLastMessage = useCallback((text) => {
        setMessages((msgs) => {
            const lastMessage = msgs[msgs.length - 1];
            if (lastMessage) {
                return [
                    ...msgs.slice(0, -1),
                    Object.assign(Object.assign({}, lastMessage), { message: lastMessage.message + text }),
                ];
            }
            return msgs;
        });
    }, [setMessages]);
    const setLastMessageDone = useCallback(() => {
        setMessages((msgs) => {
            const lastMessage = msgs[msgs.length - 1];
            if (lastMessage) {
                return [
                    ...msgs.slice(0, -1),
                    Object.assign(Object.assign({}, lastMessage), { writing: false }),
                ];
            }
            return msgs;
        });
    }, [setMessages]);
    const appendMessages = useCallback((newMessages) => setMessages((prev) => [...prev, ...newMessages]), [setMessages]);
    const chat = useCallback((userPrompt) => __awaiter(void 0, void 0, void 0, function* () {
        const REVIEW_CHAT_URL = process.env.ASSISTANT_CHAT_STREAM_URL;
        const ASSISTANT_MESSAGE_ID = uuid();
        // Append user message and system message target for chunk appending
        // When sending to API we only include the user message
        const newMessages = [
            {
                messageId: uuid(),
                role: 'user',
                message: userPrompt,
                writing: false,
            },
            {
                messageId: ASSISTANT_MESSAGE_ID,
                role: 'assistant',
                message: '',
                writing: true,
            },
        ];
        appendMessages(newMessages);
        // Request chat message and retrieve token for chat stream
        // ! We include the user chat message, but not the assistant pending message
        const token = yield chatRequest([...messages, newMessages[0]]);
        if (token) {
            // Open SSE stream for chat messages
            const evtSource = new EventSource(`${REVIEW_CHAT_URL}/chat/${token}`);
            evtSource.onmessage = (event) => {
                // TODO - ACTUALLY PARSE - ASK FOR ZOD
                const [, [kind, content]] = zServerSentMessage.parse(JSON.parse(event.data)); // ! TODO: Safe parse
                switch (kind) {
                    case 'MSG':
                        appendToLastMessage(content);
                        break;
                    case 'ACTION': {
                        const action = zAction.safeParse(JSON.parse(content));
                        if (action.success) {
                            setActions((prevActions) => {
                                const messageActions = prevActions[ASSISTANT_MESSAGE_ID] || {
                                    followUpSuggestions: [],
                                    rewriteSuggestions: {},
                                };
                                if (action.data.type === 'followUpSuggestions') {
                                    messageActions.followUpSuggestions = action.data.payload.followUpQuestions;
                                }
                                if (action.data.type === 'rewriteSuggestions') {
                                    messageActions.rewriteSuggestions = Object.assign(Object.assign({}, messageActions.rewriteSuggestions), { [action.data.payload.id]: action.data.payload });
                                }
                                return Object.assign(Object.assign({}, prevActions), { [ASSISTANT_MESSAGE_ID]: messageActions });
                            });
                        }
                        break;
                    }
                    default:
                        break;
                }
            };
            // ! The server will close the connection after sending the end token,
            // ! this is treated as an error state by the EventSource, and we thus
            // ! close the connection.
            evtSource.onerror = () => {
                evtSource.close();
                setLastMessageDone();
            };
        }
    }), [appendMessages, chatRequest, messages, appendToLastMessage, setLastMessageDone, setActions]);
    return {
        messages,
        actions,
        chat,
    };
};
