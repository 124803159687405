import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { getDocumentStatusTranslations } from 'app/domains/documents';
import { FileSystemDocumentStatus } from 'shared/domains/apollo/generated/types';
import { SearchStringFilter } from './SearchStringFilter';
export const documentStatusFilterLabel = () => t({ message: 'Document status', comment: 'Insights filter: Document category' });
export function DocumentStatusFilter() {
    const lingui = useLingui();
    const items = [
        FileSystemDocumentStatus.Unknown,
        FileSystemDocumentStatus.DraftStage,
        FileSystemDocumentStatus.ReviewStagePending,
        FileSystemDocumentStatus.AgreedStageAgreedForm,
        FileSystemDocumentStatus.SigningStagePendingNotYetSigned,
        FileSystemDocumentStatus.SigningStagePendingPartiallySigned,
        FileSystemDocumentStatus.SigningStageSigned,
    ].map((status) => ({
        id: status,
        title: lingui._(getDocumentStatusTranslations(status)),
    }));
    return <SearchStringFilter name="metadataDocumentStatus" items={items} label={documentStatusFilterLabel()}/>;
}
